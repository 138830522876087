import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    BrowserRouter as Router,
    Route,
    Redirect,
    Switch,
} from "react-router-dom";
import { loginCheck } from "./actions/login";
import { authSelector } from "./selector/auth";

import Login from "./components/auth/login";
import Home from "./components/home";
import CreateNewUser from "./components/user/create";
import UserListing from "./components/user/listing";
import UserDetails from "./components/user/details";
import CreateEvent from "./components/event/create";
import Calendar from "./components/event/calendar";
import EventDetails from "./components/event/details";
import CreateNewPlan from "./components/plan/create";
import Plans from "./components/plan/listing";
import PlanDetails from "./components/plan/details";
import RazorpayLogs from "./components/logs/razorpay";
import ExotelLogs from "./components/logs/exotel";
import PendingLoader from "./components/utils/PendingLoader";
import CreateCode from "./components/promoCode/create"
import Coupons from "./components/promoCode/listing/coupons";
import Vouchers from "./components/promoCode/listing/vouchers"
import UserBookings from "./components/user/bookings";
import ChangePassword from './components/auth/ChangePassword'
import CronJobs from "./components/cronJobs";
import TrialUsers from "./components/user/trials";
import LabTests from "./components/labTests"; 
import Webinars from "./components/webinar/listing";
import WebinarUsers from "./components/webinar/webinar_users";
import CreateWebinar from "./components/webinar/create";
import CreateTasks from "./components/tasks/createTasks";
import TaskListing from "./components/tasks/taskListing";
import CreateChallenge from "./components/challenges/CreateChallenge";
import ChallengeListing from "./components/challenges/ChallengeListing";
import ChallengeTaskListing from "./components/challenges/ChallengeTaskListing";
import ChallengeLeaderboard from "./components/challenges/ChallengeLeaderboard";
import ChallengeUserDetails from "./components/challenges/ChallengeUserDetails";
import ContentItems from "./components/content/contentItems";
import Topics from "./components/content/topics";
import CreateContent from "./components/content/createContent";
import EventsV2 from "./components/event/eventsV2";
import Payments from "./components/payments";
import PaymentsPlan from "./components/paymentsPlan";
import CreatePaymentRecord from "./components/paymentsPlan/createPaymentRecord";
import ChallengeEngagement from "./components/challenges/ChallengeEngagement";
import ExpertList from "./components/experts/ExpertList";
import CreateExpert from "./components/experts/CreateExpert";
import ExpertDetails from "./components/experts/ExpertDetails";
import LabTestsListing from "./components/labTests/LabTestsListing";
import CreateLabTest from "./components/labTests/CreateLabTest";
import SKU from "./components/SKUs";
import Orders from "./components/orders";
import OrderDetails from "./components/orders/listing";
import DoctorsListing from "./components/doctorFacingTech/doctors";
import CreateDoctor from "./components/doctorFacingTech/createDoctor";
import SKUForm from "./components/SKUs/skuForm";
import PersonalChallengeEngagement from "./components/challenges/personalChallengeEngagement";
import CouponsForm from "./components/promoCode/edit/couponsForm";
import VouchersForm from "./components/promoCode/edit/vouchersForm";


const App = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "/assets/js/app.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    return (
        <Router>
            <div id="wrapper">
                <Switch>
                    <Route path="/login" component={Login} />
                    <ProtectedRoute path="/" exact component={Home} />
                    <ProtectedRoute
                        path="/newUser"
                        exact
                        component={CreateNewUser}
                    />
                    <ProtectedRoute
                        path="/allUsers"
                        exact
                        component={UserListing}
                    />
                    <ProtectedRoute
                        path="/userDetails/:userId"
                        exact
                        component={UserDetails}
                    />
                    <ProtectedRoute
                        path="/newUser/:userId"
                        exact
                        component={CreateNewUser}
                    />
                    <ProtectedRoute
                        path="/userBookings/:userId"
                        exact
                        component={UserBookings}
                    />
                    <ProtectedRoute 
                        path="/eventsCalendar" 
                        exact 
                        component={Calendar} 
                    />
                    <ProtectedRoute
                        path="/createNewEvent"
                        exact
                        component={CreateEvent}
                    />
                    <ProtectedRoute
                        path="/createNewEvent/:eventId"
                        exact
                        component={CreateEvent}
                    />
                    <ProtectedRoute
                        path="/eventDetails/:eventId"
                        exact
                        component={EventDetails}
                    />
                    <ProtectedRoute
                        path="/createNewPlan"
                        exact
                        component={CreateNewPlan}
                    />
                    <ProtectedRoute
                        path="/createNewPlan/:planId"
                        exact
                        component={CreateNewPlan}
                    />
                    <ProtectedRoute path="/plans" exact component={Plans} />
                    <ProtectedRoute
                        path="/planDetails/:planId"
                        exact
                        component={PlanDetails}
                    />
                    <ProtectedRoute
                        path="/createCodes"
                        exact
                        component={CreateCode}
                    />
                    <ProtectedRoute
                        path="/coupons"
                        exact
                        component={Coupons}
                    />
                    <ProtectedRoute
                        path="/vouchers"
                        exact
                        component={Vouchers}
                    />
                    <ProtectedRoute
                        path="/razorpay"
                        exact
                        component={RazorpayLogs}
                    />
                    <ProtectedRoute
                        path="/exotel"
                        exact
                        component={ExotelLogs}
                    />
                    <ProtectedRoute
                        path="/changePassword"
                        exact
                        component={ChangePassword}
                    />
                    <ProtectedRoute
                        path="/cronJobs"
                        exact
                        component={CronJobs}
                    />
                    <ProtectedRoute
                        path="/trialUsers"
                        exact
                        component={TrialUsers}
                    />
                    <ProtectedRoute
                        path="/labTests"
                        exact
                        component={LabTests}
                    />
                    <ProtectedRoute
                        path="/createTask"
                        exact
                        component={CreateTasks}
                    />
                    <ProtectedRoute
                        path="/createTask/:taskId"
                        exact
                        component={CreateTasks}
                    />
                    <ProtectedRoute
                        path="/tasks"
                        exact
                        component={TaskListing}
                    />                    
                    <ProtectedRoute
                        path="/createChallenge"
                        exact
                        component={CreateChallenge}
                    />
                    <ProtectedRoute
                        path="/createChallenge/:challengeId"
                        exact
                        component={CreateChallenge}
                    />
                    <ProtectedRoute
                        path="/challenges"
                        exact
                        component={ChallengeListing}
                    />
                    <ProtectedRoute
                        path="/challenge/tasks/:challengeId"
                        exact
                        component={ChallengeTaskListing}
                    />

                    <ProtectedRoute
                        path="/challenge/:challengeId/leaderboard"
                        exact
                        component={ChallengeLeaderboard}
                    />
                    <ProtectedRoute
                        path="/challenge/:challengeId/user/:userId"
                        exact
                        component={ChallengeUserDetails}
                    />
                    <ProtectedRoute 
                        path="/webinars" 
                        exact 
                        component={Webinars} 
                    />
                    <ProtectedRoute 
                        path="/webinar/:webinarId/users" 
                        exact 
                        component={WebinarUsers} 
                    />
                    <ProtectedRoute
                        path = "/createWebinar/:webinarId"
                        exact
                        component={CreateWebinar}
                    />
                    <ProtectedRoute
                        path = "/createWebinar"
                        exact
                        component={CreateWebinar}
                    />
                    <ProtectedRoute
                        path = "/topics"
                        exact
                        component={Topics}
                    />
                    <ProtectedRoute
                        path = "/contentItems"
                        exact
                        component={ContentItems}
                    />
                    <ProtectedRoute
                        path = "/createContent/:contentId"
                        exact
                        component={CreateContent}
                    />
                    <ProtectedRoute
                        path = "/EventsV2"
                        exact
                        component={EventsV2}
                    />
                    <ProtectedRoute
                        path = "/payments"
                        exact
                        component={Payments}
                    />
                    <ProtectedRoute
                        path = "/paymentsPlan"
                        exact
                        component={PaymentsPlan}
                    />
                    <ProtectedRoute
                        path = "/createPaymentRecord"
                        exact
                        component={CreatePaymentRecord}
                    />
                    <ProtectedRoute
                        path = "/createPaymentRecord/:orderId"
                        exact
                        component={CreatePaymentRecord}
                    />
                    <ProtectedRoute
                        path = "/challengeEngagement/CHA0024"
                        exact
                        component={PersonalChallengeEngagement}
                    />
                    <ProtectedRoute
                        path = "/challengeEngagement/:challengeId"
                        exact
                        component={ChallengeEngagement}
                    />
                    <ProtectedRoute
                        path = "/experts"
                        exact
                        component={ExpertList}
                    />
                    <ProtectedRoute
                        path = "/createExpert"
                        exact
                        component={CreateExpert}
                    />
                    <ProtectedRoute
                        path = "/updateExpert/:userId"
                        exact
                        component={CreateExpert}
                    />
                    <ProtectedRoute
                        path = "/expertDetails/:userId"
                        exact
                        component={ExpertDetails}
                    />
                    <ProtectedRoute
                        path = "/LabTestsListing"
                        exact
                        component={LabTestsListing}
                    />
                    <ProtectedRoute
                        path = "/createLabTest"
                        exact
                        component={CreateLabTest}
                    />
                    <ProtectedRoute
                        path="/createLabTest/:labTestId"
                        exact
                        component={CreateLabTest}
                    />
                    <ProtectedRoute
                        path = "/SKUs"
                        exact
                        component={SKU}
                    />
                    <ProtectedRoute
                        path = "/Orders"
                        exact
                        component={Orders}
                    />
                    <ProtectedRoute
                        path="/order/:orderId"
                        exact
                        component={OrderDetails}
                    />
                    <ProtectedRoute
                        path="/doctors"
                        exact
                        component={DoctorsListing}
                    />
                    <ProtectedRoute
                        path="/createDoctor"
                        exact
                        component={CreateDoctor}
                    />
                    <ProtectedRoute
                        path="/updateDoctor/:doctorId"
                        exact
                        component={CreateDoctor}
                    />
                    <ProtectedRoute
                        path='/updateSKU/:skuId'
                        exact
                        component={SKUForm}
                    />
                    <ProtectedRoute
                        path='/createSKU'
                        exact
                        component={SKUForm}
                    />
                    <ProtectedRoute
                        path='/updateCoupon/:couponId'
                        exact
                        component={CouponsForm}
                    />
                    <ProtectedRoute
                        path='/updateVoucher/:voucherId'
                        exact
                        component={VouchersForm}
                    />
                    <ProtectedRoute path="/*" component={Home}
                     />
                </Switch>
            </div>
        </Router>
    );
};

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loginCheck());
    }, [dispatch]);
    let isAuthenticated = useSelector((state) => authSelector(state));
    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated === "pending"?<PendingLoader/>:isAuthenticated === true ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    );
};

export default App;
