import { C_TASK_ALTER, TASKS_SET } from "../../actions/tasks"

const initState = []
export const tasksReducer = (state=initState,action) =>{
    switch(action.type){
        case TASKS_SET:
            return {...state,allTasks:action.payload}
        case C_TASK_ALTER:
            return {...state,cTasks:action.payload}
        default: return state
    }
}

