import { apiRequest,API_SUCCESS,API_ERROR } from "../../../actions/api"
import {ROOT_URL} from '../../../config/'
import Alert from "sweetalert2";

import { 
    MEALS,
    MEALS_CREATE,
    MEALS_GET,
    MEAL_TEMPLATE,
    MEAL_TEMPLATE_UPDATE,
    setMeals
} from "../../../actions/meals"
import { getUserBookingsConsultLogs } from "../../../actions/user";

export const mealsMiddleware = ({dispatch}) => next => (action) =>{
    next(action)
    switch(action.type){
        case MEALS_CREATE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/generateMealTemplate`,
                method:'post',
                feature:MEALS
            }))
            break;
        
        case `${MEALS_GET}`:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/getMealPlan`,
                method:'get',
                feature:MEALS
            }))
            break;

        case `${MEALS} ${API_SUCCESS}`:
                dispatch(setMeals(action.payload))
                break;
        
        case `${MEALS} ${API_ERROR}`:
            break;

        case MEAL_TEMPLATE_UPDATE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/updateMealTemplateUrl`,
                method:'put',
                feature:MEAL_TEMPLATE
            }))
            break;
        case `${MEAL_TEMPLATE} ${API_SUCCESS}`:
            Alert.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Uploaded',
                showConfirmButton: false,
                timer: 1500
              })
              if(action.payload.userId)
              {
                dispatch(getUserBookingsConsultLogs(action.payload.userId));
              }
            break;
        case `${MEAL_TEMPLATE} ${API_ERROR}`:
            Alert.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Could not upload meal template',
                showConfirmButton: false,
                timer: 1500
              })
            break;
        default: break;
    }
}

