import { apiRequest,API_SUCCESS,API_ERROR } from "../../../actions/api"
import {ROOT_URL} from '../../../config/'
import { 
    TOPICS,
    TOPICS_GET,
    setTopics,
    setTopicsError,

    FEATURE_CREATE_TOPICS,
    TOPICS_CREATE,
    FEATURE_UPDATE_TOPICS,
    TOPICS_UPDATE,
    FEATURE_DELTE_TOPICS,
    TOPICS_DELTE,

    FEATURE_CREATE_CONTENT,
    FEATURE_DELTE_CONTENT,
    FEATURE_UPDATE_CONTENT,
    CONTENT_CREATE_REQ,
    CONTENT_UPDATE_REQ,
    CONTENT_DELETE_REQ,

    CONTENT_ITEMS,
    CONTENT_ITEMS_GET,
    setContentItemsError,
    setContentItems
} from "../../../actions/content"

export const contentMiddleware = ({dispatch}) => next => (action) =>{
    next(action)
    switch(action.type){

        case TOPICS_CREATE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/create_topic`,
                method:'post',
                feature:FEATURE_CREATE_TOPICS
            }))
            break;

        case TOPICS_UPDATE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/update_topic`,
                method:'put',
                feature:FEATURE_UPDATE_TOPICS
            }))
            break;

        case TOPICS_DELTE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/topic`,
                method:'delete',
                feature:FEATURE_DELTE_TOPICS
            }))
            break;

        case `${FEATURE_DELTE_TOPICS} ${API_SUCCESS}`:
        case `${FEATURE_UPDATE_TOPICS} ${API_SUCCESS}`:
        case `${FEATURE_CREATE_TOPICS} ${API_SUCCESS}`:
                dispatch(setTopics(action.payload))
                break;
        
        case `${FEATURE_DELTE_TOPICS} ${API_ERROR}`:
        case `${FEATURE_UPDATE_TOPICS} ${API_ERROR}`:
        case `${FEATURE_CREATE_TOPICS} ${API_ERROR}`:
                dispatch(setTopicsError(action.payload))
                console.log('could not create new user')
            break;

        case TOPICS_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/topics`,
                method:'get',
                feature:TOPICS
            }))
            break;

        case `${TOPICS} ${API_SUCCESS}`:
                dispatch(setTopics(action.payload))
                break;
        
        case `${TOPICS} ${API_ERROR}`:
                dispatch(setTopicsError(action.payload))
                console.log('could not create new user')
            break;
        
        case CONTENT_CREATE_REQ:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/create_content`,
                method:'post',
                feature:FEATURE_CREATE_CONTENT
            }))
            break;
        
        case CONTENT_DELETE_REQ:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/delete_content`,
                method:'delete',
                feature:FEATURE_DELTE_CONTENT
            }))
            break;
        
        case CONTENT_UPDATE_REQ:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/update_content`,
                method:'put',
                feature:FEATURE_UPDATE_CONTENT
            }))
            break;
        
        case CONTENT_ITEMS_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/content`,
                method:'get',
                feature:CONTENT_ITEMS
            }))
            break;

        case `${FEATURE_CREATE_CONTENT} ${API_SUCCESS}`:
        case `${FEATURE_DELTE_CONTENT} ${API_SUCCESS}`:
        case `${FEATURE_UPDATE_CONTENT} ${API_SUCCESS}`:
        case `${CONTENT_ITEMS} ${API_SUCCESS}`:
                dispatch(setContentItems(action.payload))
                break;
        
        case `${FEATURE_CREATE_CONTENT} ${API_ERROR}`:
        case `${FEATURE_DELTE_CONTENT} ${API_ERROR}`:
        case `${FEATURE_UPDATE_CONTENT} ${API_ERROR}`:
        case `${CONTENT_ITEMS} ${API_ERROR}`:
                dispatch(setContentItemsError(action.payload))
                console.log('could not create new user')
            break;

        default: break;
    }
}

