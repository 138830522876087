import { TRIAL_USERS_SET, ADMIN_LOGIN_SET, USERS_SET, FILTERED_USERS_SET, VIEW_USERS_FILTER_SET, HEALTH_COACH_SET } from "../../actions/users"

const initState = []
export const usersReducer = (state=initState,action) =>{
    switch(action.type){
        case USERS_SET:
            return {...state,users:action.payload}
        case ADMIN_LOGIN_SET:
            return {...state,adminLogin:action.payload}
        case TRIAL_USERS_SET:
            return {...state,trialUsers:action.payload}
        case FILTERED_USERS_SET:
            return {...state, filteredUsers: action.payload};
        case VIEW_USERS_FILTER_SET :
            return {...state, filter: action.payload}
        case HEALTH_COACH_SET:
            return {...state, healthCoachList: action.payload}
        default: return state
    }
}
