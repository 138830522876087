import { useEffect, useState, useRef } from "react";
import {createMeal} from "../../actions/meals"
import { useDispatch } from "react-redux";
import MealBulderMultiSelect from "./mealMultiselect";

import './planbuilder.css'

const CustomCheckBox = ({
    labelFor,
    name,
    setVal,
    val,
    compareVal,
    showVal,
}) => {
    return (
        <label htmlFor={labelFor} className="radio_label">
            <div className="checkbox-item radio_btn">
                <div className="relative">
                    <input
                        type="checkbox"
                        id={labelFor}
                        name={name}
                        value={val}
                        onChange={(e) => {
                            if (compareVal.toString() === val.toString()) {
                                setVal(-1);
                                return;
                            }
                            setVal(e.target.value);
                        }}
                        checked={compareVal.toString() === val.toString()}
                    />
                    <label className="custom_label" htmlFor={labelFor}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                        >
                            <path d="M0 0v24h24v-24h-24zm10.041 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" />
                        </svg>
                    </label>
                </div>
                <div className="meal-builder-diet-preference-val">
                    {showVal}
                </div>
            </div>
        </label>
    );
};

const CustomRadio = ({
        labelFor,
        name,
        setVal,
        val,
        compareVal,
        showVal,
        diagnosedConditions,
        fasting
    }) => {
        // TODO- this is just a jugad
        const isDisabled =
            diagnosedConditions.toString() === "1" &&
            fasting.toString() === "0" &&
            showVal === "Yes";

        return (
            <label htmlFor={labelFor} className="radio_label">
                <div className="radio-item radio_btn">
                    <div className="relative">
                        <input
                            type="radio"
                            id={isDisabled ? "" : labelFor}
                            name={name}
                            value={val}
                            onChange={(e) => {
                                setVal(e.target.value);
                            }}
                            checked={compareVal.toString() === val.toString()}
                        />
                        <label
                            className="custom_label"
                            htmlFor={labelFor}
                        ></label>
                    </div>
                    <div className="meal-builder-diet-preference-val">
                        {showVal}
                    </div>
                </div>
            </label>
        );
    };



const MealBuilder = (props) => {
    const dispatch = useDispatch();

    const [phoneNumber] = useState(props.userDetails.callingNumber || "");
    const [name, setName] = useState(`${props.userDetails.firstName} ${props.userDetails.lastName ? props.userDetails.lastName : ''}` ||"");
    const [age, setAge] = useState(parseInt(props.userDetails.age) || "");
    const [location, setLocation] = useState(props.userDetails.city || "");
    const [height, setHeight] = useState(parseInt(props.userDetails.height) || "");
    const [weight, setWeight] = useState(parseInt(props.userDetails.weight) || "");
    const [dietPreference, setDietPreference] = useState(-1);
    const [cusine, setCusine] = useState(-1);
    const [diagnosedConditions, setDiagnosedConditions] = useState(-1);
    const [fasting, setFasting] = useState(-1);

    const [leaflets, setLeaflets] = useState();
    const [labtest, setLabtests] = useState();
    const [supplement, setSupplement] = useState();
    const leafletsRef = useRef(null);
    const labtestRef = useRef(null);
    const supplementRef = useRef(null);

    const [nutritionistName, setNutritionistName] = useState("");
    const [notes, setNotes] = useState("");

    // const [intolerance, setInTolerance] = useState("");
    const [gluten, setGluten] = useState(-1);
    const [dairy, setDairy] = useState(-1);
    const [nuts, setNuts] = useState(-1);

    const [isSubmissionAllowed, setIsSubmissionAllowed] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isMealPlanBuild, setIsMealPlanBuild] = useState(false);

    const createMealPlan = async () => {
        setIsMealPlanBuild(true);
        setIsModalOpen(true);
        let intolerances = [];
        if (gluten !== -1) {
            intolerances.push(parseInt(gluten));
        }
        if (dairy !== -1) {
            intolerances.push(parseInt(dairy));
        }
        if (nuts !== -1) {
            intolerances.push(parseInt(nuts));
        }
        const data = {
            userId: props.userDetails.userId,
            name: name,
            age: parseInt(age),
            location: location,
            height: parseInt(height),
            weight:  parseInt(weight),
            programGoal: props.userDetails.programGoal,
            symptom: props.userDetails.symptom,
            dietPreference: parseInt(dietPreference),
            cuisine: parseInt(cusine),
            diagnosis: parseInt(diagnosedConditions),
            intermittentFasting: parseInt(fasting),
            callingNumber: phoneNumber,
            intolerances,
            nutritionistName,
            notes,
            leaflets, 
            labtest,
            supplement,
            eventId: props.userDetails.eventId
        };

        // resetting every entry
        setDietPreference(-1);
        setCusine(-1);
        setDiagnosedConditions(-1);
        setFasting(-1);
        setGluten(-1);
        setDairy(-1);
        setNuts(-1);
        setNotes("");
        setNutritionistName("");
        setIsSubmissionAllowed(false);
        leafletsRef.current.resetSelectedValues();
        labtestRef.current.resetSelectedValues();
        supplementRef.current.resetSelectedValues();

        dispatch(createMeal(data));

    };

    useEffect(() => {
        const data = [
            dietPreference,
            cusine,
            diagnosedConditions,
            fasting,
            nutritionistName,
        ];
        const isEmpty = (currVal) => currVal !== -1 && currVal !== "";

        if (data.every(isEmpty)) {
            setIsSubmissionAllowed(true);
        } else {
            setIsSubmissionAllowed(false);
        }
    }, [
        dietPreference,
        cusine,
        diagnosedConditions,
        fasting,
        nutritionistName,
    ]);

    useEffect(() => {
        if (diagnosedConditions === 1) {
            setFasting(0);
        }
    }, [diagnosedConditions]);


    return (
        <>
            {isModalOpen && (
                <div className="meal-builder-plan-modal-container">
                    <div>
                        <h3>
                            {isMealPlanBuild
                                ? "Your Meal Template is being generated"
                                : "User does not exists in our record"}
                        </h3>
                        <button
                            className="btn btn-primary  quiz-forward"
                            onClick={() => {
                                setIsModalOpen(false);
                                setIsMealPlanBuild(false);
                                props.setToggleModal(!props.isModal);
                            }}
                        >
                            Okay
                        </button>
                    </div>
                </div>
            )}
            <div className="meal-builder-container">
                <div
                  onClick={() => props.setToggleModal(!props.isModal)}
                  style={{"position": "absolute", "top": "50px", "right": "50px", "fontSize": "20px", cursor: "pointer"}}
                >
                  ❌
                </div>
                <h1 className="meal-builder-head">Subjective Data</h1>
                <div>
                    <div className="meal-builder-info-container">
                        <div className="meal-builder-profile-info">
                            <h2>Profile Info</h2>
                            <div>
                                <div>
                                    <label htmlFor="phone-number">
                                        Phone Number
                                    </label>
                                    <input
                                        type="number"
                                        id="phone-number"
                                        value={phoneNumber}
                                        // onChange={(e) => {
                                        //     setPhoneNumber(e.target.value);
                                        // }}
                                    />
                                </div>
                            </div>
                            <div>
                                <div>
                                    <label htmlFor="name">Name</label>
                                    <input
                                        type="text"
                                        id="name"
                                        value={name}
                                        onChange={(e) => {
                                            setName(e.target.value);
                                        }}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="age">Age</label>
                                    <input
                                        type="text"
                                        id="age"
                                        value={age}
                                        onChange={(e) => {
                                            setAge(e.target.value);
                                        }}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="location">Location</label>
                                    <input
                                        type="text"
                                        id="location"
                                        value={location}
                                        onChange={(e) => {
                                            setLocation(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div>
                                <div>
                                    <label htmlFor="height">Height (cm)</label>
                                    <input
                                        type="text"
                                        id="height"
                                        value={height}
                                        onChange={(e) => {
                                            setHeight(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div>
                                <div>
                                    <label htmlFor="weight">Weight (kg)</label>
                                    <input
                                        type="text"
                                        id="weight"
                                        value={weight}
                                        onChange={(e) => {
                                            setWeight(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div>
                                <div>
                                    <label htmlFor="weight">Nutritionist</label>
                                    <select
                                        type="text"
                                        id="weight"
                                        value={nutritionistName}
                                        style={{
                                            color:
                                                nutritionistName === "" &&
                                                "#999999",
                                        }}
                                        onChange={(e) => {
                                            setNutritionistName(e.target.value);
                                        }}
                                    >
                                        <option value="" disabled="disabled">
                                            Select a nutritionist
                                        </option>
                                        <option value="Neha S Kumar">
                                            Neha S Kumar
                                        </option>
                                        <option value="Anjali Bhosale">
                                            Anjali Bhosale
                                        </option>
                                        <option value="Manisha Bhat">
                                            Manisha Bhat
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="meal-builder-dietary-needs">
                        <h2>Dietary Needs</h2>
                        <div>
                            <div className="meal-builder-dietry-needs-p1">
                                <div>
                                    <h3>Diet preference</h3>
                                    <CustomRadio
                                        labelFor="veg"
                                        name="diet_preference"
                                        setVal={setDietPreference}
                                        val={0}
                                        showVal="Veg"
                                        compareVal={dietPreference}
                                        diagnosedConditions={diagnosedConditions}
                                        fasting={fasting}
                                    />
                                    <CustomRadio
                                        labelFor="nonveg"
                                        name="diet_preference"
                                        setVal={setDietPreference}
                                        val={1}
                                        showVal="Non Veg"
                                        compareVal={dietPreference}
                                        diagnosedConditions={diagnosedConditions}
                                        fasting={fasting}
                                    />
                                    <CustomRadio
                                        labelFor="eggetarian"
                                        name="diet_preference"
                                        setVal={setDietPreference}
                                        val={2}
                                        showVal="Eggetarian"
                                        compareVal={dietPreference}
                                        diagnosedConditions={diagnosedConditions}
                                        fasting={fasting}
                                    />
                                </div>

                                <div>
                                    <h3>Type of cuisine?</h3>
                                    <CustomRadio
                                        labelFor="south-indian-tamil"
                                        name="type_of_cusine"
                                        setVal={setCusine}
                                        val={0}
                                        showVal="South Indian Tamil"
                                        compareVal={cusine}
                                        diagnosedConditions={diagnosedConditions}
                                        fasting={fasting}
                                    />
                                    <CustomRadio
                                        labelFor="south-indian-malyalam"
                                        name="type_of_cusine"
                                        setVal={setCusine}
                                        val={1}
                                        showVal="South Indian Malyalam"
                                        compareVal={cusine}
                                        diagnosedConditions={diagnosedConditions}
                                        fasting={fasting}
                                    />
                                    <CustomRadio
                                        labelFor="north-indian"
                                        name="type_of_cusine"
                                        setVal={setCusine}
                                        val={2}
                                        showVal="North Indian"
                                        compareVal={cusine}
                                        diagnosedConditions={diagnosedConditions}
                                        fasting={fasting}
                                    />
                                    <CustomRadio
                                        labelFor="mixed"
                                        name="type_of_cusine"
                                        setVal={setCusine}
                                        val={3}
                                        showVal="Mixed South Indian + North Indian"
                                        compareVal={cusine}
                                        diagnosedConditions={diagnosedConditions}
                                        fasting={fasting}
                                    />

                                    <CustomRadio
                                        labelFor="maharashtrian"
                                        name="type_of_cusine"
                                        setVal={setCusine}
                                        val={4}
                                        showVal="Maharashtrian"
                                        compareVal={cusine}
                                        diagnosedConditions={diagnosedConditions}
                                        fasting={fasting}
                                    />

                                    <CustomRadio
                                        labelFor="gujarati"
                                        name="type_of_cusine"
                                        setVal={setCusine}
                                        val={5}
                                        showVal="Gujarati"
                                        compareVal={cusine}
                                        diagnosedConditions={diagnosedConditions}
                                        fasting={fasting}
                                    />

                                    <CustomRadio
                                        labelFor="bengali"
                                        name="type_of_cusine"
                                        setVal={setCusine}
                                        val={6}
                                        showVal="Bengali"
                                        compareVal={cusine}
                                        diagnosedConditions={diagnosedConditions}
                                        fasting={fasting}
                                    />

                                    <CustomRadio
                                        labelFor="global"
                                        name="type_of_cusine"
                                        setVal={setCusine}
                                        val={7}
                                        showVal="Global"
                                        compareVal={cusine}
                                        diagnosedConditions={diagnosedConditions}
                                        fasting={fasting}
                                    />
                                </div>

                                <div>
                                    <h3>
                                        Is the user diagnosed with the below
                                        conditions?
                                    </h3>
                                    <CustomRadio
                                        labelFor="pcos"
                                        name="diagnosed_conditions"
                                        setVal={setDiagnosedConditions}
                                        val={0}
                                        compareVal={diagnosedConditions}
                                        showVal="PCOS"
                                        diagnosedConditions={diagnosedConditions}
                                        fasting={fasting}
                                    />
                                    <CustomRadio
                                        labelFor="pcos-thyroid"
                                        name="diagnosed_conditions"
                                        setVal={setDiagnosedConditions}
                                        val={1}
                                        showVal="PCOS + Thyroid"
                                        compareVal={diagnosedConditions}
                                        diagnosedConditions={diagnosedConditions}
                                        fasting={fasting}
                                    />
                                </div>
                            </div>
                            <div className="meal-builder-dietry-needs-p2">
                                <div>
                                    <div>
                                        <h3>
                                            Is the user now fasting
                                            intermittently?
                                        </h3>
                                        <CustomRadio
                                            labelFor="yes"
                                            name="fasting"
                                            setVal={setFasting}
                                            val={1}
                                            showVal="Yes"
                                            compareVal={fasting}
                                            diagnosedConditions={diagnosedConditions}
                                        fasting={fasting}
                                        />
                                        <CustomRadio
                                            labelFor="no"
                                            name="fasting"
                                            setVal={setFasting}
                                            val={0}
                                            showVal="No"
                                            compareVal={fasting}
                                            diagnosedConditions={diagnosedConditions}
                                            fasting={fasting}
                                        />
                                    </div>
                                    <div>
                                        <h3>
                                            Is there any dietary intolerance?
                                        </h3>
                                        <CustomCheckBox
                                            labelFor="gluten"
                                            name="gluten"
                                            setVal={setGluten}
                                            val={0}
                                            showVal="Gluten"
                                            compareVal={gluten}
                                        />
                                        <CustomCheckBox
                                            labelFor="dairy"
                                            name="dairy"
                                            setVal={setDairy}
                                            val={1}
                                            showVal="Dairy"
                                            compareVal={dairy}
                                        />
                                        <CustomCheckBox
                                            labelFor="nuts"
                                            name="nuts"
                                            setVal={setNuts}
                                            val={2}
                                            showVal="Nuts"
                                            compareVal={nuts}
                                        />
                                    </div>
                                </div>
                                <div className="mealplan_builder__section">
                                    <h3>
                                        Select Lab tests to add.
                                    </h3>
                                    <MealBulderMultiSelect options="labtest" dataSet={labtest} setData={(e) => setLabtests(e)} parentRef={labtestRef}/>
                                </div>
                                <div className="mealplan_builder__section">
                                    <h3>
                                        Select Supplement to add.
                                    </h3>
                                    <MealBulderMultiSelect options="supplement" dataSet={supplement} setData={(e) => setSupplement(e)} parentRef={supplementRef}/>
                                </div>
                                <div className="mealplan_builder__section">
                                    <h3>
                                        Select Leaflets to add.
                                    </h3>
                                    <MealBulderMultiSelect options="leaflets" dataSet={leaflets} setData={(e) => setLeaflets(e)} parentRef={leafletsRef}/>
                                </div>
                                <div className="mealplan_builder__notes">
                                    <textarea
                                        placeholder="Write your notes here"
                                        value={notes}
                                        onChange={(e) => {
                                            setNotes(e.target.value);
                                        }}
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                width: "25%",
                                marginLeft: "12%",
                            }}
                        >
                            <button
                                className={`btn btn-primary  quiz-forward btn-block ${
                                    isSubmissionAllowed ? "" : "disabled"
                                }`}
                                onClick={createMealPlan}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MealBuilder;
