import { apiRequest,API_SUCCESS,API_ERROR } from "../../../actions/api"
import { PRIMARY_CONCERN, PRIMARY_CONCERN_GET, setPrimaryConcern } from "../../../actions/primaryConcern"
import {ROOT_URL} from '../../../config/'

export const primaryConcenMiddleware = ({dispatch}) => next => (action) =>{
    next(action)
    switch(action.type){
        case PRIMARY_CONCERN_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/primaryConcern`,
                method:'get',
                feature:PRIMARY_CONCERN
            }))
            break;
        case `${PRIMARY_CONCERN} ${API_SUCCESS}`:
            dispatch(setPrimaryConcern(action.payload))
            break;
        case `${PRIMARY_CONCERN} ${API_ERROR}`:
            break;
        default: break;
    }
}

