import React,{useEffect} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import Navbar from '../../navbar'
import { Grid, _ } from "gridjs-react";
import { getWebinars, sendWebinarBurst } from '../../../actions/webinar';
import { webinarSelector } from '../../../selector/webinar';
import { useHistory } from 'react-router';
import moment from 'moment';
import Alert from "sweetalert2";

const Table = () =>{    
    const dispatch = useDispatch()
    const history = useHistory()
    useEffect(()=>{
        dispatch(getWebinars())
    },[dispatch])
    
    const handleWhatsappBurst = (currentCapacity,start,joinUrl,webinarId,webinarName) => {

        Alert.fire({
            title:`Are you sure you want to send this whatsapp burst to ${currentCapacity} participants?`,
            html:`
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                Hi {{Username}}, 
                                This is a gentle reminder for Uvi's Masterclass on ${moment(start).add(330,'minutes').format("DD-MM-YYYY hh:mma")},
                                starting in 30 minutes. 
                                To join the event, just follow the link details below:
                                <br/><br/>
                                <a>${joinUrl}</a>
                            </div>
                        </div>
                    </div>
                </div>
            `,
            showDenyButton:true,
            showConfirmButton:true,
            confirmButtonText:'Go Ahead !',
            denyButtonText:'Nope Wait',
        }).then(({value})=>{
            if(value){
               dispatch(sendWebinarBurst({webinarId,webinarName,joinUrl}))
            }
        })

    }
    const webinars = useSelector((state) => webinarSelector(state));
    const data = webinars?webinars.map((webinar) => [
        webinar.webinarId,
        webinar.webinarName,
        webinar.speakerName,
        moment(webinar.start).utcOffset(0).format("DD-MM-YYYY hh:mma"),
        webinar.duration,
        webinar.currentCapacity,
        _(
            <>
                    <div className="row d-flex justify-content-between">
                        <div className="col-3">
                                <i
                                    onClick={() => history.push(`webinar/${webinar.webinarId}/users`)}
                                    className="mdi mdi-account text-info font-20"
                                ></i>

                        </div> 
                        <div className="col-3">
                                <i
                                    onClick={() => history.push(`createWebinar/${webinar.webinarId}`)}
                                    className="mdi mdi-pencil text-warning font-20"
                                ></i>
                        </div>
                        <div className="col-3">
                            <i
                                onClick={()=>handleWhatsappBurst(
                                    webinar.currentCapacity,
                                    webinar.start,
                                    webinar.joinUrl,
                                    webinar.webinarId,
                                    webinar.webinarName)}
                                className="mdi mdi-whatsapp text-success font-20"
                            ></i>
                        </div>
                </div>
            </>
        )
    ]):[]

    return(
        <div className="col-12">
            <div className="card">
                <div className="card-body">
                    <div className="tab-content">
                        <div
                            className="tab-pane show active"
                            id="basic-datatable-preview"
                        >
                            <div id="basic-datatable-wrapper">
                                <Grid
                                    data={data}
                                    columns={[
                                        {name:"Webinar Id",width:'10%'},
                                        {name:"Webinar Name",width:'20%'},
                                        {name:"Speaker",width:'20%'},
                                        {name:"Start Time",width:'20%'},
                                        {name:"Duration",width:'8%'},
                                        {name:"Registrations",width:'10%'},
                                        {name:"Action",width:'12%'},
                                    ]}
                                    search={true}
                                    pagination={{
                                        enabled: true,
                                        limit: 20,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Webinars = ()  => {
    return (
        <div>
            <Navbar pageTitle={"Current Webinars"}>
                <Table/>
            </Navbar>
        </div>
    )
}

export default Webinars
