import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Grid, _ } from 'gridjs-react';
import moment from 'moment';

import Navbar from "../../navbar";
import {getContentItems} from "../../../actions/content/index";
import { contentItemsSelector } from "../../../selector/content";

const ContentItemComponent = ({contentItems, navigateToContentDetails}) => {
    if(!contentItems || !contentItems.data || !contentItems.data.content || contentItems.error){
        return <div>Something went wrong or no content Available</div>
    } else if(contentItems.fetching){
        return <div>Fetching data</div>
    } else if(contentItems.data.content.length === 0){
      return <div>No content to dispaly</div>
    }

    const columns = [
      "Action",
      "Title",
      "Content Type",
      "Last updated at",
      "Comment",
      "likes",
      "Comments",
      "Shares"
    ]
    const data = [];
    contentItems.data.content.forEach(element => {
      const current = [
              _(
                  <button
                    type="button"
                    className="btn"
                    style={{ backgroundColor: "#373750", color: "white" }}
                    onClick={() => navigateToContentDetails(element.content_id)}
                  >
                    Details
                  </button>
                ),
                element.title || 'NA',
                element.content_type[0].toUpperCase() + element.content_type.slice(1),
                element.updated_at ? moment(element.updated_at).format("MMM Do YY, h:mm a") :moment(element.created_at).format("MMM Do YY, h:mm a"),
                element.can_comment === 1 ? 'Enabled' : 'Disabled',
                contentItems.data.likes && contentItems.data.likes[element.content_id] ?
                  contentItems.data.likes[element.content_id] : 0,
                contentItems.data.comments && contentItems.data.comments[element.content_id] ?
                  contentItems.data.comments[element.content_id] : 0,
                contentItems.data.share && contentItems.data.share[element.content_id] ?
                  contentItems.data.share[element.content_id] : 0,
              ]
      data.push(current)
    });

    return(
      <div>
        <Grid
          data={data}
          columns={columns}
          search={true}
          pagination={{
            enabled: true,
            limit: 10,
          }}
          sort={true}
        />
      </div>
    )
}

const ContentItem = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(()=>{
    const data = {}
    dispatch(getContentItems(data));
  },[dispatch])

  const contentItems = useSelector((state) => contentItemsSelector(state));

  const navigateToContentDetails = (id) => {
    history.push(`/createContent/${id}`)
  }

  return(
    <Navbar pageTitle={"Content"}>
      <ContentItemComponent contentItems={contentItems} navigateToContentDetails={navigateToContentDetails}/>
    </Navbar>
  )
}

export default ContentItem;