import React from 'react'
import {useSelector} from 'react-redux'
import { 
    exotelCompletedCallsSelector, 
    exotelOneMinCallSelector, 
    exotelSumOneMinCallSelector, 
    exotelTotalCallsSelector, 
    hcCompletedCallsSelector, 
    hcOneMinCallSelector,
    hcSumOneMinCallSelector
} from '../../../selector/exotel'
import VerticalBarChart from '../../Charts/VerticalBarChart'
import AnalyticsCard from '../../utils/AnalyticsCard'

const ExotelAnalyticsBoard = () => {
    const totalCalls = useSelector(state=>exotelTotalCallsSelector(state))
    const completedCalls = useSelector(state=>exotelCompletedCallsSelector(state))
    const oneMinCall = useSelector(state=>exotelOneMinCallSelector(state))
    const sumOneMinCall = useSelector(state=>exotelSumOneMinCallSelector(state))

    const hcTotalCalls = useSelector(state=>hcCompletedCallsSelector(state))
    const hcCompletedCalls = useSelector(state=>hcCompletedCallsSelector(state))
    const hcOneMinCall = useSelector(state=>hcOneMinCallSelector(state))
    const hcSumOneMinCall = useSelector(state=>hcSumOneMinCallSelector(state))

    const hcTotalCallsKeys = []
    const hcTotalCallsValues = []
    const hcCompletedCallsKeys = []
    const hcCompletedCallsValues = []
    const hcOneMinCallKeys = []
    const hcOneMinCallValues = []
    const hcSumOneMinCallKeys = []
    const hcSumOneMinCallValues = []

    hcTotalCalls.forEach(item=>hcTotalCallsKeys.push(Object.keys(item)))
    hcTotalCalls.forEach(item=>hcTotalCallsValues.push(Object.values(item)))

    hcCompletedCalls.forEach(item=>hcCompletedCallsKeys.push(Object.keys(item)))
    hcCompletedCalls.forEach(item=>hcCompletedCallsValues.push(Object.values(item)))

    hcOneMinCall.forEach(item=>hcOneMinCallKeys.push(Object.keys(item)))
    hcOneMinCall.forEach(item=>hcOneMinCallValues.push(Object.values(item)))

    hcSumOneMinCall.forEach(item=>hcSumOneMinCallKeys.push(Object.keys(item)))
    hcSumOneMinCall.forEach(item=>hcSumOneMinCallValues.push(Object.values(item)))


    return (
        <div>
            <div className="row">
                <div className="col-xl-8 col-lg-6">
                    <div className="row">
                        <AnalyticsCard
                            title="Total Calls"
                            data={totalCalls}
                            info1=''
                            info2=''
                        />
                        <AnalyticsCard
                            title="Total Completed Calls"
                            data={completedCalls}
                            info1=''
                            info2=''
                        />

                    </div>

                    <div className="row">
                        <AnalyticsCard
                            title="# of > 1min Calls"
                            data={oneMinCall}
                            info1=''
                            info2=''
                        />
                        <AnalyticsCard
                            title="Total Calltime in mins"
                            data={sumOneMinCall}
                            info1=''
                            info2=''
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <VerticalBarChart
                        title={'Total Calls Per Health Coach'}
                        xaxis={hcTotalCallsKeys}
                        yaxis={hcTotalCallsValues}
                    />
                </div>
                <div className="col-6">
                <VerticalBarChart
                        title={'Total Completed Calls Per Health Coach'}
                        xaxis={hcCompletedCallsKeys}
                        yaxis={hcCompletedCallsValues}
                    />
                </div>
            </div>    
            <div className="row">
                <div className="col-6">
                <VerticalBarChart
                        title={'Total Calls >1 min duration Per Health Coach'}
                        xaxis={hcOneMinCallKeys}
                        yaxis={hcOneMinCallValues}
                    />
                </div>
                <div className="col-6">
                <VerticalBarChart
                        title={'Total talktime for calls > 1 min Per Health Coach'}
                        xaxis={hcSumOneMinCallKeys}
                        yaxis={hcSumOneMinCallValues}
                    />
                </div>
            </div>    
        </div>
    )
}

export default ExotelAnalyticsBoard
