import { PAYMENTS_PLAN_SET, PAYMENTS_PLAN_DETAILS_SET } from "../../actions/paymentsPlan";
const initState = []

export const paymentsPlanReducer = (state=initState,action) => {
    switch(action.type)
    {
        case PAYMENTS_PLAN_SET:
            return {...state, paymentsPlan:action.payload};
        case PAYMENTS_PLAN_DETAILS_SET:
            return {...state, paymentsPlanDetails:action.payload};
        default: return state
    }
}