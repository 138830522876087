export const PROFILE_PRESIGNED_URL = `[PROFILE_PRESIGNED_URL]`
export const PROFILE_IMAGE_LOC = `[PROFILE_IMAGE_LOC]`

export const PROFILE_PRESIGNED_URL_GET = `${PROFILE_PRESIGNED_URL} GET`
export const PROFILE_PRESIGNED_URL_SET = `${PROFILE_PRESIGNED_URL} SET`

export const PROFILE_IMAGE_LOC_SAVE = `${PROFILE_IMAGE_LOC} SAVE`

export const getProfilePresignedUrl = (data) => ({
    type:PROFILE_PRESIGNED_URL_GET,
    payload:data
})

export const setProfilePresignedUrl = (data) => ({
    type:PROFILE_PRESIGNED_URL_SET,
    payload:data
})

export const saveProfileImageLocation = (data) => ({
    type:PROFILE_IMAGE_LOC_SAVE,
    payload:data
})