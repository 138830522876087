/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useEffect} from 'react'
import { 
    getUserBookings, 
    getUserDetails, 
    getUserBookingsPeriodLogs, 
    getUserBookingsWebinarLogs, 
    getUserBookingsSymptomLogs, 
    getUserBookingsConsultLogs 
} from '../../../actions/user';
import {useDispatch,useSelector} from "react-redux"
import { userBookingsSelector, userSelector, userBookingsPeriodLogsSelector, userBookingsWebinarLogsSelector, userBookingsSymptomLogsSelector, userBookingsConsultLogsSelector } from '../../../selector/user';
import Navbar from '../../navbar';
import { Grid } from "gridjs-react";
import moment from 'moment'
import { useHistory } from 'react-router-dom';

const EventsDetailsTable = ({userId}) => {
    const dispatch = useDispatch()


    useEffect(() => {
        dispatch(getUserBookings(userId));
        dispatch(getUserBookingsPeriodLogs(userId));
        dispatch(getUserBookingsWebinarLogs(userId));
        dispatch(getUserBookingsSymptomLogs(userId));
        dispatch(getUserBookingsConsultLogs(userId));
    }, [dispatch,userId]);

    const userBookings = useSelector(state=>userBookingsSelector(state))
    const periodLogs = useSelector(state=>userBookingsPeriodLogsSelector(state))
    const webinarLogs = useSelector(state=>userBookingsWebinarLogsSelector(state))
    const consultLogs = useSelector(state=>userBookingsConsultLogsSelector(state))
    const symptomLogs = useSelector(state=>userBookingsSymptomLogsSelector(state))

    const events = []

    if(userBookings)
    {
        userBookings.forEach((item) => {
            events.push({eventId: item.eventId, name: item.eventName, details: item.attendance === 1 ? 'Attended' : 'Not Attended', timeStamp: item.start})
        })
    }
    if(periodLogs)
    {
        periodLogs.forEach((item) => {
            events.push({eventId: "NA", name: 'Period Log', details: 'Duration - '+item.duration+'. Flow - '+item.value+" Symptoms - "+item.symptoms, timeStamp: item.startDate})
        })
    }
    if(webinarLogs)
    {
        webinarLogs.forEach((item) => {
            events.push({eventId: item.webinarId, name: 'Webinar', details: item.webinarName, timeStamp: item.start})
        })
    }
    if(consultLogs)
    {
        consultLogs.forEach((item) => {
            events.push({eventId: item.offeringTypeId, name: item.offeringTypeName, details: 'NA', timeStamp: item.start})
        })
    }
    if(symptomLogs)
    {
        symptomLogs.forEach((item) => {
            events.push({eventId: 'NA', name: 'Symptoms Log', details: item.factor+" - "+item.value, timeStamp: item.date})
        })
    }

    console.log(events)
    events.sort((a, b) => new Date(b.timeStamp) - new Date(a.timeStamp))

    const data = events?events
    .map((event)=>[
        event.eventId,
        event.name,
        event.details,
        moment(event.timeStamp).format('DD-MM-YYYY hh:mma')
    ]):[]
    return (
        <div className="col-12">
        <div className="card">
            <div className="card-body">
                <div className="tab-content">
                    <div
                        className="tab-pane show active"
                        id="basic-datatable-preview"
                    >

                        <div id="basic-datatable-wrapper">
                            <Grid
                                data={data}
                                columns={[
                                    "Event Id",
                                    "Name",
                                    "Details",
                                    "TimeStamp"
                                ]}
                                search={true}
                                pagination={{
                                    enabled: true,
                                    limit: 20,
                                }}
                            />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

const UserDetails = ({
    history,
    userId,
    firstName,
    age,
    height,
    weight,
    city,
    state,
    healthCoach,
    primaryConcern,
    interestArea,
    profilePicture
}) => {
    return (
        <div className="row">
                <div className="col-12">
                    <div className="card bg-secondary">
                        <div className="card-body profile-user-box">
                            <div className="row align-items-center">
                                <div
                                    className="col-2 justify-content-center"
                                    style={{ display: "flex" }}
                                >
                                    <div className="avatar-lg">
                                        <img
                                            src={profilePicture ? profilePicture : "/assets/images/users/avatar-9.jpg"}
                                            alt=""
                                            className="rounded-circle img-thumbnail"
                                        />
                                    </div>
                                </div>
                                <div className="col-10">
                                    <div>
                                        <div className="row">
                                            <div className="col-8">
                                                <h4 className="mt-1 mb-1 text-white">
                                                    {`${firstName}`}
                                                </h4>
                                                <p className="font-16 text-white-50">
                                                    <span>
                                                        
                                                        {age!==0 && age?<i className="mdi mdi-account"> </i>:''}
                                                        {age!==0 && age?`${age} years old`:''}
                                                    </span>
                                                    <br />
                                                    <span>
                                                        {city!== '' && city?<i className="mdi mdi-map-marker"> </i>:''}
                                                        {city!== '' && city?`from ${city}, ${state}`:''}
                                                    </span>
                                                </p>
                                            </div>
                                            <div
                                                className="col-4"
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-end",
                                                    paddingRight: "2em",
                                                }}
                                            >
                                                <a
                                                    className="btn btn-link"
                                                    
                                                >
                                                    <span 
                                                        className="text-white"
                                                        onClick={()=>{history.push(`/newUser/${userId}`)}}
                                                    >
                                                        <i className="mdi mdi-pencil"></i>{" "}
                                                        Edit User
                                                    </span>
                                                </a>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-8">
                                                <ul className="mb-0 list-inline text-light">
                                                    <li className="list-inline-item me-3">
                                                        <h5 className="mb-1">{height!==0 && height?`${height.toFixed(2)}cm`:'NA'}</h5>
                                                        <p className="mb-0 font-13 text-white-50">
                                                            Height
                                                        </p>
                                                    </li>
                                                    <li className="list-inline-item me-3">
                                                        <h5 className="mb-1">{weight!==0 && weight?`${weight.toFixed(2)}kg`:'NA'}</h5>
                                                        <p className="mb-0 font-13 text-white-50">
                                                            Weight
                                                        </p>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <h5 className="mb-1">
                                                            {" "}
                                                            {primaryConcern===null || primaryConcern==='' ?interestArea:primaryConcern}
                                                        </h5>
                                                        <p className="mb-0 font-13 text-white-50">
                                                            Primary Concerns
                                                        </p>
                                                    </li>
                                                    {/* <li className="list-inline-item">
                                                        <h5 className="mb-1">
                                                            {" "}
                                                            {secondaryConcern}
                                                        </h5>
                                                        <p className="mb-0 font-13 text-white-50">
                                                            Secondary Concerns
                                                        </p>
                                                    </li> */}
                                                </ul>
                                            </div>
                                            <div
                                                className="col-4"
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-end",
                                                    paddingRight: "2em",
                                                }}
                                            >
                                                <div>
                                                    <h5 className="mb-1 text-white">
                                                        {healthCoach!=='' && healthCoach?healthCoach:'Not Assigned'}
                                                    </h5>
                                                    <p className="mb-0 font-13 text-white-50">
                                                        Health Coach
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

const UserBookings = (props) => {
    const dispatch = useDispatch()
    const history = useHistory();

    const userId = props.match.params.userId;

    useEffect(()=>{
        dispatch(getUserDetails(userId));
    },[dispatch,userId]);

    const userDetails = useSelector((state) => userSelector(state));


    return (
        <div>
            <Navbar pageTitle='User Booking Logs'>
                <div>
                    <UserDetails 
                        dispatch={dispatch}
                        history={history}
                        userId={userId ? userId : ""}
                        firstName={userDetails ? userDetails.firstName : ""}
                        profilePicture={userDetails ? userDetails.profilePicture : ""}
                        countryCode = {userDetails ? userDetails.countryCode : ""}
                        age={userDetails ? userDetails.age : ""}
                        height={userDetails ? userDetails.height : ""}
                        weight={userDetails ? userDetails.weight : ""}
                        city={userDetails ? userDetails.city : ""}
                        state={userDetails ? userDetails.state : ""}
                        healthCoach={userDetails ? userDetails.healthCoach : ""}
                        primaryConcern={userDetails ? userDetails.primaryConcern : ""}
                        interestArea={userDetails ? userDetails.interestArea : ""}
                    />
                </div>

                <h3>Events Table</h3>
                <EventsDetailsTable
                    userId={userId}
                />
            </Navbar>
        </div>
    )
}

export default UserBookings
