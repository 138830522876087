import { apiRequest,API_SUCCESS,API_ERROR } from "../../../actions/api"
import { EXOTEL_LOGS, EXOTEL_LOGS_GET, setExotelLogs } from "../../../actions/exotel"
import {ROOT_URL} from '../../../config/'

export const exotelMiddleware = ({dispatch}) => next => (action) =>{
    next(action)
    switch(action.type){
        case EXOTEL_LOGS_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/exotelLogs`,
                method:'get',
                feature:EXOTEL_LOGS
            }))
            break;
        case `${EXOTEL_LOGS} ${API_SUCCESS}`:
            dispatch(setExotelLogs(action.payload))
            break;
        case `${EXOTEL_LOGS} ${API_ERROR}`:
            break;
        default: break;
    }
}
