import React,{useRef} from 'react'
import JoditEditor from 'jodit-react'


const  RichTextEditor = ({value,setValue}) => {
  const ref = useRef(null)
    return (
        <JoditEditor
            ref={ref}
            value={value}
            tabIndex={1}
            onChange={(content)=>{setValue(content)}}
        />
  )
}

export default RichTextEditor