import {ACQ_CHANNEL_SET} from "../../actions/acqChannel"

const initState = []
export const acqChannelReducer = (state=initState,action) =>{
    switch(action.type){
        case ACQ_CHANNEL_SET:
            return {...state,acqChannels:action.payload}
        default: return state
    }
}
