export const USER_PRIMARY_CONCERN = `[USER_PRIMARY_CONCERN]`

export const USER_PRIMARY_CONCERN_GET = `${USER_PRIMARY_CONCERN} GET`
export const USER_PRIMARY_CONCERN_SET = `${USER_PRIMARY_CONCERN} SET`

export const getUserPrimaryConcern = (data) =>({
    type:USER_PRIMARY_CONCERN_GET,
    payload:data
})
export const setUserPrimaryConcern = (data) =>({
    type:USER_PRIMARY_CONCERN_SET,
    payload:data
})
