import React from 'react';

const CustomeRadio = (props) => {

  return(
    <div
      style={{ "display": "flex", "flexDirection": "row", "margin": "10px", "cursor": "pointer", }}
      onClick={() => {props.setCheck(!props.check)}}
    >
      {
          props.check? 
            <div style={{"display": "flex", "flexDirection": "row", "cursor": "pointer", "width": "20px", "height": "20px", "borderRadius": "6px", "backgroundColor": "#00d26a", 'color': 'white', "justifyContent":  "center", "alignItems": "center"}}>
              <i className='mdi mdi-check'></i>
            </div>
            
        : <div
              className='radio__radio_button'
              style={{ "cursor": "pointer", "width": "20px", "height": "20px", "borderRadius": "6px", "border": "solid #00d26a 2px", "color": "white"}}
              onClick={() => {
                props.setCheck(!props.check);
              }}
            ></div>
      }
      
      <div style={{"marginLeft": "10px"}}>{props.name}</div>
    </div>
  )
}

export default CustomeRadio;