export const healthCoachList = () => {
    const healthCoaches = new Map();
    healthCoaches.set('09810747476',
      {
        name: 'Mehak Malik',
        totalCalls: 0,
        completedCalls: 0,
        oneMinCall: 0,
        sumOneMinCall: 0,
      });
  
    healthCoaches.set('07530017702',
      {
        name: 'Neha S Kumar',
        totalCalls: 0,
        completedCalls: 0,
        oneMinCall: 0,
        sumOneMinCall: 0,
      });
    healthCoaches.set('06388185387',
      {
        name: 'Aakriti Mahaur',
        totalCalls: 0,
        completedCalls: 0,
        oneMinCall: 0,
        sumOneMinCall: 0,
      });
    healthCoaches.set('08867346946',
      {
        name: 'Simrandeep',
        totalCalls: 0,
        completedCalls: 0,
        oneMinCall: 0,
        sumOneMinCall: 0,
      });
    healthCoaches.set('07774039263',
      {
        name: 'Deepika',
        totalCalls: 0,
        completedCalls: 0,
        oneMinCall: 0,
        sumOneMinCall: 0,
      });
    healthCoaches.set('09080124964',
      {
        name: 'Deepali',
        totalCalls: 0,
        completedCalls: 0,
        oneMinCall: 0,
        sumOneMinCall: 0,
      });
    healthCoaches.set('08265826527',
      {
        name: 'Ishani',
        totalCalls: 0,
        completedCalls: 0,
        oneMinCall: 0,
        sumOneMinCall: 0,
      });
    healthCoaches.set('09566234011',
      {
        name: 'Muzaiyen',
        totalCalls: 0,
        completedCalls: 0,
        oneMinCall: 0,
        sumOneMinCall: 0,
      });
    healthCoaches.set('UviHealthCoach',
      {
        name: 'Uvi Health Coach',
        totalCalls: 0,
        completedCalls: 0,
        oneMinCall: 0,
        sumOneMinCall: 0,
      });
    return healthCoaches;
  };