import { apiRequest,API_SUCCESS,API_ERROR } from "../../../actions/api"
import { setUserSymptom, USER_SYMPTOM, USER_SYMPTOM_GET } from "../../../actions/userSymptom"
import {ROOT_URL} from '../../../config/'

export const userSymptomMiddleware = ({dispatch}) => next => (action) =>{
    next(action)
    switch(action.type){
        case USER_SYMPTOM_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/userSymptom`,
                method:'get',
                feature:USER_SYMPTOM
            }))
            break;
        case `${USER_SYMPTOM} ${API_SUCCESS}`:
            dispatch(setUserSymptom(action.payload))
            break;
        case `${USER_SYMPTOM} ${API_ERROR}`:
            break;
        default: break;
    }
}

