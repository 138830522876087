import { PROVIDER_SET } from "../../actions/provider";
const initState = []

export const providerReducer = (state=initState,action) =>{
    switch(action.type){
        case PROVIDER_SET:
            return {...state,provider:action.payload}
        default: return state
    }
}
