/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useState} from 'react'
import {useDispatch} from 'react-redux'
import { toggleOfferingStatus } from '../../../actions/offering'
const Offering  = (
    {
        offeringId,
        colorScheme,
        name,
        quantity,
        status,
        type,
        unit,
        onOfferingSelect
    }) => {
        const [offeringStatus,setOfferingStatus] = useState(status)
        const dispatch = useDispatch()
        const handleStatus = () =>{
            if(offeringStatus===1){
                setOfferingStatus(0)
                dispatch(toggleOfferingStatus({offeringStatus:0,offeringId}))
            }else{
                setOfferingStatus(1)
                dispatch(toggleOfferingStatus({offeringStatus:1,offeringId}))
            }
            
        }
    return (
        <div className="card mb-0 rounded ">
            <div className="card-body p-2">
                <small className="float-end text-muted">{offeringId}</small>
                <span className={`badge ${colorScheme}`}>{type}</span>

                <h5 className="mt-2 mb-2">
                    <a className="text-body">{name}</a>
                </h5>

                <p className="mb-0 text-black-50">
                    <span className="pe-2 text-nowrap mb-2 d-inline-block">
                        <i className="mdi mdi-pound"> </i>
                        {quantity} {unit}
                    </span>
                </p>

                <div className="dropdown float-end">
                    <a className="dropdown-toggle text-muted arrow-none" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="mdi mdi-dots-vertical font-18"></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-end">
                        {
                            offeringStatus===1?
                                <a 
                                    className="dropdown-item"
                                    onClick={()=>{handleStatus()}}
                                >
                                        <i className=" dripicons-power text-danger"> </i>
                                            Deactivate
                                </a>
                                :
                                <a 
                                    className="dropdown-item"
                                    onClick={()=>{handleStatus()}}
                                >
                                        <i className="dripicons-power text-success"> </i>
                                        Activate
                                </a>
                        }
                        {offeringStatus===1?<a 
                            className="dropdown-item"
                            onClick={()=>{onOfferingSelect(offeringId)}}
                        >
                                <i className="mdi mdi-plus text-success"> </i>
                                Add to plan
                        </a>:null}
                        <a className="dropdown-item"><i className="mdi mdi-delete me-1"></i>Delete</a>
                    </div>
                </div>
                <p className="mb-0 text-black-50">
                    Status: {offeringStatus===1?<span className="text-success">Active</span>:<span className="text-danger">Inactive</span>}
                </p>
            </div> 
        </div>
    )
}

export default Offering
