import { CRONS_SET } from "../../actions/crons";

const initState = []
export const cronReducer = (state=initState,action) =>{
    switch(action.type){
        case CRONS_SET:
            return {...state,crons:action.payload}
        default: return state
    }
}

