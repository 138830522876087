import { apiRequest,API_SUCCESS,API_ERROR } from "../../../actions/api"
import {ROOT_URL} from '../../../config/'
import Alert from "sweetalert2";
import { 
        TASK_MARK, TASK_UNMARK, TASK, CHALLENGE, CHALLENGE_ENGAGEMENT,
        CHALLENGE_USER, CHALLENGE_USER_GET,
        CHALLENGE_CREATE, LEADERBOARD, LEADERBOARD_GET, 
        CHALLENGE_UPDATE, CHALLENGES_GET, CHALLENGES, LEADERBOARD_CREATE,
        setAllChallenges, getAllChallenges, CHALLENGE_GET, CHALLENGE_ENGAGEMENT_GET,
        setChallengeDetails, setLeaderboardSnapshot, 
        setChallengeUserDetails, getChallengeUserDetails, 
        getLeaderboardSnapshot, setChallengeEngagement,
    } from "../../../actions/challenges";

export const challengesMiddleware = ({dispatch}) => next => (action) =>{
    next(action)
    switch(action.type){
        case CHALLENGE_CREATE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/challenge`,
                method:'post',
                feature:CHALLENGE
            }))
            break;
        case CHALLENGE_UPDATE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/challenge`,
                method:'put',
                feature:CHALLENGE
            }))
            break;
        case CHALLENGE_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/challenge`,
                method:'get',
                feature:CHALLENGE
            }))
            break;
        case LEADERBOARD_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/leaderboardSnapshot`,
                method:'get',
                feature:LEADERBOARD
            }))
            break;
        case LEADERBOARD_CREATE:
                dispatch(apiRequest({
                    body:action.payload,
                    config:{},
                    url:`${ROOT_URL}/addUserInLeaderboard`,
                    method:'post',
                    feature:LEADERBOARD
                }))
                break;
        case CHALLENGE_USER_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/userChallengeSnapshot`,
                method:'get',
                feature:CHALLENGE_USER
            }))
            break;

        case TASK_MARK:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/markTask`,
                method:'put',
                feature:TASK
            }))
            break;
        case TASK_UNMARK:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/unmarkTask`,
                method:'put',
                feature:TASK
            }))
            break; 

        case CHALLENGE_ENGAGEMENT_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/engagement`,
                method:'get',
                feature:CHALLENGE_ENGAGEMENT
            }))
            break;  

        case `${CHALLENGE_ENGAGEMENT} ${API_SUCCESS}`:
            dispatch(setChallengeEngagement(action.payload));
            break;
        case `${CHALLENGE_ENGAGEMENT} ${API_ERROR}`:
            console.log('could not fetch challenge engagement')
            break;
        case `${TASK} ${API_SUCCESS}`:
            dispatch(getChallengeUserDetails(action.payload))
            Alert.fire({
                position: 'top-end',
                icon: 'error',
                title: action.message,
                showConfirmButton: false,
                timer: 1500
            })
            break;
        case `${TASK} ${API_ERROR}`:
                Alert.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: action.message,
                    showConfirmButton: false,
                    timer: 1500
                })
                break;
        case `${CHALLENGE_USER} ${API_SUCCESS}`:
            dispatch(setChallengeUserDetails(action.payload))
            break;
        case `${CHALLENGE_USER} ${API_ERROR}`:
            break;
        case `${LEADERBOARD} ${API_SUCCESS}`:
            if(action.message === 'User added')
            {
                dispatch(getLeaderboardSnapshot(action.payload))
                Alert.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: action.message,
                    showConfirmButton: false,
                    timer: 1500
                })
            }
            else{
                dispatch(setLeaderboardSnapshot(action.payload))
            }
            break;
        case `${LEADERBOARD} ${API_ERROR}`:
            if( action.message === 'User not added')
            {
                Alert.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: action.message,
                    showConfirmButton: false,
                    timer: 1500
                  })
            }
            else{
            }
            break;
        case `${CHALLENGE} ${API_SUCCESS}`:
            if(action.message === 'Challenge Created' || action.message === 'Challenge Updated')
            {
                dispatch(getAllChallenges())
                Alert.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: action.message,
                    showConfirmButton: false,
                    timer: 1500
                })
            }
            else if (action.message === 'Challenge Details'){
                dispatch(setChallengeDetails(action.payload))
            }
            break;
   
        case `${CHALLENGE} ${API_ERROR}`:
            Alert.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Task Could Not Be Marked',
                showConfirmButton: false,
                timer: 1500
              })
            break;
        case CHALLENGES_GET :
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/challenges`,
                method:'get',
                feature:CHALLENGES
            }))
            break;
        
        case `${CHALLENGES} ${API_SUCCESS}`:
            dispatch(setAllChallenges(action.payload))
            break;
        case `${CHALLENGES} ${API_ERROR}`:
            break;

        default: break;
    }
}
