import React from 'react'

const PlanRow = (
    {
        offeringId,
        offeringName,
        offeringType,
        offeringQuantity,
        colorScheme,
        onOfferingRemove
    }) => {
    return (
        <tr>
            <td>{offeringName}</td>
            <td><span className={`badge ${colorScheme}`}>{offeringType}</span></td>
            <td>{offeringQuantity}</td>
            {<td className="table-action">
                <button 
                    className="btn btn-rounded"
                    onClick={()=>{onOfferingRemove(offeringId)}}
                >
                        {" "}
                        <i
                            style={{ marginLeft: "-1rem" }}
                            className="mdi mdi-delete text-danger"
                        ></i>
                </button>
            </td>}
    </tr> 
    )
}

export default PlanRow
