import React from 'react'
import PlanRow from './PlanRow';

const PlanTable = (
    {
        planId,
        selectedOfferings,
        setSelectedOfferings,
        setOfferings,
        offerings,
        handleCreatePlan
    }) => {
    const onOfferingRemove = (offeringId) =>{
        const remainingOffering = selectedOfferings.filter(offering=>offering.offeringId!==offeringId)
        const removedOffering = selectedOfferings.filter(offering=>offering.offeringId===offeringId)
        setSelectedOfferings(remainingOffering)
        setOfferings([...offerings,...removedOffering])
    }
    return (
        <div className="card h-100">
            <div className="row mx-2">
                <div className="justify-content-between d-flex" style={{marginTop:'1.75rem'}}>
                    <h3 className="d-flex justify-content-center">Plan Offerings</h3>
                    <button 
                        type="submit" 
                        className="btn btn-success h-75"
                        onClick={()=>{handleCreatePlan()}}
                    >                   
                        { planId ? "Update This Plan" : "Create This Plan"}
                    </button>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-lg-12">
                        <table className="table table-centered w-100 dt-responsive nowrap">
                            <thead className="thead-light">
                                <tr>
                                    <th className="all">Offering Name</th>
                                    <th>Offering Type</th>
                                    <th>Quantity</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    selectedOfferings?
                                        selectedOfferings.map((
                                            {
                                                offeringId,
                                                offeringName,
                                                offeringTypeName,
                                                offeringQuantity,
                                                colorScheme
                                            })=>{
                                            return(
                                                <PlanRow
                                                    offeringId={offeringId}
                                                    offeringName={offeringName}
                                                    offeringType={offeringTypeName}
                                                    offeringQuantity={offeringQuantity}
                                                    colorScheme={colorScheme}
                                                    onOfferingRemove={onOfferingRemove}
                                                />
                                            )
                                        })
                                    :null
                                }
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PlanTable;

            