/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useEffect} from 'react'
import { Grid, _ } from "gridjs-react";
import moment from 'moment'
import {useDispatch,useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom'
import { getAllBookings } from '../../actions/insights';
import { allBookingsSelector } from '../../selector/insights';

const Table = ({data}) => {
    const history = useHistory()
    const allBookings = data.length>0?data
    .map((event)=>[
        event.eventId,
        event.eventName,
        moment(event.start).format('H:mma'),
        event.name,
        event.offeringTypeName,
        event.currentCapacity,
        _(
            <>
                <div className="col-3">
                    <i
                        className="dripicons-clipboard text-success"
                        onClick={() => {
                            history.push(`eventDetails/${event.eventId}`);}}
                    ></i>
                </div>
            </>
        )
    ]):[]
    return (
        <div className="col-12">
        <div className="card">
            <div className="card-body">
                <div className="tab-content">
                    <div
                        className="tab-pane show active"
                        id="basic-datatable-preview"
                    >

                        <div id="basic-datatable-wrapper">
                            <Grid
                                data={allBookings}
                                columns={[
                                    "Event Id",
                                    "Event Name",
                                    "Event Date",
                                    "Led By",
                                    "Offering Type",
                                    "Current Capacity",
                                    "Event Details"
                                ]}
                                search={true}
                                pagination={{
                                    enabled: true,
                                    limit: 20,
                                }}
                            />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

const AllBookings = ({startDate, endDate}) =>  {
    const dispatch = useDispatch()
    // const [start,setStart] = useState(startDate ? moment(startDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"))
    // const [end,setEnd] = useState(endDate ? moment(endDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"))

    // const handleStart = (e) => {
    //     setStart(e.target.value)
    // }
    // const handleEnd = (e) => {
    //     setEnd(e.target.value)
    // }
    // const handleClear = () => {
    //     setStart(moment().format('YYYY-MM-DD'))
    //     setEnd(moment().format('YYYY-MM-DD'))
    // }
    // const handleSearch = () => {
    //     dispatch(getAllBookings({start,end}))
    // }

    useEffect(()=>{
        dispatch(getAllBookings({start: startDate ? moment(startDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"), end: endDate ? moment(endDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")}))
    },[dispatch, startDate, endDate])

    const data = useSelector(state=>allBookingsSelector(state))
    console.log(data)
    
    return (
        <div>
            <h4 style={{color:'#6C757D'}}> All Bookings</h4>
            <div className='row'>
                <div className="col-5"></div>
                <div className="col-7">
                    {/* <form className="d-flex mb-3">
                        <div className="input-group mx-2">
                            <label
                                htmlFor="start"
                                className="col-3 mt-1"
                            >
                                Start : 
                            </label>
                            <input 
                                className="form-control" 
                                id="date" 
                                type="date" 
                                name="date"
                                value={start}
                                onChange={(e)=>{handleStart(e)}}
                                />
                        </div>
                        <div className="input-group">
                            <label
                                htmlFor="end"
                                className="col-3 mt-1"
                            >
                                End : 
                            </label>
                            <input 
                                className="form-control" 
                                id="end" 
                                type="date" 
                                name="date"
                                value={end}
                                onChange={(e)=>{handleEnd(e)}}
                                />
                        </div>
                        <a 
                            className="btn btn-primary ms-2"
                            onClick={()=>{handleSearch()}}
                            >
                            <i class="uil-search"></i>
                        </a>
                        <a 
                            className="btn btn-danger ms-2"
                            onClick={()=>{handleClear()}}
                        >
                            <i class="uil-multiply"></i>
                        </a>
                    </form> */}
                </div>

            </div>
            
            <Table
                data={data}
            />
            
        </div>
    )
}

export default AllBookings
