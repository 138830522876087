export const CRONS = `[CRONS]`
export const CRON = `[CRON]`

export const CRONS_GET = `${CRONS} GET`
export const CRONS_SET = `${CRONS} SET`
export const CRON_START = `${CRON} START`
export const CRON_STOP = `${CRON} STOP`

export const getAllCrons = () => ({
    type: CRONS_GET,
    payload:{}
})

export const startCron = (data) => ({
    type: CRON_START,
    payload:data
})

export const stopCron = (data) => ({
    type: CRON_STOP,
    payload:data
})

export const setAllCrons = (data) => ({
    type: CRONS_SET,
    payload:data
})