export const PAYMENT = `[PAYMENT]`

export const PAYMENT_INITIATE = `${PAYMENT} INITIATE`
export const PAYMENT_SET = `${PAYMENT} SET`
export const PAYMENT_CAPTURE = `${PAYMENT} CAPTURE`


export const initiatePayment = (data) => ({
    type: PAYMENT_INITIATE,
    payload: data
})

export const setPayment = (data) => ({
    type: PAYMENT_SET,
    payload: data
})

export const capturePayment = (data) => ({
    type: PAYMENT_CAPTURE,
    payload: data
})
