export const USER_PLAN = `[USER_PLAN]`

export const USER_PLAN_CREATE = `${USER_PLAN} CREATE`
export const USER_PLAN_EDIT = `${USER_PLAN} EDIT`
export const USER_PLAN_PAUSE = `${USER_PLAN} PAUSE`
export const USER_PLAN_DELETE = `${USER_PLAN} DELETE`

export const createUserPlan = (data) => ({
    type: USER_PLAN_CREATE,
    payload:data
})

export const editUserPlan = (data) => ({
    type:USER_PLAN_EDIT,
    payload:data
})

export const pauseUserPlan = (data) => ({
    type:USER_PLAN_PAUSE,
    payload:data
})

export const deleteUserPlan = (data) => ({
    type: USER_PLAN_DELETE,
    payload:data
})
