import { RAZORPAY_LOGS_SET } from "../../actions/razorpay";
const initState = []
export const razorpayReducer = (state=initState,action) =>{
    switch(action.type){
        case RAZORPAY_LOGS_SET:
            return {...state,razorpayLogs:action.payload}
        default: return state
    }
}

