export const RAZORPAY_LOGS = `[RAZORPAY_LOGS]`

export const RAZORPAY_LOGS_GET = `${RAZORPAY_LOGS} GET`
export const RAZORPAY_LOGS_SET = `${RAZORPAY_LOGS} SET`

export const getRazorpayLogs = (data) => ({
    type: RAZORPAY_LOGS_GET,
    payload: data
})

export const setRazorpayLogs = (data) => ({
    type:RAZORPAY_LOGS_SET,
    payload:data
})
