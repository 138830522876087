import React,{useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Navbar from '../navbar';
import { getSku, updateSku, createSku } from '../../actions/SKU';
import { SKUDetailsSelector } from '../../selector/SKU';
import { useHistory } from 'react-router-dom';

const SKUForm = (props) => {

    const skuId = props.match.params.skuId;
    console.log(skuId)
    const dispatch = useDispatch();
    const history = useHistory();

    const [skuName,setSkuName] = useState('')
    const [skuMapId,setSkuMapId] = useState('')
    const [skuCategory,setSkuCategory] = useState('')
    const [skuSubCategory,setSkuSubCategory] = useState('')
    const [skuUnit,setSkuUnit] = useState('')
    const [skuUnitType,setSkuUnitType] = useState('')
    const [skuMetaData,setSkuMetaData] = useState('')
    const [status, setStatus] = useState();
    
    useEffect(()=>{
        setSkuName('')
        setSkuMapId('')
        setSkuCategory('')
        setSkuSubCategory('')
        setSkuUnit('')
        setSkuUnitType('')
        setSkuMetaData('')
        setStatus('')
    },[])

    useEffect(()=>{
        if(skuId)
        {
            dispatch(getSku(skuId))
        }
    },[dispatch, skuId]);

    const SKUDetails = useSelector(state => SKUDetailsSelector(state));
    console.log(SKUDetails);

    useEffect(()=>{
        if(skuId && SKUDetails){
            setSkuName(SKUDetails.skuName);
            setSkuMapId(SKUDetails.skuMapId);
            setSkuCategory(SKUDetails.skuCategory);
            setSkuSubCategory(SKUDetails.skuSubCategory);
            setSkuUnit(SKUDetails.skuUnit);
            setSkuUnitType(SKUDetails.skuUnitType);
            setSkuMetaData(SKUDetails.skuMetaData);
            setStatus(SKUDetails.status);
        }
    },[SKUDetails, skuId]);

    const handleSubmit = (e) => {

        if(skuId)
        {
            dispatch(updateSku({skuName, skuMapId, skuCategory, skuSubCategory, skuUnit, skuUnitType, skuMetaData, status, skuId}));
        }
        else{
            dispatch(createSku({skuName, skuMapId, skuCategory, skuSubCategory, skuUnit, skuUnitType, skuMetaData, status}));
        }
        history.push('/skus');
    }

    return (
        <Navbar pageTitle={skuId ? "Update SKU" : "Create SKU"}>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="row mb-3">
                                        <label
                                            htmlFor="event-name"
                                            className="col-4 col-form-label"
                                        >
                                            SKU Name
                                        </label>
                                        <div className="col-8">
                                            <input
                                                type="text"
                                                className="form-control"        
                                                id="event-name"
                                                placeholder="SKU Name"
                                                autoComplete="off"
                                                value={skuName}
                                                onChange={(e)=>{
                                                    setSkuName(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label
                                            htmlFor="event-name"
                                            className="col-4 col-form-label"
                                        >
                                            SKU Map ID
                                        </label>
                                        <div className="col-8">
                                            <input
                                                type="text"
                                                className="form-control"        
                                                id="event-name"
                                                placeholder="SKU Map ID"
                                                autoComplete="off"
                                                value={skuMapId}
                                                onChange={(e)=>{
                                                    setSkuMapId(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label
                                            htmlFor="event-name"
                                            className="col-4 col-form-label"
                                        >
                                            SKU Category
                                        </label>
                                        <div className="col-8">
                                            <input
                                                type="text"
                                                className="form-control"        
                                                id="event-name"
                                                placeholder="SKU Category"
                                                autoComplete="off"
                                                value={skuCategory}
                                                onChange={(e)=>{
                                                    setSkuCategory(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label
                                            htmlFor="event-name"
                                            className="col-4 col-form-label"
                                        >
                                            SKU SubCategory
                                        </label>
                                        <div className="col-8">
                                            <input
                                                type="text"
                                                className="form-control"        
                                                id="event-name"
                                                placeholder="SKU SubCategory"
                                                autoComplete="off"
                                                value={skuSubCategory}
                                                onChange={(e)=>{
                                                    setSkuSubCategory(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row mb-3">
                                            <label
                                                htmlFor="event-name"
                                                className="col-4 col-form-label"
                                            >
                                                SKU Unit
                                            </label>
                                            <div className="col-8">
                                                <input
                                                    type="text"
                                                    className="form-control"        
                                                    id="event-name"
                                                    placeholder="SKU Unit"
                                                    autoComplete="off"
                                                    required
                                                    value={skuUnit}
                                                    onChange={(e)=>{
                                                        setSkuUnit(e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label
                                                htmlFor="event-name"
                                                className="col-4 col-form-label"
                                            >
                                                SKU Unit Type
                                            </label>
                                            <div className="col-8">
                                                <input
                                                    type="text"
                                                    className="form-control"        
                                                    id="event-name"
                                                    placeholder="SKU Unit Type"
                                                    autoComplete="off"
                                                    required
                                                    value={skuUnitType}
                                                    onChange={(e)=>{
                                                        setSkuUnitType(e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label
                                                htmlFor="event-name"
                                                className="col-4 col-form-label"
                                            >
                                                SKU MetaData
                                            </label>
                                            <div className="col-8">
                                                <input
                                                    type="text"
                                                    className="form-control"        
                                                    id="event-name"
                                                    placeholder="SKU MetaData"
                                                    autoComplete="off"
                                                    required
                                                    value={skuMetaData}
                                                    onChange={(e)=>{
                                                        setSkuMetaData(e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label
                                                htmlFor="event-name"
                                                className="col-4 col-form-label"
                                            >
                                                Status
                                            </label>
                                            <div className="col-8">
                                                <input
                                                    type="text"
                                                    className="form-control"        
                                                    id="event-name"
                                                    placeholder="Status"
                                                    autoComplete="off"
                                                    value={status}
                                                    onChange={(e)=>{
                                                        setStatus(e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                </div>
                                <div className="row">
                                    <div className="justify-content-center d-flex">
                                        <div>&nbsp;</div>
                                        <button
                                            type="submit"
                                            className="btn btn-success"
                                            onClick={() => handleSubmit()}
                                        >
                                            {skuId ? "Update SKU" : "Create SKU"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        </Navbar>
        
    )
}

export default SKUForm;