import { apiRequest,API_SUCCESS,API_ERROR } from "../../../actions/api"
import {ROOT_URL} from '../../../config/'


import Alert from "sweetalert2";
import { getAllTasks, setAllTasks, TASK, TASKS, TASKS_GET, TASK_CREATE, TASK_UPDATE } from "../../../actions/tasks";

export const tasksMiddleware = ({dispatch}) => next => (action) =>{
    next(action)
    switch(action.type){
        case TASK_CREATE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/task`,
                method:'post',
                feature:TASK
            }))
            break;
        case TASK_UPDATE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/task`,
                method:'put',
                feature:TASK
            }))
            break;
        case `${TASK} ${API_SUCCESS}`:
                dispatch(getAllTasks())
                Alert.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: action.message,
                    showConfirmButton: false,
                    timer: 1500
                  })
                break;   
        case `${TASK} ${API_ERROR}`:
            Alert.fire({
                position: 'top-end',
                icon: 'error',
                title: action.message,
                showConfirmButton: false,
                timer: 1500
              })
            break;
        
        case TASKS_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/tasks`,
                method:'get',
                feature:TASKS 
            }))
            break;
        case `${TASKS} ${API_SUCCESS}`:
            dispatch(setAllTasks(action.payload))
            break;
        case `${TASKS} ${API_ERROR}`:
            break;
            default: break;
    }
}

