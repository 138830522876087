import React, {useState} from 'react';
import Alert from "sweetalert2";
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";

import { getProfilePresignedUrl } from '../../../actions/profilePhotoUpload';
import { profilePhotoPresignedUrlSelector } from '../../../selector/presignedUrlSelector';

const TopicForm = (props) => {
  const [topicName, settopicName ] = useState('');
  const [heroUrl, setheroUrl ] = useState('')
  const [thumbnailUrl, setthumbnailUrl ] = useState('')
  const dispatch = useDispatch();

  
  const [thumnailFileImage,setThumnailFileImage] = useState('');
  const [heroFileImage,setHeroFileImage] = useState('');
  const [thumnailUploadStatus,setThumnailUploadStatus] = useState(0);
  const [heroUploadStatus,setHeroUploadStatus] = useState(0);

  const createTopic = () => {
    props.createTopicAction({
      topicName,
      heroUrl,
      thumbnailUrl
    })
  }

        const onFileChange = (event, type) => {
        if(type === 'thumbnail'){
            setThumnailFileImage(event.target.files[0])
            setThumnailUploadStatus(1)
        } else {
            setHeroFileImage(event.target.files[0])
            setHeroUploadStatus(1)
        }
        dispatch(getProfilePresignedUrl(event.target.files[0].type))
    }

    const uploadConfigs = useSelector(state=>profilePhotoPresignedUrlSelector(state))
    const uploadFile = async (type) => {
        const image = type === 'thumbnail' ? thumnailFileImage : heroFileImage
             await axios.put(uploadConfigs.url,image,{
                 headers:{
                    'Content-Type':image.type
                 }
             }).then((val)=>{
                const uploadUrl = `https://image-store-admin.s3.amazonaws.com/${uploadConfigs.key}`
            if(type === 'thumbnail'){
                setthumbnailUrl(uploadUrl)
                setThumnailUploadStatus(2)
                Alert.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Thumbnail Uploaded',
                    showConfirmButton: false,
                    timer: 1500
                })
            } else {
                setheroUrl(uploadUrl)
                setHeroUploadStatus(2)
                Alert.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Hero Uploaded',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
             }).catch((err)=>{
                Alert.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Image Upload Failed',
                    showConfirmButton: false,
                    timer: 1500
                  })
             })
    }

  return(
    <div style={{"width": "60%"}}>
      <div className="row">
        <div className="col-12 ">
          <form className="form-horizontal">
            
            <div className="row mb-4">
              <label
                className="col-2 col-form-label"
              >
                Topic name
              </label>
              <div className="col-10">
                <input
                  type="text"
                  className="form-control"
                  value={topicName}
                  onChange={(e) => {settopicName(e.target.value)}}
                  id="simpleInput1"
                  placeholder="Topic Name"
                  autoComplete="off"
                />
              </div>
            </div>

            <div className="row mb-4">
              <label
                className="col-2 col-form-label"
              >
                Thumbnail URL
              </label>
              <div className="col-10">
                {
                    heroUploadStatus === 1 && thumnailUploadStatus !== 2 ? <div className="col-10">Please finish uploading Hero imgae</div> : 
                      <div className="col-10">
                          {
                            thumnailFileImage || thumbnailUrl ? 
                            <img src= { thumnailFileImage ? URL.createObjectURL(thumnailFileImage) : thumbnailUrl} style={{width: '200px', height: undefined, marginBottom: '20px'}} alt='no' /> : null
                          }
                          

                          <input 
                            type="file" 
                            accept="image/*"
                            onChange={(event)=>{onFileChange(event, 'thumbnail')}}
                            style={{display:'block',}}
                          />

                          {
                            thumnailFileImage ?
                            <div style={{marginTop: '20px'}}>
                              <button
                                type="button"
                                className="btn"
                                style={{ backgroundColor: "#373750" }}
                                onClick={() => {uploadFile('thumbnail')}}
                              >
                                <span style={{ color: "#FFF" }}>
                                  Upload
                                </span>
                              </button>
                            </div> : null
                          }
                          
                      </div>
                  }
              </div>
            </div>

            <div className="row mb-4">
              <label
                className="col-2 col-form-label"
              >
                Hero URL
              </label>
              <div className="col-10">
                {
                    thumnailUploadStatus === 1 && heroUploadStatus !== 2 ? <div className="col-10">Please finish uploading Thumbnail imgae</div> : 
                      <div className="col-10">
                          {
                            heroFileImage || heroUrl ? 
                            <img src= {heroFileImage ? URL.createObjectURL(heroFileImage) : heroUrl} style={{width: '200px', height: undefined, marginBottom: '20px'}} alt='no' /> : null
                          }
                          
                          <input 
                            type="file" 
                            accept="image/*"
                            onChange={(event)=>{onFileChange(event, 'hero')}}
                            style={{display:'block'}}
                          />

                          {
                            heroFileImage ? 
                            <div style={{marginTop: '20px'}}>
                              <button
                                type="button"
                                className="btn"
                                style={{ backgroundColor: "#373750" }}
                                onClick={() => {uploadFile('hero')}}
                              >
                                <span style={{ color: "#FFF" }}>
                                  Upload
                                </span>
                              </button>
                            </div> : null
                          }
                          
                      </div>
                    }
              </div>
            </div>

            <button
              type="button"
              className="btn"
              style={{ backgroundColor: "#373750", color: "white", "marginBottom": "40px", "width": "200px"}}
              onClick={() => {
                createTopic();
                props.toggleShow(!props.show);
              }}
            >
              Create
            </button>
          
          </form>
        </div>
      </div>
    </div>
  )
}

export default TopicForm;