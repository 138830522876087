import React,{useEffect} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import Navbar from '../navbar'
import { Grid, _ } from "gridjs-react";
import { useHistory } from 'react-router-dom'
import { getAllChallenges } from '../../actions/challenges';
import { challengesSelector } from '../../selector/challenges';
import moment  from 'moment'

const Table = ({challenges}) => {
    const history = useHistory()
    const data = challenges?challenges.map((challenge)=>[
        challenge.challengeId,
        challenge.challengeName,
        moment(challenge.challengeStartDate).format('YYYY-MM-DD'),
        moment(challenge.challengeEndDate).format('YYYY-MM-DD'),
        challenge.challengeCurrentCapacity,
        _(
            <div className="col-12 d-flex justify-content-around">
                <span onClick={() => {history.push(`/challenge/${challenge.challengeId}/leaderboard`)}} className="font-20" >👸</span>
                <i 
                    className="dripicons-clipboard text-info text-primary font-18"
                    onClick={()=>{history.push(`/challenge/tasks/${challenge.challengeId}`)}}
                ></i>
                <i 
                    className="mdi mdi-chart-box-outline text-success font-22"
                    onClick={() => history.push(`/challengeEngagement/${challenge.challengeId}`)}
                ></i>
                <i 
                    className="mdi mdi-pencil text-warning font-20"
                    onClick={() => history.push(`/createChallenge/${challenge.challengeId}`)}
                ></i>
                
            </div>
        )
    ]):[]
    return(
        <div className="col-12">
            <div className="card">
                <div className="card-body">
                    <div className="tab-content">
                        <div
                            className="tab-pane show active"
                            id="basic-datatable-preview"
                        >
                            <div id="basic-datatable-wrapper">
                                <Grid
                                    data={data}
                                    columns={[
                                        {name:"Challenge Id",width:"10%"},
                                        {name:"Challenge Name",width:"20%"},
                                        {name:"Start",width:"10%"},
                                        {name:"End",width:"10%"},
                                        {name:"Current Capacity",width:"10%"},
                                        {name:"Actions",width:"10%"},
                                    ]}
                                    search={true}
                                    pagination={{
                                        enabled: true,
                                        limit: 20,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}



const ChallengeListing = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    useEffect(()=>{
        dispatch(getAllChallenges())
    },[dispatch])
    const challenges = useSelector(state=>challengesSelector(state))
    return (
        <div>
            <Navbar>
                <div className="d-flex justify-content-between mb-2 mx-3">
                    <h3> All Challenges</h3>
                    <button 
                        className='btn btn-info'
                        style={{height:'80%'}}
                        onClick={()=>{
                            history.push('/createChallenge')
                        }}
                    > 
                            CREATE NEW CHALLENGE
                    </button>
                </div>
                <Table
                    challenges={challenges}
                />
            </Navbar>
        </div>
    )
}

export default ChallengeListing;