export const USERS = `[USERS]`
export const ADMIN_LOGIN = `[ADMIN_LOGIN]`
export const TRIAL_USERS = `[TRIAL USERS]`

export const USERS_GET = `${USERS} GET`
export const USERS_SET = `${USERS} SET`
export const ADMIN_LOGIN_GET = `${ADMIN_LOGIN} GET`
export const ADMIN_LOGIN_SET = `${ADMIN_LOGIN} SET`

export const HEALTH_COACH = `[HEALTH_COACH]`
export const HEALTH_COACH_GET = `${HEALTH_COACH} GET`
export const HEALTH_COACH_SET = `${HEALTH_COACH} SET`
export const HEALTH_COACH_UPDATE = `${HEALTH_COACH} UPDATE`

export const VIEW_USERS_FILTER_SET = `[VIEW_USERS_FILTER] SET`;

export const FILTERED_USERS_SET = `[FILTERED_USERS] SET`

export const TRIAL_USERS_GET = `${TRIAL_USERS} GET`
export const TRIAL_USERS_SET = `${TRIAL_USERS} SET`


export const getAllUsers = () =>({
    type: USERS_GET,
    payload:{}
})

export const setAllUsers = (data) =>({
    type: USERS_SET,
    payload:data
})

export const getTrialUsers = (data) => ({
    type: TRIAL_USERS_GET,
    payload:data
})

export const setTrialUsers = (data) => ({
    type:TRIAL_USERS_SET,
    payload:data
})

export const setFilteredUsers = (data) => ({
    type: FILTERED_USERS_SET,
    payload: data
})

export const setFilter = (data) => ({
    type: VIEW_USERS_FILTER_SET ,
    payload: data
})

export const getHealthCoach = () => ({
    type: HEALTH_COACH_GET,
    payload: {}
})

export const setHealthCoach = (data) => ({
    type: HEALTH_COACH_SET,
    payload: data
})

export const assignHealthCoach = (data) => ({
    type: HEALTH_COACH_UPDATE,
    payload: data
})

export const getAdminLogin = () => ({
    type: ADMIN_LOGIN_GET,
    payload: {}
})

export const setAdminLogin = (data) => ({
    type: ADMIN_LOGIN_SET,
    payload: data
})
