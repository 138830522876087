import { apiRequest,API_SUCCESS,API_ERROR } from "../../../actions/api"
import { RAZORPAY_LOGS, RAZORPAY_LOGS_GET, setRazorpayLogs } from "../../../actions/razorpay"
import {ROOT_URL} from '../../../config/'

export const razorpayMiddleware = ({dispatch}) => next => (action) =>{
    next(action)
    switch(action.type){
        case RAZORPAY_LOGS_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/razorpayLogs`,
                method:'get',
                feature:RAZORPAY_LOGS
            }))
            break;
        case `${RAZORPAY_LOGS} ${API_SUCCESS}`:
            dispatch(setRazorpayLogs(action.payload))
            break;
        case `${RAZORPAY_LOGS} ${API_ERROR}`:
            break;
        default: break;
    }
}