/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/aria-proptypes */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState,useEffect } from "react";
import moment from "moment";
import Alert from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { decreaseUserOffering, getUserDetails, increaseUserOffering, sendUserMealTemplate, getUserLabReport, getUserBookingsConsultLogs, sendUserExpertReport, sendUserLabReport } from "../../../actions/user";
import { userSelector, userLabTestReportSelector, userBookingsConsultLogsSelector } from "../../../selector/user";
import Navbar from "../../navbar";
import { useHistory } from "react-router";
import { getPlans } from "../../../actions/plan";
import { planSelector } from "../../../selector/plan";
import { createUserPlan, deleteUserPlan, editUserPlan, pauseUserPlan } from "../../../actions/userPlan";
import { Grid, _ } from "gridjs-react";
import MealBuilder from "../../meals/planBuilder"
import axios from 'axios'
import { getLabReportPresignedUrl } from '../../../actions/labTests';
import { labReportPresignedUrlSelector, mealTemplatePresignedUrlSelector } from '../../../selector/presignedUrlSelector';
import { updateLabReportUrl } from '../../../actions/labTests';
import {CircleProgress} from 'react-gradient-progress'
import { 
    getUserBookings, 
    getUserBookingsPeriodLogs, 
    getUserBookingsWebinarLogs, 
    getUserBookingsSymptomLogs, 
} from '../../../actions/user';
import { userBookingsSelector, userBookingsPeriodLogsSelector, userBookingsWebinarLogsSelector, userBookingsSymptomLogsSelector} from '../../../selector/user';

import "./details.css"
import { getMealTemplatePresignedUrl, updateMealTemplateUrl } from "../../../actions/meals";

const LabTestReportTable = ({userLabTestReport, userId}) => {
    const dispatch = useDispatch()

    const [labReport, setLabReport] = useState("");
    const [progress, setProgress] = useState(0);
    const [disable, setDisable] = useState(true);

    const onFileChange = (e, orderId) => {
        setLabReport({orderId, file: e.target.files[0]})
        dispatch(getLabReportPresignedUrl(e.target.files[0].type))
    }

    const uploadConfigs = useSelector(state=>labReportPresignedUrlSelector(state))

    const uploadFile = async(orderId) => {
        setDisable(false);
        const config = {
            onUploadProgress: (progressEvent) => {
                const {loaded, total} = progressEvent;
                let percent = Math.floor( (loaded * 100) / total )
                setProgress(percent)
            },
            headers:{
                'Content-Type':labReport.file.type
             }
          }

        await axios.put(uploadConfigs.url,labReport.file, config).then((val)=>{
            dispatch(updateLabReportUrl({labReportUrl: `https://lab-test-users.s3.amazonaws.com/${uploadConfigs.key}`, orderId, userId }))
            setLabReport("")
            setProgress(0)
            setDisable(true)
        }).catch((err)=>{
            setLabReport("")
            setDisable(true)
            setProgress(0)
           Alert.fire({
               position: 'top-end',
               icon: 'error',
               title: 'Lab Report Failed',
               showConfirmButton: false,
               timer: 1500
             })
        })
    }

    const onLabReportSend = (reportUrl,userId) => {
        dispatch(sendUserLabReport({reportUrl,userId}))
    }

    const data = userLabTestReport?userLabTestReport.map((item)=>[
        item.planName,
        moment(item.labTestTimings).format('DD-MM-YYYY hh:mma '),
        _(
            <>
            {
               item.labReportUrl ? 
                    <a href={item.labReportUrl}>{`${item.name} Lab Report`}</a> :
                    <div>Please Upload the report</div>
            }
            </>
        ),
        _(
            <div className="row">
                <div className="col-4">
                    {
                        (!disable && labReport.orderId === item.orderId) ? <CircleProgress percentage={progress} width={80}/> : 
                        <>
                            { 
                                labReport.orderId !== item.orderId ?
                                    <div className="file-upload">
                                        <label for="file-input">
                                            <i
                                                className="btn btn-outline-warning btn-rounded"
                                                style={{padding:'2px' ,width:'100px', height:'28px',}}>
                                            Upload</i>
                                        </label>
                                        <input 
                                            id="file-input"
                                            type="file" 
                                            accept="application/pdf"
                                            onChange={(event)=>{onFileChange(event, item.orderId)}}
                                        />
                                    </div>
                                    :<div className="col">
                                        <h5>{labReport ? labReport.file.name : ''}</h5>
                                        <button 
                                            className="btn btn-info btn-rounded"
                                            onClick={() => uploadFile(item.orderId)}
                                            style={{padding:'2px' ,width:'100px'}}
                                        >   
                                            Upload
                                        </button>
                                    </div> 
                        }
                        </>
                    }
                </div>
                <div className="col-4">
                    <button 
                    type="button" 
                    className="btn btn-outline-success btn-rounded"
                    onClick={()=>{onLabReportSend(item.labReportUrl,userId)}}
                    disabled={!item.labReportUrl}
                    style={{padding:'2px' ,width:'100px', marginLeft:'20px'}}>
                        Send Report
                    </button>
                </div>
            </div>    
        )
    ])
    :[]
    return (
        <div className="col-12">
            <div className="card">
                <div className="card-body">
                    <div className="tab-content">
                        <div
                            className="tab-pane show active"
                            id="basic-datatable-preview"
                        >
                            <div id="basic-datatable-wrapper">
                                <Grid
                                    data={data}
                                    columns={[
                                        "Lab Test Name",
                                        "Lab Test Timing",
                                        "Report Url",
                                        "Action"
                                    ]}
                                    pagination={{
                                        enabled: true,
                                        limit: 5,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

// const MealTemplateTable = ({mealTemplates, callingNumber}) => {
//     const dispatch = useDispatch()
//     const onTemplateSend = (userId,fileName) => {
//         dispatch(sendUserMealTemplate({userId,fileName}))
//     }
//     const data = mealTemplates?mealTemplates.map((item)=>[
//         _(
//             <a href={item.location}>
//                 {item.fileName}
//             </a>
//         ),
//         moment(item.generatedOn).format('hh:mma DD-MM-YYYY'),
//         _(
//             <>
//                 <button 
//                     type="button" 
//                     className="btn btn-outline-success btn-rounded"
//                     onClick={()=>{onTemplateSend(item.userId,item.fileName)}} 
//                     style={{padding:'2px' ,width:'150px'}}>
//                         Send This Template
//                 </button>
//             </>
//         )
//     ])
//     :[]
//     return (
//         <div className="col-12">
//         <div className="card">
//             <div className="card-body">
//                 <div className="tab-content">
//                     <div
//                         className="tab-pane show active"
//                         id="basic-datatable-preview"
//                     >
//                         <div id="basic-datatable-wrapper">
//                             <Grid
//                                 data={data}
//                                 columns={[
//                                     "File Name",
//                                     "Generated On",
//                                     "Action"
//                                 ]}
//                             />
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </div>
//     )
// }

const UserConsultTable = ({
    userId,
    callingNumber,
    firstName,
    lastName,
    age,
    height,
    weight,
    city,
    symptom,
    programGoal,
    setToggleModal,
    setEventId
}) => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getUserBookingsConsultLogs(userId));
    }, [dispatch,userId]);

    const consultLogs = useSelector(state=>userBookingsConsultLogsSelector(state))

    const [report, setReport] = useState("");
    const [progress, setProgress] = useState(0);
    const [disable, setDisable] = useState(true);

    const onTemplateSend = (userId,eventId,offeringTypeName) => {
        if(offeringTypeName==='Nutrition Consultation'){
        dispatch(sendUserMealTemplate({userId,eventId}))
        }else if(offeringTypeName==='Expert Consultation'){
            dispatch(sendUserExpertReport({userId,eventId}))
            // console.log('Expert Consultation report send')
        }
    }



    const onFileChange = (e, eventId) => {
        setReport({eventId, file: e.target.files[0]})
        dispatch(getMealTemplatePresignedUrl(e.target.files[0].type))
    }

    const uploadConfigs = useSelector(state=>mealTemplatePresignedUrlSelector(state))
    console.log('uploadConfigs = ',uploadConfigs)
    console.log('report.eventId = ',report.eventId, !disable)

    const uploadFile = async(eventId) => {
        setDisable(false);
        const config = {
            onUploadProgress: (progressEvent) => {
                const {loaded, total} = progressEvent;
                let percent = Math.floor( (loaded * 100) / total )
                setProgress(percent)
            },
            headers:{
                'Content-Type':report.file.type
             }
          }
        await axios.put(uploadConfigs.url,report.file, config).then((val)=>{
            console.log('coming here')
            dispatch(
                updateMealTemplateUrl(
                    {
                        mealTemplateUrl: `https://meal-template-user.s3.amazonaws.com/${uploadConfigs.key}`, 
                        eventId, 
                        userId
                    }))
            setReport("")
            setProgress(0)
            setDisable(true)
        }).catch((err)=>{
            setReport("")
            setDisable(true)
            setProgress(0)
           Alert.fire({
               position: 'top-end',
               icon: 'error',
               title: 'Lab Report Failed',
               showConfirmButton: false,
               timer: 1500
             })
        })
    }
    
    const handleCreateClick = (offeringTypeId,eventId)=> {
        if(offeringTypeId === 'OFRT006'){
            setToggleModal(true)
            setEventId(eventId)
        }
    }

    const data = consultLogs?consultLogs.map((item)=>[
        item.name,
        _(<span className={`badge ${item.colorScheme}`}>{item.offeringTypeName}</span> ),
        moment(item.start).utcOffset(0).format('YYYY-MM-DD hh:mma'),
        item.reportUrl!==''?_(<a href={item.reportUrl}>{`${item.firstName} Report`}</a>):'Please upload the report',
        _(
            <div className="row d-flex justify-content-around">
                
                <div className="col-3">
                    <button 
                    type="button" 
                    className="btn btn-outline-primary btn-rounded"
                    style={{padding:'2px' ,width:'100px'}}
                    onClick={()=>{handleCreateClick(item.offeringTypeId, item.eventId)}}
                    disabled={item.offeringTypeId!=='OFRT006'}>
                       Create
                    </button>
                </div>
                
                <div className="col-3">
                    {
                        (!disable && (report.eventId === item.eventId)) ? <CircleProgress percentage={progress} width={80}/> : 
                        <>
                            { 
                                report.eventId !== item.eventId ?
                                    <div className="file-upload">
                                        <label for={item.eventId}>
                                            <i
                                                className="btn btn-outline-warning btn-rounded"
                                                style={{padding:'2px' ,width:'100px', height:'28px',}}>
                                            Upload</i>
                                        </label>
                                        <input 
                                            id={item.eventId}
                                            type="file" 
                                            accept="application/pdf"
                                            onChange={(event)=>{onFileChange(event, item.eventId)}}
                                        />
                                    </div>
                                    :<div className="col">
                                        <h5>{report ? report.file.name : ''}</h5>
                                        <button 
                                            className="btn btn-info btn-rounded"
                                            onClick={() => uploadFile(item.eventId)}
                                            style={{padding:'2px' ,width:'100px'}}
                                        >   
                                            Upload
                                        </button>
                                    </div> 
                        }
                        </>
                    }
                </div>

                <div className="col-3">
                    <button 
                    type="button" 
                    className="btn btn-outline-success btn-rounded"
                    onClick={()=>{onTemplateSend(userId,item.eventId,item.offeringTypeName)}}
                    style={{padding:'2px' ,width:'100px'}}>
                        Send Report
                    </button>
                </div>

            </div> 
        )
    ]):[]
    return (
        <div className="col-12">
            <div className="card">
                <div className="card-body">
                    <div className="tab-content">
                        <div
                            className="tab-pane show active"
                            id="basic-datatable-preview"
                        >
                            <div id="basic-datatable-wrapper">
                                <Grid
                                    data={data}
                                    columns={[
                                        {name:"Consult With",width:"15%"},
                                        {name:"Consult Type",width:"15%"},
                                        {name:"Consult Date",width:"15%"},
                                        {name:"Report",width:"15%"},
                                        {name:"Action",width:"40%"},
                                    ]}
                                    pagination={{
                                        enabled: true,
                                        limit: 5,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Modal = (props) => {
    return(
            <div className="modal_container">
                <MealBuilder
                    isModal={props.isModal}
                    setToggleModal={props.setToggleModal}
                    userDetails={props.userDetails}
                />
            </div>
    )
}

const CreateUserPlanModal = ({allPlans,userId}) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const[planId,setPlanId] = useState('')
    const [selectedPlan,setSelectedPlan] = useState('')
    const [planPaidCost,setPlanPaidCost] = useState('')
    const [planStartDate,setPlanStartDate] = useState('')
    const handlePlanSubmit = (e) =>{
        e.preventDefault()
        dispatch(createUserPlan({userId,planId:planId.trim(),planPaidCost,planStartDate}))
        history.push('/allUsers')
    } 
    return (
        <div id="create-user-plan-modal" className="modal fade" tabIndex="-1" role="dialog" ariaHidden="true">
            <div className="modal-dialog">
                <div className="modal-content">

                    <div className="modal-body">
                        <h3>Add User To Plan</h3>
                        <form className="ps-2 pe-2">

                            <div className="mb-3 mt-3">
                                <label hmtlFor="planName" className="form-label">Plan Name</label>
                                <select
                                    className="form-select"
                                    id="planName"
                                    value={selectedPlan}
                                    onChange={(e)=>{
                                        setPlanId(e.target.value.split(' ').slice(-1)[0]);
                                        setSelectedPlan(e.target.value)
                                        }
                                    }
                                    >
                                    <option value="" disabled selected>Select a Plan</option>
                                     {allPlans?allPlans.map((item)=>{
                                         return(
                                             <option key={item.planId}>{`${item.planName} - ${item.planId}`}</option>
                                         )
                                     }):null}
                                </select>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="planPaidCost" className="form-label">Plan Paid Cost</label>
                                <input 
                                    className="form-control" 
                                    type="text" 
                                    id="planPaidCost"
                                    value={planPaidCost}
                                    onChange={(e)=>{setPlanPaidCost(e.target.value)}}
                                    placeholder="Plan Paid Cost"/>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="planStartDate" className="form-label">Plan Start Date</label>
                                <input 
                                    className="form-control" 
                                    id="date" 
                                    type="date" 
                                    name="date"
                                    value={planStartDate}
                                    min={moment(new Date()).format("YYYY-MM-DD")}
                                    onChange={(e)=>{setPlanStartDate(e.target.value)}}
                                />

                            </div>
                            
                            <div className="mb-3 text-center">
                                <button 
                                className="btn btn-success" 
                                type="submit"
                                data-bs-toggle="modal"
                                data-bs-target="#create-user-plan-modal"
                                onClick={(e)=>{handlePlanSubmit(e)}}
                                >
                                    Add to Plan
                                </button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

const EditUserPlanModal = ({allPlans, userId, plan, dispatch}) => {
    const history = useHistory()
    const[planId,setPlanId] = useState(plan[0].planId)
    const [selectedPlan,setSelectedPlan] = useState(`${plan[0].planName} - ${plan[0].planId}`)
    const [planPaidCost,setPlanPaidCost] = useState(plan[0].planPaidCost)
    const [planStartDate,setPlanStartDate] = useState(moment(plan[0].planStartDate).format('YYYY-MM-DD'))
    const handlePlanSubmit = (e) =>{
        e.preventDefault()
        dispatch(editUserPlan({userId,planId:planId.trim(),planPaidCost,planStartDate}))
        history.push('/allUsers')
    }

    useEffect(() => {
        setPlanId(plan[0].planId)
        setSelectedPlan(`${plan[0].planName} - ${plan[0].planId}`)
        setPlanPaidCost(plan[0].planPaidCost)
        setPlanStartDate(moment(plan[0].planStartDate).format('YYYY-MM-DD'))
    }, [dispatch, plan])

    return (
        <div id="edit-user-plan-modal" className="modal fade" tabIndex="-1" role="dialog" ariaHidden="true">
            <div className="modal-dialog">
                <div className="modal-content">

                    <div className="modal-body">
                        <h3>Edit User Plan</h3>
                        <form className="ps-2 pe-2">

                            <div className="mb-3 mt-3">
                                <label hmtlFor="planName" className="form-label">Plan Name</label>
                                <select
                                    className="form-select"
                                    id="planName"
                                    value={selectedPlan}
                                    onChange={(e)=>{
                                        setPlanId(e.target.value.split(' ').slice(-1)[0]);
                                        setSelectedPlan(e.target.value)
                                        }
                                    }
                                    >
                                    <option value="" disabled selected>Select a Plan</option>
                                    {allPlans?allPlans.map((item)=>{
                                        return(
                                            <option key={item.planId}>{`${item.planName} - ${item.planId}`}</option>
                                        )
                                    }):null}
                                </select>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="planPaidCost" className="form-label">Plan Paid Cost</label>
                                <input 
                                    className="form-control" 
                                    type="text" 
                                    id="planPaidCost"
                                    value={planPaidCost}
                                    onChange={(e)=>{setPlanPaidCost(e.target.value)}}
                                    placeholder="Plan Paid Cost"/>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="planStartDate" className="form-label">Plan Start Date</label>
                                <input 
                                    className="form-control" 
                                    id="date" 
                                    type="date" 
                                    name="date"
                                    value={planStartDate}
                                    min={moment(new Date()).format("YYYY-MM-DD")}
                                    onChange={(e)=>{setPlanStartDate(e.target.value)}}
                                />

                            </div>
                            
                            <div className="mb-3 text-center">
                                <button 
                                className="btn btn-success" 
                                type="submit"
                                data-bs-toggle="modal"
                                data-bs-target="#edit-user-plan-modal"
                                onClick={(e)=>{handlePlanSubmit(e)}}
                                >
                                    Edit User Plan
                                </button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

const PausePlanModal = ({userId,plan}) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [planPauseDays,setPlanPauseDays] = useState(0)
    const handlePlanPauseSubmit = (e) =>{
        e.preventDefault()
        dispatch(pauseUserPlan({userId,planId:plan[0].planId,planPauseDays}))
        dispatch(getUserDetails(userId));
        history.push(`/allUsers`)
    }
    return (
        <div id="pause-user-plan-modal" className="modal fade" tabIndex="-1" role="dialog" ariaHidden="true">
            <div className="modal-dialog">
                <div className="modal-content">

                    <div className="modal-body">
                        <h3>Pause User Plan</h3>
                        <form className="ps-2 pe-2">

                            <div className="mb-3">
                                <label htmlFor="planPaidCost" className="form-label">Number of Pause Days To Apply</label>
                                <input 
                                    className="form-control" 
                                    type="text" 
                                    id="planPaidCost"
                                    value={planPauseDays}
                                    onChange={(e)=>{setPlanPauseDays(e.target.value)}}
                                    placeholder="Plan Paid Cost"/>
                            </div>
                            
                            <div className="mb-3 text-center">
                                <button 
                                className="btn btn-success" 
                                type="submit"
                                data-bs-toggle="modal"
                                data-bs-target="#pause-user-plan-modal"
                                onClick={(e)=>{handlePlanPauseSubmit(e)}}
                                >
                                    Apply Plan Pause
                                </button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

const handleDeletePlan = (userId,plan,dispatch,history) => {
    Alert.fire({
        title:'Are you sure you want to remove user from plan ?',
        showDenyButton:true,
        showConfirmButton:true,
        confirmButtonText:'Go Ahead !',
        denyButtonText:'Nope Wait',
    }).then(({value})=>{
        if(value){
            dispatch(deleteUserPlan({userId,planId:plan[0].planId}))
            history.push(`/allUsers`)
        }
    })
}

const userOfferingTableData = (planData, dispatch) => {
    const handleAdd = (userId,planId,offeringTypeId) => {
        dispatch(increaseUserOffering({userId,planId,offeringTypeId}))
    }
    
    const handleSubtract = (userId,planId,offeringTypeId) => {
        dispatch(decreaseUserOffering({userId,planId,offeringTypeId}))
    }

    const data = planData?planData[0].offerings.map(({
        userId,
        offeringTypeId,
        planId,
        offeringTypeName,
        defaultOfferingQuantity,
        availableOfferingQuantity
    })=>[
        offeringTypeName,
        _(
            <div className="progress-w-percent mb-0" style={{display:'flex',justifyContent:'space-around'}}>
                <div className="progress progress-sm ">
                    <div 
                        className="progress-bar bg-success" 
                        role="progressbar" 
                        style={{width: `${((defaultOfferingQuantity-availableOfferingQuantity)/defaultOfferingQuantity)*100}%`}} 
                        aria-valuenow={`${Math.round(((defaultOfferingQuantity-availableOfferingQuantity)/defaultOfferingQuantity)*100)}`} 
                        aria-valuemin="0" 
                        aria-valuemax="100">
                    </div>
                </div>
                <span>{defaultOfferingQuantity - availableOfferingQuantity}/{defaultOfferingQuantity}</span>
            </div> 
        ),
        _(
            <div style={{display:'flex', justifyContent:'space-around',}}>
                    <button 
                        type="submit" 
                        className="btn btn-success"
                        onClick={()=>{handleAdd(userId,planId,offeringTypeId)}}
                    >                      
                        <i className="uil-plus"></i>
                    </button>
                    <button 
                        type="submit" 
                        className="btn btn-danger"
                        onClick={()=>{handleSubtract(userId,planId,offeringTypeId)}}
                    >                      
                        <i className="uil-minus"></i>
                    </button>

            </div>
        )
    ]):[]
    return data
}

const renderPlanWindow = (plan,allPlans,userId,dispatch,history) => {
    let body;
    if(plan.length!==0){
        const startDate = moment(plan[0].planStartDate)
        const endDate = moment(plan[0].planEndDate)
        const currentDate = moment()
        const planDuration = endDate.diff(startDate,'days')
        let elapsedDuration = currentDate.diff(startDate,'days')
        if (elapsedDuration<0){
            elapsedDuration=0
        }
        const planElapsedPercentage=(elapsedDuration/(planDuration))*100
        body =
            <>
                <div className="justify-content-between d-flex" style={{marginTop:'1.75rem'}}>
                    <h4 className="header-title">
                        Plan Details
                    </h4>
                    <div>
                        <button 
                                    className="btn btn-outline-info btn-rounded mx-2"
                                    data-bs-toggle="modal" 
                                    data-bs-target="#edit-user-plan-modal"
                                >
                                    Edit User Plan
                        </button>
                        <button 
                                    className="btn btn-outline-warning btn-rounded mx-2"
                                    data-bs-toggle="modal" 
                                    data-bs-target="#pause-user-plan-modal"
                                >
                                    Apply Plan Pause
                        </button>
                        <button 
                                    className="btn btn-outline-danger btn-rounded mx-2"
                                    onClick={()=>{handleDeletePlan(userId,plan,dispatch,history)}}
                                >
                                    Remove User Plan
                        </button>
                    </div>

                    <EditUserPlanModal
                        allPlans={allPlans}
                        userId={userId}
                        plan={plan}
                        dispatch={dispatch}
                    />
                    <PausePlanModal
                        userId={userId}
                        plan={plan}
                    />
                </div>

                <div className="row justify-content-around">
                    <div className="col-lg-10 col-md-10 col-sm-11">
                        <div className="horizontal-steps mt-4 pb-5" id="tooltip-container">
                            <div className="horizontal-steps-content">
                                <div className="step-item">
                                    <span style={{color:'#313A46'}} data-bs-container="#tooltip-container" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Start Date">{startDate.format('YYYY-MM-DD')}</span>
                                </div>
                                <div className="step-item">
                                    <span style={{color:'#313A46'}} data-bs-container="#tooltip-container" data-bs-toggle="tooltip" data-bs-placement="bottom" title="End Date">{endDate.format('YYYY-MM-DD')}</span>
                                </div>
                            </div>

                            <div className="process-line" style={{width: `${planElapsedPercentage}%`, height:'0.25rem'}}></div>
                        </div>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-4">
                        <p className="text-muted">
                            Plan Id:
                            <span className="ms-2">
                                {plan?plan[0].planId:'NA'}
                            </span>
                        </p>
                    </div>
                    <div className="col-4">
                        <p className="text-muted">
                            Plan Name:
                            <span className="ms-2">
                            {plan?plan[0].planName:'NA'}
                            </span>
                        </p>
                    </div>
                    <div className="col-4">
                        <p className="text-muted">
                            Plan Duration:
                            <span className="ms-2">
                            {plan?`${plan[0].planDuration} days`:'NA'}
                            </span>
                        </p>
                    </div>
                    <div className="col-4">
                        <p className="text-muted">
                            Plan Cost:
                            <span className="ms-2">
                            {plan?plan[0].planCost:'NA'}
                            </span>
                        </p>
                    </div>
                    <div className="col-4">
                        <p className="text-muted">
                            Plan Paid Cost:
                            <span className="ms-2">
                            {plan?plan[0].planPaidCost:'NA'}
                            </span>
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="tab-content">
                            <div
                                className="tab-pane show active"
                                id="basic-datatable-preview"
                            >
                                <div id="basic-datatable-wrapper">
                                    <Grid
                                        data={userOfferingTableData(plan, dispatch)}
                                        columns={[
                                            {name:"Offering Name",width:'20%'},
                                            {name:"Progress",width:"50%"},
                                            {name:"Actions",width:"20%"}
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </> 
    }else{
        body = 
            <div className="row">
                <h4>No Plan Purchased</h4>
                <div class="d-flex justify-content-center">

                    <button 
                        className="btn btn-primary w-20"
                        data-bs-toggle="modal" 
                        data-bs-target="#create-user-plan-modal"
                    > 
                            Add User to Plan
                    </button>
                    <CreateUserPlanModal
                        allPlans={allPlans}
                        userId={userId}
                    />
                </div>
            </div>
    }
    
    return body
}

const UserDetailsBody = ({
    dispatch,
    history,
    userId,
    email,
    firstName,
    middleName,
    lastName,
    countryCode,
    callingNumber,
    whatsappNumber,
    age,
    height,
    weight,
    city,
    state,
    patientHistory,
    acqChannel,
    acqOn,
    diagnosis,
    ageOfDiagnosis,
    physicalFitnessLevel,
    workoutType,
    treatmentsTaken,
    currentTreatment,
    programGoal,
    reportUrl,
    healthCoach,
    primaryConcern,
    secondaryConcern,
    symptom,
    abnormalHairGrowth,
    acneAreas,
    cycleDuration,
    cycleLength,
    hairStrandsLost,
    interestArea,
    moodSwings,
    periodIssues,
    weightGained,
    plan,
    allPlans,
    mealTemplates,
    profilePicture,
    userLabTestReport,
}) => {
    const [isModal, setToggleModal] = useState(false);
    const [eventId,setEventId] = useState('');

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "/assets/js/ecommerceApp.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, [dispatch]);
    if(isModal){
        return <Modal
            isModal={isModal}
            setToggleModal={setToggleModal}
            userDetails={{
                userId,
                callingNumber,
                firstName,
                lastName,
                age,
                height,
                weight,
                city,
                symptom,
                programGoal,
                eventId,
            }}
            />
    }
    return (
        <div>
            {/* Top Profile Bar */}
            <div className="row">
                <div className="col-12">
                    <div className="card bg-secondary">
                        <div className="card-body profile-user-box">
                            <div className="row align-items-center">
                                <div
                                    className="col-2 justify-content-center"
                                    style={{ display: "flex" }}
                                >
                                    <div className="avatar-lg">
                                        <img
                                            src={profilePicture ? profilePicture : "/assets/images/users/avatar-9.jpg"}
                                            alt=""
                                            className="rounded-circle "
                                            style={{
                                                height:'100px',
                                                width:'100px',
                                                objectFit:'cover'
                                                }}
                                        />
                                    </div>
                                </div>
                                <div className="col-10">
                                    <div>
                                        <div className="row">
                                            <div className="col-8">
                                                <h4 className="mt-1 mb-1 text-white">
                                                    {`${firstName}`}
                                                </h4>
                                                <p className="font-16 text-white-50">
                                                    <span>
                                                        
                                                        {age!==0 && age?<i className="mdi mdi-account"> </i>:''}
                                                        {age!==0 && age?`${age} years old`:''}
                                                    </span>
                                                    <br />
                                                    <span>
                                                        {city!== '' && city?<i className="mdi mdi-map-marker"> </i>:''}
                                                        {city!== '' && city?`from ${city}, ${state}`:''}
                                                    </span>
                                                </p>
                                            </div>
                                            <div
                                                className="col-4"
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-end",
                                                    paddingRight: "2em",
                                                }}
                                            >
                                                <a
                                                    className="btn btn-link"
                                                    
                                                >
                                                    <span 
                                                        className="text-white"
                                                        onClick={()=>{history.push(`/newUser/${userId}`)}}
                                                    >
                                                        <i className="mdi mdi-pencil"></i>{" "}
                                                        Edit User
                                                    </span>
                                                </a>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-8">
                                                <ul className="mb-0 list-inline text-light">
                                                    <li className="list-inline-item me-3">
                                                        <h5 className="mb-1">{height!==0 && height?`${height.toFixed(2)}cm`:'NA'}</h5>
                                                        <p className="mb-0 font-13 text-white-50">
                                                            Height
                                                        </p>
                                                    </li>
                                                    <li className="list-inline-item me-3">
                                                        <h5 className="mb-1">{weight!==0 && weight?`${weight.toFixed(2)}kg`:'NA'}</h5>
                                                        <p className="mb-0 font-13 text-white-50">
                                                            Weight
                                                        </p>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <h5 className="mb-1">
                                                            {" "}
                                                            {primaryConcern===null || primaryConcern==='' ?interestArea:primaryConcern}
                                                        </h5>
                                                        <p className="mb-0 font-13 text-white-50">
                                                            Primary Concerns
                                                        </p>
                                                    </li>
                                                    {/* <li className="list-inline-item">
                                                        <h5 className="mb-1">
                                                            {" "}
                                                            {secondaryConcern}
                                                        </h5>
                                                        <p className="mb-0 font-13 text-white-50">
                                                            Secondary Concerns
                                                        </p>
                                                    </li> */}
                                                </ul>
                                            </div>
                                            <div
                                                className="col-4"
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-end",
                                                    paddingRight: "2em",
                                                }}
                                            >
                                                <div>
                                                    <h5 className="mb-1 text-white">
                                                        {healthCoach!=='' && healthCoach?healthCoach:'Not Assigned'}
                                                    </h5>
                                                    <p className="mb-0 font-13 text-white-50">
                                                        Health Coach
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* Plan Details Section */}
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            {renderPlanWindow(plan?plan:[],allPlans,userId,dispatch,history)}
                        </div>
                    </div>
                </div>
            </div>

            {/* User Boooked Consult Section */}
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <h4 className="header-title mt-0 mb-4">
                                    User Booked Consultations
                                </h4>

                            </div>
                            <UserConsultTable
                                userId={userId}
                                setToggleModal={setToggleModal}
                                setEventId={setEventId}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* User Lab Report Section */}
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <h4 className="header-title mt-0 mb-4">
                                    User Lab Test Report
                                </h4>

                            </div>
                            

                            <LabTestReportTable
                                userLabTestReport={userLabTestReport}
                                userId={userId}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Patient History Section */}
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="header-title mt-0 mb-4">
                                Patient History
                            </h4>
                            <p className="text-muted font-15">
                                {patientHistory}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Basic Information */}
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="text-start">
                                <h4 className="header-title mt-0 mb-4">
                                    Basic Information
                                </h4>

                                <div className="row">
                                    <div className="col-4">
                                        <p className="text-muted">
                                            Full Name:
                                            <span className="ms-2">
                                                {`${firstName}`}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <p className="text-muted">
                                            Calling Number:
                                            <span className="ms-2">
                                                {countryCode!=='' && countryCode!==null?`+${countryCode}-${callingNumber}`:`${callingNumber}`}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <p className="text-muted">
                                            Whatsapp Number:
                                            <span className="ms-2">
                                                {`${whatsappNumber}`}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <p className="text-muted">
                                            Email:
                                            <span className="ms-2">
                                                {email!==''?email:'NA'}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <p className="text-muted">
                                            Age:
                                            <span className="ms-2">
                                            {age!==0 && age?`${age} years old`:'NA'}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Additional Information */}
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="text-start">
                                <h4 className="header-title mt-0 mb-4">
                                    Additional Information
                                </h4>

                                <div className="row">
                                    <div className="col-4">
                                        <p className="text-muted">
                                            Acquistion Channel:
                                            <span className="ms-2">
                                                {acqChannel!=='' && acqChannel!==null ?acqChannel:'NA'}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <p className="text-muted">
                                            City:
                                            <span className="ms-2">{city!=='' && city!==null ?city:'NA'}</span>
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <p className="text-muted">
                                            State:
                                            <span className="ms-2">
                                                {state!=='' && state!==null ?state:'NA'}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <p className="text-muted">
                                            Created On:
                                            <span className="ms-2">
                                                {moment(acqOn).format(
                                                    "Do MMM YYYY hh:mm:ssa"
                                                )}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Medical Information */}
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="text-start">
                                <h4 className="header-title mt-0 mb-4">
                                    Medical Information
                                </h4>

                                <div className="row">
                                    <div className="col-4">
                                        <p className="text-muted">
                                            Diagnosed:
                                            <span className="ms-2">
                                                {diagnosis!=='' && diagnosis!==null ?diagnosis:'NA'}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <p className="text-muted">
                                            Diagnosed Age:
                                            <span className="ms-2">
                                                {(ageOfDiagnosis!=='' && ageOfDiagnosis!==null ) && ageOfDiagnosis !==0?`${ageOfDiagnosis} years`: 'NA'}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <p className="text-muted">
                                            Symptoms:
                                            <span className="ms-2">
                                                {symptom!=='' && symptom!==null ?symptom:'NA'}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <p className="text-muted">
                                            Physical Fitness Level:
                                            <span className="ms-2">
                                                {physicalFitnessLevel!=='' && physicalFitnessLevel!==null?physicalFitnessLevel:'NA'}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <p className="text-muted">
                                            Workout Types:
                                            <span className="ms-2">
                                                {workoutType!=='' && workoutType!==null?workoutType:'NA'}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <p className="text-muted">
                                            Treatments Taken:
                                            <span className="ms-2">
                                                {treatmentsTaken!=='' && treatmentsTaken!==null ?treatmentsTaken:'NA'}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <p className="text-muted">
                                            Current Treatment:
                                            <span className="ms-2">
                                                {currentTreatment!=='' && currentTreatment!==null ?currentTreatment:'NA'}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <p className="text-muted">
                                            Program Goals:
                                            <span className="ms-2">
                                                {programGoal!=='' && programGoal!==null ?programGoal:'NA'}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <p className="text-muted">
                                            Report URL:
                                            <span className="ms-2">
                                                {reportUrl!==null && reportUrl!==''?<a href={reportUrl}>Link</a>:'NA'}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <p className="text-muted">
                                            Abnormal Hair Growth : 
                                            <span className="ms-2">
                                                {abnormalHairGrowth!=='' && abnormalHairGrowth!==null ?abnormalHairGrowth:'NA'}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <p className="text-muted">
                                            Acne Areas : 
                                            <span className="ms-2">
                                            {acneAreas!=='' && acneAreas!==null?acneAreas:'NA'}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <p className="text-muted">
                                            Cycle Duration : 
                                            <span className="ms-2">
                                            {cycleDuration!=='' && cycleDuration!==null?cycleDuration:'NA'} 
                                            </span>
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <p className="text-muted">
                                            Cycle Lenght : 
                                            <span className="ms-2">
                                            {cycleLength!=='' && cycleLength!==null?cycleLength:'NA'}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <p className="text-muted">
                                            Hair Strands Lost : 
                                            <span className="ms-2">
                                            {hairStrandsLost!=='' && hairStrandsLost!==null ?hairStrandsLost:'NA'}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <p className="text-muted">
                                            Mood Swings :
                                            <span className="ms-2">
                                            {moodSwings!=='' && moodSwings!==null ?moodSwings:'NA'}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <p className="text-muted">
                                            Period Issues :
                                            <span className="ms-2">
                                                {periodIssues!=='' && periodIssues!==null ?abnormalHairGrowth:'NA'}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <p className="text-muted">
                                            Weight Gained In Last Year :
                                            <span className="ms-2">
                                            {weightGained!=='' && weightGained!==null ?weightGained:'NA'}                                                
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <UserBookings userId={userId}/>
        </div>
    );
};

const EventsDetailsTable = ({userId}) => {
    const dispatch = useDispatch()


    useEffect(() => {
        dispatch(getUserBookings(userId));
        dispatch(getUserBookingsPeriodLogs(userId));
        dispatch(getUserBookingsWebinarLogs(userId));
        dispatch(getUserBookingsSymptomLogs(userId));
        dispatch(getUserBookingsConsultLogs(userId));
    }, [dispatch,userId]);

    const userBookings = useSelector(state=>userBookingsSelector(state))
    const periodLogs = useSelector(state=>userBookingsPeriodLogsSelector(state))
    const webinarLogs = useSelector(state=>userBookingsWebinarLogsSelector(state))
    const consultLogs = useSelector(state=>userBookingsConsultLogsSelector(state))
    const symptomLogs = useSelector(state=>userBookingsSymptomLogsSelector(state))
    console.log(symptomLogs)

    const events = []

    if(userBookings)
    {
        userBookings.forEach((item) => {
            events.push({eventId: item.eventId, name: item.eventName, details: item.attendance === 1 ? 'Attended' : 'Not Attended', timeStamp: item.start})
        })
    }
    if(periodLogs)
    {
        let details = "";
        let timeStamp = ""

        periodLogs.forEach((item) => {
            details = details + 'Duration - '+item.duration+'. Flow - '+item.value+" Symptoms - "+item.symptoms + ", ";
            timeStamp = item.startDate
        });

        events.push({eventId: "NA", name: 'Period Log', details , timeStamp})
    }
    if(webinarLogs)
    {
        webinarLogs.forEach((item) => {
            events.push({eventId: item.webinarId, name: 'Webinar', details: item.webinarName, timeStamp: item.start})
        })
    }
    if(consultLogs)
    {
        consultLogs.forEach((item) => {
            events.push({eventId: item.offeringTypeId, name: item.offeringTypeName, details: 'NA', timeStamp: item.start})
        })
    }
    if(symptomLogs)
    {
        let details = "";
        let timeStamp = ""
        symptomLogs.forEach((item) => {
            details = details + item.factor.charAt(0).toUpperCase() + item.factor.slice(1)+" - "+(item.value ? item.value : "NA") + ", ";
            timeStamp = item.start
        })

        events.push({eventId: 'NA', name: 'Symptoms Log', details, timeStamp})
    }

    console.log(events)
    events.sort((a, b) => new Date(b.timeStamp) - new Date(a.timeStamp))

    const data = events?events
    .map((event)=>[
        event.eventId,
        event.name,
        event.details,
        moment(event.timeStamp).format('DD-MM-YYYY hh:mma')
    ]):[]
    return (
        <div className="col-12">
        <div className="card">
            <div className="card-body">
                <div className="tab-content">
                    <div
                        className="tab-pane show active"
                        id="basic-datatable-preview"
                    >

                        <div id="basic-datatable-wrapper">
                            <Grid
                                data={data}
                                columns={[
                                    "Event Id",
                                    "Name",
                                    "Details",
                                    "TimeStamp"
                                ]}
                                search={true}
                                pagination={{
                                    enabled: true,
                                    limit: 20,
                                }}
                            />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

const UserBookings = ({userId}) => {
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(getUserDetails(userId));
    },[dispatch,userId]);

    // const userDetails = useSelector((state) => userSelector(state));


    return (
        <div>
            <h3>Events Table</h3>
            <EventsDetailsTable
                userId={userId}
            />
        </div>
    )
}

const UserDetails = (props) => {
    const userId = props.match.params.userId;
    console.log(userId)
    const dispatch = useDispatch();
    const history = useHistory()
    useEffect(() => {
        dispatch(getUserDetails(userId));
        dispatch(getPlans())
        dispatch(getUserLabReport(userId));

        const script = document.createElement("script");
        script.src = "/assets/js/app.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, [dispatch, userId]);

    const userDetails = useSelector((state) => userSelector(state));
    const allPlans = useSelector((state)=>planSelector(state))
    const userLabTestReport = useSelector((state) => userLabTestReportSelector(state))

    return (
        <Navbar pageTitle={"User Profile"}>
            <UserDetailsBody
                dispatch={dispatch}
                history={history}
                userId={userId ? userId : ""}
                email={userDetails ? userDetails.email : ""}
                firstName={userDetails ? userDetails.firstName : ""}
                middleName={userDetails ? userDetails.middleName : ""}
                lastName={userDetails ? userDetails.lastName : ""}
                profilePicture={userDetails ? userDetails.profilePicture : ""}
                countryCode = {userDetails ? userDetails.countryCode : ""}
                callingNumber={userDetails ? userDetails.callingNumber : ""}
                whatsappNumber={userDetails ? userDetails.whatsappNumber : ""}
                age={userDetails ? userDetails.age : ""}
                height={userDetails ? userDetails.height : ""}
                weight={userDetails ? userDetails.weight : ""}
                city={userDetails ? userDetails.city : ""}
                state={userDetails ? userDetails.state : ""}
                patientHistory={userDetails ? userDetails.patientHistory : ""}
                acqChannel={userDetails ? userDetails.acqChannel : ""}
                acqOn={userDetails ? userDetails.acqOn : ""}
                diagnosis={userDetails ? userDetails.diagnosis : ""}
                ageOfDiagnosis={userDetails ? userDetails.ageOfDiagnosis : ""}
                physicalFitnessLevel={userDetails ? userDetails.physicalFitnessLevel : ""}
                workoutType={userDetails ? userDetails.workoutType : ""}
                treatmentsTaken={userDetails ? userDetails.treatmentsTaken : ""}
                currentTreatment={userDetails ? userDetails.currentTreatment : ""}
                programGoal={userDetails ? userDetails.programGoal : ""}
                reportUrl={userDetails ? userDetails.reportUrl : ""}
                healthCoach={userDetails ? userDetails.healthCoach : ""}
                BMI={userDetails ? userDetails.BMI : ""}
                primaryConcern={userDetails ? userDetails.primaryConcern : ""}
                secondaryConcern={userDetails ? userDetails.secondaryConcern : ""}
                symptom={userDetails ? userDetails.symptom : ""}
                abnormalHairGrowth={userDetails ? userDetails.abnormalHairGrowth : ""}
                acneAreas={userDetails ? userDetails.acneAreas : ""}
                cycleDuration={userDetails ? userDetails.cycleDuration : ""}
                cycleLength={userDetails ? userDetails.cycleLength : ""}
                hairStrandsLost={userDetails ? userDetails.hairStrandsLost : ""}
                interestArea={userDetails ? userDetails.interestArea : ""}
                moodSwings={userDetails ? userDetails.moodSwings : ""}
                periodIssues={userDetails ? userDetails.periodIssues : ""}
                weightGained={userDetails ? userDetails.weightGained : ""}
                plan={userDetails? userDetails.plan:[]}
                allPlans={allPlans?allPlans:[]}
                mealTemplates={userDetails? userDetails.mealTemplates:[]}
                userLabTestReport={userLabTestReport}
            />
        </Navbar>
    );
};

export default UserDetails;
