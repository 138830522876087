export const PAYMENTS_PLAN = `[PAYMENTS_PLAN]`;
export const PAYMENTS_PLAN_DETAILS = `[PAYMENTS_PLAN_DETAILS]`;

export const PAYMENTS_PLAN_GET = `${PAYMENTS_PLAN} GET`;
export const PAYMENTS_PLAN_SET = `${PAYMENTS_PLAN} SET`;

export const PAYMENTS_PLAN_DETAILS_GET = `${PAYMENTS_PLAN_DETAILS} GET`;
export const PAYMENTS_PLAN_DETAILS_SET = `${PAYMENTS_PLAN_DETAILS} SET`;

export const PAYMENTS_PLAN_CREATE = `${PAYMENTS_PLAN} CREATE`;

export const PAYMENTS_PLAN_UPDATE = `${PAYMENTS_PLAN} UPDATE`;

export const getPaymentsPlan = (data) => ({
    type: PAYMENTS_PLAN_GET,
    payload: data
})

export const setPaymentsPlan = (data) => ({
    type: PAYMENTS_PLAN_SET,
    payload: data
})

export const createPaymentsPlan = (data) => ({
    type: PAYMENTS_PLAN_CREATE,
    payload: data
})

export const getPaymentsPlanDetails = (data) => ({
    type: PAYMENTS_PLAN_DETAILS_GET,
    payload: data
})

export const setPaymentsPlanDetails = (data) => ({
    type: PAYMENTS_PLAN_DETAILS_SET,
    payload: data
})

export const updatePaymentsPlan = (data) => ({
    type: PAYMENTS_PLAN_UPDATE,
    payload: data
})