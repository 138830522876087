import React, { useEffect, useState } from 'react';

import { contentTopicsSelector } from "../../../selector/content";
import { TopicContentMapSelector } from "../../../selector/topicContent.js"
import { useSelector, useDispatch } from "react-redux";

import { addTopicContentMap, getTopicContentMap, deleteTopicContentMap, setTopicContentMap } from "../../../actions/topicsContentMap";

const TopicContentMap = (props) => {
  const { selectedContent } = props;
  const dispatch = useDispatch();

  const [activeTopic, setActiveTopic] = useState([])
  const topicItem = useSelector((state) => contentTopicsSelector(state));
  const topicContentMap = useSelector((state) => TopicContentMapSelector(state));


  useEffect(() => {
    if(selectedContent && selectedContent.content_id){
        dispatch(getTopicContentMap({contentId: selectedContent.content_id}));
    } else {
      dispatch(setTopicContentMap(null))
    }
  },[selectedContent, dispatch])

  useEffect(() => {
    if(topicContentMap.data && topicContentMap.data.map){
        const arr = [];
        topicContentMap.data.map.forEach(i => arr.push(i.topic_id))
        props.setTopicId(topicContentMap.data.map[0].topic_id)
        setActiveTopic(arr)
    } else {
      setActiveTopic([])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[dispatch, topicContentMap, topicContentMap.fetching])

  const addTopic = (topicId) => {
    if(selectedContent){
    dispatch(addTopicContentMap({contentId: selectedContent.content_id, topicId}));
    } else {
      props.setTopicId(topicId)
      setActiveTopic(topicId)
    }
  }

  const removeTopic = (topicId) => {
    const targetTopicContentRecord = topicContentMap.data.map.filter(i => i.topic_id === topicId[0]);
    const data = {topicContentId: targetTopicContentRecord[0].topic_content_id, contentId: targetTopicContentRecord[0].content_id};
    dispatch(deleteTopicContentMap(data));
  }

  if(topicContentMap.fetching){
    <div>Fetching map data</div>
  }
  // if(!selectedContent || !selectedContent.content_id){
  //   return<div>Add content to attach topics</div>
  // }
  return(
    <div>
      <div style={{"display": "flex"}}>
        {
          topicItem && topicItem.data && topicItem.data.map(i => {
            if(activeTopic && activeTopic.includes(i.topic_id)){
              return (
                <div
                  style={{"border": "solid 1px white", "margin": "6px", "padding": "6px", "borderRadius": "10px","backgroundColor": "#f5735a", "color": "white", "cursor": "pointer", position: 'relative'}}
                  onClick={() => selectedContent ? removeTopic(activeTopic.filter(j => j === i.topic_id)) : setActiveTopic([])}
                >
                  {/* <div style={{ 'height': '20px', 'width': '20px', 'backgroundColor': 'red', display: 'flex', justifyContent: 'center', alignItems: 'center', 'borderRadius': '15px', 'position': 'absolute', right: -6, top: -6}}>
                    X
                  </div> */}
                  {i.topicName}
                  <div style={{
                    'position': 'absolute', 'top': '-8px', 'right': '-8px', 'width': '20px', 'height': '20px', 'borderRadius': '10px', 'backgroundColor': '#fff', 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center', 'border': '1px solid #dc3545'}}>
                      <i style={{ color: '#dc3545'}} class="mdi mdi-close"></i>
                  </div>
                </div>
              )
            } else {
              return null
            }
          })
        }
        {
          activeTopic.length === 0 && topicItem && topicItem.data && topicItem.data.map(i => {
            if(activeTopic && activeTopic.includes(i.topic_id) === false){
              return (
                <div
                  style={{"border": "solid 1px black", "margin": "6px", "padding": "6px", "borderRadius": "10px", "cursor": "pointer" }}
                  onClick={() => addTopic(i.topic_id)}
                >
                  {i.topicName}
                </div>
              )
            } else {
              return null
            }
          })
        }
          <div></div>
      </div>
    </div>
  )
}

export default TopicContentMap;