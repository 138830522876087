
import React from 'react';
import { Bar} from 'react-chartjs-2';
import 'chart.js/auto';
const options = {
  plugins: {
    legend: false,
    datalabels: {
        display: true,
        align: 'center',
        anchor: 'center'
     }
  },
  elements:{
      line:{
        borderJoinStyle:'bevel' 
      }
  },scales: {
    x: {
      grid: {
        display: false,
      }
    },
}
  
};

const VerticalBarChart = ({title,xaxis,yaxis}) => {
    const data = {
        labels: xaxis,
        datasets: [
          {
            data: yaxis,
            backgroundColor: [
                'rgba(75, 192, 192, 0.4)'
            ],
            borderColor: [
                'rgba(75, 192, 192, 0.4)'
            ],
            borderWidth: 0.5,
            
          },
        ],
      };
  return(
  <div className="card">
    <div className='header'>
      <h4 className='title d-flex justify-content-center'>{title}</h4>
    </div>
    <Bar data={data} options={options} />
  </div>)
}
;

export default VerticalBarChart;