import { apiRequest,API_SUCCESS,API_ERROR } from "../../../actions/api"
import {ROOT_URL} from '../../../config'
import Alert from "sweetalert2";
import { LAB_REPORT_PRESIGNED_URL, LAB_REPORT_PRESIGNED_URL_GET, setLabReportPresignedUrl} from "../../../actions/labTests";
import { MEAL_TEMPLATE_PRESIGNED_URL, MEAL_TEMPLATE_PRESIGNED_URL_GET, setMealTemplatePresignedUrl } from "../../../actions/meals";

export const fileUploadMiddleware = ({dispatch}) => next => (action) => {
    next(action)
    switch(action.type){
            
        case LAB_REPORT_PRESIGNED_URL_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/labReportPresignedUrl`,
                method:'get',
                feature:LAB_REPORT_PRESIGNED_URL           
            }))
            break;
        
        case `${LAB_REPORT_PRESIGNED_URL} ${API_SUCCESS}`:
            if(action.message==='Presigned Upload Url'){
                dispatch(setLabReportPresignedUrl(action.payload))
            }
            break;

        case `${LAB_REPORT_PRESIGNED_URL} ${API_ERROR}`:
            if(action.message==='Presigned Url could not be generated'){
                Alert.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: action.message,
                    showConfirmButton: false,
                    timer: 1500
                    })
            }
            break;  

        case MEAL_TEMPLATE_PRESIGNED_URL_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/mealTemplatePresignedUrl`,
                method:'get',
                feature:MEAL_TEMPLATE_PRESIGNED_URL
            }))
            break;

        case `${MEAL_TEMPLATE_PRESIGNED_URL} ${API_SUCCESS}`:
            if(action.message==='Presigned Upload Url'){
                dispatch(setMealTemplatePresignedUrl(action.payload))
            }
            break;
        case `${MEAL_TEMPLATE_PRESIGNED_URL} ${API_ERROR}`:
            if(action.message==='Presigned Url could not be generated'){
                Alert.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: action.message,
                    showConfirmButton: false,
                    timer: 1500
                    })
            }
            break;
        
        default: break;
    }
}