import { USER_SYMPTOM_SET } from "../../actions/userSymptom"

const initState = []
export const userSymptomReducer = (state=initState,action) =>{
    switch(action.type){
        case USER_SYMPTOM_SET:
            return {...state,userSymptoms:action.payload}
        default: return state
    }
}
