/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/aria-proptypes */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, _ } from 'gridjs-react';
import moment from "moment";

import Navbar from "../../navbar";
import {getTopics, createTopic, deleteTopic, updateTopic} from "../../../actions/content/index";
import { contentTopicsSelector } from "../../../selector/content";
import TopicForm from "./topicForm";


// import { useHistory } from "react-router";

import "./topics.css"

const TopicItems = ({topicItems, deleteTopicAction}) => {
    if(!topicItems || topicItems.error){
        return <div>Something went wrong or no topics available</div>
    } else if(topicItems.fetching){
        return <div>Fetching data</div>
    } else if(topicItems.data.length === 0){
        return <div>No topics added yet</div>
    }
    
    const columns = ['Topic Name', 'Thumbnail', 'Createrd at', 'Actions'];
    const data = [];
    topicItems.data.forEach(element => {
        const current = [
             element.topicName,
             _(
                    <img
                    src={element.thumbnail_url}
                    alt="thumbnail"
                    style={{ width: 80, height: 80, backgroundColor: 'grey', borderRadius: 40}}
                />
             ),
             moment(element.created_at).format("MMM Do YY, h:mm a"),
              _(
                <button
                type="button"
                className="btn"
                style={{ backgroundColor: "#fa5c7c", color: "white" }}
                onClick={() => deleteTopicAction(element.topic_id)}
                >
                Delete
                </button>
            )
            ]
        data.push(current)
    })


    return (
        <div className="topics__collections">
            <Grid
                data={data}
                columns={columns}
                search={true}
                pagination={{
                    enabled: true,
                    limit: 10,
                }}
                sort={true}
            />
        </div>
    )
}

const Topics = (props) => {
    // const userId = props.match.params.userId;
    const dispatch = useDispatch();
    const [show, toggleShow] = useState(false);

    // const history = useHistory()

    useEffect(()=>{
        dispatch(getTopics());
    },[dispatch])

    const createTopicAction = (data) => {
        const {
            topicName,
            heroUrl,
            thumbnailUrl
        } = data;
        const playLoad = {
            topicName,
            heroUrl,
            thumbnailUrl
        }
        dispatch(createTopic(playLoad))
    }

    const updateTopicAction = (data) => {
        const {
            topicName,
            heroUrl,
            thumbnailUrl,
            topicId
        } = data;
        const playLoad = {
            topicName,
            heroUrl,
            thumbnailUrl,
            topicId
        }
        dispatch(updateTopic(playLoad))
    }

    const deleteTopicAction = (data) => {
        const playLoad = {topicId: data}
        dispatch(deleteTopic(playLoad))
    }

    const topicItems = useSelector((state) => contentTopicsSelector(state));

    return (
        <Navbar pageTitle={"Topics"}>
            <div>
                <button
                    type="button"
                    className="btn"
                    style={{ backgroundColor: "#373750", color: "white", "marginBottom": "40px", "width": "200px" }}
                    onClick={() => toggleShow(!show)}
                    >
                    {show ? "View all Topics" : "Add new Topic"}
                </button>

                {
                    show ?
                        <div>
                            <TopicForm createTopicAction={createTopicAction} toggleShow={toggleShow} show={show}/>
                        </div>
                        : 
                        <TopicItems
                            topicItems={topicItems} deleteTopicAction={deleteTopicAction}
                            updateTopicAction={updateTopicAction}
                        />
                }
            </div>
        </Navbar>
    );
};

export default Topics;
