/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useEffect,useState} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import Navbar from '../../navbar'
import { Grid, _ } from "gridjs-react";
import { getRazorpayLogs } from '../../../actions/razorpay';
import { razorpayCapturedTransactionAmountSelector, razorpayCapturedTransactionSelector, razorpayLogsSelector } from '../../../selector/razorpay';
import moment from 'moment';
import AnalyticsCard from '../../utils/AnalyticsCard';
const Table = ({logs}) => {
    const data = logs?logs.map((log)=>[
        _(
            <a
                href={`https://dashboard.razorpay.com/app/payments/${log.paymentId}`}
            >
                {log.paymentId}
            </a>
        ),
        log.orderId,
        _(<span>&#8377; {log.amount}</span>),
        _(<span style={{whiteSpace: 'nowrap', float: 'left'}}>{moment(log.createdAt).format('DD-MM-YYYY HH:mm')}</span>),
        log.method,
        _(
            log.status==='captured'?
            <span className={`badge bg-success`}>Captured</span> 
            :log.status==='refunded'?
            <span className={`badge bg-warning`}>Refunded</span>:
            <span className={`badge bg-danger`}>Failure</span>
        ),
        log.email?log.email:'NA',
        log.contact?log.contact:'NA'
    ]):[]
    return(
        <div className="col-12">
            <div className="card">
                <div className="card-body">
                    <div className="tab-content">
                        <div
                            className="tab-pane show active"
                            id="basic-datatable-preview"
                        >
                            <div id="basic-datatable-wrapper">
                                <Grid
                                    data={data}
                                    columns={[
                                        "Payment ID",
                                        "Order ID",
                                        "Amount (INR)",
                                        "Date",
                                        "Method",
                                        "Status",
                                        "Email",
                                        "Contact",
                                    ]}
                                    search={true}
                                    pagination={{
                                        enabled: true,
                                        limit: 20,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const RazorpayLogs = () => {
    const [start,setStart] = useState()
    const [end,setEnd] = useState()
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(getRazorpayLogs())
    },[dispatch])
    const logs = useSelector(state=>razorpayLogsSelector(state))
    const capturedTransactions = useSelector(state=>razorpayCapturedTransactionSelector(state))
    const capturedTransactionsAmount = useSelector(state=>razorpayCapturedTransactionAmountSelector(state))

    const handleStart = (e) => {
        setStart(e.target.value)
    }
    const handleEnd = (e) => {
        setEnd(e.target.value)
    }
    const handleClear = () => {
        dispatch(getRazorpayLogs())
        setStart('')
        setEnd('')
    }
    const handleSearch = () => {
        dispatch(getRazorpayLogs({start,end}))
    }

    return (
        <div>
            <Navbar>
                <div className="page-title-box">
                    <div className="page-title-right">
                        <form className="d-flex">
                            <div className="input-group mx-2">
                                <label
                                    htmlFor="start"
                                    className="col-3 mt-1"
                                >
                                    Start : 
                                </label>
                                <input 
                                    className="form-control" 
                                    id="date" 
                                    type="date" 
                                    name="date"
                                    value={start}
                                    onChange={(e)=>{handleStart(e)}}
                                    />
                            </div>
                            <div className="input-group">
                                <label
                                    htmlFor="end"
                                    className="col-3 mt-1"
                                >
                                    End : 
                                </label>
                                <input 
                                    className="form-control" 
                                    id="end" 
                                    type="date" 
                                    name="date"
                                    value={end}
                                    onChange={(e)=>{handleEnd(e)}}
                                    />
                            </div>
                            <a 
                                className="btn btn-primary ms-2"
                                onClick={()=>{handleSearch()}}
                                >
                                <i class="uil-search"></i>
                            </a>
                            <a 
                                className="btn btn-danger ms-2"
                                onClick={()=>{handleClear()}}
                            >
                                <i class="uil-multiply"></i>
                            </a>
                        </form>
                    </div>
                    <h3 className="page-title">Razorpay Logs</h3>
                </div>
                <div>
                <div className="row">
                <div className="col-xl-8 col-lg-6">
                    <div className="row">
                        <AnalyticsCard
                            title="Captured Transactions"
                            data={capturedTransactions}
                            info1=''
                            info2=''
                        />
                        <AnalyticsCard
                            title="Transaction Amount"
                            data={capturedTransactionsAmount}
                            info1=''
                            info2=''
                        />

                    </div>
                </div>
            </div>
                </div>
                <Table
                    logs={logs}
                />
            </Navbar>
        </div>
    )
}

export default RazorpayLogs
