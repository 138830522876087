/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef, useCallback, useMemo} from "react";
import Navbar from "../navbar";
import { getChallengeEngagement, getChallengeDetails } from "../../actions/challenges";
import { challengeEngagementSelector, challengeDetailsSelector } from "../../selector/challenges";
import moment from "moment";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import {DateRangePicker} from "react-dates";
import { useDispatch, useSelector } from "react-redux";

const ChallengeDetails = ({
    challengeName,
    challengeStartDate,
    challengeEndDate,
    challengeCurrentCapacity,
    challengeId,
}) => {
    return (
        <div className="row">
            <div className="col-12">
                <div className="card bg-secondary">
                    <div className="card-body profile-user-box">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <div>
                                    <div className="row">
                                        <div className="col-9">
                                            <h1 className="mt-1 mb-1 text-white">
                                                {challengeName}
                                            </h1>
                                            <h3 className="text-white-50 mb-1">
                                                Current Capacity: {challengeCurrentCapacity} | {challengeId}
                                            </h3>
                                            <h3 className="text-white mb-1">
                                                Start: {moment(challengeStartDate).format('DD-MM-YYYY')} End: {moment(challengeEndDate).format('DD-MM-YYYY')}
                                            </h3>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const PersonalChallengeEngagement = (props) => {

    const dispatch = useDispatch();
    const challengeId = "CHA0024";

    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    const [focusedInput, setFocusedInput] = useState();

    useEffect(() => {
        dispatch(getChallengeDetails(challengeId))
        dispatch(getChallengeEngagement(challengeId));
    }, [dispatch, challengeId])

    const challengeEngagement = useSelector((state) => challengeEngagementSelector(state));
    const challengeDetails = useSelector(state=>challengeDetailsSelector(state))
    const allTask = challengeDetails.challengeTask ? challengeDetails.challengeTask : {};

    let data = (challengeEngagement.length !== 0) ? challengeEngagement.map((item) => {
        // const points = item.task.length !== 0 ? item.task.reduce((total, task) => {
        //     if(task.status === 1)
        //     {
        //         return total+task.taskReward;
        //     }
        //     return total;
        // }, 0):0;

        // const totalPoints = item.task.length !== 0 ? item.task.reduce((total, task) => {
        //     return total+task.taskReward;
        // }, 0):0;

        const engagement = {
            userId: item.userId,
            userName: item.userName
        }

        const taskMap = new Map();
        if(item.task.length !== 0)
        {
            item.task.forEach((task) => {
                const day = moment(task.taskDate).format("YYYY-MM-DD")
                if(!taskMap.has(day))
                {
                    taskMap.set(day, (task.status === 1 ? task.taskReward : 0))
                }
                else{
                    taskMap.set(day, taskMap.get(day) + (task.status === 1 ? task.taskReward : 0))
                }
            })
        }

        let taskDateRewardArray = []

        taskMap.forEach((value, key) => {
            taskDateRewardArray.push([key, value])
        })

        taskDateRewardArray.sort((a,b)=> {
            const date1 = new Date(a[0]);
            const date2 = new Date(b[0]);
            
            return date1 - date2;
        })

        let dataObj = []
        if(allTask.length !== 0){
            dataObj = Object.entries(allTask)
        }

        let overallAcheivedRewards = 0, overallTotalRewards = 0, engagementCount = 0, totalEngagementDay = 0;

       
        dataObj.forEach(([key,value],index) => {
            let rewardAcheived = 0
            let totalReward = 0

            let currentDate="", a="", b="", diffDays="";

            if(taskDateRewardArray.length > index)
            {
                currentDate = moment().format('YYYY-MM-DD')
                a = moment(taskDateRewardArray[index][0],'YYYY-MM-DD');
                b = moment(currentDate,'YYYY-MM-DD');
                diffDays = a.diff(b, 'days')+1;
                rewardAcheived = rewardAcheived + taskDateRewardArray[index][1];
            }

            if(diffDays <= 0)
            {
                totalEngagementDay++;
            }

            // if(taskMap.has(key))
            // {
            //     rewardAcheived = rewardAcheived + taskMap.get(key);
            // }

            if(value.length !== 0) 
            {
                value.forEach((task) => {
                    totalReward = totalReward + task.taskReward
                })
            } 

            overallAcheivedRewards += rewardAcheived;
            overallTotalRewards += totalReward;

            if(rewardAcheived !== 0)
            {
                engagementCount = engagementCount + 1;
            }

            engagement[`day${index+1}`] = `${rewardAcheived}/${totalReward}|${taskDateRewardArray.length > index ? taskDateRewardArray[index][0] : ''}`
        })

        engagement['points'] = `${overallAcheivedRewards}/${overallTotalRewards}`;
        engagement['engagement'] = Math.floor((engagementCount/(totalEngagementDay === 0 ? 1 : totalEngagementDay))*100)+"%"

        return engagement;
    }) : [];

    const dateRange = Object.keys(allTask).length

    const column = [
        {headerName:"User Id", field:"userId", resizable:true, sortable:true, filter:true, width:150, suppressNavigable: true, cellClass: 'no-border'},
        {headerName:"User Name", field:"userName", resizable:true, sortable:true, filter:true, width:150, suppressNavigable: true, cellClass: 'no-border'},
        {headerName:"Points", field:"points",resizable:true, sortable:true, filter:true, width:160, suppressNavigable: true, cellClass: 'no-border'},
        {headerName:"Engagement", field:"engagement", resizable:true, sortable:true, filter:true, width:150, suppressNavigable: true, cellClass: 'no-border'},
    ]

    const exportColumnKeys = ['userId', "userName", "points", "engagement"]

    const [columnDefs, setColumnDefs] = useState(column)
    const [exportColumn, setExportColumn] = useState(exportColumnKeys)
    const [tableData, setTableData] = useState(data)

    useEffect(() => {
        setTableData(data)
    }, [data.length])

    useEffect(() => {
        let dataObj = []
        if(allTask.length !== 0){
            dataObj = Object.entries(allTask)
        }

        dataObj.forEach(([key,value],i) =>
        {
            const currentDate = moment().format('YYYY-MM-DD')

            var a = moment(key,'YYYY-MM-DD');
            var b = moment(currentDate,'YYYY-MM-DD');
            var diffDays = a.diff(b, 'days');

            exportColumnKeys.push(`day${i+1}`)

            if(diffDays === 0)
            {
                column.push({
                    headerName:`Day ${i+1}`, 
                    field:`day${i+1}`, 
                    cellRenderer: params =>  (!params.value) ? "0/0" : params.value.split('|')[0],
                    resizable:true, 
                    sortable:true, 
                    filter:true, 
                    width:150, 
                    suppressNavigable: true, 
                    cellClass: 'no-border', 
                    cellStyle: params => {
                        const value = params.value.split('|')[0];
                        const date = moment(params.value.split('|')[1], 'YYYY-MM-DD');
                        const currentDate = moment().format('YYYY-MM-DD')
                        const diff = date.diff(currentDate, 'days');

                        if(diff === 0)
                        {
                            return {backgroundColor: 'rgb(255, 220, 168)'};
                        }

                        if (value && value.split('/')[0] === '0' && value.split('/')[1] !== '0') {
                            return {color: 'red', };
                        }
                        else if(value && value.split('/')[0] !== '0' && value.split('/')[1] !== '0' && value.split('/')[0] === value.split('/')[1])
                        {
                            return {color: "Green", }
                        }
                    }
                })
            }
            else if(diffDays > 0)
            {
                column.push({
                headerName:`Day ${i+1}`, 
                field:`day${i+1}`, 
                cellRenderer: params =>  (!params.value) ? "0/0" : params.value.split('|')[0],
                resizable:true, 
                sortable:true, 
                filter:true, 
                width:150, 
                suppressNavigable: true, 
                cellClass: 'no-border', 
                cellStyle: params => {
                        const value = params.value.split('|')[0];
                        const date = moment(params.value.split('|')[1], 'YYYY-MM-DD');
                        const currentDate = moment().format('YYYY-MM-DD')
                        const diff = date.diff(currentDate, 'days');
    
                        if(diff === 0)
                        {
                            return {backgroundColor: 'rgb(255, 220, 168)'};
                        }
    
                        if (value && value.split('/')[0] === '0' && value.split('/')[1] !== '0') {
                            return { backgroundColor: 'rgb(209, 209, 224)'};
                        }
                        else if(value && value.split('/')[0] !== '0' && value.split('/')[1] !== '0' && value.split('/')[0] === value.split('/')[1])
                        {
                            return {backgroundColor: 'rgb(209, 209, 224)'}
                        }
                        // return {backgroundColor: 'rgb(209, 209, 224)'};
                }
            })
            }
            else{
                column.push({
                    headerName:`Day ${i+1}`, 
                    field:`day${i+1}`, 
                    cellRenderer: params =>  (!params.value) ? "0/0" : params.value.split('|')[0],
                    resizable:true, 
                    sortable:true, 
                    filter:true, 
                    width:150, 
                    suppressNavigable: true, 
                    cellClass: 'no-border',
                    cellStyle: params => {
                        
                        const value = params.value.split('|')[0];
                        const date = moment(params.value.split('|')[1], 'YYYY-MM-DD');
                        const currentDate = moment().format('YYYY-MM-DD')
                        const diff = date.diff(currentDate, 'days');

                        if(diff === 0)
                        {
                            return {backgroundColor: 'rgb(255, 220, 168)'};
                        }

                        if (value && value.split('/')[0] === '0' && value.split('/')[1] !== '0') {
                            return {color: 'red'};
                        }
                        else if(value && value.split('/')[0] !== '0' && value.split('/')[1] !== '0' && value.split('/')[0] === value.split('/')[1])
                        {
                            return {color: "Green"}
                        }
                        return null
                    }
                })
            }
        })
        setColumnDefs(column)
        setExportColumn(exportColumnKeys)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange])

    const handleDateChange = (startDate, endDate) => {
        setStartDate(startDate);
        setEndDate(endDate);

        if(startDate && endDate)
        {
            data = data.filter((item) => {
                if(item.day1)
                {
                    const date = moment(item.day1.split('|')[1],'YYYY-MM-DD');
                    // const startDate = moment(startDate,'YYYY-MM-DD');
                    // const endDate = moment(endDate,'YYYY-MM-DD');
                    const startDateDiff = date.diff(startDate, 'days');
                    const endDateDiff = endDate.diff(date, 'days')
                    console.log(startDateDiff, endDateDiff)
    
                    if(startDateDiff >= 0 && endDateDiff >= 0)
                    {
                        return item;
                    }
                    return null;
                }
                return null;
            })
            setTableData(data)
        }
    }

    const gridRef = useRef();

    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current.api.setQuickFilter(
            document.getElementById('filter-text-box').value
        );
    }, []);
    
    // const onPrintQuickFilterTexts = useCallback(() => {
    //     gridRef.current.api.forEachNode(function (rowNode, index) {
    //         console.log(
    //         'Row ' +
    //             index +
    //             ' quick filter text is ' +
    //             rowNode.quickFilterAggregateText
    //         );
    //     });
    // }, []);

    const onBtnExport = useCallback(() => {
        const exportCols = {
            columnKeys: exportColumn,
        };
        gridRef.current.api.exportDataAsCsv(exportCols);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const popupParent = useMemo(() => {
        return document.body;
    }, []);

    const getRowStyle = params => {
        return { fontFamily: "Cerebri Sans", fontSize: "0.9rem", border: "none", borderBottom: "1px solid #eef2f7"};
    };


    return (
            <Navbar>
            <div className="row mb-3">
                    <div className="row mb-3">
                        <div className="col-8">
                            <h3 className="page-title page-title-text">
                            Challenge Engagement Analytics
                            </h3>
                        </div>
                        <div className="col-4 d-flex justify-content-end">
                            <DateRangePicker
                                startDate={startDate}
                                startDateId="start-date"
                                endDate={endDate}
                                endDateId="end-date"
                                onDatesChange={({ startDate, endDate }) => {
                                    handleDateChange(startDate, endDate)
                                }}
                                focusedInput={focusedInput}
                                onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                                displayFormat="DD/MM/YYYY"
                                isOutsideRange={() => false}
                                minimumNights={0}
                                pagination={true} 
                                paginationPageSize={20}
                            />
                        </div>
                    </div>
                    
                    <div>
                        <ChallengeDetails
                            challengeName={challengeDetails.challengeName}
                            challengeStartDate={challengeDetails.challengeStartDate}
                            challengeEndDate={challengeDetails.challengeEndDate}
                            challengeId={challengeId}
                            challengeCurrentCapacity={challengeDetails.challengeCurrentCapacity}
                        />
                    </div>
            </div>
               
            
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="tab-content">
                                <div
                                    className="tab-pane show active"
                                    id="basic-datatable-preview"
                                >
    
                                    <div id="basic-datatable-wrapper" className="ag-theme-material" style={{height: '100vh'}}>
                                        <div className="col d-flex justify-content-between px-3">
                                            <input 
                                                type="text"
                                                id="filter-text-box"
                                                placeholder="Type a keyword..." 
                                                aria-label="Type a keyword..." 
                                                className="gridjs-input gridjs-search-input mb-2" 
                                                onInput={onFilterTextBoxChanged}
    
                                            />
                                            <div>
                                                <a 
                                                    className="btn btn-info ms-2"
                                                    onClick={onBtnExport}
                                                    style={{width:'45px', height:'45px'}}
                                                    
                                                    >
                                                    <i class="dripicons-download"></i>
                                                </a>
                                            </div>
                                            
                                        </div>
    
                                        <AgGridReact
                                            ref={gridRef}
                                            rowData={tableData}
                                            columnDefs={columnDefs}
                                            pagination={true} 
                                            paginationPageSize={20}
                                            cacheQuickFilter={true}
                                            suppressExcelExport={true}
                                            popupParent={popupParent}
                                            enableCellTextSelection={true}
                                            suppressCellSelection={true}
                                            getRowStyle={getRowStyle}
                                        />
                                    </div>
    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </Navbar>
    )
}

export default PersonalChallengeEngagement;