import React from 'react'
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';

const ExpandedDonut = ({title, data, legend}) => {

    const options = {
        plugins: {
            legend:false,
           title: {
            display: true,
            align: 'center'
            }
        },
        maintainAspectRatio: false,
        cutout: 50
      };

    // const colorMap = {
    //     'Nutrition Consultation': 'rgba(235, 59, 90,1.0)',
    //      Yoga: 'rgba(32, 191, 107,1.0)',
    //     'Beginner Yoga': 'rgba(15, 185, 177,1.0)',
    //     'Power Yoga':'rgba(154, 236, 219,1.0)',
    //     'Pop Pilates':'rgba(254, 211, 48,1.0)' ,
    //     'Expert Consultation':'rgba(179, 55, 113,1.0)',
    //      Zumba: 'rgba(130, 88, 159,1.0)',
    //     'Strength Training':'rgba(27, 156, 252,1.0)' 
    // }

    const colorMap = [
        'rgba(55, 55, 80, 1)',
        'rgba(32, 191, 107,1.0)',
        'rgba(245, 115, 90, 0.9)',
        'rgba(15, 185, 177,1.0)',
        'rgba(154, 236, 219,1.0)',
        'rgba(254, 211, 48,1.0)' ,
        'rgba(179, 55, 113,1.0)',
        'rgba(130, 88, 159,1.0)',
        'rgba(172, 156, 252,1.0)',
        'rgba(27, 156, 222,1.0)',
        'rgba(72, 156, 242,1.0)',
        'rgba(27, 156, 152,1.0)',
        'rgba(27, 156, 250,1.0)',
        'rgba(27, 156, 52,1.0)',
        'rgba(27, 156, 202,1.0)',
        'rgba(27, 15, 252,1.0)',
        'rgba(27, 146, 252,1.0)',
        'rgba(27, 6, 252,1.0)',
        'rgba(27, 16, 252,1.0)',
        'rgba(27, 196, 252,1.0)',
        'rgba(27, 156, 196,1.0)',
        'rgba(27, 156, 121,1.0)',
        'rgba(27, 156, 284,1.0)',
        'rgba(27, 169, 252,1.0)',
        'rgba(27, 69, 252,1.0)',

    ]

    //const bgColorList = legend ? legend.map((item)=>colorMap[item]) : []
    
    const dataset = {
        labels: legend,
        datasets: [{
          label: 'My First Dataset',
          data: data,
          backgroundColor: colorMap,
          hoverOffset: 4
        }]
      };

    // const textColor = ["text-primary", "text-danger", "text-success", "text-warning"];
                        
  return (
    <div className="col-12">
        <div className="card" style={{height:'450px'}}>
            <div className="card-body overflow-auto">
                <h4 
                    className="header-title"
                    style={{color:'#6C757D'}}
                    >
                    {title}
                </h4>

                <div className="">
                    <Doughnut
                        data={dataset}
                        options={options}
                        height={200}
                        width={600}
                    />
                </div> 

                {/* <div id="average-sales" className="apex-charts mb-4 mt-4"
                    data-colors="#727cf5,#0acf97,#fa5c7c,#ffbc00"></div> */}

                <div className="chart-widget-list pt-3" >
                    {
                        (legend.length !== 0) ? legend.map((name, idx) => {

                            if(title === 'Revenue By Plan' || title === "Revenue By Gateway")
                            {
                                return (
                                    <p style={{fontSize: "0.7rem"}}>
                                        <i className={`mdi mdi-square`} style={{color:colorMap[idx]}}></i> {name.charAt(0).toUpperCase() + name.slice(1)}
                                        <span className="float-end">₹ {data[idx].toString().replace(/(\d)(?=(\d\d)+\d$)/g, "$1,")}</span>
                                    </p>
                                )
                            }
                            else{
                                return (
                                    <p>
                                        <i className={`mdi mdi-square`} style={{color:colorMap[idx]}}></i> {name}
                                        <span className="float-end">{data[idx]}</span>
                                    </p>
                                )
                            }
                        }) : <div style={{color:'#98A6AD', textAlign: 'center'}}>No Data Available</div>
                    }
                    
                    {/* <p>
                        <i className="mdi mdi-square text-danger"></i> Affilliate
                        <span className="float-end">$135.18</span>
                    </p>
                    <p>
                        <i className="mdi mdi-square text-success"></i> Sponsored
                        <span className="float-end">$48.96</span>
                    </p>
                    <p className="mb-0">
                        <i className="mdi mdi-square text-warning"></i> E-mail
                        <span className="float-end">$154.02</span>
                    </p> */}
                </div>
            </div>
        </div>  
    </div>
  )
}

export default ExpandedDonut