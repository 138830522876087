export const PLAN = `[PLAN]`
export const PLANS = `[PLANS]`
export const PLAN_STATUS = `[PLAN_STATUS]`

export const PLAN_CREATE = `${PLAN} CREATE`;
export const PLAN_UPDATE = `${PLAN} UPDATE`
export const PLANS_GET = `${PLANS} GET`
export const PLANS_SET = `${PLANS} SET`
export const PLAN_STATUS_TOGGLE = `${PLAN_STATUS} TOGGLE`

export const PLAN_GET = `${PLAN} GET`
export const PLAN_SET = `${PLAN} SET`


export const createPlan = (data) => ({
    type:PLAN_CREATE,
    payload:data
})

export const updatePlan = (data) => ({
    type: PLAN_UPDATE,
    payload: data
})

export const getPlans = () => ({
    type: PLANS_GET,
     payload:{}
})

export const setPlans = (data) => ({
    type: PLANS_SET,
    payload:data
})

export const togglePlanStatus = (data) => ({
    type:PLAN_STATUS_TOGGLE,
    payload:data
})

export const getPlanDetails = (data) => ({
    type: PLAN_GET,
    payload: data
})

export const setPlanDetails = (data) => ({
    type: PLAN_SET,
    payload: data
})
