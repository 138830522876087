import React from 'react'
import PatientRow from './patientRow'

const PatientTable = ({users,handleDeleteParticipant,showActions}) => {
    return (
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive">
                                <h4 className="header-title mt-0 mb-2">
                                    Patients
                                </h4>
                            <table className="table table-centered w-100 dt-responsive nowrap">
                                <thead className="thead-light">
                                    <tr>
                                        <th className="all">Name</th>
                                        <th>Calling Number</th>
                                        <th>WhatsApp Number</th>
                                        <th>Age</th>
                                        <th>Height</th>
                                        <th>Weight</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users?users.map(
                                        ({userId,firstName,lastName,callingNumber,whatsappNumber, age, height, weight})=>{
                                            return(
                                                <PatientRow
                                                    firstName={firstName}
                                                    lastName={lastName}
                                                    callingNumber={callingNumber}
                                                    whatsappNumber={whatsappNumber}
                                                    age={age}
                                                    height={height}
                                                    weight={weight}
                                                    key={userId}
                                                />
                                            )
                                        }):null}
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            )
}

export default PatientTable

