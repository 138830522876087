export const CONTENT_PRESIGNED_URL = `[CONTENT_PRESIGNED_URL]`
export const CONTENT_IMAGE_LOC = `[CONTENT_IMAGE_LOC]`

export const CONTENT_PRESIGNED_URL_GET = `${CONTENT_PRESIGNED_URL} GET`
export const CONTENT_PRESIGNED_URL_SET = `${CONTENT_PRESIGNED_URL} SET`

export const CONTENT_IMAGE_LOC_SAVE = `${CONTENT_IMAGE_LOC} SAVE`

export const getContentPresignedUrl = (data) => ({
    type:CONTENT_PRESIGNED_URL_GET,
    payload:data
})

export const setContentPresignedUrl = (data) => ({
    type:CONTENT_PRESIGNED_URL_SET,
    payload:data
})

export const saveContentImageLocation = (data) => ({
    type:CONTENT_IMAGE_LOC_SAVE,
    payload:data
})