import React,{useState,useEffect}  from 'react'
import OfferingList from './OfferingList'
import PlanTable from './PlanTable'
import {useDispatch,useSelector} from 'react-redux'
import { getOffering } from '../../../actions/offering'
import { offeringSelector } from '../../../selector/offering'

const PlanBuilder = (
    {
        planId,
        selectedOfferings,
        setSelectedOfferings,
        handleCreatePlan
    }) =>{
    const [offerings,setOfferings] = useState()
    
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(getOffering())
    },[dispatch])
    const fetchedOffering = useSelector(state=>offeringSelector(state))
    useEffect(()=>{
        setOfferings(fetchedOffering)
    },[fetchedOffering])
    
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <div className="col-lg-4">
                            <OfferingList
                                offerings={offerings?offerings:[]}
                                setOfferings={setOfferings}
                                selectedOfferings={selectedOfferings}
                                setSelectedOfferings={setSelectedOfferings}
                            />
                        </div>
                        <div className="col-lg-8">
                            <PlanTable
                                selectedOfferings={selectedOfferings}
                                setSelectedOfferings={setSelectedOfferings}
                                setOfferings={setOfferings}
                                offerings={offerings}
                                handleCreatePlan={handleCreatePlan}
                                planId={planId}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
        
    )
}

export default PlanBuilder
