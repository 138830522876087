export const CHALLENGE = `[CHALLENGE]`
export const CHALLENGES = `[CHALLENGES]`

export const LEADERBOARD = `[LEADERBOARD]`
export const LEADERBOARD_GET = `[LEADERBOARD] GET`
export const LEADERBOARD_SET = `[LEADERBOARD] SET`
export const LEADERBOARD_CREATE = `[LEADERBOARD] CREATE`

export const CHALLENGE_USER = `[CHALLENGE_USER]`
export const CHALLENGE_USER_GET = `${CHALLENGE_USER} GET`
export const CHALLENGE_USER_SET = `${CHALLENGE_USER} SET`

export const CHALLENGE_THUMBNAIL_PRESIGNED_URL = `[CHALLENGE_THUMBNAIL_PRESIGNED_URL]`

export const CHALLENGE_THUMBNAIL_PRESIGNED_URL_GET = `${CHALLENGE_THUMBNAIL_PRESIGNED_URL} GET`
export const CHALLENGE_THUMBNAIL_PRESIGNED_URL_SET = `${CHALLENGE_THUMBNAIL_PRESIGNED_URL} SET`

export const CHALLENGE_HERO_PRESIGNED_URL = `[CHALLENGE_HERO_PRESIGNED_URL]`

export const CHALLENGE_HERO_PRESIGNED_URL_GET = `${CHALLENGE_HERO_PRESIGNED_URL} GET`
export const CHALLENGE_HERO_PRESIGNED_URL_SET = `${CHALLENGE_HERO_PRESIGNED_URL} SET`

export const CHALLENGE_LANDING_PRESIGNED_URL = `[CHALLENGE_LANDING_PRESIGNED_URL]`

export const CHALLENGE_LANDING_PRESIGNED_URL_GET = `${CHALLENGE_LANDING_PRESIGNED_URL} GET`
export const CHALLENGE_LANDING_PRESIGNED_URL_SET = `${CHALLENGE_LANDING_PRESIGNED_URL} SET`


export const CHALLENGE_CREATE = `${CHALLENGE} CREATE`
export const CHALLENGE_UPDATE = `${CHALLENGE} UPDATE`
export const CHALLENGE_GET = `${CHALLENGE} GET`
export const CHALLENGE_SET = `${CHALLENGE} SET`

export const CHALLENGES_GET = `${CHALLENGES} GET`
export const CHALLENGES_SET = `${CHALLENGES} SET`

export const TASK = `[TASK]`

export const TASK_MARK = `${TASK} MARK`
export const TASK_UNMARK = `${TASK} UNMARK`

export const CHALLENGE_ENGAGEMENT = `[CHALLENGE_ENGAGEMENT]`;
export const CHALLENGE_ENGAGEMENT_GET = `${CHALLENGE_ENGAGEMENT} GET`;
export const CHALLENGE_ENGAGEMENT_SET = `${CHALLENGE_ENGAGEMENT} SET`;

export const getChallengeThumbnailPresignedUrl = (data) => ({
    type: CHALLENGE_THUMBNAIL_PRESIGNED_URL_GET,
    payload:data
})

export const setChallengeThumbnailPresignedUrl = (data) => ({
    type:CHALLENGE_THUMBNAIL_PRESIGNED_URL_SET,
    payload:data
})

export const getChallengeHeroPresignedUrl = (data) => ({
    type: CHALLENGE_HERO_PRESIGNED_URL_GET,
    payload:data
})

export const setChallengeHeroPresignedUrl = (data) => ({
    type:CHALLENGE_HERO_PRESIGNED_URL_SET,
    payload:data
})

export const getChallengeLandingPresignedUrl = (data) => ({
    type: CHALLENGE_LANDING_PRESIGNED_URL_GET,
    payload:data
})

export const setChallengeLandingPresignedUrl = (data) => ({
    type:CHALLENGE_LANDING_PRESIGNED_URL_SET,
    payload:data
})

export const createNewChallenge = (data) => ({
    type:CHALLENGE_CREATE,
    payload:data
}) 

export const updateChallenege = (data) => ({
    type: CHALLENGE_UPDATE,
    payload: data
})

export const getAllChallenges = () => ({
    type:CHALLENGES_GET,
    payload:{}
})

export const setAllChallenges = (data) => ({
    type:CHALLENGES_SET,
    payload:data
})

export const getChallengeDetails = (data) => ({
    type:CHALLENGE_GET,
    payload:data
})

export const setChallengeDetails = (data) => ({
    type:CHALLENGE_SET,
    payload:data
})

export const getLeaderboardSnapshot = (data) => ({
    type: LEADERBOARD_GET,
    payload: data
})

export const setLeaderboardSnapshot = (data) => ({
    type: LEADERBOARD_SET,
    payload: data
})

export const getChallengeUserDetails = (data) => ({
    type: CHALLENGE_USER_GET,
    payload: data
})

export const setChallengeUserDetails = (data) => ({
    type: CHALLENGE_USER_SET,
    payload: data
})

export const markTask = (data) => ({
    type: TASK_MARK,
    payload: data
})

export const unmarkTask = (data) => ({
    type: TASK_UNMARK,
    payload: data
})

export const addUserInLeaderboard = (data) => ({
    type: LEADERBOARD_CREATE,
    payload: data
});

export const getChallengeEngagement = (data) => ({
    type: CHALLENGE_ENGAGEMENT_GET,
    payload: data
})

export const setChallengeEngagement = (data) => ({
    type: CHALLENGE_ENGAGEMENT_SET,
    payload: data
})