/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLoggedOut, setAuthentication } from "../../actions/login";
import { authDetailsSeletor } from "../../selector/auth";
import { useHistory } from "react-router";
import { getUnmarkedEventCount } from "../../actions/navbarStats/unmarkedTrials";
import { unmarkedEventCountSelector } from "../../selector/navbarStats";

const Navbar = (props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getUnmarkedEventCount())
        const script = document.createElement("script");
        script.src = "/assets/js/advancedForm.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, [dispatch]);
    const authDetails = useSelector((state) => authDetailsSeletor(state));
    const { name, roleName,profileImage } = authDetails;
    const unmarkedEventCount = useSelector((state)=>unmarkedEventCountSelector(state))
    const handleLogout = () => {
        dispatch(setAuthentication({isAuthenticated:"pending"}))
        dispatch(getLoggedOut());
        history.push("/login")
    };
    const history = useHistory();
    return (
        <div>
            <div className="leftside-menu">
                <a 
                    href="#" 
                    className="logo text-center"
                    onClick={()=>{history.push("/")}}
                >
                    <span className="logo-lg">
                        <img
                            src="/assets/images/uvi-logo-clear.png"
                            alt=""
                            height="50"
                        />
                    </span>
                    <span className="logo-sm">
                        <img
                            src="/assets/images/uvi-logo-clear.png"
                            alt=""
                            height="56"
                        />
                    </span>
                </a>

                <div
                    className="h-100"
                    id="leftside-menu-container"
                    data-simplebar
                >
                    <ul className="side-nav">
                        {/* Navigation */}
                        <li className="side-nav-title side-nav-item">
                            Navigation
                        </li>
                        
                        {/* Home  */}
                        <li
                            className="side-nav-item nav-item-text"
                            onClick={() => {
                                history.push("/");
                            }}
                        >
                            <a
                                data-bs-toggle="collapse1"
                                aria-expanded="false"
                                aria-controls="sidebarDashboards"
                                className={history.location.pathname==='/'?`side-nav-link text-white`:`side-nav-link`}
                            >
                                <i className="uil-home-alt"></i>
                                <span> Home </span>
                            </a>
                            <div
                                className="collapse1"
                                id="sidebarDashboards"
                            ></div>
                        </li>

                        {/* Repository */}
                        <li className="side-nav-title side-nav-item">
                            Repository
                        </li>

                        {/* Users */}
                        <li className="side-nav-item nav-item-text">
                            <a
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="sidebarDashboards"
                                className={history.location.pathname==='/allUsers' || history.location.pathname==='/newUser' ?`side-nav-link text-white`:`side-nav-link`}
                            >
                                <i className="uil-user"></i>
                                <span>Users</span>
                            </a>
                            <div className="collapse2" id="sidebarDashboards">
                                <ul className="side-nav-second-level">
                                    <li className="nav-item-text">
                                        <a
                                            onClick={() => {
                                                history.push("/allUsers");
                                            }}
                                            className={history.location.pathname==='/allUsers'?`text-white`:null}    
                                        >
                                            View Users
                                        </a>
                                    </li>
                                    <li className="nav-item-text">
                                        <a
                                            onClick={() => {
                                                history.push("/newUser");
                                            }}
                                            className={history.location.pathname==='/newUser'?`text-white`:null}
                                        >
                                            {" "}
                                            Create User
                                        </a>
                                    </li>
                                    <li className="nav-item-text">
                                        <a
                                            onClick={() => {
                                                history.push("/trialUsers");
                                            }}
                                            className={history.location.pathname==='/trialUsers'?`text-white`:null}
                                        >
                                            {" "}
                                            Today's Trials 
                                            
                                            {unmarkedEventCount!==0?<span className="badge bg-danger float-end">{unmarkedEventCount}</span>:null}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        <li className="side-nav-item nav-item-text">
                            <a
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="sidebarDashboards"
                                className={history.location.pathname==='/allUsers' || history.location.pathname==='/newUser' ?`side-nav-link text-white`:`side-nav-link`}
                            >
                                <i className="uil-user"></i>
                                <span>Doctor Facing Tech</span>
                            </a>
                            <div className="collapse2" id="sidebarDashboards">
                                <ul className="side-nav-second-level">
                                    <li className="nav-item-text">
                                        <a
                                            onClick={() => {
                                                history.push("/doctors");
                                            }}
                                            className={history.location.pathname==='/doctors'?`text-white`:null}    
                                        >
                                            Doctors
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        {/* Plans */}
                        <li className="side-nav-item nav-item-text">
                            <a
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="sidebarDashboards"
                                className={history.location.pathname==='/plans' || history.location.pathname==='/createNewPlan' ?`side-nav-link text-white`:`side-nav-link`}
                            >
                                <i className="dripicons-clipboard"></i>
                                <span>Plans</span>
                            </a>
                            <div className="collapse2" id="sidebarDashboards">
                                <ul className="side-nav-second-level">
                                    <li className="nav-item-text">
                                        <a
                                            onClick={() => {
                                                history.push("/plans");
                                            }}
                                            className={history.location.pathname==='/plans'?`text-white`:null}
                                        >
                                            All Plans
                                        </a>
                                    </li>
                                    <li className="nav-item-text">
                                        <a
                                            onClick={() => {
                                                history.push("/createNewPlan");
                                            }}
                                            className={history.location.pathname==='/createNewPlan'?`text-white`:null}
                                        >
                                           Plan Builder
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        {/* Events */}
                        <li className="side-nav-item nav-item-text">
                            <a
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="sidebarDashboards"
                                className={
                                    history.location.pathname==='/Events' || 
                                    history.location.pathname==='/createNewEvent' || 
                                    history.location.pathname==='/EventsV2'  
                                    ?`side-nav-link text-white`:`side-nav-link`}
                            >
                                <i className="uil-calendar-alt"></i>
                                <span>Events</span>
                            </a>
                            <div className="collapse2" id="sidebarDashboards">
                                <ul className="side-nav-second-level">
                                    <li className="nav-item-text">
                                        <a
                                            onClick={() => {
                                                history.push("/EventsV2");
                                            }}
                                            className={history.location.pathname==='/EventsV2'?`text-white`:null}
                                        >
                                            Event Records
                                        </a>
                                    </li>
                                    <li className="nav-item-text">
                                        <a
                                            onClick={() => {
                                                history.push("/EventsCalendar");
                                            }}
                                            className={history.location.pathname==='/EventsCalendar'?`text-white`:null}
                                        >
                                            Event Calendar
                                        </a>
                                    </li>
                                    <li className="nav-item-text">
                                        <a
                                            onClick={() => {
                                                history.push("/createNewEvent");
                                            }}
                                            className={history.location.pathname==='/createNewEvent'?`text-white`:null}
                                        >
                                            Create New Event
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        {/* Lab Tests */}
                        <li className="side-nav-item nav-item-text">
                            <a
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="sidebarDashboards"
                                className={history.location.pathname==='/labTests' || history.location.pathname==='/labTestsListing' ?`side-nav-link text-white`:`side-nav-link`}
                            >
                                <i className="uil-syringe"></i>
                                <span>Lab Tests</span>
                            </a>
                            <div className="collapse2" id="sidebarDashboards">
                                <ul className="side-nav-second-level">
                                    <li className="nav-item-text">
                                        <a
                                            onClick={() => {
                                                history.push("/labTestsListing");
                                            }}
                                            className={history.location.pathname==='/labTestsListing'?`text-white`:null}
                                        >
                                            Lab Tests SKUs
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="collapse2" id="sidebarDashboards">
                                <ul className="side-nav-second-level">
                                    <li className="nav-item-text">
                                        <a
                                            onClick={() => {
                                                history.push("/labTests");
                                            }}
                                            className={history.location.pathname==='/labTests'?`text-white`:null}
                                        >
                                            Lab Test Transactions
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>



                        {/* Experts */}
                        <li className="side-nav-item nav-item-text">
                            <a
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="sidebarDashboards"
                                className={history.location.pathname==='/experts' || history.location.pathname==='/createExperts' ?`side-nav-link text-white`:`side-nav-link`}
                            >
                                <i className="uil-user"></i>
                                <span>Experts</span>
                            </a>
                            <div className="collapse2" id="sidebarDashboards">
                                <ul className="side-nav-second-level">
                                    <li className="nav-item-text">
                                        <a
                                            onClick={() => {
                                                history.push("/experts");
                                            }}
                                            className={history.location.pathname==='/experts'?`text-white`:null}    
                                        >
                                            All Experts
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        {/* Order Management */}
                        <li className="side-nav-item nav-item-text">
                            <a
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="sidebarDashboards"
                                className={history.location.pathname==='/Orders'?`side-nav-link text-white`:`side-nav-link`}
                            >
                                <i className="uil-cart"></i>
                                <span>Order Management</span>
                            </a>
                            <div className="collapse2" id="sidebarDashboards">
                                <ul className="side-nav-second-level">
                                    <li className="nav-item-text">
                                        <a
                                            onClick={() => {
                                                history.push("/Orders");
                                            }}
                                            className={history.location.pathname==='/Orders'?`text-white`:null}
                                        >
                                            Orders
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        {/* SKU Management */}
                        <li className="side-nav-item nav-item-text">
                            <a
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="sidebarDashboards"
                                className={history.location.pathname==='/SKUs'?`side-nav-link text-white`:`side-nav-link`}
                            >
                                <i className="uil-shop"></i>
                                <span>SKU Management</span>
                            </a>
                            <div className="collapse2" id="sidebarDashboards">
                                <ul className="side-nav-second-level">
                                    <li className="nav-item-text">
                                        <a
                                            onClick={() => {
                                                history.push("/SKUs");
                                            }}
                                            className={history.location.pathname==='/SKUs'?`text-white`:null}
                                        >
                                            All SKUs
                                        </a>
                                        <a
                                            onClick={() => {
                                                history.push("/createSKU");
                                            }}
                                            className={history.location.pathname==='/createSKU'?`text-white`:null}
                                        >
                                            Create SKU
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        {/* Arcade */}
                        <li className="side-nav-item nav-item-text">
                            <a
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="sidebarDashboards"
                                className={
                                    history.location.pathname==='/tasks' || 
                                    history.location.pathname==='/challenges' ||
                                    history.location.pathname==='/createTask' ||
                                    history.location.pathname==='/createChallenge' ||
                                    history.location.pathname==='/challenge/tasks'  ?`side-nav-link text-white`:`side-nav-link`}
                            >
                                <i className="mdi mdi-run"></i>
                                <span>Arcade</span>
                            </a>
                            <div className="collapse2" id="sidebarDashboards">
                                <ul className="side-nav-second-level">
                                    <li className="nav-item-text">
                                        <a
                                            onClick={() => {
                                                history.push("/tasks");
                                            }}
                                            className={history.location.pathname==='/tasks'?`text-white`:null}
                                        >
                                            Tasks
                                        </a>
                                    </li>
                                    <li className="nav-item-text">
                                        <a
                                            onClick={() => {
                                                history.push("/challenges");
                                            }}
                                            className={
                                                history.location.pathname==='/challenges' ||
                                                history.location.pathname==='/challenge/tasks'
                                            ?`text-white`:null}
                                        >
                                            Challenges
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        {/* Webinars */}
                        <li className="side-nav-item nav-item-text">
                            <a
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="sidebarDashboards"
                                className={history.location.pathname==='/createWebinar' || history.location.pathname==='/webinars' || history.location.pathname==='/vouchers' ?`side-nav-link text-white`:`side-nav-link`}
                            >
                                <i className="dripicons-monitor"></i>
                                <span>Webinars</span>
                            </a>
                            <div className="collapse2" id="sidebarDashboards">
                                <ul className="side-nav-second-level">
                                    <li className="nav-item-text">
                                        <a
                                            onClick={() => {
                                                history.push("/webinars");
                                            }}
                                            className={history.location.pathname==='/webinars'?`text-white`:null}
                                        >
                                           All Webinars 
                                        </a>
                                    </li>
                                    <li className="nav-item-text">
                                        <a
                                            onClick={() => {
                                                history.push("/createWebinar");
                                            }}
                                            className={history.location.pathname==='/createWebinar'?`text-white`:null}
                                        >
                                           Create Webinar 
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        {/* Content */}
                        <li className="side-nav-item nav-item-text">
                            <a
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="sidebarDashboards"
                                className={history.location.pathname==='/topics' || history.location.pathname==='/contentItems' || history.location.pathname==='/vouchers' 
                                || history.location.pathname.includes('createContent')
                                ?`side-nav-link text-white`:`side-nav-link`}
                            >
                                <i className="dripicons-archive"></i>
                                <span>Content</span>
                            </a>
                            <div className="collapse2" id="sidebarDashboards">
                                <ul className="side-nav-second-level">
                                    <li className="nav-item-text">
                                        <a
                                            onClick={() => {
                                                history.push("/topics");
                                            }}
                                            className={history.location.pathname==='/topics'?`text-white`:null}
                                        >
                                           Topics
                                        </a>
                                    </li>
                                    <li className="nav-item-text">
                                        <a
                                            onClick={() => {
                                                history.push("/contentItems");
                                            }}
                                            className={history.location.pathname==='/contentItems'?`text-white`:null}
                                        >
                                           Content
                                        </a>
                                    </li>
                                    <li className="nav-item-text">
                                        <a
                                            onClick={() => {
                                                history.push("/createContent/new");
                                            }}
                                            className={history.location.pathname==='/createContent/null'?`text-white`:null}
                                        >
                                           Create content
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        {/* Promo Code */}
                        <li className="side-nav-item nav-item-text">
                            <a
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="sidebarDashboards"
                                className={history.location.pathname==='/createCodes' || history.location.pathname==='/coupons' || history.location.pathname==='/vouchers' ?`side-nav-link text-white`:`side-nav-link`}
                            >
                                <i className=" uil-bill"></i>
                                <span>Promo Code</span>
                            </a>
                            <div className="collapse2" id="sidebarDashboards">
                                <ul className="side-nav-second-level">
                                    <li className="nav-item-text">
                                        <a
                                            onClick={() => {
                                                history.push("/coupons");
                                            }}
                                            className={history.location.pathname==='/coupons'?`text-white`:null}
                                        >
                                           Coupons 
                                        </a>
                                    </li>
                                    <li className="nav-item-text">
                                        <a
                                            onClick={() => {
                                                history.push("/vouchers");
                                            }}
                                            className={history.location.pathname==='/vouchers'?`text-white`:null}
                                        >
                                           Vouchers 
                                        </a>
                                    </li>
                                    <li className="nav-item-text">
                                        <a
                                            onClick={() => {
                                                history.push("/createCodes");
                                            }}
                                            className={history.location.pathname==='/createCodes'?`text-white`:null}
                                        >
                                            Create Codes
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        {/* Logs */}
                        <li className="side-nav-title side-nav-item">
                            Logs
                        </li>

                        {/* Payments Tracker */}
                        <li className="side-nav-item nav-item-text">
                            <a
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="sidebarDashboards"
                                className={history.location.pathname==='/paymentsPlan'?`side-nav-link text-white`:`side-nav-link`}
                                onClick={()=>{history.push('/paymentsPlan')}}
                            >
                                <i className="uil-dollar-alt"> </i>
                                <span>Payments Tracker</span>
                            </a>
                        </li>

                        {/* Exotel */}
                        <li className="side-nav-item nav-item-text">
                            <a
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="sidebarDashboards"
                                className={history.location.pathname==='/exotel'?`side-nav-link text-white`:`side-nav-link`}
                                onClick={()=>{history.push('/exotel')}}
                            >
                            <i className=" uil-phone-alt"></i>
                            <span>Exotel</span>
                            </a>
                        </li>

                        {/* Controls */}
                        <li className="side-nav-title side-nav-item">
                            Controls
                        </li>

                        {/* Cron Jobs */}
                        <li className="side-nav-item nav-item-text">
                            <a
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="sidebarDashboards"
                                className={history.location.pathname==='/cronJobs'?`side-nav-link text-white`:`side-nav-link`}
                                onClick={()=>{history.push('/cronJobs')}}
                            >
                            <i className="uil-processor"></i>
                            <span>Cron Jobs</span>
                            </a>
                        </li>

                        {/* Payments Testing */}
                        <li className="side-nav-item nav-item-text">
                            <a
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="sidebarDashboards"
                                className={history.location.pathname==='/payments'?`side-nav-link text-white`:`side-nav-link`}
                                onClick={()=>{history.push('/payments')}}
                            >
                            <i className="uil-wallet"></i>
                            <span>Payments Testing</span>
                            </a>
                        </li>

                    </ul>
                    <div className="clearfix"></div>
                </div>
            </div>

            <div className="content-page">
                <div className="content">
                    <div className="navbar-custom">
                        <ul className="list-unstyled topbar-menu float-end mb-0">
                            <li className="dropdown notification-list">
                                <a
                                    className="nav-link dropdown-toggle nav-user arrow-none me-0"
                                    data-bs-toggle="dropdown"
                                    href="#"
                                    role="button"
                                    aria-haspopup="false"
                                    aria-expanded="false"
                                >
                                    <span className="account-user-avatar nav-item-text">
                                        <img
                                            src={profileImage!==null?profileImage:"/assets/images/users/avatar.png"}
                                            alt="user-image"
                                            className="rounded-circle"
                                        />
                                    </span>
                                    <span>
                                        <span className="account-user-name nav-item-text">
                                            {name}
                                        </span>
                                        <span className="account-position nav-item-text">
                                            {roleName}
                                        </span>
                                    </span>
                                </a>
                                <div
                                    className="
                                    dropdown-menu dropdown-menu-end dropdown-menu-animated
                                    topbar-dropdown-menu
                                    profile-dropdown
                                    "
                                >
                                    <div className="dropdown-header noti-title">
                                        <h6 className="text-overflow m-0">
                                            Welcome!
                                        </h6>
                                    </div>

                                    {/* <a
                                        href="#"
                                        className="dropdown-item notify-item"
                                    >
                                        <i className="mdi mdi-account-circle me-1"></i>
                                        <span>My Account</span>
                                    </a> */}

                                    <a href="#" className="dropdown-item notify-item"
                                        onClick={()=>{history.push('/changePassword')}}
                                    >
                                        <i className="mdi mdi-account-edit me-1"></i>
                                        <span>Account Settings</span>
                                    </a>

                                    <a
                                        href="#"
                                        onClick={handleLogout}
                                        className="dropdown-item notify-item"
                                    >
                                        <i className="mdi mdi-logout me-1"></i>
                                        <span>Logout</span>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div className="container-fluid">
                        <div className="page-container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box">
                                        <h4 className="page-title page-title-text">
                                            {props.pageTitle}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">{props.children}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="footer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <script>
                                    document.write(new Date().getFullYear());
                                </script>
                                © Uvi Health (Curapy Health Pvt Ltd)
                            </div>
                            <div className="col-md-6">
                                <div className="text-md-end footer-links d-none d-md-block">
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://www.uvihealth.in/terms-conditions"
                                    >
                                        Privacy Policy
                                    </a>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://www.uvihealth.in/terms-conditions"
                                    >
                                        Terms & Conditions
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    );
};

export default Navbar;
