import React,{useState, useEffect} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { createNewTask, getTaskThumbnailPresignedUrl, getAllTasks, updateTask } from '../../actions/tasks'
import { taskThumbnailPresignedUrlSelector } from '../../selector/presignedUrlSelector'
import Navbar from '../navbar'
import axios from 'axios'
import Alert from "sweetalert2";
import {allTasksSelector} from '../../selector/tasks/index';


const CreateTasks = (props) => {

    const taskId = props.match.params.taskId;

    const dispatch = useDispatch()
    const history = useHistory()
    const [taskTitle,setTaskTitle] = useState('')
    const [validateTaskTitle,setValidateTaskTitle] = useState(true)

    const [taskType,setTaskType] = useState('')
    const [validateTaskType,setValidateTaskType] = useState(true)

    const [taskReward,setTaskReward] = useState('')
    const [validateTaskReward,setValidateTaskReward] = useState(true)

    const [taskActionType,setTaskActionType] = useState('')
    const [validateTaskActionType,setValidateTaskActionType] = useState(true)

    const [taskAction,setTaskAction] = useState('')

    const [taskDescription,setTaskDescription] = useState('')
    const [validateTaskDescription,setValidateTaskDescription] = useState(true)

    const [taskTimeTaken, setTaskTimeTaken] = useState('')
    const [validateTaskTimeTaken, setValidateTaskTimeTaken] = useState(true)

    const [taskThumbnail,setTaskThumbnail] = useState('')
    const [uploadUrl,setUploadUrl] = useState('')
    let src = '/assets/images/users/avatar.png'

    const onFileChange = (event) => {
        setTaskThumbnail(event.target.files[0])
        dispatch(getTaskThumbnailPresignedUrl(event.target.files[0].type))
    }
    const uploadConfigs = useSelector(state=>taskThumbnailPresignedUrlSelector(state))

    const uploadFile = async () => {
        await axios.put(uploadConfigs.url,taskThumbnail,{
            headers:{
               'Content-Type':taskThumbnail.type
            }
        }).then((val)=>{
           setUploadUrl(`https://d1caq8coktrxsi.cloudfront.net/${uploadConfigs.key}`)
           Alert.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Thumbnail Uploaded',
            showConfirmButton: false,
            timer: 1500
          })
           
        }).catch((err)=>{
           Alert.fire({
               position: 'top-end',
               icon: 'error',
               title: 'Image Upload Failed',
               showConfirmButton: false,
               timer: 1500
             })
        })
    }

    useEffect(() => {
        if(taskId)
        {
            dispatch(getAllTasks());
        }
    }, [dispatch, taskId]);

    const allTasks = useSelector((state) => allTasksSelector(state));
    const currentTask = allTasks.filter((task) => task.taskId === taskId)[0];
    src = currentTask?currentTask.taskThumbnail:'/assets/images/users/avatar.png'
    console.log(currentTask)

    useEffect(() => {
        if(taskId && currentTask)
        {
            setTaskTitle(currentTask.taskTitle);
            setTaskReward(currentTask.taskReward);
            setTaskActionType(currentTask.taskActionType);
            setTaskAction(currentTask.taskAction);
            setTaskDescription(currentTask.taskDescription);
            setUploadUrl(currentTask.taskThumbnail);
            setTaskTimeTaken(currentTask.taskTimeTaken);
        }
        else if(!taskId)
        {
            setTaskTitle("");
            setTaskReward("");
            setTaskActionType("");
            setTaskAction("");
            setTaskDescription("");
            setUploadUrl("");
            setTaskTimeTaken("");
        }
    }, [dispatch, taskId, currentTask])
    
    const handleSubmit = () => {

        if(taskTitle === ''){
            setValidateTaskTitle(false)
        }
        if(taskType === '') {
            setValidateTaskType(false)
        }
        if(taskReward === ''){
            setValidateTaskReward(false)
        }
        if(taskActionType===''){
            setValidateTaskActionType(false)
        }
        if(taskDescription===''){
            setValidateTaskDescription(false)
        }
        if(taskTimeTaken===''){
            setValidateTaskTimeTaken(false)
        }
        if(uploadUrl===''){
            Alert.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Task thumbnail not uploaded',
                showConfirmButton: false,
                timer: 1500
              })
            
        }else{
            if(taskId)
            {
                dispatch(updateTask({
                    taskId,
                    taskTitle,
                    taskReward,
                    taskAction,
                    taskActionType,
                    taskDescription,
                    taskThumbnail:uploadUrl,
                    taskTimeTaken
                }))
            }
            else{
                dispatch(createNewTask({
                    taskTitle,
                    taskType,
                    taskReward,
                    taskAction,
                    taskActionType,
                    taskDescription,
                    taskThumbnail:uploadUrl,
                    taskTimeTaken
                }))
            }
            history.push('/tasks')
        }    
    }

  return (
    <div>
    <Navbar pageTitle={"Create New Tasks"}>
        <div className="card">
            <div className="card-body">
                <div className="row">

                    
                    <div className="col-lg-6 ">

                        {/* Task Title */}
                        <div className="row mb-3">
                            <label
                                htmlFor="task-title"
                                className="col-2 col-form-label mt-2"
                            >
                                Task Title
                            </label>
                            <div className="col-9">
                                <input
                                    type="text"
                                    className="form-control mt-2"
                                    value={taskTitle}
                                    onChange={(e) => {
                                        setTaskTitle(e.target.value);
                                    }}
                                    onBlur={(e)=>{
                                        if(e.target.value === ''){
                                            setValidateTaskTitle(false)
                                        }else{
                                            setValidateTaskTitle(true)
                                        }
                                    }}
                                    id="task-title"
                                    placeholder="Title for the Task"
                                    autoComplete="off"
                                />
                                {validateTaskTitle === false ?(
                                    <div className="text-danger">
                                        Please give the task a name.
                                    </div>
                                ):null}
                            </div>
                        </div>

                        {/* Task Type */}
                        <div className="row mb-3">
                            <label
                                htmlFor="task-type"
                                className="col-2 col-form-label"
                            >
                                Type:
                            </label>
                            <div className="col-9">
                                <select
                                        className="form-select"
                                        id="task-type"
                                        value={taskType}
                                        onChange={e=>setTaskType(e.target.value)}
                                        placeholder="Select Task Type"
                                        onBlur={(e)=>{
                                            if(e.target.value === 'Select Task Type'){
                                                setValidateTaskType(false)
                                            }else{
                                                setValidateTaskType(true)
                                            }
                                        }}
                                    >
                                        <option value="" disabled selected>Select Task Type</option>
                                        <option>Fitness Workout</option>
                                        <option>Nutrition</option>
                                    </select>
                                    {
                                        validateTaskType === false ? (
                                        <div className="text-danger">
                                            Please select task type
                                        </div>
                                        ) : null
                                    }
                            </div>
                        </div>

                        {/* Task Reward */}
                        <div className="row mb-3">
                            <label
                                htmlFor="task-reward"
                                className="col-2 col-form-label"
                            >
                                Reward
                            </label>
                            <div className="col-9">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={taskReward}
                                    onChange={(e) => {
                                        setTaskReward(e.target.value);
                                    }}
                                    onBlur={(e)=>{
                                        if(e.target.value === ''){
                                            setValidateTaskReward(false)
                                        }else{
                                            setValidateTaskReward(true)
                                        }
                                    }}
                                    id="task-reward"
                                    placeholder="Assign Coin Reward Task ( integer )"
                                    autoComplete="off"
                                />
                                
                                {validateTaskReward === false ?(
                                    <div className="text-danger">
                                        Please assign a reward to the task.
                                    </div>
                                ):null}
                            </div>
                        </div> 

                        {/* Task Time Taken */}
                        <div className="row mb-3">
                            <label
                                htmlFor="task-reward"
                                className="col-2 col-form-label"
                            >
                                Time Taken
                            </label>
                            <div className="col-9">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={taskTimeTaken}
                                    onChange={(e) => {
                                        setTaskTimeTaken(e.target.value);
                                    }}
                                    onBlur={(e)=>{
                                        if(e.target.value === ''){
                                            setValidateTaskTimeTaken(false)
                                        }else{
                                            setValidateTaskTimeTaken(true)
                                        }
                                    }}
                                    id="task-time-taken"
                                    placeholder="Assign Task Time Taken ( integer )"
                                    autoComplete="off"
                                />
                                
                                {validateTaskReward === false ?(
                                    <div className="text-danger">
                                        Please assign a time taken to the task.
                                    </div>
                                ):null}
                            </div>
                        </div> 
                        
                        {/* Task Action Type */}
                        <div className="row mb-3">
                            <label
                                htmlFor="action-type"
                                className="col-2 col-form-label"
                            >
                                Type:
                            </label>
                            <div className="col-9">
                                <select
                                        className="form-select"
                                        id="task-type"
                                        value={taskActionType}
                                        onChange={e=>setTaskActionType(e.target.value)}
                                        placeholder="Select Action Type"
                                        onBlur={(e)=>{
                                            if(e.target.value === 'Select Action Type'){
                                                setValidateTaskActionType(false)
                                            }else{
                                                setValidateTaskActionType(true)
                                            }
                                        }}
                                    >
                                        <option value="" disabled selected>Select Action Type</option>
                                        <option>None</option>
                                        <option>Screen Redirection</option>
                                    </select>
                                    {
                                        validateTaskActionType === false ? (
                                        <div className="text-danger">
                                            Please select action type
                                        </div>
                                        ) : null
                                    }
                            </div>
                        </div>

                        {/* Task Action */}
                        <div className="row mb-3">
                            <label
                                htmlFor="task-action"
                                className="col-2 col-form-label"
                            >
                                Task Action: 
                            </label>
                            <div className="col-9">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={taskAction}
                                    onChange={(e) => {
                                        setTaskAction(e.target.value);
                                    }}
                                    id="task-action"
                                    placeholder="Task Action"
                                    autoComplete="off"
                                />
                            </div>
                        </div> 
                        
                    </div>
                    
                    
                    <div className="col-lg-6">
                        
                        {/* Task Description */}
                        <div className="row mb-3 mt-2">
                            <textarea
                                className="form-control"
                                value={taskDescription}
                                onChange={(e) => {
                                    setTaskDescription(e.target.value);
                                }}
                                placeholder="Description for the Task"
                                id="floatingTextarea"
                                style={{ height: "120px" }}
                                autoComplete="off"
                                onBlur={(e)=>{
                                    if(e.target.value === ''){
                                        setValidateTaskDescription(false)
                                    }else{
                                        setValidateTaskDescription(true)
                                    }
                                }}
                            ></textarea>
                            {validateTaskDescription === false ?(
                                <div className="text-danger">
                                    Please give the task a description.
                                </div>
                            ):null}
                        </div>

                        {/* Task Thumbnail  */}
                        <div className="row mb-3">
                            <div>
                                <h4>Upload Task Thumbail</h4>
                                <img 
                                    src={taskThumbnail? URL.createObjectURL(taskThumbnail) : src} 
                                    alt="profile"
                                    style={{height:'50px',width:'50px'}}    
                                    />
                                
                                <div>
                                
                                    {taskThumbnail===''?
                                    <>
                                        <h5>Choose a task thumbnail</h5>
                                        <input 
                                            type="file" 
                                            accept="image/*"
                                            onChange={(event)=>{onFileChange(event)}}
                                            style={{display:'block'}}
                                            />
                                    </>
                                    
                                    :<button 
                                        className="btn btn-info btn-rounded mt-3"
                                        onClick={uploadFile}
                                        style={{padding:'2px' ,width:'100px'}}
                                    >   
                                        Upload
                                    </button>
                                    }
                                </div>
                            </div>         
                        </div>


                    </div>
                </div>
                <div className="row">
                    <div className="justify-content-center d-flex" style={{marginTop:'1.75rem', marginLeft:"-25px"}}>
                        <button 
                            type="submit" 
                            className="btn btn-success"
                            onClick={()=>{handleSubmit()}}
                        >                   
                            {
                                taskId ? "Update Task" : "Create Task"
                            }
                        </button>
                    </div>
                </div>
            </div>

        </div>
    </Navbar>
</div>
  )
}

export default CreateTasks
