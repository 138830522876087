//Features
export const TOPIC_CONTENT_MAP = `[TOPIC_CONTENT_MAP]`;
export const TOPIC_CONTENT_ADD = `[TOPIC_CONTENT_MAP_ADD]`;
export const TOPIC_CONTENT_REMOVE = `[TOPIC_CONTENT_MAP_REMOVE]`;




//TOPICS action types
export const TOPIC_CONTENT_MAP_GET  =  `${TOPIC_CONTENT_MAP} GET`
export const TOPIC_CONTENT_MAP_SET  =  `${TOPIC_CONTENT_MAP} SET`
export const TOPIC_CONTENT_MAP_ERROR = `${TOPIC_CONTENT_MAP} SET_ERROR`

export const TOPIC_CONTENT_ADD_REQ  =  `${TOPIC_CONTENT_ADD} REQ`
export const TOPIC_CONTENT_ADD_SET  =  `${TOPIC_CONTENT_ADD} SET`
export const TTOPIC_CONTENT_ADD_ERROR = `${TOPIC_CONTENT_ADD} SET_ERROR`

export const TOPIC_CONTENT_REMOVE_REQ  =  `${TOPIC_CONTENT_REMOVE} REQ`
export const TOPIC_CONTENT_REMOVE_SET  =  `${TOPIC_CONTENT_REMOVE} SET`
export const TOPIC_CONTENT_REMOVE_ERROR = `${TOPIC_CONTENT_REMOVE} SET_ERROR`

//TOPICS action creators
export const getTopicContentMap = (data) => ({
    type: TOPIC_CONTENT_MAP_GET,
    payload: data
})

export const addTopicContentMap = (data) => ({
    type: TOPIC_CONTENT_ADD_REQ,
    payload: data
});

export const deleteTopicContentMap = (data) => ({
    type: TOPIC_CONTENT_REMOVE_REQ,
    payload: data
});

export const setTopicContentMap = (data) => ({
    type: TOPIC_CONTENT_MAP_SET,
    payload: data
})

export const setErrorTopicContentMap = (data) => ({
    type: TOPIC_CONTENT_MAP_ERROR,
    payload: data
})