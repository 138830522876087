export const ACQ_CHANNEL = `[ACQ_CHANNEL]`

export const ACQ_CHANNEL_GET = `${ACQ_CHANNEL} GET`
export const ACQ_CHANNEL_SET = `${ACQ_CHANNEL} SET`

export const getAcqChannel = () =>({
    type: ACQ_CHANNEL_GET,
    payload:{}
})

export const setAcqChannel = (data) =>({
    type:ACQ_CHANNEL_SET,
    payload:data
})