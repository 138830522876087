import { CHALLENGES_SET, CHALLENGE_SET, LEADERBOARD_SET, CHALLENGE_USER_SET, CHALLENGE_ENGAGEMENT_SET } from "../../actions/challenges"

const initState = []
export const challengesReducer = (state=initState,action) =>{
    switch(action.type){
        case CHALLENGES_SET:
            return {...state,challenges:action.payload}
        case CHALLENGE_SET:
            return {...state,challengeDetails:action.payload}
        case LEADERBOARD_SET:
            return {...state, leaderboard:action.payload}
        case CHALLENGE_USER_SET:
            return {...state, challengeUser:action.payload}
        case CHALLENGE_ENGAGEMENT_SET:
            return {...state, challengeEngagement: action.payload}
        default: return state
    }
}

