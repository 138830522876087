import { apiRequest,API_SUCCESS,API_ERROR } from "../../../actions/api"
import { ACQ_CHANNEL,ACQ_CHANNEL_GET, setAcqChannel } from "../../../actions/acqChannel"
import {ROOT_URL} from '../../../config/'

export const acqChannelMiddleware = ({dispatch}) => next => (action) =>{
    next(action)
    switch(action.type){
        case ACQ_CHANNEL_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/acqChannel`,
                method:'get',
                feature:ACQ_CHANNEL
            }))
            break;
        case `${ACQ_CHANNEL} ${API_SUCCESS}`:
            dispatch(setAcqChannel(action.payload))
            break;
        case `${ACQ_CHANNEL} ${API_ERROR}`:
            break;
        default: break;
    }
}
