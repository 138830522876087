import { apiRequest,API_SUCCESS,API_ERROR } from "../../../actions/api"
import {ROOT_URL} from '../../../config/'
import { 
    COMMENT,
    COMMENT_GET,
    setComment,
    setCommentError,
    DELETE_COMMENT
} from "../../../actions/comments"

export const commentMiddleware = ({dispatch}) => next => (action) =>{
    next(action)
    switch(action.type){

        case COMMENT_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/comments`,
                method:'get',
                feature:COMMENT
            }))
            break;
        case DELETE_COMMENT:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/comments`,
                method:'delete',
                feature:COMMENT
            }))
            break;
        case `${COMMENT} ${API_SUCCESS}`:
                dispatch(setComment(action.payload))
                break;
        case `${COMMENT} ${API_ERROR}`:
                dispatch(setCommentError(action.payload))
                break;
        default: break;
    }
}

