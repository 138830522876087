export const UNMARKED_EVENT = `[UNMARKED_EVENT]`

export const UNMARKED_EVENT_GET_COUNT = `${UNMARKED_EVENT} GET_COUNT`
export const UNMARKED_EVENT_SET_COUNT = `${UNMARKED_EVENT} SET_COUNT`


export const getUnmarkedEventCount = () => ({
    type: UNMARKED_EVENT_GET_COUNT,
    payload:{}
})

export const setUnmarkedEventCount = (data) => ({
    type: UNMARKED_EVENT_SET_COUNT,
    payload:data
})
