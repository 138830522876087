/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import Navbar from '../navbar'
import { Grid, _ } from "gridjs-react";
import { useDispatch, useSelector } from 'react-redux';
import { getChallengeDetails } from '../../actions/challenges';
import { challengeDetailsSelector } from '../../selector/challenges';
import moment from 'moment';


const Task = ({
    taskThumbnail,
    taskTitle,
    taskReward,
}) => {
    return (
        <div class="row mx-n1 g-0 w-100">
            <div className="col-12">
                <div 
                    className="card m-1 shadow-none border"
                    >
                    <div className="p-1">
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <div className="avatar-sm">
                                    <span className="avatar-title bg-white text-secondary rounded">
                                        <img
                                            src={taskThumbnail}
                                            alt="thumbnail"
                                            style={{height:'48px',width:'48px'}}
                                        />
                                    </span>
                                </div>
                            </div>
                            <div className="col ps-0">
                                <a href="" className="text-muted fw-bold">{taskTitle}</a>
                                <p className="mb-0 font-13">🌕 x {taskReward}</p>
                            </div>
                        </div> 
                    </div> 
                </div> 
            </div>
        </div>

    )
}

const Table = ({challengeTask}) => {
    let dataObj = []
    if(challengeTask.length !== 0){
        dataObj = Object.entries(challengeTask)
    }
    const data = dataObj.map(([key,value],index)=>[
        `Day ${index+1} - ${moment(key).format('DD-MM-YYYY')}`,
        _(
            <>
                {value?value.map((task)=>{
                    return(
                    <Task
                        taskThumbnail={task.taskThumbnail}
                        taskTitle={task.taskTitle}
                        taskReward={task.taskReward}
                    />)
                }):''}
            </>

        )
    ])

    return (
        <div className="col-12">
            <div className="card">
                <div className="card-body">
                    <div className="tab-content">
                        <div
                            className="tab-pane show active"
                            id="basic-datatable-preview"
                        >
                            <div id="basic-datatable-wrapper">
                                <Grid
                                    data={data}
                                    columns={[
                                        {name:"Challenge Day",width:"25%"},
                                        {name:"Tasks",width:"75%"},
                                    ]}
                                    pagination={{
                                        enabled: true,
                                        limit: 10,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ChallengeDetails = ({
    challengeName,
    challengeCurrentCapacity,
    challengeId,
    challengeStartDate,
    challengeEndDate
}) => {
    return (
        <div className="row">
            <div className="col-12">
                <div className="card bg-secondary">
                    <div className="card-body profile-user-box">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <div>
                                    <div className="row">
                                        <div className="col-9">
                                            <h1 className="mt-1 mb-1 text-white">
                                                {challengeName}
                                            </h1>
                                            <h3 className="text-white-50 mb-1">
                                                Current Capacity: {challengeCurrentCapacity} | {challengeId}
                                            </h3>
                                            <h3 className="text-white mb-1">
                                                Start: {moment(challengeStartDate).format('DD-MM-YYYY')} End: {moment(challengeEndDate).format('DD-MM-YYYY')}
                                            </h3>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ApplicablePlans = ({plans}) => {
    return(
            <div className="col-12"
                style={{
                    display:'grid',
                    gridTemplateColumns:'repeat(auto-fill, minmax(150px, 1fr))',
                    gridGap:'2rem'
                }}
            >
                {plans.map(({planName})=>{
                    return(
                        <button 
                            type="button" 
                            className="btn btn-outline btn-rounded" 
                            style={{
                                padding:'2px' ,
                                width:'200px',
                                backgroundColor:'#F5735A',
                                color:'white',
                                'pointerEvents': 'none',                                
                            }}
                            
                        >
                        {planName}
                        </button>
                    )
                })}
            </div>
        )
    }

const ChallengeTaskListing = (props) => {
    const dispatch = useDispatch()
    const challengeId = props.match.params.challengeId
    useEffect(()=>{
        dispatch(getChallengeDetails(challengeId))
    },[dispatch,challengeId])
    const challengeDetails = useSelector(state=>challengeDetailsSelector(state))
  return (
        <div>
            <Navbar>
                <div className="d-flex justify-content-between mb-2 mx-3">
                    <h3> Challenge Tasks</h3>
                </div>
                <div>
                    <ChallengeDetails
                        challengeName={challengeDetails.challengeName}
                        challengeCurrentCapacity={challengeDetails.challengeCurrentCapacity}
                        challengeId={challengeDetails.challengeId}
                        challengeStartDate={challengeDetails.challengeStartDate}
                        challengeEndDate={challengeDetails.challengeEndDate}
                    />
                </div>
                <div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="text-start">
                                        <h4 className="header-title mt-0 mb-4">
                                            Pay To Join Plans
                                        </h4>
                                        <ApplicablePlans
                                             plans={challengeDetails.payToJoinPlans?
                                                challengeDetails.payToJoinPlans:[]
                                                }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="text-start">
                                        <h4 className="header-title mt-0 mb-4">
                                            Free To Join Plans
                                        </h4>
                                        <ApplicablePlans
                                            plans={challengeDetails.freeToJoinPlans?
                                                challengeDetails.freeToJoinPlans:[]

                                                }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Table
                    challengeTask={
                       Object.keys(challengeDetails).length!==0?challengeDetails.challengeTask:{}
                    }
                />
            </Navbar>
        </div>
  )
}

export default ChallengeTaskListing