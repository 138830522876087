export const SYMPTOM = `[SYMPTOM]`
export const SYMPTOM_GET = `${SYMPTOM} GET`
export const SYMPTOM_SET = `${SYMPTOM} SET`

export const getSymptom = () =>({
    type: SYMPTOM_GET,
    payload:{}
})

export const setSymptom = (data) => ({
    type: SYMPTOM_SET,
    payload:data
})
