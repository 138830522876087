import React,{useEffect,useState} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import { getPlanDetails, togglePlanStatus } from '../../../actions/plan'
import { planDetailsSelector } from '../../../selector/plan'
import Navbar from '../../navbar'

const PlanOfferingRow = ({offeringId,offeringName,offeringStatus,offeringQuantity,offeringUnitName,offeringTypeName}) =>{
    return(
        <tr>
            <td>{offeringId}</td>
            <td>{offeringName}</td>
            <td>{offeringStatus===1?<span className={`badge bg-success`}>Active</span>:<span className={`badge bg-danger`}>Inactive</span>}</td>
            <td>{offeringQuantity}</td>
            <td>{offeringUnitName}</td>
            <td>{offeringTypeName}</td>
        </tr>
        
    )
}

const PlanOfferingsTable = ({offerings}) =>{
    return(
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive">
                                <h4 className="header-title mt-0 mb-2">
                                    Plan Offerings
                                </h4>
                            <table className="table table-centered w-100 dt-responsive nowrap">
                                <thead className="thead-light">
                                    <tr>
                                        <th className="all">Offering Id</th>
                                        <th>Offering Name</th>
                                        <th>Status</th>
                                        <th>Quantity</th>
                                        <th>Unit</th>
                                        <th>Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                  {
                                      offerings?offerings.map(({
                                          offeringId,
                                          offeringName,
                                          offeringStatus,
                                          offeringQuantity,
                                          offeringUnitName,
                                          offeringTypeName
                                          })=>{
                                          return(
                                            <PlanOfferingRow
                                                offeringId={offeringId}
                                                offeringName={offeringName}
                                                offeringStatus={offeringStatus}
                                                offeringQuantity={offeringQuantity}
                                                offeringUnitName={offeringUnitName}
                                                offeringTypeName={offeringTypeName}
                                            /> 
                                          )
                                      }):null
                                  } 
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
    )
}

const PlanDetailsBody = ({planName,planStatus,planId,planDuration,planCost,offerings}) =>{
    const [status,setStatus] = useState(planStatus)
    const dispatch = useDispatch()
    const handleStatus = () =>{
        if(status===1){
            setStatus(0)
            dispatch(togglePlanStatus({planStatus:0,planId}))
        }else{
            setStatus(1)
            dispatch(togglePlanStatus({planStatus:1,planId}))
        }
    }
    return(
        <>
            <div>
            
                {/* details Banner */}
                <div className="row">
                <div className="col-12">
                    <div className="card bg-secondary">
                        <div className="card-body profile-user-box">
                            <div className="row align-items-center">
                                <div className="col-12">
                                    <div>
                                        <div className="row">
                                            <div className="col-9">
                                                <div className="row d-flex flex-col">
                                                    <p>
                                                    {status===1?<span className={`badge bg-success`}>Active</span>:<span className={`badge bg-danger`}>Inactive</span>}
                                                    </p>
                                                    <h1 className="mt-1 mb-1 text-white">
                                                        {planName} 
                                                    </h1>
                                                </div>
                                                
                                                <h3 className="text-white-50 mb-1">
                                                    {planId}
                                                </h3>
                                                <h3 className="text-white mb-1">Plan Duration: {planDuration} days.</h3> 
                                                <h3 className="text-white mb-1">Cost: &#8377; {planCost}</h3>

                                            </div>
                                            <div
                                                className="col-3"
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-end",
                                                    paddingRight: "2em",
                                                }}
                                            >
                                                    <div className="row w-100">
                                                        <div className="col-12">
                                                            <div className="row ">
                                                                <div className="col-12 mb-2">
                                                                    {status===1?<button 
                                                                        className="btn btn-danger w-100"
                                                                        onClick={handleStatus}
                                                                        >
                                                                            Deactivate Plan
                                                                    </button>:<button 
                                                                        className="btn btn-success w-100"
                                                                        onClick={handleStatus}
                                                                        >
                                                                            Activate Plan
                                                                    </button>}
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <PlanOfferingsTable
                offerings={offerings}
            /> 
        </>

    )
}

const PlanDetails = (props)=> {

    const dispatch = useDispatch()
    const planId = props.match.params.planId
    useEffect(()=>{
        dispatch(getPlanDetails(planId))
    },[dispatch,planId])
    const planDetails = useSelector(state=>planDetailsSelector(state))
    return (
       <Navbar>
           <PlanDetailsBody
                planName={planDetails?planDetails.planName:''}
                planStatus={planDetails?planDetails.planStatus:''}
                planId={planDetails?planDetails.planId:''}
                planDuration={planDetails?planDetails.planDuration:''}
                planCost={planDetails?planDetails.planCost:''}
                offerings={planDetails?planDetails.offerings:''}            
           />
       </Navbar>
    )
}

export default PlanDetails
