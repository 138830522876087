/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useCallback, useMemo}from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../navbar";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { getAllExpert, updateExpertStatus } from "../../actions/expert";
import { allExpertsSelector } from "../../selector/expert";
import Toggle from 'react-toggle'
import './toggle.css'

const Action = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {userId, status} = props.data.action;

    return (
        <>
            <div className="col-3">
                <i
                    data-bs-toggle="modal" 
                    data-bs-target="#create-payments-plan-modal"
                    className="mdi mdi-pencil text-warning font-18"
                    onClick={() => history.push(`/updateExpert/${userId}`)}
                >
                </i>
                <i 
                    className="dripicons-clipboard text-info text-primary font-18 mx-2"
                    onClick={()=>{history.push(`/expertDetails/${userId}`)}}
                ></i>
                <Toggle
                    defaultChecked={status===1 ? true : false}
                    onChange={() => dispatch(updateExpertStatus({userId, status}))}
                />
            </div>
        </>
    )
}

const Name = (props) => {
    const userDetails = props.data.userDetails;

    return (
        <>
            <div className="row">
                <img
                    src={userDetails.profileImage}
                    alt=""
                    className="rounded-circle img-thumbnail col-3"
                />
                <p className="col-3">{userDetails.name}</p>
            </div>
        </>
    )
}

const ExpertList = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    useState(() => {
        dispatch(getAllExpert())
    }, [dispatch])

    const allExperts = useSelector((state) => allExpertsSelector(state))
    console.log(allExperts)

    const data = (allExperts.length !== 0) ? allExperts.map((item) => {
        return {
            userDetails: {name: item.name, profileImage: item.profileImage},
            spec: item.spec,
            rate: "₹ "+item.rate,
            action: {userId: item.userId, status: item.status}
        }
    }) : [];

    const [columnDefs] = useState([
        {headerName:"Name", field:"userDetails", cellRenderer:Name, resizable:true, sortable:true, width:300, autoHeight: true,},
        {headerName:"Spec", field:"spec",  resizable:true, sortable:true, filter:true, width:400, suppressNavigable: true, cellClass: 'no-border'},
        {headerName:"Rate", field:"rate", resizable:true, sortable:true, filter:true, width:150, suppressNavigable: true, cellClass: 'no-border'},
        {headerName:"Action", field:"action", cellRenderer:Action, resizable:true, sortable:true, width:200}
    ])

    const gridRef = useRef();

    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current.api.setQuickFilter(
            document.getElementById('filter-text-box').value
        );
    }, []);

    // const onBtnExport = useCallback(() => {
    //     const exportCols = {
    //         columnKeys: ['paymentId','orderId', 'planId', 'userId', 'amount', 'promoCode', 'paymentGateway', 'paymentTimeStamp'],
    //     };
    //     gridRef.current.api.exportDataAsCsv(exportCols);
    // }, []);

    const popupParent = useMemo(() => {
        return document.body;
    }, []);

    const getRowStyle = params => {
        return { fontFamily: "Cerebri Sans", fontSize: "0.9rem", border: "none", borderBottom: "1px solid #eef2f7"};
    };

    return (
        <Navbar pageTitle={"All Experts"}>
        
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="tab-content">
                            <div
                                className="tab-pane show active"
                                id="basic-datatable-preview"
                            >

                                <div id="basic-datatable-wrapper" className="ag-theme-material" style={{height: '100vh'}}>
                                    <div className="col d-flex justify-content-between px-3">
                                        <input 
                                            type="text"
                                            id="filter-text-box"
                                            placeholder="Type a keyword..." 
                                            aria-label="Type a keyword..." 
                                            className="gridjs-input gridjs-search-input mb-2" 
                                            onInput={onFilterTextBoxChanged}

                                        />
                                        <div>
                                            <button 
                                                className="btn btn-success"
                                                style={{width:'200px', height:'45px'}}
                                                onClick={() => {
                                                    history.push('/createExpert')
                                                }}
                                            > 
                                                    Create Expert
                                            </button>
                                            <a 
                                                className="btn btn-info ms-2"
                                                // onClick={onBtnExport}
                                                style={{width:'45px', height:'45px'}}
                                                
                                                >
                                                <i class="dripicons-download"></i>
                                            </a>
                                        </div>
                                    </div>

                                    <AgGridReact
                                        ref={gridRef}
                                        rowData={data}
                                        columnDefs={columnDefs}
                                        pagination={true} 
                                        paginationPageSize={20}
                                        cacheQuickFilter={true}
                                        suppressExcelExport={true}
                                        popupParent={popupParent}
                                        enableCellTextSelection={true}
                                        suppressCellSelection={true}
                                        getRowStyle={getRowStyle}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Navbar>
    )
}

export default ExpertList;