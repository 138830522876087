export const ORDERS = `[ORDERS]`
export const ORDER = `[ORDER]`

export const ORDERS_GET = `${ORDERS} GET`
export const ORDERS_SET = `${ORDERS} SET`

export const ORDER_GET = `${ORDER} GET`
export const ORDER_SET = `${ORDER} SET`

export const PROCESS_ORDER = `${ORDER} PROCESS`

export const getOrders = (data) => ({
    type: ORDERS_GET,
    payload:data
})

export const getOrder = (data) => ({
    type: ORDER_GET,
    payload:data
})

export const setOrders = (data) => ({
    type: ORDERS_SET,
    payload:data
})

export const setOrder = (data) => ({
    type: ORDER_SET,
    payload:data
})

export const processOrder = (data) => ({
    type: PROCESS_ORDER,
    payload:data
})