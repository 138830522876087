/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/aria-proptypes */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { commentsSelector } from '../../../selector/comments';
import { Grid, _ } from 'gridjs-react';
import moment from 'moment';
import Alert from "sweetalert2";

import { getComment, deleteComment } from "../../../actions/comments"

import "./createContent.css"

const Comments = (props) => {
    const dispatch = useDispatch();
    const data = props.contentData;
    const contentId = data.content_id;

    useEffect(() => {
      if(data.can_comment === 1){
        dispatch(getComment(contentId))
      }
    }, [contentId, data.can_comment])

    const contentItems = useSelector((state) => commentsSelector(state));

    if(data.can_comment === 0){
        return(
          <div>
            Comments disabled
          </div>
        )
    }

    if(!contentItems.data || !contentItems.data.length){
        return(
          <div>
            Comments not available
          </div>
        )
    }

    const columns = [
      "User",
      "Message",
      "Created at",
      "Action"
    ]
    const renderdata = [];
    contentItems.data.forEach(element => {
      const current = [
              _(
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <img src= {element.profilePicture} style={{width: '40px', height: undefined, borderRadius: '20px'}} alt='No Image'/>
                    <div>{element.firstName}</div>
                  </div>
                ),
                element.comment_text || 'NA',
                moment(element.created_at).format("MMM Do YY, h:mm a"),
                 _(
                  <button
                    type="button"
                    className="btn"
                    style={{ backgroundColor: "red", color: "white" }}
                    onClick={() => {
                      Alert.fire({
                        title:"Are you sure you want to delete? it's permanent.",
                        showDenyButton:true,
                        showConfirmButton:true,
                        confirmButtonText:'Delete',
                        denyButtonText:'Cancel',
                    }).then(({value})=>{
                        if(value){
                            dispatch(deleteComment({commentId: element.comment_id, contentId}))
                        }
                    })
                    }}
                  >
                    Delete
                  </button>
                ),
              ]
      renderdata.push(current)
    });

    return (
      <div style={{ }}>
        <Grid
          data={renderdata}
          columns={columns}
          search={true}
          pagination={{
            enabled: true,
            limit: 10,
          }}
          sort={true}
        />
      </div>
    );
};

export default Comments;