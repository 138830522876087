import { PAYMENT_SET } from "../../actions/payments"


const initState = []
export const paymentsReducer = (state=initState,action) =>{
    switch(action.type){
        case PAYMENT_SET:
            return {...state,paymentObj:action.payload}
        default: return state
    }
}

