import React, { useState, useEffect } from "react";
import Navbar from "../../navbar";
import moment from "moment";
import { useDispatch,useSelector } from 'react-redux';
import { providerSelector } from '../../../selector/provider';
import { getProvider } from '../../../actions/provider';
import { createWebinar, getWebinars, updateWebinar } from "../../../actions/webinar";
import { useHistory } from "react-router-dom";
import { webinarSelector } from "../../../selector/webinar";

const CreateWebinar = (props) => {
    const dispatch = useDispatch();
    const history = useHistory()

    const webinarId = props.match.params.webinarId;

    useEffect(() => {
        dispatch(getProvider())
        if(webinarId)
        {
            dispatch(getWebinars())
        }
    }, [dispatch, webinarId])
    
    const provider = useSelector(state=>providerSelector(state));
    const allWebinars = useSelector((state) => webinarSelector(state));
    const webinarDetails = allWebinars.filter((webinar) => webinar.webinarId === webinarId);

    const [webinarName, setWebinarName] = useState((webinarDetails.length !== 0) ? webinarDetails[0].webinarName : "");
    const [validateWebinarName, setValidateWebinarName] = useState(true)
    const [webinarDesc, setWebinarDesc] = useState((webinarDetails.length !== 0) ? webinarDetails[0].webinarDesc : "");
    const [validateWebinarDesc, setValidateWebinarDesc] = useState(true)
    const [speakerName, setSpeakerName] = useState((webinarDetails.length !== 0) ? webinarDetails[0].speakerName : "");
    const [validateSpeakerName, setValidateSpeakerName] = useState(true)
    const [startDate,setStartDate] = useState(moment(new Date()).format("YYYY-MM-DD"))
    const [startTime,setStartTime] = useState('12:00')
    const [duration,setDuration] = useState((webinarDetails.length !== 0) ? webinarDetails[0].duration : "");
    const [validateDuration,setValidateDuration] = useState(true)
    const [joinUrl, setJoinUrl] = useState((webinarDetails.length !== 0) ? webinarDetails[0].joinUrl : "");
    const [webinarHeroUrl, setWebinarHeroUrl] = useState((webinarDetails.length !== 0) ? webinarDetails[0].webinarHeroUrl : "");
    const [speakerThumbnailUrl, setSpeakerThumbnailUrl] = useState((webinarDetails.length !== 0) ? webinarDetails[0].speakerThumbnailUrl : "");
    
    useEffect(() => {
        if(webinarId)
        {
            setWebinarName((webinarDetails.length !== 0) ? webinarDetails[0].webinarName : "")
            setWebinarDesc((webinarDetails.length !== 0) ? webinarDetails[0].webinarDesc : "")
            setJoinUrl((webinarDetails.length !== 0) ? webinarDetails[0].joinUrl : "")
            setDuration((webinarDetails.length !== 0) ? webinarDetails[0].duration : "")
            setWebinarHeroUrl((webinarDetails.length !== 0) ? webinarDetails[0].webinarHeroUrl : "")
            setSpeakerThumbnailUrl((webinarDetails.length !== 0) ? webinarDetails[0].speakerThumbnailUrl : "")
            setSpeakerName((webinarDetails.length !== 0) ? webinarDetails[0].speakerName : "")
            setStartDate((webinarDetails.length !== 0) ? moment(webinarDetails[0].start).format("YYYY-MM-DD") : "")
            setStartTime((webinarDetails.length !== 0) ? moment(webinarDetails[0].start).utcOffset(0).format("HH:mm") : "")
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, webinarDetails.length]);

    const handleSpeakerName = (e) => {
        setSpeakerName(e.target.value)
        const speaker = provider.filter((item) => item.name.trim() === e.target.value.trim());
        setSpeakerThumbnailUrl(speaker[0].profileImage)
    }

    const handleCreateWebinar = () =>{

        if(webinarName===''){
            setValidateWebinarName(false)
        }
        if(webinarDesc===''){
            setValidateWebinarDesc(false)
        }
        if(speakerName===''){
            setValidateSpeakerName(false)
        }
        if(duration===''){
            setValidateDuration(false)
        }
        else{
            if(webinarId)
            {
                dispatch(updateWebinar({
                    webinarId,
                    webinarName,
                    webinarDesc,
                    speakerName,
                    start: `${startDate} ${startTime}`,
                    duration,
                    joinUrl,
                    webinarHeroUrl,
                    speakerThumbnailUrl
                }));
            }
            else{
                dispatch(createWebinar({
                    webinarName,
                    webinarDesc,
                    speakerName,
                    start: `${startDate} ${startTime}`,
                    duration,
                    joinUrl,
                    webinarHeroUrl,
                    speakerThumbnailUrl
                }));
            }
            history.push('/webinars')
        }  
    }

    return (
        <Navbar pageTitle={webinarId?"Update Webinar":"Create New Webinar"}>
            <div>
                <div className="row">
                    <div className="col-8">
                        <form className="form-horizontal">
                            <div className="row mb-4">
                                <label
                                    htmlFor="simpleInput1"
                                    className="col-2 col-form-label"
                                >
                                    Webinar Name
                                </label>
                                <div className="col-10">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={webinarName}
                                        onChange={(e) => {
                                            setWebinarName(e.target.value);
                                        }}
                                        onBlur={(e)=>{
                                            if(e.target.value === ''){
                                                setValidateWebinarName(false)
                                            }else{
                                                setValidateWebinarName(true)
                                            }
                                        }}
                                        id="simpleInput1"
                                        placeholder="Webinar Name"
                                        autoComplete="off"
                                    />
                                    {validateWebinarName === false ?(
                                        <div className="text-danger">
                                            Please give webinar name.
                                        </div>
                                    ):null}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <label
                                    htmlFor="simpleInput3"
                                    className="col-2 col-form-label"
                                >
                                    Webinar Description
                                </label>
                                <div className="col-10">
                                    <textarea
                                        className="form-control"
                                        value={webinarDesc}
                                        onChange={(e) => {
                                            setWebinarDesc(e.target.value);
                                        }}
                                        onBlur={(e)=>{
                                            if(e.target.value === ''){
                                                setValidateWebinarDesc(false)
                                            }else{
                                                setValidateWebinarDesc(true)
                                            }
                                        }}
                                        id="simpleInput1"
                                        placeholder="Webinar Description"
                                        autoComplete="off"
                                    />
                                    {validateWebinarDesc === false ?(
                                        <div className="text-danger">
                                            Please give webinar description.
                                        </div>
                                    ):null}
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label
                                    htmlFor="speakerName"
                                    className="col-2 col-form-label"
                                >
                                    
                                    Speaker Name
                                </label>
                                <div className="col-10">
                                    <select
                                        className="form-select"
                                        id="speakerName"
                                        value={speakerName}
                                        onChange={e=> handleSpeakerName(e)}
                                        onBlur={(e)=>{
                                            if(e.target.value === ''){
                                                setValidateSpeakerName(false)
                                            }else{
                                                setValidateSpeakerName(true)
                                            }
                                        }}
                                    >
                                        <option value="" disabled selected>Select Speaker Name</option>
                                        {provider?provider.map((item)=>(
                                            <option key={item.userId}>{item.name}</option>
                                            )):null}
                                    </select>
                                    {validateSpeakerName === false ?(
                                        <div className="text-danger">
                                            Please select speaker name.
                                        </div>
                                    ):null}
                                </div>
                                
                            </div>

                            <div className="row mb-3">
                                <label
                                    htmlFor="start-time"
                                    className="col-2 col-form-label"
                                >
                                    Start Time
                                </label>
                                <div className="col-10">
                                    <div className="row">
                                        <div className="col-8">
                                            <input 
                                                className="form-control" 
                                                id="date" 
                                                type="date" 
                                                name="date"
                                                min={moment(new Date()).format("YYYY-MM-DD")}
                                                value={startDate}
                                                onChange={(e)=>{setStartDate(e.target.value)}}

                                                />
                                        </div>
                                        <div className="col-4">
                                            <input 
                                                className="form-control" 
                                                id="example-time" 
                                                type="time" 
                                                name="time"
                                                value={startTime}
                                                onChange={(e)=>{setStartTime(e.target.value)}}
                                                
                                                />   
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label
                                    htmlFor="duration"
                                    className="col-2 col-form-label"
                                >
                                    Duration
                                </label>
                                <div className="col-10">
                                    <select
                                        className="form-select"
                                        id="led-by"
                                        value={duration}
                                        onChange={e=>setDuration(e.target.value)}
                                        onBlur={(e)=>{
                                            if(e.target.value === ""){
                                                setValidateDuration(false)
                                            }else{
                                                setValidateDuration(true)
                                            }
                                        }}
                                    >
                                        <option value="" disabled selected>Select Event Duration in Minutes</option>
                                        <option >15</option>
                                        <option >30</option>
                                        <option >45</option>
                                        <option >60</option>
                                    </select> 
                                    {validateDuration === false ?(
                                        <div className="text-danger">
                                            Please select duration.
                                        </div>
                                    ):null}
                                </div>
                            </div>

                            <div className="row mb-4">
                                <label
                                    htmlFor="simpleInput1"
                                    className="col-2 col-form-label"
                                >
                                    Join URL
                                </label>
                                <div className="col-10">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={joinUrl}
                                        onChange={(e) => {
                                            setJoinUrl(e.target.value);
                                        }}
                                        id="simpleInput1"
                                        placeholder="Join URL"
                                        autoComplete="off"
                                    />
                                </div>
                            </div>

                            <div className="row mb-4">
                                <label
                                    htmlFor="simpleInput1"
                                    className="col-2 col-form-label"
                                >
                                    Webinar Hero URL
                                </label>
                                <div className="col-10">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={webinarHeroUrl}
                                        onChange={(e) => {
                                            setWebinarHeroUrl(e.target.value);
                                        }}
                                        id="simpleInput1"
                                        placeholder="Webinar Hero URL"
                                        autoComplete="off"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="justify-content-center d-flex">
                                    <div>&nbsp;</div>
                                    <button
                                        type="submit"
                                        className="btn btn-success"
                                        onClick={() => handleCreateWebinar()}
                                    >
                                        {
                                            (webinarId)?"Update Webinar":"Create Webinar"
                                        }
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Navbar>
    )
}

export default CreateWebinar;