import React,{useEffect} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import Navbar from '../../navbar'
import { Grid, _ } from "gridjs-react";
import { getWebinarUsers } from '../../../actions/webinar';
import { webinarUsersSelector } from '../../../selector/webinar';
import { useHistory } from 'react-router';

const Table = (props) =>{    
    const webinarId = props.webinarId;
    const dispatch = useDispatch()
    const history = useHistory()
    useEffect(()=>{
        dispatch(getWebinarUsers(webinarId))
    },[dispatch, webinarId])
    
    const users = useSelector((state) => webinarUsersSelector(state));
    const data = users?users.map((user) => [
        user.userId,
        user.firstName+((user.middleName)?user.middleName:"")+((user.lastName)?user.lastName:""),
        user.planName,
        _(
            <>
                <div className="row">
                 <div className="col-3">
                          <i
                              onClick={() => history.push(`/userDetails/${user.userId}`)}
                              className="mdi mdi-account text-info"
                          ></i>

                 </div> 
                </div>
            </>
        )
    ]):[]

    return(
        <div className="col-12">
            <div className="card">
                <div className="card-body">
                    <div className="tab-content">
                        <div
                            className="tab-pane show active"
                            id="basic-datatable-preview"
                        >
                            <div id="basic-datatable-wrapper">
                                <Grid
                                    data={data}
                                    columns={[
                                        "User Id",
                                        "User Name",
                                        "Plan",
                                        "User"
                                    ]}
                                    search={true}
                                    pagination={{
                                        enabled: true,
                                        limit: 20,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const WebinarUsers = (props)  => {
    return (
        <div>
            <Navbar pageTitle={"Registered Users"}>
                <Table webinarId={props.match.params.webinarId}/>
            </Navbar>
        </div>
    )
}

export default WebinarUsers;
