/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useEffect, useState} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import Navbar from '../navbar'
import { Grid, _ } from "gridjs-react";
import moment from 'moment';
import { getOrders, processOrder } from '../../actions/orders';
import { dateFilterSelector } from '../../selector/Event';
import { DateRangePicker } from 'react-dates';
import { setDateFilter } from '../../actions/event';
import { ordersSelector } from '../../selector/orders';
import { useHistory } from 'react-router-dom';



const DateFilter = ({startDate,endDate,setStartDate,setEndDate}) => {
    const dispatch = useDispatch();
    const [focusedInput, setFocusedInput] = useState();

    return(
        <div className="col-4 d-flex justify-content-end">
            <DateRangePicker
                startDate={startDate}
                startDateId="start-date"
                endDate={endDate}
                endDateId="end-date"
                onDatesChange={({ startDate, endDate }) => {
                    setStartDate(startDate);
                    setEndDate(endDate);
                    dispatch(setDateFilter({startDate, endDate}))
                }}
                focusedInput={focusedInput}
                onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                displayFormat="DD/MM/YYYY"
                isOutsideRange={() => false}
                minimumNights={0}
            />
        </div>
    )
    
}


const Table = ({startDate,endDate}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const orders = useSelector(state => ordersSelector(state))

    const handleClick = (orderId) => {
        dispatch(processOrder({orderId}))
        dispatch(getOrders({startDate,endDate}))
    }
    const data = orders?orders.map((order)=>[
        _(
            <>
                <span style={{color:'blue'}} onClick={()=>{history.push(`/order/${order.orderId}`)}}>{order.orderId}</span>
            </>
        ),
        order.name,
        order.callingNumber,
        `₹ ${Math.round((order.txnAmount + Number.EPSILON) * 100) / 100}`,
        moment(order.paymentTimestamp).utcOffset(0).format('YYYY-MM-DD HH:mm'),
        _(
            order.orderStatus==='Success'?
            <span className={`badge bg-success`}>Success</span> 
            :order.orderStatus==='Pending'?
            <span className={`badge bg-warning`}>Pending</span>:
            <span className={`badge bg-danger`}>Failure</span>
        ),
        order.orderStatus==='Pending'?_(
            <>
                <button 
                    type="button" 
                    className="btn btn-outline-success btn-rounded"
                    onClick={()=>{handleClick(order.orderId)}}
                    style={{padding:'2px' ,width:'100px', marginLeft:'20px'}}>
                        Mark as Paid
                    </button> 
            </>
        ):_(
            <>
            <button 
                    type="button" 
                    className="btn btn-outline-primary btn-rounded"
                    disabled={true}
                    style={{padding:'2px' ,width:'100px', marginLeft:'20px'}}>
                        Settled
                    </button>
        </>
        )
    ]):[]
    return (
        <div className="col-12">
            <div className="card">
                <div className="card-body">
                    <div className="tab-content">
                        <div
                            className="tab-pane show active"
                            id="basic-datatable-preview"
                        >
                            <div id="basic-datatable-wrapper">
                                <Grid
                                    data={data}
                                    columns={[
                                        "Order ID",
                                        "Name",
                                        "Contact",
                                        "Amount",
                                        "Timestamp",
                                        "Status",
                                        "Action",
                                    ]}
                                    search={true}
                                    pagination={{
                                        enabled: true,
                                        limit: 20,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
}



const Orders = () => {    
    const dispatch = useDispatch();
    const dateFilter = useSelector((state) => dateFilterSelector(state))
    const [startDate,setStartDate] = useState(dateFilter.startDate ? dateFilter.startDate : moment());
    const [endDate,setEndDate] = useState(dateFilter.endDate ? dateFilter.endDate : moment());

    useEffect(()=>{
        dispatch(getOrders({startDate,endDate}))
    },[dispatch, startDate, endDate])
    
  return (
    <div>
    <Navbar>
        <div className="row mb-3">
            <div className="col-8">
                <h3 className="page-title page-title-text">
                    All Orders
                </h3>
            </div>
            <DateFilter
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
            />
        </div>
        <Table
            startDate={startDate}
            endDate={endDate}       
        />
    </Navbar>
</div>
  )
}

export default Orders