export const PRIMARY_CONCERN = `[PRIMARY_CONCERN]`

export const PRIMARY_CONCERN_GET = `${PRIMARY_CONCERN} GET`

export const PRIMARY_CONCERN_SET = `${PRIMARY_CONCERN} SET`

export const getPrimaryConcern = () =>({
    type: PRIMARY_CONCERN_GET,
    payload:{}
})

export const setPrimaryConcern = (data) =>({
    type:PRIMARY_CONCERN_SET,
    payload:data
})