import { apiRequest,API_SUCCESS,API_ERROR } from "../../../actions/api"
import {ROOT_URL} from '../../../config'
import Alert from "sweetalert2";
import { PROFILE_IMAGE_LOC, PROFILE_IMAGE_LOC_SAVE, PROFILE_PRESIGNED_URL, PROFILE_PRESIGNED_URL_GET, setProfilePresignedUrl } from "../../../actions/profilePhotoUpload";
import { getLoggedOut } from "../../../actions/login";
import { TASK_THUMBNAIL_PRESIGNED_URL_GET,TASK_THUMBNAIL_PRESIGNED_URL, setTaskThumbnailPresignedUrl } from "../../../actions/tasks";
import { CHALLENGE_THUMBNAIL_PRESIGNED_URL_GET,CHALLENGE_THUMBNAIL_PRESIGNED_URL, CHALLENGE_HERO_PRESIGNED_URL, CHALLENGE_HERO_PRESIGNED_URL_GET, CHALLENGE_LANDING_PRESIGNED_URL, CHALLENGE_LANDING_PRESIGNED_URL_GET, setChallengeLandingPresignedUrl, setChallengeHeroPresignedUrl, setChallengeThumbnailPresignedUrl } from "../../../actions/challenges";
import { LAB_TEST_THUMBNAIL_PRESIGNED_URL, LAB_TEST_THUMBNAIL_PRESIGNED_URL_GET, setLabTestThumbnailPresignedUrl } from "../../../actions/labTests";
import { CONTENT_PRESIGNED_URL_GET, CONTENT_PRESIGNED_URL, setContentPresignedUrl, CONTENT_IMAGE_LOC_SAVE } from '../../../actions/contentImageUpload';
import { DOCTOR_PROFILE_PRESIGNED_URL, DOCTOR_PROFILE_PRESIGNED_URL_GET, setDoctorProfilePresignedUrl } from "../../../actions/doctorFacingTech";

export const photoUploadMiddleware = ({dispatch}) => next => (action) => {
    next(action)
    switch(action.type){
            
        case PROFILE_PRESIGNED_URL_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/profilePhotoPresignedUrl`,
                method:'get',
                feature:PROFILE_PRESIGNED_URL               
            }))
            break;
        case DOCTOR_PROFILE_PRESIGNED_URL_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/doctorPresignedUrl`,
                method:'get',
                feature: DOCTOR_PROFILE_PRESIGNED_URL              
            }))
            break;
        case CONTENT_PRESIGNED_URL_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/contentPresignedUrl`,
                method:'get',
                feature:CONTENT_PRESIGNED_URL               
            }))
            break;
        case PROFILE_IMAGE_LOC_SAVE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/saveProfileImageLoc`,
                method:'post',
                feature:PROFILE_IMAGE_LOC               
            }))
            break;
        case CONTENT_IMAGE_LOC_SAVE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/saveProfileImageLoc`,
                method:'post',
                feature:PROFILE_IMAGE_LOC               
            }))
            break;
        case `${PROFILE_PRESIGNED_URL} ${API_SUCCESS}`:
            if(action.message==='Presigned Upload Url'){
                dispatch(setProfilePresignedUrl(action.payload))
            }
            break;
        case `${PROFILE_PRESIGNED_URL} ${API_ERROR}`:
            if(action.message==='Presigned Url could not be generated'){
                Alert.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: action.message,
                    showConfirmButton: false,
                    timer: 1500
                    })
            }
            break;
        case `${DOCTOR_PROFILE_PRESIGNED_URL} ${API_SUCCESS}`:
            dispatch(setDoctorProfilePresignedUrl(action.payload))
            break;
        case `${DOCTOR_PROFILE_PRESIGNED_URL} ${API_ERROR}`:
            Alert.fire({
                position: 'top-end',
                icon: 'error',
                title: action.message,
                showConfirmButton: false,
                timer: 1500
            })
            break;
        case `${CONTENT_PRESIGNED_URL} ${API_SUCCESS}`:
            if(action.message==='Presigned Upload Url'){
                dispatch(setContentPresignedUrl(action.payload))
            }
            break;
        case `${CONTENT_PRESIGNED_URL} ${API_ERROR}`:
            if(action.message==='Presigned Url could not be generated'){
                Alert.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: action.message,
                    showConfirmButton: false,
                    timer: 1500
                    })
            }
            break;  
        case `${PROFILE_IMAGE_LOC} ${API_SUCCESS}`:
            if(action.message==='Image Upload Successful'){
                Alert.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: action.message,
                    showConfirmButton: false,
                    timer: 1500
                    })
            }
            dispatch(getLoggedOut())
            break;
        case `${PROFILE_IMAGE_LOC} ${API_ERROR}`:
            if(action.message==='Image Upload Failed'){
                Alert.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: action.message,
                    showConfirmButton: false,
                    timer: 1500
                    })
            }
            break;  
        
        case TASK_THUMBNAIL_PRESIGNED_URL_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/getTaskThumbnailPresignedUrl`,
                method:'get',
                feature:TASK_THUMBNAIL_PRESIGNED_URL             
            }))
            break;
        case `${TASK_THUMBNAIL_PRESIGNED_URL} ${API_SUCCESS}`:
            if(action.message==='Presigned Upload Url'){
                dispatch(setTaskThumbnailPresignedUrl(action.payload))
            }
            break;
        case `${TASK_THUMBNAIL_PRESIGNED_URL} ${API_ERROR}`:
            if(action.message==='Presigned Url could not be generated'){
                Alert.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: action.message,
                    showConfirmButton: false,
                    timer: 1500
                    })
            }
            break; ;
        
        case CHALLENGE_THUMBNAIL_PRESIGNED_URL_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/getChallengeThumbnailPresignedUrl`,
                method:'get',
                feature:CHALLENGE_THUMBNAIL_PRESIGNED_URL           
            }))
            break;
        case `${CHALLENGE_THUMBNAIL_PRESIGNED_URL} ${API_SUCCESS}`:
            if(action.message==='Presigned Upload Url'){
                dispatch(setChallengeThumbnailPresignedUrl(action.payload))
            }
            break;
        case `${CHALLENGE_THUMBNAIL_PRESIGNED_URL} ${API_ERROR}`:
            if(action.message==='Presigned Url could not be generated'){
                Alert.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: action.message,
                    showConfirmButton: false,
                    timer: 1500
                    })
            }
            break;
        
        case CHALLENGE_HERO_PRESIGNED_URL_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/getChallengeThumbnailPresignedUrl`,
                method:'get',
                feature:CHALLENGE_HERO_PRESIGNED_URL           
            }))
            break;
        case `${CHALLENGE_HERO_PRESIGNED_URL} ${API_SUCCESS}`:
            if(action.message==='Presigned Upload Url'){
                dispatch(setChallengeHeroPresignedUrl(action.payload))
            }
            break;
        case `${CHALLENGE_HERO_PRESIGNED_URL} ${API_ERROR}`:
            if(action.message==='Presigned Url could not be generated'){
                Alert.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: action.message,
                    showConfirmButton: false,
                    timer: 1500
                    })
            }
            break;

        case CHALLENGE_LANDING_PRESIGNED_URL_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/getChallengeLandingPresignedUrl`,
                method:'get',
                feature:CHALLENGE_LANDING_PRESIGNED_URL           
            }))
            break;
        case `${CHALLENGE_LANDING_PRESIGNED_URL} ${API_SUCCESS}`:
            if(action.message==='Presigned Upload Url'){
                dispatch(setChallengeLandingPresignedUrl(action.payload))
                Alert.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: "Image Uploaded",
                    showConfirmButton: false,
                    timer: 1500
                })
            }
            break;
        case `${CHALLENGE_LANDING_PRESIGNED_URL} ${API_ERROR}`:
            if(action.message==='Presigned Url could not be generated'){
                Alert.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: action.message,
                    showConfirmButton: false,
                    timer: 1500
                    })
            }
            break;

        case LAB_TEST_THUMBNAIL_PRESIGNED_URL_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/labTestThumbnailPresignedUrl`,
                method:'get',
                feature:LAB_TEST_THUMBNAIL_PRESIGNED_URL
            }))
            break;
        case `${LAB_TEST_THUMBNAIL_PRESIGNED_URL} ${API_SUCCESS}`:
            if(action.message==='Presigned Upload Url'){
                dispatch(setLabTestThumbnailPresignedUrl(action.payload))
            }
            break;
        case `${LAB_TEST_THUMBNAIL_PRESIGNED_URL} ${API_ERROR}`:
            if(action.message==='Presigned Url could not be generated'){
                Alert.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: action.message,
                    showConfirmButton: false,
                    timer: 1500
                    })
            }
            break;
            default: break;
    }
}
