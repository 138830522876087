import React,{useEffect} from 'react'
import { Grid, _ } from "gridjs-react";
import moment from 'moment';
import {useDispatch,useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom'
import { getMyBookings } from '../../actions/insights';
import { myBookingsSelector } from '../../selector/insights';

const Table = ({data}) => {
    const history = useHistory()
    const myBookings = data.length>0?data
    .map((event)=>[
        event.eventId,
        event.eventName,
        moment(event.start).format('YYYY-MM-DD HH:mm'),
        event.offeringTypeName,
        event.currentCapacity,
        _(
            <>
                <div className="col-3">
                    <i
                        className="dripicons-clipboard text-success"
                        onClick={() => {
                            history.push(`eventDetails/${event.eventId}`);}}
                    ></i>
                </div>
            </>
        )
    ]):[]
    return (
        <div className="col-12">
        <div className="card">
            <div className="card-body">
                <div className="tab-content">
                    <div
                        className="tab-pane show active"
                        id="basic-datatable-preview"
                    >

                        <div id="basic-datatable-wrapper">
                            <Grid
                                data={myBookings}
                                columns={[
                                    "Event Id",
                                    "Event Name",
                                    "Event Date",
                                    "Offering Type",
                                    "Current Capacity",
                                    "Event Details"
                                ]}
                                search={true}
                                pagination={{
                                    enabled: true,
                                    limit: 20,
                                }}
                            />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}


const MyBookings = () =>  {
    const date = moment().format('YYYY-MM-DD')
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(getMyBookings({startDate:date}))
    },[date,dispatch])
    const data = useSelector(state=>myBookingsSelector(state))
    return (
        <div>
            <h3> My Bookings - Today</h3>
            <Table
                data={data}
            />
            
        </div>
    )
}

export default MyBookings
