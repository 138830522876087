export const USER = `[USER]`
export const USER_BOOKINGS = `[USER_BOOKINGS]`
export const USER_MEAL_TEMPLATE = `[USER_MEAL_TEMPLATE]`
export const USER_EXPERT_REPORT = `[USER_EXPERT_REPORT]`
export const USER_OFFERING = `[USER_OFFERING]`
export const USER_LAB_REPORT = `[USER_LAB_REPORT]`;

export const USER_BOOKINGS_PERIOD_LOGS = `[USER_BOOKINGS_PERIOD_LOGS]`;
export const USER_BOOKINGS_WEBINAR_LOGS = `[USER_BOOKINGS_WEBINAR_LOGS]`;
export const USER_BOOKINGS_SYMPTOM_LOGS = `[USER_BOOKINGS_SYMPTOM_LOGS]`;
export const USER_BOOKINGS_CONSULT_LOGS = `[USER_BOOKINGS_CONSULT_LOGS]`;

export const USER_CREATE  =  `${USER} CREATE`
export const USER_EDIT = `${USER} EDIT`
export const USER_GET = `${USER} GET`
export const USER_SET = `${USER} SET`
export const USER_BOOKINGS_GET = `${USER} BOOKINGS GET`
export const USER_BOOKINGS_SET = `${USER} BOOKINGS SET`

export const USER_BOOKINGS_PERIOD_LOGS_GET = `${USER_BOOKINGS_PERIOD_LOGS} GET`;
export const USER_BOOKINGS_PERIOD_LOGS_SET = `${USER_BOOKINGS_PERIOD_LOGS} SET`;
export const USER_BOOKINGS_WEBINAR_LOGS_GET = `${USER_BOOKINGS_WEBINAR_LOGS} GET`;
export const USER_BOOKINGS_WEBINAR_LOGS_SET = `${USER_BOOKINGS_WEBINAR_LOGS} SET`;
export const USER_BOOKINGS_SYMPTOM_LOGS_GET = `${USER_BOOKINGS_SYMPTOM_LOGS} GET`;
export const USER_BOOKINGS_SYMPTOM_LOGS_SET = `${USER_BOOKINGS_SYMPTOM_LOGS} SET`;
export const USER_BOOKINGS_CONSULT_LOGS_GET = `${USER_BOOKINGS_CONSULT_LOGS} GET`;
export const USER_BOOKINGS_CONSULT_LOGS_SET = `${USER_BOOKINGS_CONSULT_LOGS} SET`;

export const USER_MEAL_TEMPLATE_SEND = `${USER_MEAL_TEMPLATE} SEND`
export const USER_EXPERT_REPORT_SEND = `${USER_EXPERT_REPORT} SEND`

export const USER_OFFERING_INCREASE = `${USER_OFFERING} INCREASE`
export const USER_OFFERING_DECREASE = `${USER_OFFERING} DECREASE`


export const USER_LAB_REPORT_GET = `${USER_LAB_REPORT} GET`;
export const USER_LAB_REPORT_SET = `${USER_LAB_REPORT} SET`;
export const USER_LAB_REPORT_SEND = `${USER_LAB_REPORT} SEND`;

export const sendUserMealTemplate = (data) => ({
    type: USER_MEAL_TEMPLATE_SEND,
    payload:data
})

export const sendUserExpertReport = (data) => ({
    type: USER_EXPERT_REPORT_SEND,
    payload:data
})

export const sendUserLabReport = (data) => ({
    type: USER_LAB_REPORT_SEND,
    payload:data
})

export const createNewUser = (data) =>({
    type: USER_CREATE,
    payload:data
})

export const getUserDetails = (data) =>({
    type:USER_GET,
    payload:data
})

export const setUserDetails = (data) =>({
    type:USER_SET,
    payload:data
})

export const editUserDetails = (data) =>({
    type:USER_EDIT,
    payload:data
})

export const getUserBookings = (data) => ({
    type:USER_BOOKINGS_GET,
    payload:data
})

export const setUserBookings = (data) => ({
    type:USER_BOOKINGS_SET,
    payload:data
})

export const increaseUserOffering = (data) => ({
    type:USER_OFFERING_INCREASE,
    payload:data
})


export const decreaseUserOffering = (data) => ({
    type:USER_OFFERING_DECREASE,
    payload:data
})

export const getUserBookingsPeriodLogs = (data) => ({
    type: USER_BOOKINGS_PERIOD_LOGS_GET,
    payload: data
});

export const setUserBookingsPeriodLogs = (data) => ({
    type: USER_BOOKINGS_PERIOD_LOGS_SET,
    payload: data
});

export const getUserBookingsWebinarLogs = (data) => ({
    type: USER_BOOKINGS_WEBINAR_LOGS_GET,
    payload: data
});

export const setUserBookingsWebinarLogs = (data) => ({
    type: USER_BOOKINGS_WEBINAR_LOGS_SET,
    payload: data
});

export const getUserBookingsSymptomLogs = (data) => ({
    type: USER_BOOKINGS_SYMPTOM_LOGS_GET,
    payload: data
});

export const setUserBookingsSymptomLogs = (data) => ({
    type: USER_BOOKINGS_SYMPTOM_LOGS_SET,
    payload: data
});

export const getUserBookingsConsultLogs = (data) => ({
    type: USER_BOOKINGS_CONSULT_LOGS_GET,
    payload: data
});

export const setUserBookingsConsultLogs = (data) => ({
    type: USER_BOOKINGS_CONSULT_LOGS_SET,
    payload: data
});

export const getUserLabReport = (data) => ({
    type: USER_LAB_REPORT_GET,
    payload: data
})

export const setUserLabReport = (data) => ({
    type: USER_LAB_REPORT_SET,
    payload: data
})