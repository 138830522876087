import { apiRequest,API_SUCCESS,API_ERROR } from "../../../actions/api"
import {ROOT_URL} from '../../../config'
import { 
    TOPIC_CONTENT_MAP,
    TOPIC_CONTENT_MAP_GET,

    TOPIC_CONTENT_ADD,
    TOPIC_CONTENT_ADD_REQ,

    TOPIC_CONTENT_REMOVE,
    TOPIC_CONTENT_REMOVE_REQ,

    setTopicContentMap,
    setErrorTopicContentMap
} from "../../../actions/topicsContentMap"

export const topicsContentMapMiddleware = ({dispatch}) => next => (action) =>{
    next(action)
    switch(action.type){
        case TOPIC_CONTENT_MAP_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/get_topic_content_map`,
                method:'get',
                feature:TOPIC_CONTENT_MAP
            }))
            break;

        case TOPIC_CONTENT_ADD_REQ:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/add_topic_content_map`,
                method:'post',
                feature:TOPIC_CONTENT_ADD
            }))
            break;

        case TOPIC_CONTENT_REMOVE_REQ:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/delete_topic_content_map`,
                method:'delete',
                feature:TOPIC_CONTENT_REMOVE
            }))
            break;

        case `${TOPIC_CONTENT_MAP} ${API_SUCCESS}`:
        case `${TOPIC_CONTENT_ADD} ${API_SUCCESS}`:
        case `${TOPIC_CONTENT_REMOVE} ${API_SUCCESS}`:
                dispatch(setTopicContentMap(action.payload))
                break;
        
        case `${TOPIC_CONTENT_MAP} ${API_ERROR}`:
        case `${TOPIC_CONTENT_ADD} ${API_ERROR}`:
        case `${TOPIC_CONTENT_REMOVE} ${API_ERROR}`:
                dispatch(setErrorTopicContentMap(action.payload))
            break;

        default: break;
    }
}

