import { apiRequest,API_SUCCESS,API_ERROR } from "../../../actions/api"
import {ROOT_URL} from '../../../config/'
import { PROVIDER, PROVIDER_GET, setProvider } from "../../../actions/provider"

export const providerMiddleware = ({dispatch}) => next => (action) =>{
    next(action)
    switch(action.type){
        case `${PROVIDER_GET}`:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/provider`,
                method:'get',
                feature:PROVIDER
            }))
            break;
        case `${PROVIDER} ${API_SUCCESS}`:
            dispatch(setProvider(action.payload))
            break;
        case `${PROVIDER} ${API_ERROR}`:
            break;

        default: break;
    }
}