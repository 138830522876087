import { SKUS_SET, SKU_SET } from "../../actions/SKU";

const initState = []
export const SKUReducer = (state=initState,action) =>{
    switch(action.type){
        case SKUS_SET:
            return {...state,SKUs:action.payload}
        case SKU_SET:
            return {...state,SKUDetails:action.payload}
        default: return state
    }
}
