import { apiRequest,API_SUCCESS,API_ERROR } from "../../../actions/api"
import Alert from "sweetalert2";
import { 
    CHANGE_PASSWORD,
    forceLogout, 
    getLoggedOut, 
    LOGIN, 
    LOGIN_AUTH, 
    LOGIN_CHECK, 
    LOGOUT, 
    LOGOUT_AUTH, 
    PASSWORD, 
    setAuthentication,  
} from "../../../actions/login/"
import {ROOT_URL} from '../../../config/'

export const loginMiddleware = ({dispatch}) => next => (action) =>{
    next(action)
    switch(action.type){
        case LOGIN_AUTH:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/login`,
                method:'post',
                feature:LOGIN
            }))
            break;
        case LOGOUT_AUTH:
            dispatch(apiRequest({
                body:null,
                config:{},
                url:`${ROOT_URL}/logout`,
                method:'get',
                feature:LOGOUT
            }))
            break;
        case LOGIN_CHECK:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/isLoggedIn`,
                method:'get',
                feature:LOGIN
            }))
            break;
        case CHANGE_PASSWORD:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/changePassword`,
                method:'post',
                feature:PASSWORD
            }))
            break;
        case `${LOGIN} ${API_SUCCESS}`:
            dispatch(setAuthentication(action.payload))
            break;
        case `${LOGIN} ${API_ERROR}`:
            dispatch(setAuthentication({isAuthenticated:false}))
            break;
        case `${LOGOUT} ${API_SUCCESS}`:
            dispatch(setAuthentication({isAuthenticated:"pending"}))
            break;
        case `${LOGOUT} ${API_ERROR}`:
            dispatch(forceLogout())
            break;
        case `${PASSWORD} ${API_SUCCESS}`:
                Alert.fire({
                position: 'top-end',
                icon: 'success',
                title: action.message,
                showConfirmButton: false,
                timer: 1500
              })
            dispatch(setAuthentication({isAuthenticated:false}))
            dispatch(getLoggedOut());
            break;
        case `${PASSWORD} ${API_ERROR}`:
            Alert.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Password Change Request Failed',
                showConfirmButton: false,
                timer: 1500
              })
              break;
        default: break;
    }
}
