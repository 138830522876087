export const EXOTEL_LOGS = `[EXOTEL_LOGS]`

export const EXOTEL_FILTER_SET = `[EXOTEL_FILTER] SET`;

export const EXOTEL_LOGS_GET = `${EXOTEL_LOGS} GET`
export const EXOTEL_LOGS_SET = `${EXOTEL_LOGS} SET`
export const EXOTEL_FILTERED_LOGS_SET = `[EXOTEL_FILTERED_LOGS] SET`

export const getExotelLogs = (data) => ({
    type: EXOTEL_LOGS_GET,
    payload: data
})

export const setExotelLogs = (data) => ({
    type:EXOTEL_LOGS_SET,
    payload:data
})

export const setExotelFilteredLogs = (data) => ({
    type:EXOTEL_FILTERED_LOGS_SET,
    payload:data
})

export const setFilter = (data) => ({
    type: EXOTEL_FILTER_SET ,
    payload: data
})