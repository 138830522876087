/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import Navbar from '../navbar'
import { Grid, _ } from "gridjs-react";
import { useDispatch, useSelector } from 'react-redux';
import { getChallengeUserDetails, markTask, unmarkTask } from '../../actions/challenges';
import { challengeUserSelector } from '../../selector/challenges';
import moment from 'moment';

const Task = ({
    taskId,
    taskThumbnail,
    taskTitle,
    taskReward,
    message,
    userId,
    challengeId,
    dispatch,
    isMarked,
    date
}) => {

    const [mark, setMark] = useState(isMarked)
    const [taskDate,setTaskDate] = useState(date)

    const onMarkEvent = () => {
        if(mark===1){
            setMark(0)
            setTaskDate(null)
            dispatch(unmarkTask({userId, taskId, taskDate, challengeId}))

        }else{
            setMark(1)
            setTaskDate(moment().format('DD-MM-YYYY'))
            message = "alert alert-success";
            dispatch(markTask({userId, taskId, taskDate, challengeId}))
        }
    }

    return (
        <div class="row mx-n1 g-0 w-100">
            <div className="col-12">
                <div className={`card m-1 shadow-none border ${message}`}>
                    <div className={`form-check form-check-inline`}>
                        <label className="form-check-label">
                            <div className="p-1">
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                        <div className="avatar-sm">
                                            <span className="avatar-title bg-white text-secondary rounded">
                                                <img
                                                    src={taskThumbnail}
                                                    alt="thumbnail"
                                                    style={{height:'48px',width:'48px'}}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col ps-0">
                                        <a href="#" className="text-muted fw-bold">{taskTitle}</a>
                                        <p className="mb-0 font-13">🌕 x {taskReward}</p>
                                    </div>
                                </div> 
                            </div> 
                        </label>
                        <input 
                        type="checkbox" 
                        className="form-check-input" 
                        checked={mark===1?true:false}
                        onChange={()=>{onMarkEvent()}}
                        />
                    </div>
                </div> 
            </div>
        </div>

    )
}


const Table = ({challengeTask, challengeId, dispatch, userId}) => {
    let dataObj = []
    if(challengeTask){
        dataObj = Object.entries(challengeTask)
    }

    const data = dataObj.map(([key,value],index)=>[
        `Day ${index+1} - ${moment(key).format('DD-MM-YYYY')}`,
        _(
            <>
                {value?value.map((task)=>{
                    let message = "alert alert-success";
                    let isMarked = 1;

                    if(task.taskStatus === "failed")
                    {
                        message = "alert alert-danger";
                        isMarked = 0
                    }
                    else if(task.taskStatus === "upcoming")
                    {
                        message = "alert alert-warning";
                        isMarked = 0
                    }
                    return (
                        <Task
                            taskId={task.taskId}
                            taskThumbnail={task.taskThumbnail}
                            taskTitle={task.taskTitle}
                            taskReward={task.taskReward}
                            message={message}
                            challengeId={challengeId}
                            dispatch={dispatch}
                            isMarked={isMarked}
                            date={task.taskDate}
                            userId={userId}
                        />)
                }):''}
            </>

        ),
        value?value.reduce((total, task) => {
            if(task.taskStatus === "completed")
            {
                return total+task.taskReward;
            }
            return total;
        }, 0):0
    ])

    return (
        <div className="col-12">
            <div className="card">
                <div className="card-body">
                    <div className="tab-content">
                        <div
                            className="tab-pane show active"
                            id="basic-datatable-preview"
                        >
                            <div id="basic-datatable-wrapper">
                                <Grid
                                    data={data}
                                    columns={[
                                        {name:"Challenge Day",width:"25%"},
                                        {name:"Tasks",width:"50%"},
                                        {name:"Rewards",width:"25%"}
                                    ]}
                                    pagination={{
                                        enabled: true,
                                        limit: 10,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const UserDetailsBody = ({challengeUserDetails, userId, challengeId, dispatch}) => {

    const userName = challengeUserDetails?challengeUserDetails.userName:"";
    const profilePicture = challengeUserDetails.profilePicture;

    const challenges = challengeUserDetails.enrolledChallenges?
        challengeUserDetails.enrolledChallenges.filter((challenge) => challenge.challengeId === challengeId)
        :[];

    const rank = (challenges.length !== 0)?challenges[0].currentStandings:0;
    const totalPoints = (challenges.length !== 0)?challenges[0].currentPoints:0;
    const challengeName = (challenges.length !== 0)?challenges[0].challengeName:'';
    
    return (
        <div>
            {/* Top Profile Bar */}
            <div className="row">
                <div className="col-12">
                    <div className="card bg-secondary">
                        <div className="card-body profile-user-box">
                            <div className="row align-items-center">
                                <div
                                    className="col-2 justify-content-center"
                                    style={{ display: "flex" }}
                                >
                                    <div className="avatar-lg">
                                        <img
                                            src={profilePicture?profilePicture:"/assets/images/users/avatar-9.jpg"}
                                            alt=""
                                            className="rounded-circle img-thumbnail"
                                        />
                                    </div>
                                </div>
                                <div className="col-10">
                                    <div>
                                        <div className="row">
                                            <div className="col-8">
                                                <h4 className="mt-1 mb-1 text-white">
                                                    {`${userName}`}
                                                </h4>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-8">
                                                <ul className="mb-0 list-inline text-light">
                                                    <li className="list-inline-item me-3">
                                                        <h5 className="mb-1">{totalPoints}</h5>
                                                        <p className="mb-0 font-13 text-white-50">
                                                            Total Points
                                                        </p>
                                                    </li>
                                                    <li className="list-inline-item me-3">
                                                        <h5 className="mb-1">{rank}</h5>
                                                        <p className="mb-0 font-13 text-white-50">
                                                            Rank
                                                        </p>
                                                    </li>
                                                    <li className="list-inline-item me-3">
                                                        <h5 className="mb-1">{challengeName}</h5>
                                                        <p className="mb-0 font-13 text-white-50">
                                                            Challenge Name
                                                        </p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Table
                challengeTask={
                    Object.keys(challenges).length!==0?challenges[0].challengeTasks:{}
                }
                challengeId={challengeId}
                dispatch={dispatch}
                userId={userId}
            />
        </div>
    );
};

const ChallengeUserDetails = (props) => {

    const dispatch = useDispatch()

    const userId = props.match.params.userId;
    const challengeId = props.match.params.challengeId;

    const challengeUserDetails = useSelector(state=>challengeUserSelector(state))

    useEffect(()=>{
        dispatch(getChallengeUserDetails(userId))
    },[dispatch,challengeId,userId])
    
    return (
        <Navbar pageTitle={"User Tasks"}>
            <UserDetailsBody 
                challengeUserDetails={challengeUserDetails}
                userId={userId}
                challengeId={challengeId}
                dispatch={dispatch}
            />
        </Navbar>
    );
};

export default ChallengeUserDetails;
