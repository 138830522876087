import React,{useEffect,useState} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import { createNewOffering, getOfferingType, getOfferingUnit } from '../../../actions/offering'

import { offeringTypeSelector, offeringUnitSelector } from '../../../selector/offering'

import Offering from './Offering'


const Modal = () =>{
    const [offeringName,setOfferingName] = useState('')
    const [offeringQuantity,setOfferingQuantity] = useState('')
    const [offeringUnit,setOfferingUnit] = useState('')
    const [offeringType,setOfferingType] = useState([''])

    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(getOfferingType())
        dispatch(getOfferingUnit())
    },[dispatch])
    const offeringTypes = useSelector(state=>offeringTypeSelector(state))
    const offeringUnits = useSelector(state=>offeringUnitSelector(state))
    
    const handleSubmit = (e) =>{
        e.preventDefault()
        dispatch(createNewOffering(
            {offeringName,offeringQuantity,offeringType,offeringUnit}
            ))
        
    }
    return(
        <div id="mymodal" className="modal fade" tabIndex="-1" role="dialog" ariaHidden="true">
            <div className="modal-dialog">
                <div className="modal-content">

                    <div className="modal-body">
                        <form className="ps-2 pe-2">

                            <div className="mb-3 mt-3">
                                <label hmtlFor="offeringName" className="form-label">Offering Name</label>
                                <input 
                                    className="form-control" 
                                    type="text" 
                                    id="offeringName"
                                    value={offeringName}
                                    onChange={(e)=>{setOfferingName(e.target.value)}}  
                                    placeholder="offeringName"/>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="offeringQuantity" className="form-label">Offering Quantity</label>
                                <input 
                                    className="form-control" 
                                    type="text" 
                                    id="offeringQuantity"
                                    value={offeringQuantity}
                                    onChange={(e)=>{setOfferingQuantity(e.target.value)}} 
                                    placeholder="offeringQuantity"/>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="offeringUnit" className="form-label">Offering Unit</label>
                                <select
                                    className="form-select"
                                    id="offeringUnit"
                                    value={offeringUnit}
                                    onChange={(e)=>{setOfferingUnit(e.target.value)}}
                                    >
                                    <option value="" disabled selected>Select Offering Unit</option>
                                    {offeringUnits?offeringUnits.map((item)=>{
                                        return(
                                            <option key={item.offeringUnitId}>{item.offeringUnitName}</option>
                                        )
                                        }):null}
                                    
                                </select>
                            </div>
                            
                            <div className="mb-3">
                                <label htmlFor="offeringUnit" className="form-label">Offering Type</label>
                                <select
                                    className="form-select"
                                    id="offeringType"
                                    value={offeringType}
                                    onChange={(e)=>{setOfferingType(e.target.value)}}
                                    >
                                    <option value="" disabled selected>Select Offering Type</option>
                                    {offeringTypes?offeringTypes.map((item)=>{
                                        return(
                                            <option key={item.offeringTypeId}>{item.offeringTypeName}</option>
                                        )
                                        }):null}
                                    
                                </select>
                            </div>


                            <div className="mb-3 text-center">
                                <button 
                                className="btn btn-success" 
                                type="submit"
                                data-bs-toggle="modal"
                                data-bs-target="#mymodal"
                                onClick={(e)=>{handleSubmit(e)}}
                                >
                                    Create New Offering
                                </button>
                            </div>

                        </form>

                    </div>
                </div>
            </div>
        </div>
    )
}

const OfferingList = ({offerings,setOfferings,selectedOfferings,setSelectedOfferings}) => {
    const onOfferingSelect = (offeringId) =>{
        const remainingOffering = offerings.filter(offering=>offering.offeringId!==offeringId)
        const selectedOffering = offerings.filter(offering=>offering.offeringId===offeringId)
        setOfferings(remainingOffering)
        setSelectedOfferings([...selectedOfferings,...selectedOffering])
    }
    return (
        <div className="tasks h-100 w-100">
            <div classname="w-100">
                <h4 className="justify-content-between d-flex task-header align-items-center">
                    All Offerings
                    <button 
                        type="submit" 
                        className="btn btn-secondary"
                        data-bs-toggle="modal" 
                        data-bs-target="#mymodal"
                    >                      
                        <i className="uil-plus"></i>
                    </button>
                    <Modal/>
                </h4>
            </div>
            <div id="offeringList" class="task-list-items">
            {offerings?offerings.map((offering)=>{
                                return(
                                    <Offering
                                        offeringId={offering.offeringId}
                                        colorScheme={offering.colorScheme}
                                        name={offering.offeringName}
                                        quantity={offering.offeringQuantity}
                                        status={offering.offeringStatus}
                                        type={offering.offeringTypeName}
                                        unit={offering.offeringUnitName}
                                        onOfferingSelect={onOfferingSelect}
                                        key={offering.offeringId}
                                    />
                                )
                            }):null}
            </div> 
        </div>
    )
}

export default OfferingList
