import { apiRequest, API_SUCCESS, API_ERROR } from "../../../actions/api";
import { ROOT_URL } from "../../../config";
import { 
    WEBINAR, 
    WEBINARS, 
    WEBINAR_USERS, 
    WEBINAR_UPDATE, 
    WEBINARS_GET, 
    WEBINAR_USERS_GET, 
    WEBINAR_CREATE,  
    setWebinars, 
    setWebinarUsers, 
    getWebinars, 
    WEBINAR_SEND_BURST 
} from "../../../actions/webinar";
import Alert from "sweetalert2";

export const webinarMiddleware = ({dispatch}) => next => (action) => {
    next(action);

    switch(action.type){
        case WEBINARS_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/webinars`,
                method:'get',
                feature: WEBINARS
            }));
            break;
        case WEBINAR_CREATE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/webinar`,
                method:'post',
                feature: WEBINAR
            }));
            break;
        case WEBINAR_UPDATE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/webinar`,
                method:'put',
                feature: WEBINAR
            }));
            break;
        case WEBINAR_USERS_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/webinarUsers`,
                method:'get',
                feature: WEBINAR_USERS
            }));
            break;
        case WEBINAR_SEND_BURST:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/webinarBurst`,
                method:'get',
                feature: WEBINAR
            }));
            break;
        case `${WEBINARS} ${API_SUCCESS}`:
                dispatch(setWebinars(action.payload))
            break;
        case `${WEBINARS} ${API_ERROR}`:
            Alert.fire({
                position: 'top-end',
                icon: 'error',
                title: action.message,
                showConfirmButton: false,
                timer: 1500
              })
            break;
        case `${WEBINAR_USERS} ${API_SUCCESS}`:
            dispatch(setWebinarUsers(action.payload))
            break;
        case `${WEBINAR} ${API_SUCCESS}`:
            if(action.message === 'Webinar burst sent'){
                Alert.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Webinar burst sent',
                    showConfirmButton: false,
                    timer: 1500
                  })
            }else{
                Alert.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: action.message,
                    showConfirmButton: false,
                    timer: 1500
                    }).then(()=>{
                        dispatch(getWebinars())
                })
            }
            
            break;
        case `${WEBINAR} ${API_ERROR}`:
            Alert.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Webinar Could Not Be Created',
                showConfirmButton: false,
                timer: 1500
                })
            break;
        case `${WEBINAR_USERS} ${API_ERROR}`:
            Alert.fire({
                position: 'top-end',
                icon: 'error',
                title: action.message,
                showConfirmButton: false,
                timer: 1500
                })
            break;
        default:
            break;
    }
}
