import { apiRequest,API_SUCCESS,API_ERROR } from "../../../actions/api"
import {ROOT_URL} from '../../../config/'
import { 
    PLAN_CREATE, 
    PLAN, 
    PLANS_GET, 
    PLANS, 
    setPlans, 
    getPlans, 
    PLAN_STATUS_TOGGLE,
    PLAN_STATUS,
    PLAN_GET,
    PLAN_UPDATE,
    setPlanDetails,
} from "../../../actions/plan"
import Alert from "sweetalert2";

export const planMiddleware = ({dispatch}) => next => (action) => {
    next(action)
    switch(action.type){
        case PLAN_CREATE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/plan`,
                method:'post',
                feature:PLAN
            }))
            dispatch(getPlans())
            break;
        case PLAN_UPDATE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/plan`,
                method:'put',
                feature:PLAN
            }))
            dispatch(getPlans())
            break;
        case PLANS_GET:
            dispatch(apiRequest({
                body: action.payload,
                config:{},
                url:`${ROOT_URL}/plan`,
                method:'get',
                feature:PLANS
            }))
            break;
        case PLAN_GET:
            dispatch(apiRequest({
                body: action.payload,
                config:{},
                url:`${ROOT_URL}/planDetails`,
                method:'get',
                feature:PLAN
            }))
            break;
        case `${PLAN} ${API_SUCCESS}`:
            if(action.message==='New Plan Created' || action.message === 'Plan Updated'){
                Alert.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: action.message,
                    showConfirmButton: false,
                    timer: 1500
                  })  
            }else{
                dispatch(setPlanDetails(action.payload))
            }
               
            break;
        case `${PLAN} ${API_ERROR}`:
            Alert.fire({
                position: 'top-end',
                icon: 'error',
                title: action.message,
                showConfirmButton: false,
                timer: 1500
              })
            break;
        case `${PLANS} ${API_SUCCESS}`:
            dispatch(setPlans(action.payload))
            break;
        case `${PLANS} ${API_ERROR}`:
            Alert.fire({
                position: 'top-end',
                icon: 'error',
                title: action.message,
                showConfirmButton: false,
                timer: 1500
              })
            break;
        case PLAN_STATUS_TOGGLE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/planStatus`,
                method:'put',
                feature:PLAN
            }))
            break;
            case `${PLAN_STATUS} ${API_SUCCESS}`:
                Alert.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: action.message,
                    showConfirmButton: false,
                    timer: 1500
                  })
            break;
        case `${PLAN_STATUS} ${API_ERROR}`:
            Alert.fire({
                position: 'top-end',
                icon: 'error',
                title: action.message,
                showConfirmButton: false,
                timer: 1500
              })
            break;
        
            default: break;
    }
}
