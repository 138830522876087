import { COUPONS_SET, COUPON_SET } from "../../../actions/promoCodes"

const initState = []
export const couponsReducer = (state=initState,action) =>{
    switch(action.type){
        case COUPONS_SET:
            return {...state,coupons:action.payload}
        case COUPON_SET:
            return {...state,couponDetails:action.payload}
        default: return state
    }
}