import { apiRequest,API_SUCCESS,API_ERROR } from "../../../actions/api"
import { LAB_TESTS, LAB_REPORT_URL, LAB_TESTS_GET, LAB_REPORT_URL_UPDATE, setAllLabTests, getAllLabTests, LAB_TESTS_CONFIG_GET, LAB_TESTS_CONFIG, setLabTestsConfig, LAB_TEST_SKU, LAB_TEST_SKU_CREATE, LAB_TEST_SKUS_GET, LAB_TEST_SKUS, setAllLabTestSKUs, LAB_TEST_SKU_UPDATE, LAB_TEST_SKU_DELETE, getAllLabTestSKUs } from "../../../actions/labTests"
import {ROOT_URL} from '../../../config/'
import Alert from "sweetalert2";
import {getUserLabReport } from "../../../actions/user";

export const labTestsMiddleware = ({dispatch}) => next => (action) =>{
    next(action)
    switch(action.type){
        case LAB_TESTS_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/labTests`,
                method:'get',
                feature:LAB_TESTS
            }))
            break;

        case LAB_REPORT_URL_UPDATE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/updateLabReportUrl`,
                method:'put',
                feature:LAB_REPORT_URL
            }))
            break;
        case `${LAB_REPORT_URL} ${API_SUCCESS}`:
            Alert.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Lab Report Uploaded',
                showConfirmButton: false,
                timer: 1500
              })

              if(action.payload.userId)
              {
                  dispatch(getUserLabReport(action.payload.userId))
              }
              if(action.payload.start && action.payload.end)
              {
                dispatch(getAllLabTests({start: action.payload.start, end: action.payload.end}));
              }
            break;
        case `${LAB_REPORT_URL} ${API_ERROR}`:
            Alert.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Lab Report Failed',
                showConfirmButton: false,
                timer: 1500
              })
            break;

        case `${LAB_TESTS} ${API_SUCCESS}`:
                dispatch(setAllLabTests(action.payload))
                break;
        case `${LAB_TESTS} ${API_ERROR}`:
            break;

        case LAB_TESTS_CONFIG_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/labTestConfigs`,
                method:'get',
                feature:LAB_TESTS_CONFIG
            }))
            break;
        case `${LAB_TESTS_CONFIG} ${API_SUCCESS}`:
                dispatch(setLabTestsConfig(action.payload))
                break;
        case `${LAB_TESTS_CONFIG} ${API_ERROR}`:
            break;
        
        case LAB_TEST_SKU_CREATE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/labTestSKU`,
                method:'post',
                feature:LAB_TEST_SKU
            }))
            break;
        
        case LAB_TEST_SKU_UPDATE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/labTestSKU`,
                method:'put',
                feature:LAB_TEST_SKU
            }))
            break;

        case LAB_TEST_SKU_DELETE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/labTestSKU`,
                method:'delete',
                feature:LAB_TEST_SKU
            }))
            break;

        case `${LAB_TEST_SKU} ${API_SUCCESS}`:
            if(action.message === 'Lab Test SKU Deleted')
            {
                Alert.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Lab Test SKU Deleted',
                    showConfirmButton: false,
                    timer: 1500
                  })

                dispatch(getAllLabTestSKUs())
            }
            else{
                Alert.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: action.message,
                    showConfirmButton: false,
                    timer: 1500
                    })
                    // dispatch(getAllLabTests())
            }
            break;

        case `${LAB_TEST_SKU} ${API_ERROR}`:
            Alert.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Lab Test Failed',
                showConfirmButton: false,
                timer: 1500
                })
                break;
        
        case LAB_TEST_SKUS_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/labTestSKUs`,
                method:'get',
                feature:LAB_TEST_SKUS
            }))
            break;
        case `${LAB_TEST_SKUS} ${API_SUCCESS}`:
            dispatch(setAllLabTestSKUs(action.payload))
            break;
        case `${LAB_TEST_SKUS} ${API_ERROR}`:
            Alert.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Could not fetch lab tests SKUs',
                showConfirmButton: false,
                timer: 1500
                })
            break;
                default: break;
    }
}

