import { apiRequest,API_SUCCESS,API_ERROR } from "../../../actions/api"
import { setUnmarkedEventCount, UNMARKED_EVENT, UNMARKED_EVENT_GET_COUNT } from "../../../actions/navbarStats/unmarkedTrials"
import {ROOT_URL} from '../../../config/'

export const navbarStatsMiddleware = ({dispatch}) => next => (action) =>{
    next(action)
    switch(action.type){
        case UNMARKED_EVENT_GET_COUNT:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/unmarkedEventCount`,
                method:'get',
                feature:UNMARKED_EVENT
            }))
            break;
        
        case `${UNMARKED_EVENT} ${API_SUCCESS}`:
                dispatch(setUnmarkedEventCount(action.payload))
                break;
        
        case `${UNMARKED_EVENT} ${API_ERROR}`:
            break;
        default: break;
    }
}

