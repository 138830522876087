import { WEBINARS_SET, WEBINAR_USERS_SET } from "../../actions/webinar";
const initState = []

export const webinarReducer = (state=initState, action) => {
    switch(action.type)
    {
        case WEBINARS_SET:
            return {...state, webinars: action.payload}
        case WEBINAR_USERS_SET:
            return {...state, webinarUsers: action.payload}
        default:
            return state
    }
}