import { PLANS_SET, PLAN_SET } from "../../actions/plan";
const initState = []
export const planReducer = (state=initState,action) =>{
    switch(action.type){
        case PLANS_SET:
            return {...state,plans:action.payload}
        case PLAN_SET:
            return {...state,plan:action.payload}
        default: return state
    }
}

