import { apiRequest,API_SUCCESS,API_ERROR } from "../../../actions/api"
import {ROOT_URL} from '../../../config/'

import Alert from "sweetalert2";
import { CRON, CRONS, CRONS_GET, CRON_START, CRON_STOP, getAllCrons, setAllCrons } from "../../../actions/crons";

export const cronMiddleware = ({dispatch}) => next => (action) =>{
    next(action)
    switch(action.type){
        case CRONS_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/getCrons`,
                method:'get',
                feature:CRONS
            }))
            break;
        
        case CRON_START:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/startCron`,
                method:'put',
                feature:CRON
            }))
            break;

        case CRON_STOP:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/stopCron`,
                method:'put',
                feature:CRON
            }))
            break;
         
        case `${CRONS} ${API_SUCCESS}`:
                dispatch(setAllCrons(action.payload))
                break;
        
        case `${CRONS} ${API_ERROR}`:
            break;

        case `${CRON} ${API_SUCCESS}`:
                Alert.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: action.message,
                    showConfirmButton: false,
                    timer: 1500
                  })
                dispatch(getAllCrons())
                break;
        
        case `${CRON} ${API_ERROR}`:
            Alert.fire({
                position: 'top-end',
                icon: 'error',
                title: action.message,
                showConfirmButton: false,
                timer: 1500
              })
              dispatch(getAllCrons())
            break;
        default: break;
    }
}

