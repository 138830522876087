import { apiRequest,API_SUCCESS,API_ERROR } from "../../../actions/api"
import {ROOT_URL} from '../../../config/'
import { 
    OFFERING,
    OFFERING_GET, 
    OFFERING_TYPE, 
    OFFERING_TYPE_GET, 
    OFFERING_UNIT, 
    OFFERING_UNIT_GET,
    OFFERING_CREATE,
    OFFERING_STATUS_TOGGLE, 
    OFFERING_STATUS,
    setOffering, 
    setofferingType, 
    setOfferingUnit 
} from "../../../actions/offering"

export const offeringMiddleware = ({dispatch}) => next => (action) =>{
    next(action)
    switch(action.type){
        case OFFERING_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/offering`,
                method:'get',
                feature:OFFERING
            }))
            break;
        case `${OFFERING} ${API_SUCCESS}`:
            dispatch(setOffering(action.payload))
            break;
        case `${OFFERING} ${API_ERROR}`:
            break;
        case OFFERING_TYPE_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/offeringType`,
                method:'get',
                feature:OFFERING_TYPE
            }))
            break;
        case `${OFFERING_TYPE} ${API_SUCCESS}`:
            dispatch(setofferingType(action.payload))
            break;
        case `${OFFERING_TYPE} ${API_ERROR}`:
            break;
        case OFFERING_UNIT_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/offeringUnit`,
                method:'get',
                feature:OFFERING_UNIT
            }))
            break;
        case `${OFFERING_UNIT} ${API_SUCCESS}`:
            dispatch(setOfferingUnit(action.payload))
            break;
        case `${OFFERING_UNIT} ${API_ERROR}`:
            break;
        case `${OFFERING_CREATE}`:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/offering`,
                method:'post',
                feature:OFFERING
            }))
            break;
        case `${OFFERING_STATUS_TOGGLE}`:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/offeringStatus`,
                method:'put',
                feature:OFFERING_STATUS
            }))
            break;
        default: break;
    }
}

