import { USER_PRIMARY_CONCERN_SET } from "../../actions/userPrimaryConcern"

const initState = []
export const userPrimaryConcernReducer = (state=initState,action) =>{
    switch(action.type){
        case USER_PRIMARY_CONCERN_SET:
            return {...state,userPrimaryConcerns:action.payload}
        default: return state
    }
}
