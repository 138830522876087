import Multiselect from 'multiselect-react-dropdown';

const availableOptions = {
    labtest: [
        {
            name: "Thyroid Profile Test",
            file: "Labtest-ThyroidProfileTest.pdf"
        },
         {
            name: "Thyroid Basic Panel",
            file: "Labtest-ThyroidBasicPanel.pdf"
        },
         {
            name: "Thyroid Advanced Test",
            file: "Labtest-ThyroidAdvancedTest.pdf"
        },
         {
            name: "PCOS Basic Panel",
            file: "Labtest-PCOSBasicPanel.pdf"
        },
         {
            name: "PCOS Advanced Panel",
            file: "Labtest-PCOSAdvancedPanel.pdf"
        },
         {
            name: "Comprehensive Fertility Package",
            file: "Labtest-ComprehensiveFertilityPackage.pdf"
        },
    ],
    leaflets: [
        {
            name: "Acne",
            file: "Leaflets-Acne.pdf"
        },
        {
            name: "Alcohol consumption",
            file: "Leaflets-Alcoholconsumption.pdf"
        },
        {
            name: "Bloating",
            file: "Leaflets-Bloating.pdf"
        },
        {
            name: "Constipation",
            file: "Leaflets-Constipation.pdf"
        },
        {
            name: "Disordered eating",
            file: "Leaflets-Disorderedeating.pdf"
        },
        {
            name: "Eating in a restaurant",
            file: "Leaflets-Eatinginarestaurant.pdf",
        },
        {
            name: "Fasting",
            file: "Leaflets-Fasting.pdf"
        },
        {
            name: "Fussy Eaters",
            file: "Leaflets-FussyEaters.pdf"
        },
        {
            name: "Grocery shopping",
            file: "Leaflets-Groceryshopping.pdf"
        },
        {
            name: "Hair loss",
            file: "Leaflets-Hairloss.pdf"
        },
        {
            name: "Heavy Periods",
            file: "Leaflets-HeavyPeriods.pdf"
        },
        {
            name: "Homemaker",
            file: "Leaflets-Homemaker.pdf"
        },
        {
            name: "Hostelite",
            file: "Leaflets-Hostelite.pdf"
        },
        {
            name: "Hydration Tips for Summers",
            file: "Leaflets-HydrationTipsforSummers.pdf"
        },
        {
            name: "Inflammation",
            file: "Leaflets-Inflammation.pdf"
        },
        {
            name: "Insulin Resistance",
            file: "Leaflets-InsulinResistance.pdf"
        },
        {
            name: "Migraine",
            file: "Leaflets-Migraine.pdf"
        },
        {
            name: "Night shifters",
            file: "Leaflets-Nightshifters.pdf"
        },
        {
            name: "Painful Periods",
            file: "Leaflets-PainfulPeriods.pdf"
        },
        {
            name: "Work from home",
            file: "Leaflets-Workfromhome.pdf"
        },
        {
            name: "Working women",
            file: "Leaflets-Workingwomen.pdf"
        },
        {
            name: "High Protein Breakfast Recipes (non-veg)",
            file: "Leaflets-HighProteinBreakfastRecipes(non-veg).pdf"
        },
        {
            name: "High Protein Breakfast Recipes (veg)",
            file: "Leaflets-HighProteinBreakfastRecipes(veg).pdf"
        },
        {
            name: "High Protein Breakfast Recipes (egg)",
            file: "Leaflets-HighProteinBreakfastRecipes(egg).pdf"
        },
        {
            name: "Easy to make recipes (Veg)",
            file: "Leaflets-Easytomakerecipes(Veg).pdf"
        },
        {
            name: "Easy to make recipes (NV)",
            file: "Leaflets-Easytomakerecipes(NV).pdf"
        },
        {
            name: "Easy to make recipes (Egg)",
            file: "Leaflets-Easytomakerecipes(Egg).pdf"
        },
        {
            name: "Mid meal snacking- veg",
            file: "Leaflets-Midmealsnacking-veg.pdf"
        },
        {
            name: "Mid meal snacking- egg",
            file: "Leaflets-Midmealsnacking-egg.pdf"
        },
        {
            name: "Mid meal snacking- non veg",
            file: "Leaflets-Midmealsnacking-nonveg.pdf"
        },
        {
            name: "Low calorie dinner recipes-Veg",
            file: "Leaflets-Lowcaloriedinnerrecipes-Veg.pdf"
        },
        {
            name: "Low calorie dinner recipes- Egg",
            file: "Leaflets-Lowcaloriedinnerrecipes- Egg.pdf"
        },
        {
            name: "Low calorie dinner recipes- Non-veg",
            file: "Leaflets-Lowcaloriedinnerrecipes-Non-veg.pdf"
        },
        {
            name: "Homemade protein powders and bars",
            file: "Leaflets-Homemadeproteinpowdersandbars.pdf"
        },
        {
            name: "Post Workout Recipes",
            file: "Leaflets-PostWorkoutRecipes.pdf"
        },
        {
            name: "Pre Workout Recipes",
            file: "Leaflets-PreWorkoutRecipes.pdf"
        },
        {
            name: "Millet based recipes",
            file: "Leaflets-Milletbasedrecipes.pdf"
        },
    ],
    supplement: [
        {
            name: "Magnesium",
            file: "Supplemen-Magnesium.pdf"
        },
        {
            name: "Omega 3",
            file: "Supplemen-Omega3.pdf"
        },
        {
            name: "Probiotic",
            file: "Supplemen-Probiotic.pdf"
        },
        {
            name: "Vitamin B12",
            file: "Supplemen-VitaminB12.pdf"
        },
        {
            name: "Vitamin C",
            file: "Supplemen-VitaminC.pdf"
        },
        {
            name: "Vitamin D",
            file: "Supplemen-VitaminD.pdf"
        },
    ]
}

const MealBulderMultiSelect = (props) => {
    const {options, setData, parentRef} = props;

    const add = (a) => {
        setData(a.map(i => i.file))
    }

    const remove = (a) => {
        setData(a.map(i => i.file))
    }

    const style = {
            chips: { 
                background: '#F5735A'
            },
        }
    
    return(
        <div style={{"cursor": "pointer"}}>
            <Multiselect
                options={availableOptions[options]}
                selectedValues={[]}
                onSelect={add}
                onRemove={remove}
                displayValue='name'
                closeIcon='cancel'
                style={style}
                singleSelect={ options === "supplement" ? false : true}
                ref={parentRef}
            />
        </div>
        
    )
    
}

export default MealBulderMultiSelect;