export const MEALS = `[MEALS]`
export const MEAL_TEMPLATE = `[MEAL_TEMPLATE]`

export const MEALS_CREATE  =  `${MEALS} CREATE`
export const MEALS_GET = `${MEALS} GET`
export const MEALS_SET = `${MEALS} SET`

export const MEAL_TEMPLATE_UPDATE = `${MEAL_TEMPLATE} UPDATE`
export const MEAL_TEMPLATE_PRESIGNED_URL = `${MEAL_TEMPLATE} PRESIGNED_URL]`;
export const MEAL_TEMPLATE_PRESIGNED_URL_GET = `${MEAL_TEMPLATE_PRESIGNED_URL} GET`;
export const MEAL_TEMPLATE_PRESIGNED_URL_SET = `${MEAL_TEMPLATE_PRESIGNED_URL} SET`;

export const MEALS_CREATE_SUCESS  =  `${MEALS} API_SUCCESS`
export const MEALS_CREATE_FAILURE  =  `${MEALS} API_ERROR`

export const getMealTemplatePresignedUrl = (data) => ({
    type: MEAL_TEMPLATE_PRESIGNED_URL_GET,
    payload: data
})

export const setMealTemplatePresignedUrl = (data) => ({
    type: MEAL_TEMPLATE_PRESIGNED_URL_SET,
    payload: data
})

export const createMeal = (data) => ({
    type: MEALS_CREATE,
    payload:data
})

export const getMeals = (data) =>({
    type: MEALS_GET,
    payload:data
})

export const setMeals = (data) =>({
    type: MEALS_SET,
    payload:data
})

export const updateMealTemplateUrl = (data) => ({
    type: MEAL_TEMPLATE_UPDATE,
    payload: data
})
