/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useEffect} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import Navbar from '../navbar'
import { Grid, _ } from "gridjs-react";
import { getAllCrons, startCron, stopCron } from '../../actions/crons';
import { cronSelector } from '../../selector/crons';
import moment from 'moment';

const Table = ({crons}) => {
    const dispatch = useDispatch()

    const handleStart = (cronId) => {
        dispatch(startCron({cronId}))
    }
    const handleStop = (cronId) => {
        dispatch(stopCron({cronId}))
    }

    const data = crons?crons.map((cron)=>[
        cron.cronId,
        cron.cronName,
        cron.runsEvery,
        cron.startedAt===null?'--:--':moment(cron.startedAt).format('YYYY-MM-DD HH:mm'),
        cron.endedAt===null?'--:--':moment(cron.endedAt).format('YYYY-MM-DD HH:mm'),
        cron.status===0?_(
            <>
                <button
                    className="btn btn-success btn-rounded"
                    onClick={()=>{handleStart(cron.cronId)}}
                    style={{marginLeft:'-30px'}}
                >
                    Start
                </button>  
            </>
        ):_(
            <>
            <button
                className="btn btn-danger btn-rounded"
                onClick={()=>{handleStop(cron.cronId)}}
                style={{marginLeft:'-30px'}}
            >
                Stop
            </button>  
        </>
        )
    ]):[]
    return (
    <div className="col-12">
        <div className="card">
            <div className="card-body">
                <div className="tab-content">
                    <div
                        className="tab-pane show active"
                        id="basic-datatable-preview"
                    >
                        <div id="basic-datatable-wrapper">
                            <Grid
                                data={data}
                                columns={[
                                    "Cron ID",
                                    "Cron Name",
                                    "Runs Every",
                                    "Started At",
                                    "Ended At",
                                    "Action"
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}
const CronJobs = ()  => {
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(getAllCrons())
    },[dispatch])
    const crons = useSelector(state=>cronSelector(state))
        
    return (
        <div>
            <Navbar pageTitle={"Cron Jobs"}>
                <Table
                    crons={crons}
                />
            </Navbar>
        </div>
    )
}

export default CronJobs

