import React from 'react'

const MetricCard = ({
    cardTitle,
    cardValue,
    cardIcon,
    cardValue2    
}) => {
  return (
    <div className="card widget-flat">
        <div className="card-body">
            <div className="float-end">
                <i className={`${cardIcon} font-20`}
                    style={{color: 'rgb(152, 166, 173'}}
                >

                </i>
                

            </div>
            <h5 
                className="fw-normal mt-1" 
                style={{color:'#98A6Ad'}}
            >
                    {cardTitle}
            </h5>
            <h3 
                className="mt-3 mb-3"
                style={{color:'#6C757D'}}
                >
                    {cardValue}
                </h3>
            <p className="mb-0">
                <span 
                    className="text-nowrap"
                    style={{color:'#98A6Ad'}}
                    >
                    {`MTD ${cardTitle} : `}<span style={{color:'#6C757D'}}>{cardValue2}</span>
                </span>
            </p>
        </div> 
    </div> 
  )
}

export default MetricCard