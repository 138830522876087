import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Navbar from '../../navbar';
import axios from 'axios';
import Alert from "sweetalert2";
import { doctorProfilePresignedUrlSelector } from '../../../selector/presignedUrlSelector';
import { getDoctorProfilePresignedUrl, getDoctorPatients, createDoctor, updateDoctor, getDoctor, connectDoctorWithExpert, assignPatientToDoctor } from '../../../actions/doctorFacingTech';
import { useHistory } from 'react-router-dom';
import { doctorProfileDataSelector, doctorPatientsSelector } from '../../../selector/doctorFacingTech';
import Toggle from 'react-toggle'
import Swal from 'sweetalert2';
import { getAllExpert } from "../../../actions/expert";
import { allExpertsSelector } from "../../../selector/expert";
import SelectSearch, { fuzzySearch } from 'react-select-search';
import { getAllUsers } from '../../../actions/users';
import { usersSelector } from '../../../selector/users';
import PatientTable from './patientTable';
import Multiselect from 'multiselect-react-dropdown';
import { getCoupons } from '../../../actions/promoCodes';
import { couponsSelector } from '../../../selector/promoCodes/coupons';
import { getAdminLogin } from '../../../actions/users';
import { adminLoginSelector } from '../../../selector/users';

const EducationalDetails = ({ degreeDetail, degreeDetails, setDegreeDetails, i }) => {


    const degreeQualificationsList = [
        "MBBS",
        "MD",
        "MS",
        "DNB",
        "DM",
        "M.Ch",
        "BDS",
        "BAMS",
        "BUMS",
        "BHMS",
        "BYNS",
        "DGO",
    ];

    const yearList = [
        "2022",
        "2021",
        "2020",
        "2019",
        "2018",
        "2017",
        "2016",
        "2015",
        "2014",
        "2013",
        "2012",
        "2011",
        "2010",
        "2009",
        "2008",
        "2007",
        "2006",
        "2005",
        "2004",
        "2003",
        "2002",
        "2001",
        "2000",
        "1999",
        "1998",
        "1997",
        "1996",
        "1995",
        "1994",
        "1993",
        "1992",
        "1991",
        "1990",
    ]

    return (
        <div>
            <div className="my-2 row">

                <div className="col-4 mb-3">
                    <label
                        htmlFor="event-name"
                        className="col-4 col-form-label"
                    >
                        Degree Qualification
                    </label>
                    <div className="col-8">
                        <select
                            className="form-select disabledbutton"
                            id="event-name"
                            value={degreeDetail.degreeOfQualification}
                            required={true}
                            onChange={e => {
                                let tempDegreeDetails = [...degreeDetails];
                                tempDegreeDetails[i].degreeOfQualification = e.target.value;
                                setDegreeDetails(tempDegreeDetails);
                            }}
                        >
                            <option value="" disabled selected>Select Degree Qualification</option>
                            {
                                degreeQualificationsList.map((item) =>
                                    <option value={item}>{item}</option>
                                )
                            }
                        </select>
                    </div>
                </div>
                <div className="col-4 mb-3">
                    <label
                        htmlFor="event-name"
                        className="col-4 col-form-label"
                    >
                        Year of Qualification
                    </label>
                    <div className="col-8">
                        <select
                            className="form-select disabledbutton"
                            id="event-name"
                            value={degreeDetail.yearOfQualification}
                            required={true}
                            onChange={(e) => {
                                let temp = [...degreeDetails];
                                temp[i].yearOfQualification = e.target.value;
                                setDegreeDetails(temp);
                            }}
                        >
                            <option value="" disabled selected>Select Year of Qualification</option>
                            {
                                yearList.map((item) =>
                                    <option value={item}>{item}</option>
                                )
                            }
                        </select>
                    </div>
                </div>
                <div className="col-4 mb-3">
                    <label
                        htmlFor="event-name"
                        className="col-4 col-form-label"
                    >
                        Specialisation
                    </label>
                    <div className='row'>
                        <div className="col-8">
                            <div>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="event-name"
                                    placeholder="Specialisation"
                                    autoComplete="off"
                                    required
                                    value={degreeDetail.specialisation}
                                    onChange={(e) => {
                                        let temp = [...degreeDetails];
                                        temp[i].specialisation = e.target.value;
                                        setDegreeDetails(temp);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-4 py-1">
                            {
                                i ? <i
                                    style={{ fontStyle: 'normal', cursor: 'pointer' }}
                                    onClick={() => {
                                        let temp = [...degreeDetails];
                                        temp.splice(i, 1);
                                        setDegreeDetails(temp);
                                    }}
                                >&#10060;</i> :
                                    <></>
                            }
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

const CreateDoctor = (props) => {

    const doctorId = props.match.params.doctorId;

    const dispatch = useDispatch()
    const history = useHistory()

    const [name, setName] = useState('');
    const [gender, setGender] = useState('');
    const [rmpNumber, setRmpNumber] = useState('')
    const [callingNumber, setCallingNumber] = useState('')
    const [whatsappNumber, setWhatsappNumber] = useState('')
    const [email, setEmail] = useState('')
    const [specialisation, setSpecialisation] = useState('');
    const [clinicName, setClinicName] = useState('')
    const [clinicAddress, setClinicAddress] = useState('');
    const [clinicPincode, setClinicPincode] = useState('')
    const [verified, setVerified] = useState(0);
    const [onboarded, setOnboarded] = useState(0);
    const [signatureStyle, setSignatureStyle] = useState('');
    const [isAffiliated, setIsAffiliated] = useState(true)
    const [isConsultant, setIsConsultant] = useState(true)
    const [degreeDetails, setDegreeDetails] = useState([{ degreeOfQualification: '', yearOfQualification: '', specialisation: '' }]);
    const [expert, setExpert] = useState("");
    const [selectedUsers, setSelectedUsers] = useState([])
    const [selectedOption, setSelectedOption] = useState([])
    const [applicableCoupons, setApplicableCoupons] = useState([])
    const [adminUser, setAdminUser] = useState("")

    const [profileImage, setProfileImage] = useState('')
    const [uploadUrl, setUploadUrl] = useState('');
    const [src, setSrc] = useState('/assets/images/users/avatar.png')

    useEffect(() => {
        dispatch(getAllUsers())
        dispatch(getCoupons())
        dispatch(getAdminLogin())
    }, [dispatch])

    const allUsers = useSelector((state) => usersSelector(state))
    const options = allUsers ? allUsers.map((user) => ({ name: `${user.firstName} ${user.lastName} (cn: ${user.callingNumber}, wn: ${user.whatsappNumber})`, value: user.userId })) : []
    const coupons = useSelector((state) => couponsSelector(state))
    const couponOptions = coupons.length ? coupons.map((coupon) => ({ name: coupon.offerName + " - " + coupon.couponId, value: coupon.couponId })) : []
    const adminLogin = useSelector((state) => adminLoginSelector(state))

    const specialisationsList = [
        "Obstetrics and Gynecology",
        "Dermatology",
        "Internal Medicine",
        "Urology",
        "Endocrinology",
        "Orthopedics",
        "Pediatrics",
        "Radiology",
        "General Surgery",
        "Ophthalmology",
        "ENT",
        "Pathology",
        "Venerology",
    ];

    const gendersList = ["Male", "Female", "Transgender", "Non-binary"];

    useEffect(() => {
        if (doctorId) {
            dispatch(getDoctor(doctorId))
            dispatch(getDoctorPatients(doctorId))
        }
    }, [dispatch, doctorId])

    const doctorDetails = useSelector(state => doctorProfileDataSelector(state));
    const doctorPatients = useSelector(state => doctorPatientsSelector(state));

    useState(() => {
        dispatch(getAllExpert())
    }, [dispatch])

    const allExperts = useSelector((state) => allExpertsSelector(state))

    useEffect(() => {
        if (doctorId && doctorDetails) {
            setName(doctorDetails.name)
            setGender(doctorDetails.gender)
            setRmpNumber(doctorDetails.rmp_number)
            setCallingNumber(doctorDetails.calling_number)
            setWhatsappNumber(doctorDetails.whatsapp_number)
            setEmail(doctorDetails.email)
            setSpecialisation(doctorDetails.specialisation)
            setClinicName(doctorDetails.clinic_name)
            setClinicAddress(doctorDetails.clinic_address)
            setClinicPincode(doctorDetails.clinic_pincode)
            setVerified(doctorDetails.verified)
            setOnboarded(doctorDetails.onboarded)
            setSignatureStyle(doctorDetails.signature_style)
            setUploadUrl(doctorDetails.profile_picture)
            setSrc(doctorDetails.profile_picture)
            setIsAffiliated(doctorDetails.is_affiliated === 1 ? true : false)
            setIsConsultant(doctorDetails.is_consultant === 1 ? true : false)
            setVerified(doctorDetails.verified)
            setOnboarded(doctorDetails.onboarded)
            setDegreeDetails(doctorDetails.degree_details ? doctorDetails.degree_details : [{ degreeOfQualification: '', yearOfQualification: '', specialisation: '' }])

            const expertName = allExperts.filter((item) => item.userId === doctorDetails.expertId)
            if (expertName.length > 0) {
                setExpert(expertName[0].name + "-" + doctorDetails.expertId)
            }
            else {
                setExpert("")
            }

            if (doctorPatients.length > 0) {
                setSelectedUsers(doctorPatients.map((item) => ({
                    firstName: item.name,
                    lastName: "",
                    callingNumber: item.calling_number,
                    whatsappNumber: item.whatsapp_number,
                    age: item.age,
                    height: item.height,
                    weight: item.weight,
                })))
            }
            else {
                setSelectedUsers([])
            }

            if (doctorDetails.appliedCoupons) {
                const appliedCouponsMap = new Map()
                doctorDetails.appliedCoupons.forEach((item) => {
                    appliedCouponsMap.set(item.couponId, item)
                })

                setApplicableCoupons(couponOptions.length ? couponOptions.filter((coupon) => appliedCouponsMap.has(coupon.value)) : [])
            }

            if (adminLogin) {
                const userName = adminLogin.filter((item) => item.userId === doctorDetails.userId)[0]

                if (userName) {
                    setAdminUser(userName.name + "-" + userName.userId)
                }
                else {
                    setAdminUser("")
                }
            }
        }
    }, [doctorId, doctorDetails, doctorPatients, adminLogin])


    const onFileChange = (event) => {
        setProfileImage(event.target.files[0])
        dispatch(getDoctorProfilePresignedUrl(event.target.files[0].type))
    }
    const uploadConfigs = useSelector(state => doctorProfilePresignedUrlSelector(state))

    const connectWithExpert = (e) => {
        setExpert(e.target.value)
        dispatch(connectDoctorWithExpert({ doctorId: doctorDetails.doctor_id, expertId: e.target.value.split('-')[1] }))
    }


    const uploadFile = async () => {
        await axios.put(uploadConfigs.url, profileImage, {
            headers: {
                'Content-Type': profileImage.type
            }
        }).then((val) => {
            const url = `https://image-store-admin.s3.amazonaws.com/${uploadConfigs.key}`
            setUploadUrl(url);
            Alert.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Image Uploaded',
                showConfirmButton: false,
                timer: 1500
            })
        }).catch((err) => {
            Alert.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Image Upload Failed',
                showConfirmButton: false,
                timer: 1500
            })
        })
    }

    const validateForm = () => {
        if (!name || name.length < 3) {
            Swal.fire("Please enter your name");
            return false;
        }
        if (!callingNumber || callingNumber.length !== 12) {
            Swal.fire("Please enter your 12 digit phone number");
            return false;
        }
        if (!gender) {
            Swal.fire("Please select your gender");
            return false;
        }
        if (!whatsappNumber || whatsappNumber.length !== 12) {
            Swal.fire("Please enter your WhatsApp number");
            return false;
        }
        const validateEmail = (email) => {
            return String(email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        };
        if (!email || !validateEmail(email)) {
            Swal.fire("Please enter a valid email address");
            return false;
        }
        if (!specialisation) {
            Swal.fire("Please enter your specialisation");
            return false;
        }

        if (!clinicName) {
            Swal.fire("Please enter your clinic name");
            return false;
        }
        if (!clinicAddress) {
            Swal.fire("Please enter your clinic address");
            return false;
        }

        return true;
    };

    const handleSubmit = () => {
        if (validateForm()) {
            if (doctorId) {
                dispatch(updateDoctor({
                    doctorId,
                    name,
                    gender,
                    rmpNumber,
                    callingNumber,
                    whatsappNumber,
                    email,
                    specialisation,
                    clinicName,
                    clinicAddress,
                    clinicPincode,
                    signatureStyle,
                    profilePicture: uploadUrl,
                    isAffiliated,
                    isConsultant,
                    verified,
                    onboarded,
                    degreeDetails: JSON.stringify(degreeDetails),
                    applicableCoupons,
                    userId: adminUser.split('-')[1],
                }))
            }
            else {
                dispatch(createDoctor({
                    name,
                    gender,
                    rmpNumber,
                    callingNumber,
                    whatsappNumber,
                    email,
                    specialisation,
                    clinicName,
                    clinicAddress,
                    clinicPincode,
                    verified: 1,
                    onboarded: 0,
                    signatureStyle,
                    profilePicture: uploadUrl,
                    isAffiliated,
                    isConsultant,
                    degreeDetails: JSON.stringify(degreeDetails),
                    applicableCoupons,
                    userId: adminUser.split('-')[1],
                }))
            }

            history.push('/doctors')
        }

    }

    const handleAddParticipant = () => {
        const user = allUsers.find(element => element.userId === selectedOption)

        const existingUserMap = new Map()
        selectedUsers.forEach((item) => {
            existingUserMap.set(item.callingNumber + item.whatsappNumber, item)
        })

        if (!existingUserMap.has(user.callingNumber ? user.callingNumber : "" + user.whatsappNumber ? user.whatsappNumber : "")) {
            setSelectedUsers([...selectedUsers, user])
            dispatch(assignPatientToDoctor({
                doctorId: doctorId,
                callingNumber: user.callingNumber,
                whatsappNumber: user.whatsappNumber,
                name: user.firstName + " " + user.lastName,
                age: user.age,
                gender: "",
                height: user.height,
                weight: user.weight,
            }))
        }
        else {
            Alert.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Patient already added',
                showConfirmButton: false,
                timer: 1500
            })
        }
    }

    return (
        <Navbar pageTitle={doctorId ? "Update Doctor" : "Create Doctor"}>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="row mb-3">
                                        <label
                                            htmlFor="event-name"
                                            className="col-4 col-form-label"
                                        >
                                            Name
                                        </label>
                                        <div className="col-8">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="event-name"
                                                placeholder="Doctor Name"
                                                autoComplete="off"
                                                value={name}
                                                required
                                                onChange={(e) => {
                                                    setName(e.target.value)
                                                }}
                                            />
                                            {/* {validatePlanName === false ?(
                                                    <div className="text-danger">
                                                        Please give plan a name.
                                                    </div>
                                                ):null} */}
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label
                                            htmlFor="event-name"
                                            className="col-4 col-form-label"
                                        >
                                            Gender
                                        </label>
                                        <div className="col-8">
                                            <select
                                                className="form-select disabledbutton"
                                                id="event-name"
                                                value={gender}
                                                required={true}
                                                onChange={e => setGender(e.target.value)}
                                            >
                                                <option value="" disabled selected>Select Gender</option>
                                                {
                                                    gendersList.map((item) =>
                                                        <option value={item}>{item}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label
                                            htmlFor="event-name"
                                            className="col-4 col-form-label"
                                        >
                                            RMP Number
                                        </label>
                                        <div className="col-8">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="event-name"
                                                placeholder="RMP Number"
                                                autoComplete="off"
                                                value={rmpNumber}
                                                onChange={(e) => {
                                                    setRmpNumber(e.target.value)
                                                }}
                                            />
                                            {/* {validatePlanPrice === false ?(
                                                    <div className="text-danger">
                                                        Please set the Price of the plan
                                                    </div>
                                                ):null} */}
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label
                                            htmlFor="event-name"
                                            className="col-4 col-form-label"
                                        >
                                            Calling Number
                                        </label>
                                        <div className="col-8">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="event-name"
                                                placeholder="Calling Number"
                                                autoComplete="off"
                                                required={true}
                                                value={callingNumber}
                                                onChange={(e) => {
                                                    setCallingNumber(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label
                                            htmlFor="event-name"
                                            className="col-4 col-form-label"
                                        >
                                            Whatsapp Number
                                        </label>
                                        <div className="col-8">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="event-name"
                                                placeholder="Whatsapp Number"
                                                autoComplete="off"
                                                required={true}
                                                value={whatsappNumber}
                                                onChange={(e) => {
                                                    setWhatsappNumber(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label
                                            htmlFor="event-name"
                                            className="col-4 col-form-label"
                                        >
                                            Email
                                        </label>
                                        <div className="col-8">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="event-name"
                                                placeholder="Email"
                                                autoComplete="off"
                                                required={true}
                                                value={email}
                                                onChange={(e) => {
                                                    setEmail(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label
                                            htmlFor="event-name"
                                            className="col-3 col-form-label"
                                        >
                                            Affiliated
                                        </label>
                                        <div className="col-3">
                                            <Toggle
                                                checked={isAffiliated}
                                                onChange={() => setIsAffiliated(!isAffiliated)}
                                            />
                                        </div>
                                        <label
                                            htmlFor="event-name"
                                            className="col-3 col-form-label"
                                        >
                                            Consultant
                                        </label>
                                        <div className="col-3">
                                            <Toggle
                                                checked={isConsultant}
                                                onChange={() => setIsConsultant(!isConsultant)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="row mb-3">
                                        <label
                                            htmlFor="event-name"
                                            className="col-4 col-form-label"
                                        >
                                            Specialisation
                                        </label>
                                        <div className="col-8">
                                            <select
                                                className="form-select disabledbutton"
                                                id="event-name"
                                                value={specialisation}
                                                required={true}
                                                onChange={e => setSpecialisation(e.target.value)}
                                            >
                                                <option value="" disabled selected>Select Specialisation</option>
                                                {
                                                    specialisationsList.map((item) =>
                                                        <option value={item}>{item}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label
                                            htmlFor="event-name"
                                            className="col-4 col-form-label"
                                        >
                                            Clinic Name
                                        </label>
                                        <div className="col-8">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="event-name"
                                                placeholder="Clinic Name"
                                                autoComplete="off"
                                                required={true}
                                                value={clinicName}
                                                onChange={(e) => {
                                                    setClinicName(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label
                                            htmlFor="event-name"
                                            className="col-4 col-form-label"
                                        >
                                            Clinic Address
                                        </label>
                                        <div className="col-8">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="event-name"
                                                placeholder="Clinic Address"
                                                autoComplete="off"
                                                required={true}
                                                value={clinicAddress}
                                                onChange={(e) => {
                                                    setClinicAddress(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label
                                            htmlFor="event-name"
                                            className="col-4 col-form-label"
                                        >
                                            Clinic Pin Code
                                        </label>
                                        <div className="col-8">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="event-name"
                                                placeholder="Pin Code"
                                                autoComplete="off"
                                                required
                                                value={clinicPincode}
                                                onChange={(e) => {
                                                    setClinicPincode(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label
                                            htmlFor="event-name"
                                            className="col-4 col-form-label"
                                        >
                                            Signature Style
                                        </label>
                                        <div className="col-8">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="event-name"
                                                placeholder="Signature Style"
                                                autoComplete="off"
                                                required
                                                value={signatureStyle}
                                                onChange={(e) => {
                                                    setSignatureStyle(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label
                                            htmlFor="event-name"
                                            className="col-4 col-form-label"
                                        >
                                            Profile Image
                                        </label>

                                        <div className="col-4">

                                            {profileImage === '' ?
                                                <>
                                                    <h5>Choose a profile photo</h5>
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={(event) => { onFileChange(event) }}
                                                        style={{ display: 'block' }}
                                                    />
                                                </>

                                                : <button
                                                    className="btn btn-success mt-3"
                                                    style={{ marginLeft: '50px' }}
                                                    onClick={() => uploadFile()}
                                                >
                                                    Upload
                                                </button>
                                            }
                                        </div>
                                        <div className='ml-2 col-4'>
                                            <img
                                                src={profileImage ? URL.createObjectURL(profileImage) : src}
                                                alt="profile"
                                                style={{ height: '70px', width: '70px' }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* Connect with expert */}
                                <h4 className='my-4'>Connect with expert</h4>
                                <div className="row mb-3">
                                    <label
                                        htmlFor="event-name"
                                        className="col-2 col-form-label"
                                    >
                                        Select Expert
                                    </label>
                                    <div className="col-4">
                                        <select
                                            className="form-select disabledbutton"
                                            id="event-name"
                                            value={expert}
                                            required={true}
                                            onChange={e => connectWithExpert(e)}
                                        >
                                            <option value="" disabled selected>Select Expert</option>
                                            {
                                                allExperts.length !== 0 ? allExperts.map((item, idx) =>
                                                    <option value={item.name + "-" + item.userId}>{item.name + "-" + item.userId}</option>
                                                ) : <></>
                                            }
                                        </select>
                                    </div>
                                </div>

                                {/* Assign admin user */}
                                <h4 className='my-4'>Assign Admin User</h4>
                                <div className="row mb-3">
                                    <label
                                        htmlFor="event-name"
                                        className="col-2 col-form-label"
                                    >
                                        Select Admin User
                                    </label>
                                    <div className="col-4">
                                        <select
                                            className="form-select disabledbutton"
                                            id="event-name"
                                            value={adminUser}
                                            required={true}
                                            onChange={e => setAdminUser(e.target.value)}
                                        >
                                            <option value="" disabled selected>Select Admin User</option>
                                            {
                                                adminLogin.length !== 0 ? adminLogin.map((item, idx) =>
                                                    <option value={item.name + "-" + item.userId}>{item.name + "-" + item.userId}</option>
                                                ) : <></>
                                            }
                                        </select>
                                    </div>
                                </div>

                                {/* Educational Details */}
                                <h4 className='my-4'>Educational Details</h4>
                                {
                                    degreeDetails.map((item, index) =>
                                        <EducationalDetails degreeDetail={item} degreeDetails={degreeDetails} setDegreeDetails={setDegreeDetails} i={index} />
                                    )
                                }
                                <i
                                    className="uil-plus text-primary font-20"
                                    style={{ fontStyle: 'normal', cursor: 'pointer' }}
                                    onClick={() => {
                                        setDegreeDetails([...degreeDetails, { degreeOfQualification: '', yearOfQualification: '', specialisation: '' }])
                                    }}
                                >
                                    Add Education
                                </i>


                                {/* Assign Coupon */}
                                <h4 className='my-4'>Add Coupons</h4>
                                <div className="row mb-3">
                                    <label
                                        htmlFor="event-name"
                                        className="col-2 col-form-label"
                                    >
                                        Select Coupons
                                    </label>
                                    <div className="col-4">
                                        <Multiselect
                                            options={couponOptions}
                                            selectedValues={applicableCoupons}
                                            onSelect={(selectedCoupons) => {
                                                setApplicableCoupons(selectedCoupons)
                                            }}
                                            onRemove={(selectedList) => { setApplicableCoupons(selectedList) }}
                                            displayValue="name"
                                            closeIcon='cancel'
                                            style={{
                                                chips: {
                                                    background: '#F5735A'
                                                },
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="justify-content-center d-flex">
                                        <div>&nbsp;</div>
                                        <button
                                            type="submit"
                                            className="btn btn-success"
                                            onClick={() => handleSubmit()}
                                        >
                                            {doctorId ? "Update Doctor" : "Create Doctor"}
                                        </button>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                doctorId ?
                    <>
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-9">
                                                <div className="row">
                                                    <label
                                                        htmlFor="zoom-link"
                                                        className="col-2 col-form-label"
                                                    >
                                                        Add Patient
                                                    </label>
                                                    <div className="col-9">
                                                        <SelectSearch
                                                            options={options}
                                                            search
                                                            filterOptions={fuzzySearch}
                                                            emptyMessage="Not Found"
                                                            placeholder="Select Patient"
                                                            onChange={(e) => { setSelectedOption(e) }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <button
                                                    type="submit"
                                                    className="btn btn-info w-100"
                                                    onClick={handleAddParticipant}
                                                >
                                                    Add Patient
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <PatientTable
                            users={selectedUsers}
                        />
                    </> : <></>
            }

        </Navbar>
    )
}

export default CreateDoctor;