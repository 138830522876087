export const USER_INSIGHTS = `[USER_INSIGHTS]`
export const EVENT_INSIGHTS = `[EVENT_INSIGHTS]`
export const PAYMENT_INSIGHTS = `[PAYMENT_INSIGHTS]`
export const ALL_BOOKINGS = `[ALL_BOOKINGS]`
export const MY_BOOKINGS = `[MY_BOOKINGS]`

export const USER_INSIGHTS_GET = `${USER_INSIGHTS} GET`
export const EVENT_INSIGHTS_GET = `${EVENT_INSIGHTS} GET`
export const USER_INSIGHTS_SET = `${USER_INSIGHTS} SET`
export const EVENT_INSIGHTS_SET = `${EVENT_INSIGHTS} SET`
export const ALL_BOOKINGS_GET = `${ALL_BOOKINGS} GET`
export const ALL_BOOKINGS_SET = `${ALL_BOOKINGS} SET`
export const MY_BOOKINGS_GET = `${MY_BOOKINGS} GET `
export const MY_BOOKINGS_SET = `${MY_BOOKINGS} SET `
export const PAYMENT_INSIGHTS_GET = `${PAYMENT_INSIGHTS} GET`
export const PAYMENT_INSIGHTS_SET = `${PAYMENT_INSIGHTS} SET`


export const getUserInsights = (data) => ({
    type: USER_INSIGHTS_GET,
    payload:data
})

export const setUserInsights = (data) => ({
    type:USER_INSIGHTS_SET,
    payload:data
})

export const getEventInsights = (data) => ({
    type:EVENT_INSIGHTS_GET,
    payload:data
})

export const setEventInsights = (data) =>({
    type: EVENT_INSIGHTS_SET,
    payload:data
})

export const getPaymentInsights = (data) => ({
    type:PAYMENT_INSIGHTS_GET,
    payload:data
})

export const setPaymentInsights = (data) =>({
    type: PAYMENT_INSIGHTS_SET,
    payload:data
})

export const getAllBookings = (data) => ({
    type:ALL_BOOKINGS_GET,
    payload:data
})

export const setAllBookings = (data) => ({
    type:ALL_BOOKINGS_SET,
    payload:data
})

export const getMyBookings = (data) => ({
    type:MY_BOOKINGS_GET,
    payload:data
})

export const setMyBookings = (data) => ({
    type:MY_BOOKINGS_SET,
    payload:data
})