/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

const UserRow = (
    {
        userId,
        firstName,
        lastName,
        callingNumber,
        planName,
        interestArea,
        handleDeleteParticipant,
        showActions
    }) => {
    return (
        <tr>
            <td>
                <p
                    className="m-0 d-inline-block align-middle font-16"
                >
                <a
                    href="apps-ecommerce-products-details.html"
                    className="text-body"
                >
                    {`${firstName} ${lastName}`}
                </a>
                <br />
                </p>
            </td>
            <td>{callingNumber}</td>
            <td>{planName}</td>
            <td>{interestArea}</td>
            {showActions?<td className="table-action">
                <button 
                    className="btn btn-rounded"
                    onClick={()=>{handleDeleteParticipant(userId)}}
                >
                          {" "}
                          <i
                              style={{ marginLeft: "-1rem" }}
                              className="mdi mdi-delete text-danger"
                              
                          ></i>
                </button>
            </td>:null}
        </tr> 
    )
}

export default UserRow
