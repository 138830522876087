import React, { useEffect } from "react";
import { useDispatch} from "react-redux";


const MedicalInfo = ({
    setBasicInfoActive,
    setOtherInfoActive,
    setMedicalInfoActive,

    patientHistory,
    setPatientHistory,
    diagnosis,
    setDiagnosis,
    ageOfDiagnosis,
    setAgeOfDiagnosis,
    physicalFitnessLevel,
    setPhysicalFitnessLevel,
    workoutType,
    setWorkoutType,
    treatmentsTaken,
    setTreatmentsTaken,
    currentTreatment,
    setCurrentTreatment,
    programGoal,
    setProgramGoal,
    reportUrl,
    setReportUrl,
    healthCoach,
    setHealthCoach,
    primaryConcern,
    setPrimaryConcern,
    secondaryConcern,
    setSecondaryConcern,
    symptom,
    setSymptom,
    handleSubmit,
    userId,
}) => {
    const dispatch = useDispatch();
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "/assets/js/advancedForm.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, [dispatch]);

    primaryConcern = primaryConcern ? primaryConcern.split(","):[]
    secondaryConcern = secondaryConcern ? secondaryConcern.split(","):[]
    symptom = symptom ? symptom.split(","):[]
    workoutType = workoutType ? workoutType.split(",") : [];
    treatmentsTaken = treatmentsTaken ? treatmentsTaken.split(",") : [];
    currentTreatment = currentTreatment ? currentTreatment.split(","):[];
    programGoal = programGoal ? programGoal.split(",") : [];


    const getWorkoutType = (e) => {
        setWorkoutType(
            Array.from(e.target.selectedOptions)
                .map((item) => item.innerText)
                .join()
        );
    };

    const getTreatmentsTaken = (e) => {
        setTreatmentsTaken(
            Array.from(e.target.selectedOptions)
                .map((item) => item.innerText)
                .join()
        );
    };

    const getCurrentTreatment = (e) => {
        setCurrentTreatment(
            Array.from(e.target.selectedOptions)
                .map((item) => item.innerText)
                .join()
        );
    };

    const getProgramGoal = (e) => {
        setProgramGoal(
            Array.from(e.target.selectedOptions)
                .map((item) => item.innerText)
                .join()
        );
    };


    const getPrimaryConcern = (e) =>{
        setPrimaryConcern(
            Array.from(e.target.selectedOptions)
                .map(item=>item.innerText)
                .join()
        )
    }

    const getSecondaryConcern = (e) =>{
        setSecondaryConcern(
            Array.from(e.target.selectedOptions)
                .map(item=>item.innerText)
                .join()
        )
    }

    const getSymptom = (e) =>{
        setSymptom(
            Array.from(e.target.selectedOptions)
                .map(item=>item.innerText)
                .join()
        )
    }

    return (
        <div>
            <div className="row">
                <div className="col-12">
                    <form className="form-horizontal">
                        <input
                            autoComplete="false"
                            name="hidden"
                            type="text"
                            style={{ display: "none" }}
                        />

                        {/* Patient History */}
                        <div className="row mb-4">
                            <label
                                htmlFor="floatingTextarea"
                                className="col-2 col-form-label"
                            >
                                Patient History
                            </label>
                            <div className="col-10">
                                <textarea
                                    className="form-control"
                                    value={patientHistory}
                                    onChange={(e) => {
                                        setPatientHistory(e.target.value);
                                    }}
                                    placeholder="Fill patient history here"
                                    id="floatingTextarea"
                                    style={{ height: "150px" }}
                                ></textarea>
                            </div>
                        </div>
                        
                        {/* Diagnosis */}
                        <div className="row mb-4">
                            <label
                                htmlFor="example-select"
                                className="col-2 col-form-label"
                            >
                                {" "}
                                Diagnosis
                            </label>
                            <div className="col-10">
                                <select
                                    className="form-select"
                                    id="example-select"
                                    value={diagnosis}
                                    onChange={e=>setDiagnosis(e.target.value)}
                                >
                                    <option>Diagnosed</option>
                                    <option>Not Diagnosed</option>
                                </select>
                            </div>
                        </div>
                        
                        {/* Age of Diagnosis */}
                        <div className="row mb-4">
                            <label
                                htmlFor="simpleInput3"
                                className="col-2 col-form-label"
                            >
                                Age at the time of Diagnosis
                            </label>
                            <div className="col-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={ageOfDiagnosis}
                                    onChange={(e) => {
                                        setAgeOfDiagnosis(e.target.value);
                                    }}
                                    id="simpleInput1"
                                    placeholder="Age of Diagnosis"
                                    autoComplete="off"
                                />
                            </div>
                        </div>

                        {/* Physical Fitness Level */}
                        <div className="row mb-4">
                            <label
                                htmlFor="example-select"
                                className="col-2 col-form-label"
                            >
                                Level of Physical Fitness
                            </label>
                            <div className="col-10">
                                <select
                                    className="form-select"
                                    id="example-select"
                                    value={physicalFitnessLevel}
                                    onChange={(e) => {
                                        setPhysicalFitnessLevel(e.target.value);
                                    }}
                                >
                                    {"None" === "physicalFitnessLevel" ? (
                                        <option slected="selected">None</option>
                                    ) : (
                                        <option>None</option>
                                    )}
                                    {"Beginner" === "physicalFitnessLevel" ? (
                                        <option slected="selected">
                                            Beginner
                                        </option>
                                    ) : (
                                        <option>Beginner</option>
                                    )}
                                    {"Intermediate" ===
                                    "physicalFitnessLevel" ? (
                                        <option slected="selected">
                                            Intermediate
                                        </option>
                                    ) : (
                                        <option>Intermediate</option>
                                    )}
                                    {"Advanced" === "physicalFitnessLevel" ? (
                                        <option slected="selected">
                                            Advanced
                                        </option>
                                    ) : (
                                        <option>Advanced</option>
                                    )}
                                    {"Extreme" === "physicalFitnessLevel" ? (
                                        <option slected="selected">
                                            Extreme
                                        </option>
                                    ) : (
                                        <option>Extreme</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        
                        {/* Workout Types */}
                        <div className="row mb-4">
                            <label className="col-2 col-form-label">
                                Types of Workout
                            </label>
                            <div className="col-10">
                                <select
                                    className="select2 form-control select2-multiple"
                                    onChange={(e) => {
                                        getWorkoutType(e);
                                    }}
                                    data-toggle="select2"
                                    multiple="multiple"
                                    data-placeholder="Choose ..."
                                >
                                    <optgroup label="Choose Workout Types">
                                        <option
                                            selected={
                                                workoutType.includes("Yoga")
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Yoga"
                                        >
                                            Yoga
                                        </option>
                                        <option
                                            selected={
                                                workoutType.includes(
                                                    "Home Workout"
                                                )
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Home Workout"
                                        >
                                            Home Workout
                                        </option>
                                        <option
                                            selected={
                                                workoutType.includes("Dance")
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Dance"
                                        >
                                            Dance
                                        </option>
                                        <option
                                            selected={
                                                workoutType.includes("Walk")
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Walk"
                                        >
                                            Walk
                                        </option>
                                        <option
                                            selected={
                                                workoutType.includes("Others")
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Others"
                                        >
                                            Others
                                        </option>
                                    </optgroup>
                                </select>
                            </div>
                        </div>

                        {/* Treatments Taken */}
                        <div className="row mb-4">
                            <label className="col-2 col-form-label">
                                Treatments Taken
                            </label>
                            <div className="col-10">
                                <select
                                    className="select2 form-control select2-multiple"
                                    onChange={(e) => {
                                        getTreatmentsTaken(e);
                                    }}
                                    data-toggle="select2"
                                    multiple="multiple"
                                    data-placeholder="Choose ..."
                                >
                                    <optgroup label="Choose Treatments Taken">
                                        <option
                                            selected={
                                                treatmentsTaken.includes("None")
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="None"
                                        >
                                            None
                                        </option>
                                        <option
                                            selected={
                                                treatmentsTaken.includes("OCP")
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="OCP"
                                        >
                                            OCP
                                        </option>
                                        <option
                                            selected={
                                                treatmentsTaken.includes(
                                                    "Homeopathy"
                                                )
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Homeopathy"
                                        >
                                            Homeopathy
                                        </option>
                                        <option
                                            selected={
                                                treatmentsTaken.includes(
                                                    "Naturopathy"
                                                )
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Naturopathy"
                                        >
                                            Naturopathy
                                        </option>
                                        <option
                                            selected={
                                                treatmentsTaken.includes(
                                                    "Allopathy"
                                                )
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Allopathy"
                                        >
                                            Allopathy
                                        </option>
                                        <option
                                            selected={
                                                treatmentsTaken.includes(
                                                    "Ayurveda"
                                                )
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Ayurveda"
                                        >
                                            Ayurveda
                                        </option>
                                        <option
                                            selected={
                                                treatmentsTaken.includes(
                                                    "Others"
                                                )
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Others"
                                        >
                                            Others
                                        </option>
                                    </optgroup>
                                </select>
                            </div>
                        </div>
                        
                        {/* Current Treatment */}
                        <div className="row mb-4">
                            <label className="col-2 col-form-label">
                                 Current Treatment
                            </label>
                            <div className="col-10">
                                <select
                                    className="select2 form-control select2-multiple"
                                    onChange={(e) => {
                                        getCurrentTreatment(e);
                                    }}
                                    data-toggle="select2"
                                    multiple="multiple"
                                    data-placeholder="Choose ..."
                                >
                                    <optgroup label="Choose Treatments Taken">
                                        <option
                                            selected={
                                                treatmentsTaken.includes("None")
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="None"
                                        >
                                            None
                                        </option>
                                        <option
                                            selected={
                                                treatmentsTaken.includes("OCP")
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="OCP"
                                        >
                                            OCP
                                        </option>
                                        <option
                                            selected={
                                                treatmentsTaken.includes(
                                                    "Homeopathy"
                                                )
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Homeopathy"
                                        >
                                            Homeopathy
                                        </option>
                                        <option
                                            selected={
                                                treatmentsTaken.includes(
                                                    "Naturopathy"
                                                )
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Naturopathy"
                                        >
                                            Naturopathy
                                        </option>
                                        <option
                                            selected={
                                                treatmentsTaken.includes(
                                                    "Allopathy"
                                                )
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Allopathy"
                                        >
                                            Allopathy
                                        </option>
                                        <option
                                            selected={
                                                treatmentsTaken.includes(
                                                    "Ayurveda"
                                                )
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Ayurveda"
                                        >
                                            Ayurveda
                                        </option>
                                        <option
                                            selected={
                                                treatmentsTaken.includes(
                                                    "Others"
                                                )
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Others"
                                        >
                                            Others
                                        </option>
                                    </optgroup>
                                </select>
                            </div>
                        </div>

                        {/* Primary Concern */}
                        <div className="row mb-4">
                            <label className="col-2 col-form-label">
                                 Primary Concern
                            </label>
                            <div className="col-10">
                                <select
                                    className="select2 form-control select2-multiple"
                                    onChange={(e) => {
                                        getPrimaryConcern(e);
                                    }}
                                    data-toggle="select2"
                                    multiple="multiple"
                                    data-placeholder="Choose ..."
                                >
                                    <optgroup label="Choose Primary Concern">
                                        <option
                                            selected={
                                                primaryConcern.includes("PCOS")
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="PCOS"
                                        >
                                            PCOS
                                        </option>
                                        <option
                                            selected={
                                                primaryConcern.includes("PCOS and Thyroid")
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="PCOS and Thyroid"
                                        >
                                            PCOS and Thyroid
                                        </option>
                                        <option
                                            selected={
                                                primaryConcern.includes("Weight Loss")
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Weight Loss"
                                        >
                                            Weight Loss
                                        </option>
                                        <option
                                            selected={
                                                primaryConcern.includes("Pre-natal")
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Pre-natal"
                                        >
                                            Pre-natal
                                        </option>
                                        <option
                                            selected={
                                                primaryConcern.includes("Post-natal")
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Post-natal"
                                        >
                                            General Fitness
                                        </option>
                                        <option
                                            selected={
                                                primaryConcern.includes("General Fitness")
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="General Fitness"
                                        >
                                            General Fitness
                                        </option>
                                        <option
                                            selected={
                                                primaryConcern.includes("Spondylitis")
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Spondylitis"
                                        >
                                            Spondylitis
                                        </option>
                                        <option
                                            selected={
                                                primaryConcern.includes("Spine Disorder")
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Spine Disorder"
                                        >
                                            Spine Disorder
                                        </option>
                                        <option
                                            selected={
                                                primaryConcern.includes("Wellbeing")
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Wellbeing"
                                        >
                                            Wellbeing
                                        </option>
                                        <option
                                            selected={
                                                primaryConcern.includes("Weight loss")
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Weight loss"
                                        >
                                            Weight loss
                                        </option>
                                    </optgroup>
                                </select>
                            </div>
                        </div>
                        
                        {/* Secondary Concern */}
                        <div className="row mb-4">
                            <label className="col-2 col-form-label">
                                 Secondary Concern
                            </label>
                            <div className="col-10">
                                <select
                                    className="select2 form-control select2-multiple"
                                    onChange={(e) => {
                                        getSecondaryConcern(e);
                                    }}
                                    data-toggle="select2"
                                    multiple="multiple"
                                    data-placeholder="Choose ..."
                                >
                                    <optgroup label="Choose Primary Concern">
                                        <option
                                            selected={
                                                secondaryConcern.includes("Endometriosis")
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Endometriosis"
                                        >
                                            Endometriosis
                                        </option>
                                        <option
                                            selected={
                                                secondaryConcern.includes("Fibroids")
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Fibroids"
                                        >
                                            Fibroids
                                        </option>
                                        <option
                                            selected={
                                                secondaryConcern.includes("Urinary Incontinence")
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Urinary Incontinence"
                                        >
                                            Urinary Incontinence
                                        </option>
                                        <option
                                            selected={
                                                secondaryConcern.includes("Fatty Liver")
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Fatty Liver"
                                        >
                                            Fatty Liver
                                        </option>
                                        <option
                                            selected={
                                                secondaryConcern.includes("Anxiety")
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Anxiety"
                                        >
                                            Anxiety
                                        </option>
                                        <option
                                            selected={
                                                secondaryConcern.includes("Diabetes")
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Diabetes"
                                        >
                                            Diabetes
                                        </option>
                                        <option
                                            selected={
                                                secondaryConcern.includes("Others")
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Others"
                                        >
                                            Others
                                        </option>
                                    </optgroup>
                                </select>
                            </div>
                        </div>

                        {/* Symptom                     */}
                        <div className="row mb-4">
                            <label className="col-2 col-form-label">
                                 Symptom
                            </label>
                            <div className="col-10">
                                <select
                                    className="select2 form-control select2-multiple"
                                    onChange={(e) => {
                                        getSymptom(e);
                                    }}
                                    data-toggle="select2"
                                    multiple="multiple"
                                    data-placeholder="Choose ..."
                                >
                                    <optgroup label="Choose Primary Concern">
                                        <option
                                            selected={
                                                symptom.includes("Acne")
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Acne"
                                        >
                                            Acne
                                        </option>
                                        <option
                                            selected={
                                                symptom.includes("Hair Loss/Thinning")
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Hair Loss/Thinning"
                                        >
                                            Hair Loss/Thinning
                                        </option>
                                        <option
                                            selected={
                                                symptom.includes("Abnormal Hair Growth")
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Abnormal Hair Growth"
                                        >
                                            Abnormal Hair Growth
                                        </option>
                                        <option
                                            selected={
                                                symptom.includes("Dark Patches on Skin")
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Dark Patches on Skin"
                                        >
                                            Dark Patches on Skin
                                        </option>
                                        <option
                                            selected={
                                                symptom.includes("Weight Gain")
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Weight Gain"
                                        >
                                            Weight Gain
                                        </option>
                                        <option
                                            selected={
                                                symptom.includes("Irregular Periods")
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Irregular Periods"
                                        >
                                            Irregular Periods
                                        </option>
                                        <option
                                            selected={
                                                symptom.includes("Sugar Cravings")
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Sugar Cravings"
                                        >
                                            Sugar Cravings
                                        </option>
                                        <option
                                            selected={
                                                symptom.includes("Fatigue")
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Fatigue"
                                        >
                                            Fatigue
                                        </option>
                                        <option
                                            selected={
                                                symptom.includes("Sleep Issues")
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Sleep Issues"
                                        >
                                            Sleep Issues
                                        </option>
                                        <option
                                            selected={
                                                symptom.includes("Stress")
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Stress"
                                        >
                                            Stress
                                        </option>
                                    </optgroup>
                                </select>
                            </div>
                        </div>

                        {/* Program Goals */}
                        <div className="row mb-4">
                            <label className="col-2 col-form-label">
                                Goals From Program
                            </label>
                            <div className="col-10">
                                <select
                                    className="select2 form-control select2-multiple"
                                    onChange={(e) => {
                                        getProgramGoal(e);
                                    }}
                                    data-toggle="select2"
                                    multiple="multiple"
                                    data-placeholder="Choose ..."
                                >
                                    <optgroup label="Choose Treatments Taken">
                                        <option
                                            selected={
                                                programGoal.includes(
                                                    "Improved Stamina"
                                                )
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Improved Stamina"
                                        >
                                            Improved Stamina
                                        </option>
                                        <option
                                            selected={
                                                programGoal.includes(
                                                    "Reduce Weight"
                                                )
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Reduce Weight"
                                        >
                                            Reduce Weight
                                        </option>
                                        <option
                                            selected={
                                                programGoal.includes(
                                                    "Regularize Menstrual Cycles"
                                                )
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Regularize Menstrual Cycles"
                                        >
                                            Regularize Menstrual Cycles
                                        </option>
                                        <option
                                            selected={
                                                programGoal.includes(
                                                    "Improve Sleep Cycle"
                                                )
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Improve Sleep Cycle"
                                        >
                                            Improve Sleep Cycle
                                        </option>
                                        <option
                                            selected={
                                                programGoal.includes(
                                                    "Balance Hormone Levels"
                                                )
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Balance Hormone Levels"
                                        >
                                            Balance Hormone Levels
                                        </option>
                                        <option
                                            selected={
                                                programGoal.includes(
                                                    "Reduce Stress"
                                                )
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Reduce Stress"
                                        >
                                            Reduce Stress
                                        </option>
                                        <option
                                            selected={
                                                programGoal.includes(
                                                    "Improve Skin Issues"
                                                )
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Improve Skin Issues"
                                        >
                                            Improve Skin Issues
                                        </option>
                                        <option
                                            selected={
                                                programGoal.includes(
                                                    "Improve Mental Health"
                                                )
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Improve Mental Health"
                                        >
                                            Improve Mental Health
                                        </option>    
                                        <option
                                            selected={
                                                programGoal.includes(
                                                    "Improve Hair Health"
                                                )
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Improve Hair Health"
                                        >
                                            Improve Hair Health
                                        </option>
                                        <option
                                            selected={
                                                programGoal.includes(
                                                    "Improve Thyroid Levels"
                                                )
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Improve Thyroid Levels"
                                        >
                                            Improve Thyroid Levels
                                        </option>
                                        <option
                                            selected={
                                                programGoal.includes(
                                                    "Gain Weight"
                                                )
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Gain Weight"
                                        >
                                            Gain Weight
                                        </option>
                                        <option
                                            selected={
                                                programGoal.includes(
                                                    "Improve Immunity"
                                                )
                                                    ? "selected"
                                                    : ""
                                            }
                                            value="Improve Immunity"
                                        >
                                            Improve Immunity
                                        </option>
                                    </optgroup>
                                </select>
                            </div>
                        </div>

                        {/* Report Url */}
                        <div className="row mb-4">
                            <label
                                htmlFor="simpleInput3"
                                className="col-2 col-form-label"
                            >
                                Report Url
                            </label>
                            <div className="col-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={reportUrl}
                                    onChange={(e) => {
                                        setReportUrl(e.target.value);
                                    }}
                                    id="simpleInput1"
                                    placeholder="Report Url"
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                        
                        {/* Health Coach */}
                        <div className="row mb-4">
                            <label
                                htmlFor="simpleInput3"
                                className="col-2 col-form-label"
                            >
                                Health Coach
                            </label>
                            <div className="col-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={healthCoach}
                                    onChange={(e) => {
                                        setHealthCoach(e.target.value);
                                    }}
                                    id="simpleInput1"
                                    placeholder="Health Coach"
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-center row mt-5 ">
                            <div className="col-12 d-flex justify-content-between">
                                <button
                                    type="button"
                                    className="btn"
                                    style={{ backgroundColor: "#373750" }}
                                    onClick={() => {
                                        setBasicInfoActive("");
                                        setOtherInfoActive("active");
                                        setMedicalInfoActive("");
                                    }}
                                >
                                    <span style={{ color: "#FFF" }}>
                                        <i className="mdi mdi-arrow-left"></i>{" "}
                                        Previous
                                    </span>
                                </button>
                                <button
                                    type="button"
                                    className="btn"
                                    style={{ backgroundColor: "#373750" }}
                                    onClick={handleSubmit}
                                >
                                    <span style={{ color: "#FFF" }}>
                                        {userId
                                            ? "Edit User Details"
                                            : "Create New User"}{" "}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default MedicalInfo;
