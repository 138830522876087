import React,{useEffect} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import Navbar from '../navbar'
import { Grid, _ } from "gridjs-react";
import { useHistory } from 'react-router-dom'
import { getAllLabTestSKUs } from '../../actions/labTests';
import { labTestSKUsSelector } from '../../selector/labTests';


const Table = ({labTestSKUs}) => {
    const history = useHistory()
    const defaultThumbnail = "https://image-store-admin.s3.amazonaws.com/labTestThumbnail/a0362ee4-8f8f-4ad9-be38-c42623dbceb5.image/png"

    const data = labTestSKUs?labTestSKUs.map((item)=>[
        _(
            <>
                <img
                    src={item.thumbnailUrl ? item.thumbnailUrl : defaultThumbnail}
                    alt={item.name}
                    style={{marginRight:'15px ',height:'48px',width:'48px'}}

                />
                <span className="mt-3">{item.name}</span>
            </>
            
         ),
         item.actualPrice,
         item.type,
         item.selectedLabPartner?item.selectedLabPartner.map((item)=>item.name).join(', '):'',
         item.spreadsheetName,
         _(
            <div>
                <div className="row d-flex justify-content-left">
                    
                    <div 
                        className="col-3"
                        onClick={()=>{
                            history.push(`/createLabTest/${item.sku_id}`)
                        }}
                    >
                        <i className="mdi mdi-pencil text-warning font-20"></i>
                    </div> 
                    
                </div>
            </div>
        )

    ]):[]
    return(
        <div className="col-12">
            <div className="card">
                <div className="card-body">
                    <div className="tab-content">
                        <div
                            className="tab-pane show active"
                            id="basic-datatable-preview"
                        >
                            <div id="basic-datatable-wrapper">
                                <Grid
                                    data={data}
                                    columns={[
                                        {name:"Lab Test Name",width:"30%"},
                                        {name:"Price",width:"8%"},
                                        {name:"Lab Test Type",width:"12%"},
                                        {name:"Lab Partner",width:"20%"},
                                        {name:"Spreadsheet Name",width:"20%"},
                                        {name:"Action",width:"10%"},
                                    ]}
                                    search={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    )
}


const LabTestsListing = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(getAllLabTestSKUs())
    },[dispatch])
    const labTestSKUs = useSelector(state=> labTestSKUsSelector(state))
    console.log(labTestSKUs[1])

    return(
        <div>
            <Navbar>
                <div className="d-flex justify-content-between mb-2 mx-3">
                    <h3> Lab Tests SKUs</h3>
                    <button 
                        className='btn btn-info'
                        style={{height:'80%'}}
                        onClick={()=>{
                            history.push('/createLabTest')
                        }}
                    > 
                            CREATE NEW LAB TEST
                    </button>
                </div>
                <Table
                        labTestSKUs={labTestSKUs}
                />
                
            </Navbar>
        </div>
    )
}


export default LabTestsListing;
