export const OFFERING = `[OFFERING]`
export const OFFERING_TYPE = `[OFFERING_TYPE]`
export const OFFERING_UNIT =  `[OFFERING_UNIT]`
export const OFFERING_STATUS = `[OFFERING_STATUS]`

export const OFFERING_GET = `${OFFERING} GET`
export const OFFERING_SET = `${OFFERING} SET`
export const OFFERING_CREATE = `${OFFERING} CREATE`

export const OFFERING_TYPE_GET = `${OFFERING_TYPE} GET`
export const OFFERING_TYPE_SET = `${OFFERING_TYPE} SET`

export const OFFERING_UNIT_GET = `${OFFERING_UNIT} GET`
export const OFFERING_UNIT_SET = `${OFFERING_UNIT} SET`

export const OFFERING_STATUS_TOGGLE = `${OFFERING_STATUS} TOGGLE` 

export const getOffering = () => ({
    type: OFFERING_GET,
    payload:{}
})

export const setOffering = (data) => ({
    type: OFFERING_SET,
    payload: data
})

export const getOfferingType = () => ({
    type:OFFERING_TYPE_GET,
    payload:{}
})

export const setofferingType = (data) => ({
    type: OFFERING_TYPE_SET,
    payload:data
})
export const getOfferingUnit = () => ({
    type: OFFERING_UNIT_GET,
    payload: {}
})

export const setOfferingUnit = (data) => ({
    type: OFFERING_UNIT_SET,
    payload:data
})

export const createNewOffering = (data) => ({
    type: OFFERING_CREATE,
    payload: data
})

export const toggleOfferingStatus = (data) => ({
    type: OFFERING_STATUS_TOGGLE,
    payload:data
})
