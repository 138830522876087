export const COMMENT = `[COMMENT]`

//action types
export const COMMENT_GET  =  `${COMMENT} GET`
export const COMMENT_SET  =  `${COMMENT} SET`
export const COMMENT_SET_ERROR = `${COMMENT} SET_ERROR`

//action types
export const DELETE_COMMENT  =  `${COMMENT} DELETE`


//action creator
export const getComment = (data) =>({
    type: COMMENT_GET,
    payload:data
})

export const setComment = (data) => ({
    type: COMMENT_SET,
    payload: data
})

export const setCommentError = (data) => ({
    type: COMMENT_SET_ERROR,
    payload: data
})

export const deleteComment = (data) =>({
    type: DELETE_COMMENT,
    payload:data
})