import { apiRequest,API_SUCCESS,API_ERROR } from "../../../actions/api"
import { ORDERS_GET, ORDERS, setOrders, ORDER_GET, ORDER, setOrder, PROCESS_ORDER } from "../../../actions/orders";
import {ROOT_URL} from '../../../config/'

import Alert from "sweetalert2";

export const ordersMiddleware = ({dispatch}) => next => (action) =>{
    next(action)
    switch(action.type){
        case ORDERS_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/orders`,
                method:'get',
                feature:ORDERS
            }))
            break;
        case `${ORDERS} ${API_SUCCESS}`:
                dispatch(setOrders(action.payload))
                break;
        case `${ORDERS} ${API_ERROR}`:
            break;
        
        case ORDER_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/order`,
                method:'get',
                feature:ORDER
            }))
            break;
        case PROCESS_ORDER:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/manualOrderProcessing`,
                method:'put',
                feature:ORDER
            }))
            break;
            
        case `${ORDER} ${API_SUCCESS}`:
                if (action.message === 'Order processed'){
                    Alert.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Sent',
                        showConfirmButton: false,
                        timer: 1500
                      })
                    break;
                }else if (action.message === 'Order Data Fetched'){
                    dispatch(setOrder(action.payload))
                }
                break;
        case `${ORDER} ${API_ERROR}`:
            break;
    
        default: break;
    }
}

