export const COUPONS = `[COUPONS]`
export const COUPON = `[COUPON]`
export const VOUCHERS = `[VOUCHERS]`
export const VOUCHER = `[VOUCHER]`

export const COUPONS_CREATE = `${COUPONS} CREATE`
export const VOUCHERS_CREATE = `${VOUCHERS} CREATE`

export const COUPON_UPDATE = `${COUPON} UPDATE`
export const VOUCHER_UPDATE = `${VOUCHER} UPDATE`

export const COUPONS_GET = `${COUPONS} GET`
export const COUPONS_SET = `${COUPONS} SET`
export const COUPON_GET = `${COUPON} GET`
export const COUPON_SET = `${COUPON} SET`

export const VOUCHERS_GET = `${VOUCHERS} GET`
export const VOUCHERS_SET = `${VOUCHERS} SET`
export const VOUCHER_GET = `${VOUCHER} GET`
export const VOUCHER_SET = `${VOUCHER} SET`

export const COUPONS_ACTIVATE = `${COUPONS} ACTIVATE`
export const COUPONS_DEACTIVATE = `${COUPONS} DEACTIVATE`

export const createCoupons = (data) => ({
    type: COUPONS_CREATE,
    payload:data
})

export const createVouchers = (data)=> ({
    type: VOUCHERS_CREATE,
    payload:data
})

export const getCoupons = () => ({
    type:COUPONS_GET,
    payload:{}
})

export const getVouchers = () => ({
    type:VOUCHERS_GET,
    payload:{}
})

export const setCoupons = (data) => ({
    type: COUPONS_SET,
    payload:data
})

export const setVouchers = (data) => ({
    type: VOUCHERS_SET,
    payload:data
})

export const activateCoupons = (data) => ({
    type:COUPONS_ACTIVATE,
    payload:data
})

export const deactivateCoupons = (data) => ({
    type:COUPONS_DEACTIVATE,
    payload:data
})

export const getCouponDetails = (data) => ({
    type:COUPON_GET,
    payload:data
})

export const setCouponDetails = (data) => ({
    type:COUPON_SET,
    payload:data
})

export const updateCoupon = (data) => ({
    type:COUPON_UPDATE,
    payload:data
})

export const updateVoucher = (data) => ({
    type:VOUCHER_UPDATE,
    payload:data
})

export const getVoucherDetails = (data) => ({
    type:VOUCHER_GET,
    payload:data
})

export const setVoucherDetails = (data) => ({
    type:VOUCHER_SET,
    payload:data
})
