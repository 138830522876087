import React,{useEffect} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import { getOrder } from '../../actions/orders';
import { orderSelector } from '../../selector/orders';
import Navbar from '../navbar'
import { Grid, _ } from "gridjs-react";


const Banner = ({
    orderId,
    name,
    callingNumber,
    amount,
    paymentGateway,
    promoCode

}) => {
    return (
        <div className="row">
            <div className="col-12">
                <div className="card bg-secondary">
                    <div className="card-body profile-user-box">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <div>
                                    <div className="row">
                                        <div className="col-9">
                                            <h1 className="mt-1 mb-1 text-white">
                                                Order ID: {orderId}
                                            </h1>
                                            <h2 className="mt-1 mb-1 text-white-50">
                                                {name} - {callingNumber}
                                            </h2>
                                            <h2 className="text-white mb-1">
                                                Amount: ₹ {Math.round((amount + Number.EPSILON) * 100) / 100} | {paymentGateway} | Promo Code: {promoCode || 'N/A'}
                                            </h2>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const OrderItems = ({orderItems}) => {
    const data = orderItems?orderItems.map(({skuId,skuName,itemId,skuUnit,price,orderItemStatus})=>[
        skuId,
        skuName,
        itemId,
        skuUnit,
        `₹ ${Math.round((price + Number.EPSILON) * 100) / 100}`,
        orderItemStatus
    ]):[]
    return (
        <div className="col-12">
            <div className="card">
                <div className="card-body">
                    <div className="tab-content">
                        <div
                            className="tab-pane show active"
                            id="basic-datatable-preview"
                        >
                            <div id="basic-datatable-wrapper">
                                <Grid
                                    data={data}
                                    columns={[
                                        "SKU Id",
                                        "SKU Name",
                                        "Details",
                                        "Quantity",
                                        "Price",
                                        "Status",
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

const OrderDetails = (props) => {    
    const dispatch = useDispatch();
    const orderId = props.match.params.orderId;

    useEffect(()=>{
        dispatch(getOrder(orderId))
    },[dispatch,orderId])
    
    const orderData = useSelector(state=>orderSelector(state))
    console.log(orderData)
  return (
    <div>
        <Navbar>
            <div className="row mb-3">
                <div className="col-8">
                    <h3 className="page-title page-title-text">
                        All Orders
                    </h3>
                </div>
                <div>
                    <Banner
                        orderId={orderData.orderId?orderData.orderId:''}
                        name={orderData.name?orderData.name:''}
                        callingNumber={orderData.callingNumber?orderData.callingNumber:''}
                        amount={orderData.txnAmount?orderData.txnAmount:''}
                        paymentGateway={orderData.paymentGateway?orderData.paymentGateway:''}
                        promoCode={orderData.promoCode?orderData.promoCode:''}
                    />
                    <OrderItems
                        orderItems={orderData.orderItems?orderData.orderItems:[]}
                        
                    />
                </div>
            </div>
        </Navbar>
    </div>
  ) 
}

export default OrderDetails