import { OFFERING_SET, OFFERING_TYPE_SET, OFFERING_UNIT_SET } from "../../actions/offering"
const initState = []
export const offeringReducer = (state=initState,action) =>{
    switch(action.type){
        case OFFERING_SET:
            return {...state,offering:action.payload}
        case OFFERING_TYPE_SET:
            return {...state,offeringType:action.payload}
        case OFFERING_UNIT_SET:
            return {...state,offeringUnit:action.payload}
        default: return state
    }
}
