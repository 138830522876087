import React,{useEffect} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import Navbar from '../navbar'
import { Grid, _ } from "gridjs-react";
import { getAllSKUs, updateSkuStatus, deleteSku } from '../../actions/SKU';
import { SKUSelector } from '../../selector/SKU';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

const Table = ({skus}) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const data = skus?skus.map((sku)=>[
        sku.skuId,
        sku.skuName,
        sku.skuMapId,
        sku.skuCategory,
        sku.skuSubCategory,
        `${sku.skuUnit} x ${sku.skuUnitType}`,
        _(
            
            <>
                <input 
                    type="checkbox" 
                    id="switch3" 
                    data-switch="success"
                    checked={sku.status==='active'}
                    onClick={()=>{
                        console.log(sku.status,sku.skuId)
                        if(sku.status==='active'){
                            dispatch(updateSkuStatus({skuId:sku.skuId,status:'inactive'}))
                        }else{
                            dispatch(updateSkuStatus({skuId:sku.skuId,status:'active'}))
                        }
                    }}
            />
                <label htmlFor="switch3" data-on-label="" data-off-label=""></label>
            </>
        ),
        _(
            <div>
                <div className="row d-flex justify-content-left">
                    
                    <div 
                        className="col-4"
                        onClick={()=>{
                            history.push(`/updateSKU/${sku.skuId}`)
                        }}
                    >
                        <i className="mdi mdi-pencil text-warning font-20"></i>
                    </div> 
                    <div 
                        className="col-4"
                        onClick={()=>{
                            Swal.fire({
                                title: 'Are you sure?',
                                text: "You won't be able to revert this!",
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes, delete it!'
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        dispatch(deleteSku({skuId:sku.skuId}))
                                    }
                            })
                        }}
                    >
                        <i className="mdi mdi-delete text-danger font-20"></i>
                    </div> 
                </div>
            </div>
        ),
    ]):[]
    return (
        <div className="col-12">
            <div className="card">
                <div className="card-body">
                    <div className="tab-content">
                        <div
                            className="tab-pane show active"
                            id="basic-datatable-preview"
                        >
                            <div id="basic-datatable-wrapper">
                                <Grid
                                    data={data}
                                    columns={[
                                        "SKU ID",
                                        "SKU Name",
                                        "SKU Map ID",
                                        "SKU Category",
                                        "SKU Sub Category",
                                        "SKU Unit",
                                        "Status",
                                        "Action"
                                    ]}
                                    search={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}


const SKU = () => {
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(getAllSKUs())
    },[dispatch])
    const skus = useSelector(state=>SKUSelector(state))
    return (
        <div>
            <Navbar pageTitle={"SKUs Listing"}>
                <Table
                    skus={skus}
                />
            </Navbar>
        </div>
    )
}

export default SKU