import React,{useEffect} from 'react'
import Navbar from '../../navbar'
import {useDispatch,useSelector} from 'react-redux'
import { deleteEvent, getEventDetails } from '../../../actions/event'
import { eventDetailsSelector } from '../../../selector/Event'
import moment from 'moment'
import { useHistory } from 'react-router'
import { Grid, _ } from "gridjs-react";
const EventDetailsBody = (
    {
        eventName,
        offeringTypeName,
        eventId,
        start,
        duration,
        startUrl,
        name,
        userId,
        roleName,
        phoneNumber,
        email,
        participants,
        joinUrl


    }) => {
        const dispatch = useDispatch()
        const history = useHistory()
        const handleDeleteEvent = (joinUrl) => {
            const meetingId = joinUrl.split('/').slice(-1)[0];
            dispatch(deleteEvent({meetingId,eventId}))
            history.push("/")
        }
        const handleEditEvent = () =>{
            history.push(`/createNewEvent/${eventId}`)
        }
        const data = participants?participants.map((item)=>[
            item.firstName,
            item.callingNumber,
            item.planName,
            item.interestArea,
            _(
                item.attendance===1?
                    <span className={`badge bg-success`}>Attended</span>:
                    <span className={`badge bg-danger`}>Did Not Attend</span>
            ),
        ]):[]
    return (
        <div>
            {/* details banner */}
            <div className="row">
                <div className="col-12">
                    <div className="card bg-secondary">
                        <div className="card-body profile-user-box">
                            <div className="row align-items-center">
                                <div className="col-12">
                                    <div>
                                        <div className="row">
                                            <div className="col-9">
                                                <h1 className="mt-1 mb-1 text-white">
                                                    {eventName}
                                                </h1>
                                                <h3 className="text-white-50 mb-1">
                                                    {offeringTypeName} | {eventId}
                                                </h3>
                                                <h3 className="text-white mb-1">{`${moment(start).format("Do, MMM YYYY HH:mm")} (${duration} mins)`}</h3>
                                            </div>
                                            <div
                                                className="col-3"
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-end",
                                                    paddingRight: "2em",
                                                }}
                                            >
                                                    <div className="row w-100">
                                                        <div className="col-12">
                                                            <div className="row ">
                                                                <div className="col-12 mb-2">
                                                                    <a href={startUrl}>
                                                                        <button className="btn btn-info w-100">Start Event</button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="row ">
                                                                <div className="col-12 mb-2">
                                                                    <button 
                                                                        className="btn btn-warning w-100"
                                                                        onClick={()=>{
                                                                            handleEditEvent()
                                                                        }}
                                                                    >
                                                                        Edit Event
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="row ">
                                                                <div className="col-12 mb-2">
                                                                    <button 
                                                                        className="btn btn-danger w-100"
                                                                        onClick={()=>{
                                                                            handleDeleteEvent(joinUrl)
                                                                        }}
                                                                        >
                                                                            Cancel Event
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* lead details */}
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="text-start">
                                <h4 className="header-title mt-0 mb-4">
                                    Lead Info
                                </h4>

                                <div className="row">
                                    <div className="col-4">
                                        <p className="text-muted">
                                            Full Name:
                                            <span className="ms-2">
                                                {name?name:'NA'}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <p className="text-muted">
                                            User Id:
                                            <span className="ms-2">
                                                {userId?userId:'NA'}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <p className="text-muted">
                                            Role:
                                            <span className="ms-2">
                                            {roleName?roleName:'NA'}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <p className="text-muted">
                                            Contact:
                                            <span className="ms-2">
                                                {phoneNumber?phoneNumber:'NA'}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <p className="text-muted">
                                            Email:
                                            <span className="ms-2">
                                                {email?email:'NA'}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* participants */}
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="tab-content">
                            <div
                                className="tab-pane show active"
                                id="basic-datatable-preview"
                            >
                                <div id="basic-datatable-wrapper">
                                    <Grid
                                        data={data}
                                        columns={[
                                            "Participant Name",
                                            "Phone Number",
                                            "Plan Name",
                                            "Primary Concern",
                                            "Attendance"
                                        ]}
                                        search={false}
                                        pagination={{
                                            enabled: false,
                                            limit: 20,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const EventDetails = (props) =>{
    const dispatch = useDispatch()
    const eventId = props.match.params.eventId
    useEffect(()=>{
        dispatch(getEventDetails(eventId))
    },[dispatch,eventId])
    const eventDetails = useSelector(state=>eventDetailsSelector(state))
    return(
        <Navbar>
            <EventDetailsBody
                eventName={eventDetails.eventName}
                offeringTypeName={eventDetails.offeringTypeName}
                eventId={eventDetails.eventId}
                start={eventDetails.start}
                duration={eventDetails.duration}
                startUrl={eventDetails.startUrl}
                name={eventDetails.name}
                userId={eventDetails.userId}
                roleName={eventDetails.roleName}
                phoneNumber={eventDetails.phoneNumber}
                email={eventDetails.email}
                participants={eventDetails.participants?eventDetails.participants:[]}
                joinUrl={eventDetails.joinUrl}
            />
        </Navbar>
    )
}

export default EventDetails 
