import { apiRequest,API_SUCCESS,API_ERROR } from "../../../actions/api"
import { 
    DOCTORS, 
    DOCTOR,
    DOCTORS_GET, 
    DOCTOR_ONBOARDING, 
    DOCTOR_ONBOARDING_UPDATE,
    DOCTOR_VERIFIED_UPDATE,
    DOCTOR_VERIFIED, 
    DOCTOR_CREATE,
    DOCTOR_UPDATE,
    DOCTOR_GET,
    DOCTOR_CONNECT_WITH_EXPERT,
    PATIENT_TO_DOCTOR,
    PATIENT_TO_DOCTOR_ASSIGN,   
    DOCTOR_PATIENTS,
    DOCTOR_PATIENTS_GET,
    setDoctorPatients,
    getAllDcotors,
    setAllDcotors, 
    setDoctor} 
from "../../../actions/doctorFacingTech"
import {ROOT_URL} from '../../../config/'
import Alert from "sweetalert2";
import { act } from "react-dom/test-utils";

export const doctorFacingTechMiddleware = ({dispatch}) => next => (action) => {
    next(action)
    switch(action.type){
        case DOCTORS_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/doctors`,
                method:'get',
                feature:DOCTORS
            }))
            break;
        
        case DOCTOR_VERIFIED_UPDATE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/updateDoctorVerified`,
                method:'put',
                feature: DOCTOR_VERIFIED
            }))
            break;
        
        case DOCTOR_ONBOARDING_UPDATE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/updateDoctorOnboarding`,
                method:'put',
                feature: DOCTOR_ONBOARDING
            }))
            break;

        case DOCTOR_CONNECT_WITH_EXPERT:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/connectDoctorWithExpert`,
                method:'post',
                feature: DOCTOR
            }))
            break;


        case DOCTOR_CREATE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/createDoctor`,
                method:'post',
                feature: DOCTOR
            }))
            break;

        case DOCTOR_UPDATE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/updateDoctor`,
                method:'put',
                feature: DOCTOR
            }))
            break;

        case DOCTOR_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/getDoctorDetails`,
                method:'get',
                feature: DOCTOR
            }))
            break;

        case PATIENT_TO_DOCTOR_ASSIGN:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/assignPatientToDoctor`,
                method:'post',
                feature: PATIENT_TO_DOCTOR
            }))
            break;

        case DOCTOR_PATIENTS_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/getDoctorPatients`,
                method:'get',
                feature: DOCTOR_PATIENTS
            }))
            break;

        case `${DOCTOR_PATIENTS} ${API_SUCCESS}`:
            dispatch(setDoctorPatients(action.payload))
            break;

        case `${PATIENT_TO_DOCTOR} ${API_SUCCESS}`:
            Alert.fire({
                position: 'top-end',
                icon: 'success',
                title: action.message,
                showConfirmButton: false,
                timer: 1500
            })
            break;
        case `${PATIENT_TO_DOCTOR} ${API_ERROR}`:
            Alert.fire({
                position: 'top-end',
                icon: 'error',
                title: action.message,
                showConfirmButton: false,
                timer: 1500
            })
            break;

        case `${DOCTOR_ONBOARDING} ${API_SUCCESS}`:
            dispatch(getAllDcotors())
            break;

        case `${DOCTOR_VERIFIED} ${API_SUCCESS}`:
            dispatch(getAllDcotors())
            break;

        case `${DOCTORS} ${API_SUCCESS}`:
            dispatch(setAllDcotors(action.payload))
            break;
        
        case `${DOCTOR} ${API_SUCCESS}`:
            if(action.message === 'Doctor Profile Data Added')
            {
                Alert.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: action.message,
                    showConfirmButton: false,
                    timer: 1500
                })
                dispatch(getAllDcotors())
            }
            else if(action.message === 'Doctor Profile Data Updated')
            {
                Alert.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: action.message,
                    showConfirmButton: false,
                    timer: 1500
                })
                dispatch(getAllDcotors())
            }
            else if(action.message === 'Doctor Connected With Expert')
            {
                Alert.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: action.message,
                    showConfirmButton: false,
                    timer: 1500
                })

            }
            else{
                dispatch(setDoctor(action.payload))
            }
            break;

        case `${DOCTOR} ${API_ERROR}`:
            Alert.fire({
                position: 'top-end',
                icon: 'error',
                title: action.message,
                showConfirmButton: false,
                timer: 1500
            })
            break;

        case `${DOCTOR_ONBOARDING} ${API_ERROR}`:
            Alert.fire({
                position: 'top-end',
                icon: 'error',
                title: action.message,
                showConfirmButton: false,
                timer: 1500
              })
            break;

        case `${DOCTOR_VERIFIED} ${API_ERROR}`:
            Alert.fire({
                position: 'top-end',
                icon: 'error',
                title: action.message,
                showConfirmButton: false,
                timer: 1500
              })
            break;
    }
}