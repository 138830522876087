import { MEALS_CREATE_SUCESS,
MEALS_CREATE_FAILURE, MEALS_CREATE } from "../../actions/meals"

const initState = []
export const mealsReducer = (state=initState,action) =>{
    switch(action.type){
        case MEALS_CREATE:
            return {...state,meals:{status: "fetching"}}
        case MEALS_CREATE_SUCESS:
            return {...state,meals:{status: "success", ...action.payload}}
        case MEALS_CREATE_FAILURE:
            return {...state,meals:{status: "failed", ...action.payload}}
        default: return state
    }
}

