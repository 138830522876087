import React,{useEffect} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { getAllTasks } from '../../actions/tasks'
import { allTasksSelector } from '../../selector/tasks'
import Navbar from '../navbar'
import { Grid, _ } from "gridjs-react";
import { useHistory } from 'react-router-dom'

const Table = ({tasks}) => {
    const history = useHistory();
    const data = tasks?tasks.map((task)=>[
        _(
            <>
                <span className="mt-3">{task.taskId}</span>
                <img
                    src={task.taskThumbnail}
                    alt={task.taskTitle}
                    style={{marginLeft:'30px', height:'48px',width:'48px'}}

                />
            </>
            
         ),
        task.taskTitle,
        `🌕 x ${task.taskReward}`,
        _(
            <div className="col-3">
            <i
               onClick={() => {
                history.push(`/createTask/${task.taskId}`);
            }} 
                className="mdi mdi-pencil text-warning"
            ></i>
    </div>
        )
    ]):[]
    return (
        <div className="col-12">
            <div className="card">
                <div className="card-body">
                    <div className="tab-content">
                        <div
                            className="tab-pane show active"
                            id="basic-datatable-preview"
                        >
                            <div id="basic-datatable-wrapper">
                                <Grid
                                    data={data}
                                    columns={[
                                        {name:"Task Id",width:"20%"},
                                        {name:"Task Title",width:"20%"},
                                        {name:"Task Reward",width:"20%"},
                                        {name:"Action",width:"20%"},
                                    ]}
                                    search={true}
                                    pagination={{
                                        enabled: true,
                                        limit: 20,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}



const TaskListing = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  useEffect(()=>{
    dispatch(getAllTasks())
  },[dispatch])
  const tasks = useSelector(state=>allTasksSelector(state))
    return (
    <div>
        <Navbar>
            <div className="d-flex justify-content-between mb-2 mx-3">
                <h3> All Tasks</h3>
                <button 
                    className='btn btn-info'
                    style={{height:'80%'}}
                    onClick={()=>{
                        history.push('/createTask')
                    }}
                > 
                        CREATE NEW TASK
                </button>
            </div>
            <Table
                tasks={tasks}
            />
        </Navbar>
    </div>
  )
}

export default TaskListing
