import { apiRequest,API_SUCCESS,API_ERROR } from "../../../actions/api"
import { PAYMENTS_PLAN, PAYMENTS_PLAN_DETAILS, PAYMENTS_PLAN_GET, PAYMENTS_PLAN_CREATE, PAYMENTS_PLAN_DETAILS_GET, PAYMENTS_PLAN_UPDATE, setPaymentsPlan, getPaymentsPlan, setPaymentsPlanDetails } from "../../../actions/paymentsPlan";
import {ROOT_URL} from '../../../config/';
import Alert from "sweetalert2";
import moment from "moment";

export const paymentsPlanMiddleware = ({dispatch}) => next => (action) => {
    next(action)

    switch(action.type)
    {
        case PAYMENTS_PLAN_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/paymentsPlan`,
                method:'get',
                feature: PAYMENTS_PLAN
            }))
            break;

        case PAYMENTS_PLAN_CREATE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/paymentsPlan`,
                method:'post',
                feature: PAYMENTS_PLAN
            }))
            break;
        
        case PAYMENTS_PLAN_DETAILS_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/getPaymentsPlanDetail`,
                method:'get',
                feature: PAYMENTS_PLAN_DETAILS
            }))
            break;

        case PAYMENTS_PLAN_UPDATE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/paymentsPlan`,
                method:'put',
                feature: PAYMENTS_PLAN
            }))
            break;

        case `${PAYMENTS_PLAN_DETAILS} ${API_SUCCESS}`:
            dispatch(setPaymentsPlanDetails(action.payload));
            break;
        
        case `${PAYMENTS_PLAN_DETAILS} ${API_ERROR}`:
            break;

        case `${PAYMENTS_PLAN} ${API_SUCCESS}`:
            if(action.message === 'Payment Created Successfully' || action.message === 'Payment Updated Successfully')
            {
                Alert.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: action.message,
                    showConfirmButton: false,
                    timer: 1500
                  })
                dispatch(getPaymentsPlan({start: moment(), end: moment()}))
            }
            else{
                dispatch(setPaymentsPlan(action.payload));
            }
            
            break;

        case `${PAYMENTS_PLAN} ${API_ERROR}`:
            if(action.message === "Payment Not Created")
            {
                Alert.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Payment Not Created',
                    showConfirmButton: false,
                    timer: 1500
                  })
            }
            break;
        default:break;
    }
}