/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/aria-proptypes */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Alert from "sweetalert2";
import axios from 'axios';

import Navbar from "../../navbar";
import {createContent, deleteContent, updateContent, getTopics} from "../../../actions/content/index"; 
import { contentItemsSelector, contentTopicsSelector } from "../../../selector/content";
import ContentForm from "./createContentForm";
import ContentPreview from "./createContentPreview";
import { getProfilePresignedUrl, saveProfileImageLocation } from '../../../actions/profilePhotoUpload';
import { getComment } from "../../../actions/comments"
import { getContentPresignedUrl } from '../../../actions/contentImageUpload';
import { contentPhotoPresignedUrlSelector } from '../../../selector/presignedUrlSelector';
import Comments from './comments';

import "./createContent.css"

const CreateContent = (props) => {
    const dispatch = useDispatch();
    let topicItem = useSelector((state) => contentTopicsSelector(state));
    const contentItems = useSelector((state) => contentItemsSelector(state));

    const [contentType, setContentType] = useState('poll');
    const [selectedTopics, setSelectedTopics] = useState({});
    const [selectedContent, setSelectedContent] = useState(null);

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [thumbnailURL, setThumbnailURL] = useState('');
    const [heroURL, setHeroURL] = useState('');
    const [pollOptions, setPollOptions] = useState([]);
    const [renderer, reRender] = useState(false);
    const [check, setCheck] = useState(false);
    const [topics, setTopics] = useState(topicItem);
    const [thumnailFileImage,setThumnailFileImage] = useState('');
    const [heroFileImage,setHeroFileImage] = useState('');
    const [thumnailUploadStatus,setThumnailUploadStatus] = useState(0);
    const [heroUploadStatus,setHeroUploadStatus] = useState(0);
    const [topicId,setTopicId] = useState(null);
    const [uploadingImage,setUploadingImage] = useState(false);


    const [contentURL,setContentURL] = useState('');
    const [captions,setCaptions] = useState('');
    const [validity,setValidity] = useState(0);
    const [readTime,setReadTime] = useState(0);

    const [allcontentData,setAllcontentData] = useState(null);



    const history = useHistory();


    const contentId = props.match.params.contentId;

     useEffect(()=>{
        dispatch(getTopics());
    },[dispatch])

    useEffect(() => {
         if(contentId && contentItems && contentItems.data && contentItems.data.content && contentItems.data.content.length){
            const data = contentItems.data.content.filter(i => i.content_id === contentId);
            setSelectedContent(data[0])
        }

        if(selectedContent){
            setContentType(selectedContent.content_type);
            setTitle(selectedContent.title);
            setDescription(selectedContent.content_description);
            setThumbnailURL(selectedContent.thumbnail_url);
            setHeroURL(selectedContent.hero_url);
            setPollOptions(selectedContent.poll_data);
            setCheck(selectedContent.can_comment);
            setContentURL(selectedContent.content_url);
            setCaptions(selectedContent.caption);
            setValidity(selectedContent.validity);
            setReadTime(selectedContent.read_time)
            setAllcontentData(selectedContent);
        } else {
            setContentType('poll');
            setTitle('');
            setDescription('');
            setThumbnailURL('');
            setHeroURL('');
            setPollOptions([]);
            setCheck(false);
            setAllcontentData(null)
        }
    }, [contentId, contentItems, selectedContent])
 


    const addTopic = (item) => {
        setSelectedTopics(item);
    }

    const getPayload = (contentType) => {
        let payload;
        switch (contentType){
            case 'poll':
                if(!title || !pollOptions || !topicId){
                    payload = false
                } else {
                    payload = {
                        contentType,
                        title,
                        contentDescription: description,
                        thumbnailUrl: thumbnailURL,
                        heroUrl: heroURL,
                        pollData: pollOptions,
                        canComment: check,
                        validity,
                        topicId
                    }
                }
                break;
            case 'video':
                if(!title || !contentURL || !topicId){
                    payload = false
                } else {
                    payload = {
                        contentType,
                        title,
                        captions,
                        contentURL,
                        readTime,
                        canComment: check,
                        topicId
                    }
                }
                break;
            case 'blog':
                if(!title || !description || !contentURL || !topicId){
                    payload = false
                } else {
                    payload = {
                        contentType,
                        title,
                        captions,
                        contentDescription: description,
                        contentURL,
                        readTime,
                        thumbnailUrl: thumbnailURL,
                        heroUrl: heroURL,
                        canComment: check,
                        topicId
                    }
                }
                break;
            case 'post':
                if(!heroURL || !topicId){
                    payload = false;
                } else {
                    payload = {
                        contentType,
                        title,
                        captions,
                        contentDescription: description,
                        thumbnailUrl: thumbnailURL,
                        heroUrl: heroURL,
                        canComment: check,
                        topicId
                    }
                }
                break;
            case 'recipie':
                if(!title || !description || !topicId){
                    return false
                } else {
                    payload = {
                        contentType,
                        title,
                        contentDescription: description,
                        thumbnailUrl: thumbnailURL,
                        heroUrl: heroURL,
                        canComment: check,
                        validity,
                        topicId
                    }
                }
                break;
            default:
                payload = null;
        }
        return payload;
    }

    const actionCreateContent = (data) => {
        const payload = getPayload(contentType);
        if( payload === false){
            alert('Please fill all the mandatory data and make sure a topic is selected.')
        } else {
            dispatch(createContent(payload));
            history.push('/contentItems')
        }
    }

    const actionUpdateContent = (data) => {
        const payload = getPayload(contentType);
        if( payload === false){
            alert('Please fill all the mandatory data and make sure a topic is selected.')
        } else {
            payload.contentId = selectedContent.content_id;
            dispatch(updateContent(payload));
            Alert.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Content updated',
                showConfirmButton: false,
                timer: 1500
            })
        }
       
    }

    const actionDeleteContent = () => {
        const payload = {contentIds: [selectedContent.content_id]};
        dispatch(deleteContent(payload));
        history.goBack()
    }

      const onFileChange = (event, type) => {
        if(type === 'thumbnail'){
            setThumnailFileImage(event.target.files[0])
            setThumnailUploadStatus(1)
        } else {
            setHeroFileImage(event.target.files[0])
            setHeroUploadStatus(1)
        }
        dispatch(getContentPresignedUrl(event.target.files[0].type))
    }

    const uploadConfigs = useSelector(state=>contentPhotoPresignedUrlSelector(state))
    const uploadFile = async (type) => {
        const image = type === 'thumbnail' ? thumnailFileImage : heroFileImage
        setUploadingImage(true)
             await axios.put(uploadConfigs.url,image,{
                 headers:{
                    'Content-Type':image.type
                 }
             }).then((val)=>{
                const cloudfrontURL = `https://da1lb90jsypal.cloudfront.net/${uploadConfigs.key}`
            if(type === 'thumbnail'){
                setThumbnailURL(cloudfrontURL)
                setThumnailUploadStatus(2)
                Alert.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Thumbnail Uploaded',
                    showConfirmButton: false,
                    timer: 1500
                })
            } else {
                setHeroURL(cloudfrontURL)
                setHeroUploadStatus(2)
                // Alert.fire({
                //     position: 'top-end',
                //     icon: 'success',
                //     title: 'Hero Uploaded',
                //     showConfirmButton: false,
                //     timer: 1500
                // })
            }
            setUploadingImage(false)
             }).catch((err)=>{
                Alert.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Image Upload Failed',
                    showConfirmButton: false,
                    timer: 1500
                  })
                  setUploadingImage(false)
             })
    }

    const clearForm = () => {
        setContentType('poll');
        setTitle('');
        setDescription('');
        setThumbnailURL('');
        setHeroURL('');
        setPollOptions([]);
        setCheck(false);
    }

    return (
        <Navbar pageTitle={selectedContent ? "Update content" : "Create Content"}>
            <div>
            <div className="create_content_container">
                <div className="create_content_form">
                    <ContentForm
                        contentType={contentType}
                        title={title}
                        description={description}
                        thumbnailURL={thumbnailURL}
                        heroURL={heroURL}
                        pollOptions={pollOptions}
                        renderer={renderer}
                        check={check}

                        setContentType={setContentType}
                        setTitle={setTitle}
                        setDescription={setDescription}
                        setThumbnailURL={setThumbnailURL}
                        setHeroURL={setHeroURL}
                        setPollOptions={setPollOptions}
                        reRender={reRender}
                        setCheck={setCheck}

                        actionCreateContent={actionCreateContent}
                        actionUpdateContent={actionUpdateContent}
                        addTopic={addTopic}
                        topics={topics}

                        selectedContent={selectedContent}
                        clearForm={clearForm}
                        actionDeleteContent={actionDeleteContent}

                        onFileChange={onFileChange}
                        thumnailFileImage={thumnailFileImage}
                        uploadFile={uploadFile}
                        heroFileImage={heroFileImage}
                        thumnailUploadStatus={thumnailUploadStatus}
                        heroUploadStatus={heroUploadStatus}

                        setContentURL={setContentURL}
                        contentURL={contentURL}
                        setCaptions={setCaptions}
                        captions={captions}
                        setValidity={setValidity}
                        validity={validity}
                        setReadTime={setReadTime}
                        readTime={readTime}

                        setTopicId={setTopicId}
                        allcontentData={allcontentData}

                        uploadingImage={uploadingImage}
                    />
                </div>
                <div>
                    <div className="create_content_form__Heading">Preview (Galaxy S8+)</div>
                    <div className="create_content_preview">
                    <ContentPreview
                        contentType={contentType}
                        title={title}
                        description={description}
                        thumbnailURL={thumbnailURL}
                        heroURL={heroURL}
                        pollOptions={pollOptions}
                        renderer={renderer}
                        check={check}
                        topics={topics}
                        contentURL={contentURL}
                        captions={captions}
                        readTime={readTime}
                        validity={validity}
                        selectedContent={selectedContent}
                    />
                </div>
                </div>
            </div>
                { allcontentData ? <div className="create_content_form__Heading">Comments</div> : null }
                { allcontentData ? <Comments contentData={allcontentData} /> : null }

            </div>
        </Navbar>
    );
};

export default CreateContent;