/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useState,useEffect, useCallback, useRef,useMemo } from 'react'
import {useDispatch,useSelector} from 'react-redux';
import moment from 'moment'
import Navbar from '../../navbar';
import { getTrialUsers, getHealthCoach, assignHealthCoach  } from '../../../actions/users';
import { trialUsersSelector, healthCoachListSelector} from '../../../selector/users';
import { authDetailsSeletor } from '../../../selector/auth';
import { markEvent,unmarkEvent } from '../../../actions/event';
import { unmarkedEventCountSelector } from '../../../selector/navbarStats';
import { setUnmarkedEventCount } from '../../../actions/navbarStats/unmarkedTrials';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';


const Event = ({colorScheme,eventName, isMarked, markedBy,markedByName,markedTimestamp,eventId, startTime,currentName,currentUserId,dispatch,unmarkedEventCount,setUnmarkedEventCount, userId}) => {
    const [mark,setMark] = useState(isMarked)
    const [markName,setMarkName] = useState(markedByName)
    const [markTimestamp,setMarkTimestamp] = useState(moment(markedTimestamp).format('hh:mma on DD-MM-YYYY'))

    const onMarkEvent = () => {
        if(mark===1){
            setMark(0)
            setMarkName(null)
            setMarkTimestamp(null)
            //dispatch event to unmark
            dispatch(unmarkEvent({eventId,markedByUserId:currentUserId, userId}))

        }else{
            setMark(1)
            setMarkName(currentName)
            setMarkTimestamp(moment().format('hh:mma on DD-MM-YYYY'))
            //dispatch event to mark
            dispatch(markEvent({eventId, markedByUserId:currentUserId, userId}))
        }
    }
    return (
        <>
            <div className={`alert alert-${colorScheme?colorScheme.split('-')[1]:'success'}`} role="alert">
                <div className={`form-check form-check-inline form-checkbox-${colorScheme?colorScheme.split('-')[1]:'success'}`}>
                    <label className="form-check-label">{`${eventName} at ${startTime}`}</label>
                    <input 
                        type="checkbox" 
                        className="form-check-input" 
                        checked={mark===1?true:false}
                        onChange={()=>{onMarkEvent()}}    
                        />
                </div>

                {mark===1?
                    <div className="mx-3">
                        <small>{`Marked by ${markName} at ${markTimestamp}`}</small>
                    </div>
                    :null
                }
            </div> 
        </>  
    )
}

const BookedSessions = (props) => {
    return (
        <div>
            {
                props.data.bookedSession.events.map((event) => (
                    <Event
                        colorScheme={event.colorScheme}
                        eventName={event.eventName}
                        isMarked={event.isMarked}
                        markedBy={event.markedBy}
                        markedByName={event.markedByName}
                        markedTimestamp={event.markedTimestamp}
                        eventId={event.eventId}
                        startTime={event.startTime}
                        currentName={props.data.bookedSession.currentName}
                        currentUserId={props.data.bookedSession.currentUserId}
                        dispatch={props.data.bookedSession.dispatch}
                        unmarkedEventCount={props.data.bookedSession.unmarkedEventCount}
                        setUnmarkedEventCount={props.data.bookedSession.setUnmarkedEventCount}
                        userId={props.data.bookedSession.userId}
                    />
                ))
            }
        </div>
    )
}

const HealthCoachDropdown = (props) => {
    const [healthCoach, setHealthCoach] = useState(props.data.assignHC.healthCoach);
    const dispatch = props.data.assignHC.dispatch;
    
    const handleHealthCoach = (e) => {
        setHealthCoach(e.target.value)
        dispatch(assignHealthCoach({healthCoach: e.target.value, userId: props.data.assignHC.userId, start: props.data.assignHC.start, end: props.data.assignHC.end}))
        //dispatch(getTrialUsers({start: props.data.assignHC.start, end: props.data.assignHC.end}))
    }

    return (
        <select 
            className="form-select"
            value={healthCoach}
            onChange={(e) => {handleHealthCoach(e)}}
        >
            <option value="" disabled selected>Assign health coach</option>
            {
                props.data.assignHC.healthCoachList ? props.data.assignHC.healthCoachList.map((item) => (
                    <option>{item.name}</option>
                )) : ""
            }
        </select>
    )
}

const Attendance = (props) => {
    return (
        <div>
            {
                props.data.attendance.map((item) => (
                    <div className='my-4'>{item.attendance}</div>
                ))
            }
        </div>
    )
}

const Table = ({data,currentName,currentUserId,dispatch,unmarkedEventCount,setUnmarkedEventCount, healthCoach, start, end}) => {

    const trialUsersData = data?data.map((item)=>{
        return {
            userId: item.userId,
            name: item.firstName,
            contact: item.callingNumber,
            bookedSession: {userId: item.userId, events: item.events, currentName, currentUserId, dispatch, unmarkedEventCount, setUnmarkedEventCount},
            attendance: item.events,
            assignHC: {healthCoachList: healthCoach, healthCoach: item.healthCoach ? item.healthCoach : "", userId: item.userId, start, end, dispatch},
            currentHC: item.healthCoach ? item.healthCoach : null,
            sessionType: item.events.reduce((type, event) => event.eventName + ", " + type, ""),
            date: item.events.reduce((date, event) => event.startTime + ", " + date, ""),
            overallAttendance: item.events.reduce((attendance, event) => event.attendance + ", " + attendance, ""),
        }
    }):[]

    const [columnDefs] = useState([
        {headerName: "Health Coach", field: "assignHC", cellRenderer:HealthCoachDropdown, width: 300, cellStyle: {'height': '100%','display': 'flex ','justify-content': 'center','align-items': 'center ',}},
        {headerName: "User Id", field: "userId", width: 200, cellStyle: {'display': 'flex ','justify-content': 'center','align-items': 'center ',}},
        {headerName: "Name", field: "name", width: 200, cellStyle: {'display': 'flex ','justify-content': 'center','align-items': 'center ',}},
        {headerName: "Contact", field: "contact", width: 200, cellStyle: {'display': 'flex ','justify-content': 'center','align-items': 'center ',}},
        {headerName:"Booked Sessions", field:"bookedSession", cellRenderer:BookedSessions, width:400, autoHeight:true, cellStyle: {'margin-top': "1%"}},
        {headerName: "Attendance", field: "attendance", cellRenderer:Attendance, width: 130, cellStyle: {'display': 'flex ','justify-content': 'center','align-items': 'center ',}},
        {headerName: "Current HC", field: "currentHC", width: 200, hide: true},
        {headerName: "Session type", field: "sessionType", width: 200, hide: true},
        {headerName: "Date", field: "date", width: 200, hide: true},
        {headerName: "Overall Attendance", field: "overallAttendance", width: 200, hide: true}
    ])


    const gridRef = useRef();

    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current.api.setQuickFilter(
            document.getElementById('filter-text-box').value
        );
    }, []);

    const onBtnExport = useCallback(() => {
        const exportCols = {
            columnKeys: ['userId','name', 'contact', 'currentHC', 'sessionType', 'overallAttendance', 'date'],
        };
        gridRef.current.api.exportDataAsCsv(exportCols);
      }, []);

      const popupParent = useMemo(() => {
        return document.body;
      }, []);

    const getRowStyle = () => {
        return { display: "flex", justifyContent: "center", alignItems: "center" };
    };

    

    return (
        <div className="col-12">
        <div className="card">
            <div className="card-body">
                <div className="tab-content">
                    <div
                        className="tab-pane show active"
                        id="basic-datatable-preview"
                    >
                        <div id="basic-datatable-wrapper" className="ag-theme-material" style={{height: '100vh'}}>
                            <div className="row d-flex justify-content-between px-3">
                                <input 
                                    type="text"
                                    id="filter-text-box"
                                    placeholder="Type a keyword..." 
                                    aria-label="Type a keyword..." 
                                    className="gridjs-input gridjs-search-input mb-2" 
                                    onInput={onFilterTextBoxChanged}

                                />
                                <a 
                                    className="btn btn-info ms-2"
                                    onClick={onBtnExport}
                                    style={{width:'45px', height:'45px'}}
                                    
                                    >
                                    <i class="dripicons-download"></i>
                                </a>
                            </div>

                            <AgGridReact
                                ref={gridRef}
                                rowData={trialUsersData}
                                columnDefs={columnDefs}
                                pagination={true} 
                                paginationPageSize={100}
                                cacheQuickFilter={true}
                                suppressExcelExport={true}
                                popupParent={popupParent}
                                getRowStyle={getRowStyle}
                                enableCellTextSelection={true}
                            >
                             </AgGridReact>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}



const TrialUsers = () => {
    const [start,setStart] = useState(moment().format('YYYY-MM-DD'))
    const [end,setEnd] = useState(moment().format('YYYY-MM-DD'))

    const dispatch = useDispatch()

    const handleStart = (e) => {
        setStart(e.target.value)
    }
    const handleEnd = (e) => {
        setEnd(e.target.value)
    }
    const handleClear = () => {
        setStart(moment().format('YYYY-MM-DD'))
        setEnd(moment().format(', markedByUserIdYYYY-MM-DD'))
    }
    const handleSearch = () => {
        dispatch(getTrialUsers({start,end}))
    }

    useEffect(()=>{
        dispatch(getTrialUsers({start:'',end:''}))
        dispatch(getHealthCoach());
    },[dispatch])

    const data =  useSelector(state=>trialUsersSelector(state))
    const authDetails = useSelector((state) => authDetailsSeletor(state));
    const { name,userId} = authDetails;
    const unmarkedEventCount = useSelector((state)=>unmarkedEventCountSelector(state))

    const healthCoachList = useSelector((state) => healthCoachListSelector(state))

    
    return (
    <div>
        <Navbar pageTitle={"Today's Trial Users Bookings"}>
            <div className='row'>
                <div className="col-5"></div>
                <div className="col-7">
                    <form className="d-flex mb-3">
                        <div className="input-group mx-2">
                            <label
                                htmlFor="start"
                                className="col-3 mt-1"
                            >
                                Start : 
                            </label>
                            <input 
                                className="form-control" 
                                id="date" 
                                type="date" 
                                name="date"
                                value={start}
                                onChange={(e)=>{handleStart(e)}}
                                />
                        </div>
                        <div className="input-group">
                            <label
                                htmlFor="end"
                                className="col-3 mt-1"
                            >
                                End : 
                            </label>
                            <input 
                                className="form-control" 
                                id="end" 
                                type="date" 
                                name="date"
                                value={end}
                                onChange={(e)=>{handleEnd(e)}}
                                />
                        </div>
                        <a 
                            className="btn btn-primary ms-2"
                            onClick={()=>{handleSearch()}}
                            >
                            <i class="uil-search"></i>
                        </a>
                        <a 
                            className="btn btn-danger ms-2"
                            onClick={()=>{handleClear()}}
                        >
                            <i class="uil-multiply"></i>
                        </a>
                    </form>
                </div>

            </div>
                <Table
                    data={data}
                    currentName={name}
                    currentUserId={userId}
                    dispatch={dispatch}
                    unmarkedEventCount={unmarkedEventCount}
                    setUnmarkedEventCount={setUnmarkedEventCount}
                    healthCoach={healthCoachList}
                    start={start}
                    end={end}
                />
        </Navbar>
    </div>
  )
}

export default TrialUsers;