import { EXOTEL_LOGS_SET, EXOTEL_FILTERED_LOGS_SET, EXOTEL_FILTER_SET } from "../../actions/exotel";
const initState = []
export const exotelReducer = (state=initState,action) =>{
    switch(action.type){
        case EXOTEL_LOGS_SET:
            return {...state,exotelLogs:action.payload}
        case EXOTEL_FILTERED_LOGS_SET:
            return {...state, filteredLogs:action.payload}
        case EXOTEL_FILTER_SET:
            return {...state, filter:action.payload}
        default: return state
    }
}
