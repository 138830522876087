/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useState,useEffect} from 'react'
import { useSelector,useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import Navbar from '../navbar'
import { authDetailsSeletor, authSelector } from '../../selector/auth'
import { getEventInsights, getUserInsights, getPaymentInsights} from '../../actions/insights'
import { eventInsightsSelector, userInsightsSelector, paymentInsightsSelector } from '../../selector/insights'
import AllBookings from './AllBookings'
import MyBookings from './MyBookings'

import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment'
import MetricCard from './MetricCard'
import DailyAppSignupsChart from './DailyAppSignupsChart'
import ExpandedDonut from './ExpandedDonut'
import PurchaseTable from './PurchaseTable'
import ActivityTable from './ActivityTable'


const Home = () =>{
    const dispatch = useDispatch()
    const history = useHistory()
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "/assets/js/dashboardAnalytics.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, [dispatch]);

    const isAuthenticated = useSelector(state=>authSelector(state))
    const authRole = useSelector(state=>authDetailsSeletor(state)).roleName

    useEffect(()=>{
        if(isAuthenticated==='pending'){
            history.push("/login")
        }
    },[isAuthenticated,history])

    const [startDate, setStartDate] = useState(moment())
    const [endDate, setEndDate] = useState(moment());
    const [focusedInput, setFocusedInput] = useState();
    
    const acqUsersPerDayKeys = []
    const acqUsersPerDayValues = []
    
    useEffect(()=>{
        dispatch(getUserInsights({start: startDate ? moment(startDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),end: endDate ? moment(endDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")}))
        dispatch(getEventInsights({start: startDate ? moment(startDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),end: endDate ? moment(endDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")}))
        dispatch(getPaymentInsights({start: startDate ? moment(startDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),end: endDate ? moment(endDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")}))
    },[dispatch, startDate, endDate])

    const userInsights = useSelector(state=>userInsightsSelector(state))
    const eventInsights = useSelector(state=>eventInsightsSelector(state));
    const paymentInsights = useSelector(state=>paymentInsightsSelector(state))
    //console.table(userInsights);

    const totalEventBookings = eventInsights.totalData ? eventInsights.totalData.totalExpertConsultationBooking + eventInsights.totalData.totalFitnessSessionBooking + eventInsights.totalData.totalNutritionConsultBooking : 0;
    const monthToDateEventBookings = eventInsights.monthToDateData ? eventInsights.monthToDateData.totalExpertConsultationBooking + eventInsights.monthToDateData.totalFitnessSessionBooking + eventInsights.monthToDateData.totalNutritionConsultBooking : 0;

    const totalHCCalls = eventInsights.totalData ? eventInsights.totalData.eventDistribution ? eventInsights.totalData.eventDistribution.reduce((total, item) => {
        if(item.offeringTypeId === 'OFRT010')
        {
            return total+Number(item.totalBookings);
        }
        return total;
    }, 0) : 0 : 0;

    const monthToDateHCCalls = eventInsights.monthToDateData ? eventInsights.monthToDateData.eventDistribution ? eventInsights.monthToDateData.eventDistribution.reduce((total, item) => {
        if(item.offeringTypeId === 'OFRT010')
        {
            return total+Number(item.totalBookings);
        }
        return total;
    }, 0) : 0 : 0;

    if(startDate.format("DD-MM-YYYY") === moment().format("DD-MM-YYYY") && endDate.format("DD-MM-YYYY") === moment().format("DD-MM-YYYY") && userInsights.latestSignups)
    {
        userInsights.latestSignups.forEach(item=>acqUsersPerDayKeys.push(Object.keys(item)))
        userInsights.latestSignups.forEach(item=>acqUsersPerDayValues.push(Object.values(item)[0]))
    }
    else if(userInsights.totalData && userInsights.totalData.numOfUsersPerDay){
        userInsights.totalData.numOfUsersPerDay.forEach(item=>acqUsersPerDayKeys.push(Object.keys(item)))
        userInsights.totalData.numOfUsersPerDay.forEach(item=>acqUsersPerDayValues.push(Object.values(item)[0]))
    }

    const fitnessBookingLegends = [];
    const fitnessBookingData = [];

    if(eventInsights.totalData && eventInsights.totalData.eventDistribution)
    {
        eventInsights.totalData.eventDistribution.sort((a, b) => b.totalBookings - a.totalBookings)
        eventInsights.totalData.eventDistribution.forEach(({offeringTypeId, offeringTypeName, totalBookings}) => {
            if(offeringTypeId !== 'OFRT006' && offeringTypeId !== 'OFRT007' && offeringTypeId !== 'OFRT009' && offeringTypeId !== 'OFRT010')
            {
                fitnessBookingLegends.push(offeringTypeName);
                fitnessBookingData.push(totalBookings)
            }
        })
    }

    const nutritionConsultsData = [];
    const nutritionConsultsLegend = [];

    const expertConsultsData = [];
    const expertConsultsLegend = [];

    const hcConsultsData = [];
    const hcConsultsLegend = [];

    if(eventInsights.totalData && eventInsights.totalData.expertDistribution)
    {
        eventInsights.totalData.expertDistribution.sort((a, b) => b.totalBookings - a.totalBookings)
        eventInsights.totalData.expertDistribution.forEach(({roleId, name, totalBookings}) => {
            if(roleId === "ROLE0005")
            {
                nutritionConsultsData.push(totalBookings);
                nutritionConsultsLegend.push(name);
            }
            else if(roleId === "ROLE0006")
            {
                expertConsultsData.push(totalBookings)
                expertConsultsLegend.push(name);
            }
            else if(roleId === "ROLE0003")
            {
                hcConsultsData.push(totalBookings)
                hcConsultsLegend.push(name)
            }
        })
    }

    const revenueByPlanData = [];
    const revenueByPlanLegend = [];

    let revenueByPlanMap = new Map();
    let revenueByGatewayMap = new Map();

    const revenueByGatewayData = [];
    const revenueByGatewayLegend = [];

    if(paymentInsights.totalData)
    {
        if(paymentInsights.totalData.paymentsPlan)
        {
            paymentInsights.totalData.paymentsPlan.forEach(({planName, amount}) => {
                if(revenueByPlanMap.has(planName))
                {
                    revenueByPlanMap.set(planName, revenueByPlanMap.get(planName) + amount)
                }
                else{
                    revenueByPlanMap.set(planName, parseInt(amount))
                }
            });

            revenueByPlanMap = new Map([...revenueByPlanMap.entries()].sort((a, b) => b[1] - a[1]));

            revenueByPlanMap.forEach((value, key) => {
                revenueByPlanData.push(value);
                revenueByPlanLegend.push(key);
            })
        }
        if(paymentInsights.totalData.paymentsGateway)
        {
            paymentInsights.totalData.paymentsGateway.forEach(({paymentGateway, amount}) => {
                if(revenueByGatewayMap.has(paymentGateway))
                {
                    revenueByGatewayMap.set(paymentGateway, revenueByGatewayMap.get(paymentGateway) + parseInt(amount))
                }
                else{
                    revenueByGatewayMap.set(paymentGateway, parseInt(amount))
                }
            })

            revenueByGatewayMap = new Map([...revenueByGatewayMap.entries()].sort((a, b) => b[1] - a[1]));

            revenueByGatewayMap.forEach((value, key) => {
                revenueByGatewayData.push(value);
                revenueByGatewayLegend.push(key);
            })
        }
    }
                
    return (
        <Navbar>
        
            {/* date picker row */}
            <div className='row'>
                <div className="col-md-8 col-12">
                    <h3 className="page-title page-title-text">
                        Home
                    </h3>
                </div>
                <div className="col-md-4 col-12 d-flex justify-content-end">
                    <DateRangePicker
                        startDate={startDate}
                        startDateId="start-date"
                        endDate={endDate}
                        endDateId="end-date"
                        onDatesChange={({ startDate, endDate }) => {
                            setStartDate(startDate);
                            setEndDate(endDate);
                            }}
                        focusedInput={focusedInput}
                        onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                        isOutsideRange={() => false}
                        displayFormat="DD-MM-YYYY"
                        minimumNights={0}
                    />
                </div>
            </div>
            
            {/* Metric card row */}
            <div className='row mt-3'>
                {/* <div className="col-4">
                    <MetricCard
                        cardTitle="Revenue"
                        cardValue={`₹ ${paymentInsights.totalData ? paymentInsights.totalData.totalAmount ? paymentInsights.totalData.totalAmount.toString().replace(/(\d)(?=(\d\d)+\d$)/g, "$1,") : 0 : 0}`}
                        cardIcon={'mdi mdi-currency-inr'}
                        cardValue2={`₹ ${paymentInsights.monthToDateData ? paymentInsights.monthToDateData.totalAmount ? paymentInsights.monthToDateData.totalAmount.toString().replace(/(\d)(?=(\d\d)+\d$)/g, "$1,") : 0 : 0}`}
                    />
                </div> */}
                <div className="col-md-6 col-12">
                    <MetricCard
                        cardTitle="App Signups"
                        cardValue={userInsights.totalData ? userInsights.totalData.numOfUsers ? userInsights.totalData.numOfUsers : 0 : 0}
                        cardIcon={'mdi mdi-download '}
                        cardValue2={userInsights.monthToDateData ? userInsights.monthToDateData.numOfUsers ? userInsights.monthToDateData.numOfUsers : 0 : 0}
                    />
                </div>
                <div className="col-md-6 col-12">
                    <MetricCard
                        cardTitle="Event Bookings"
                        cardValue={totalEventBookings ? totalEventBookings : 0}
                        cardIcon={'uil-calender'}
                        cardValue2={monthToDateEventBookings ? monthToDateEventBookings : 0}
                    />
                </div>
            </div>
            
            {/* signups and revenue split row */}
            <div className='row'>
                <div className="col-12">
                    <DailyAppSignupsChart
                        title="Daily App Signups"
                        xaxis={acqUsersPerDayKeys}
                        yaxis={acqUsersPerDayValues}
                    />
                </div>
                {/* <div className="col-3">
                    <ExpandedDonut
                        title={"Revenue By Plan"}
                        data={revenueByPlanData}
                        legend={revenueByPlanLegend}
                    />
                </div>
                <div className="col-3">
                    <ExpandedDonut
                        title={"Revenue By Gateway"}
                        data={revenueByGatewayData}
                        legend={revenueByGatewayLegend}
                    />
                </div> */}

            </div>
            
            {/* purchases and recent activity row */}
            {/* <div className='row'>
                <div className="col-8">
                    <PurchaseTable
                        data={paymentInsights.latestTransactions ? paymentInsights.latestTransactions : []}
                    />
                </div>
                <div className="col-4">
                    <ActivityTable/>
                </div>
            </div> */}

            <br />

            {/* events mertic card row */}
            <div className='row'>
                <div className="col-md-3 col-12">
                    <MetricCard
                        cardTitle="Fitness Bookings"
                        cardValue={eventInsights.totalData ? eventInsights.totalData.totalFitnessSessionBooking ? eventInsights.totalData.totalFitnessSessionBooking : 0 : 0}
                        cardIcon={'mdi mdi-run'}
                        cardValue2={eventInsights.monthToDateData ? eventInsights.monthToDateData.totalFitnessSessionBooking ? eventInsights.monthToDateData.totalFitnessSessionBooking : 0 : 0}
                    />
                </div>
                <div className="col-md-3 col-12">
                    <MetricCard
                            cardTitle="Nutrition Consults"
                            cardValue={eventInsights.totalData ? eventInsights.totalData.totalNutritionConsultBooking ? eventInsights.totalData.totalNutritionConsultBooking : 0 : 0}
                            cardIcon={'mdi mdi-food-variant'}
                            cardValue2={eventInsights.monthToDateData ? eventInsights.monthToDateData.totalNutritionConsultBooking ? eventInsights.monthToDateData.totalNutritionConsultBooking : 0 : 0}
                    />
                </div>
                <div className="col-md-3 col-12">
                    <MetricCard
                        cardTitle="Expert Consults"
                        cardValue={eventInsights.totalData ? eventInsights.totalData.totalExpertConsultationBooking ? eventInsights.totalData.totalExpertConsultationBooking : 0 : 0}
                        cardIcon={'mdi mdi-doctor'}
                        cardValue2={eventInsights.monthToDateData ? eventInsights.monthToDateData.totalExpertConsultationBooking ? eventInsights.monthToDateData.totalExpertConsultationBooking : 0 : 0}
                    />
                </div>
                <div className="col-md-3 col-12">
                    <MetricCard
                            cardTitle="HC Call"
                            cardValue={totalHCCalls}
                            cardIcon={'uil-phone-alt'}
                            cardValue2={monthToDateHCCalls}
                        />
                </div>
            </div>
            
            {/* event mertic breakdow row */}
            <div className='row'>
                <div className="col-md-3 col-12">
                    <ExpandedDonut
                        title={"Fitness Bookings"}
                        data={fitnessBookingData}
                        legend={fitnessBookingLegends}
                    />
                </div>
                <div className="col-md-3 col-12">
                    <ExpandedDonut
                        title={"Nutrition Consults"}
                        data={nutritionConsultsData}
                        legend={nutritionConsultsLegend}
                    />
                </div>
                <div className="col-md-3 col-12">
                    <ExpandedDonut
                        title={"Expert Consults"}
                        data={expertConsultsData}
                        legend={expertConsultsLegend}
                    />
                </div>
                <div className="col-md-3 col-12">
                    <ExpandedDonut
                        title={"HC Call"}
                        data={hcConsultsData}
                        legend={hcConsultsLegend}
                    />
                </div>
            </div>

            {/* all bookings table row */}
            <div className='row'>
                <div className="col-12">
                    <div className="row">
                        <div className="col-12">
                            {authRole==='Admin' || authRole ==='Operations'?<AllBookings startDate={startDate} endDate={endDate}/>:<MyBookings/>}
                        </div>
                    </div>
                </div>
            </div>
                
        </Navbar>
        
    )
}


export default Home
