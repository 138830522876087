import { ORDERS_SET, ORDER_SET } from "../../actions/orders";

const initState = []
export const ordersReducer = (state=initState,action) =>{
    switch(action.type){
        case ORDERS_SET:
            return {...state,orders:action.payload}
        case ORDER_SET:
            return {...state,order:action.payload}
        default: return state
    }
}

