import { apiRequest,API_SUCCESS,API_ERROR } from "../../../actions/api"
import { EXPERTS, EXPERT, EXPERT_STATUS, EXPERT_STATUS_UPDATE, EXPERT_CREATE, EXPERT_DETAILS_GET, EXPERT_UPDATE, EXPERTS_GET, SPEC, SPEC_GET, setAllExpert, getAllExpert, setSpec, setExpertDetails } from "../../../actions/expert";
import {ROOT_URL} from '../../../config/';
import Alert from "sweetalert2";

export const expertMiddleware = ({dispatch}) => next => (action) => {
    next(action)

    switch(action.type)
    {
        case EXPERTS_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/experts`,
                method:'get',
                feature: EXPERTS
            }))
            break;
        
        case EXPERT_CREATE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/createExpert`,
                method:'post',
                feature: EXPERT
            }))
            break;
        
        case EXPERT_UPDATE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/updateExpert`,
                method:'put',
                feature: EXPERT
            }))
            break;

        case EXPERT_STATUS_UPDATE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/updateExpertStatus`,
                method:'put',
                feature: EXPERT_STATUS
            }))
            break;

        case EXPERT_DETAILS_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/expert`,
                method:'get',
                feature: EXPERT
            }))
            break;

        case SPEC_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/specialisation`,
                method:'get',
                feature: SPEC
            }))
            break;
        
        case `${EXPERT} ${API_SUCCESS}`:
            if(action.message === 'Expert Successfully Updated' || action.message === 'Expert Successfully Created')
            {
                Alert.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: action.message,
                    showConfirmButton: false,
                    timer: 1500
                  })
            }
            else{
                dispatch(getAllExpert())
                dispatch(setExpertDetails(action.payload))
            }
            break;

        case `${EXPERT} ${API_ERROR}`:
            Alert.fire({
                position: 'top-end',
                icon: 'error',
                title: action.message,
                showConfirmButton: false,
                timer: 1500
              })
            break;

        case `${EXPERTS} ${API_SUCCESS}`:
            dispatch(setAllExpert(action.payload));
            break;

        case `${EXPERTS} ${API_ERROR}`:
            console.log("Could not get expert list")
            break;

        case `${EXPERT_STATUS} ${API_SUCCESS}`:
            dispatch(getAllExpert());
            break;

        case `${EXPERT_STATUS} ${API_ERROR}`:
            console.log("Could not update expert status")
            break;

        case `${SPEC} ${API_SUCCESS}`:
            dispatch(setSpec(action.payload));
            break;

        case `${SPEC} ${API_ERROR}`:
            console.log("Could not get specialisation list")
            break;
        default:break;
    }
}