export const PROVIDER = `[PROVIDER]`

export const PROVIDER_GET = `${PROVIDER} GET`
export const PROVIDER_SET = `${PROVIDER} SET`

export const getProvider = () => ({
    type: PROVIDER_GET,
    payload:{}
})

export const setProvider = (data) =>({
    type: PROVIDER_SET,
    payload: data
})
