/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef, useCallback, useMemo} from "react";
import { useHistory } from "react-router";
import Navbar from "../navbar";
import {DateRangePicker} from "react-dates";
import { getPaymentsPlan, setPaymentsPlanDetails  } from "../../actions/paymentsPlan";
import { useDispatch, useSelector } from "react-redux";
import { paymentsPlanSelector } from "../../selector/paymentsPlan";
import moment from "moment";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';


const Action = (props) => {
    const history = useHistory();
    const payment = props.data.action;

    return (
        <div className="row" style={{width:'100%'}}>
                <div className="col-12">
                    <div className="card border" >
                        <div className="card-body">
                            <div className="text-start row">
                                <h4 className="header-title col">
                                    {payment.name}
                                </h4>
                                <h4 className="col d-flex justify-content-end">
                                    {`₹ ${payment.amount.toString().replace(/(\d)(?=(\d\d)+\d$)/g, "$1,")}`}
                                </h4>
                            </div>
                            <div className="row d-flex justify-content-left"  style={{width:'100%'}}>
                                <div className="col-3 mx-2">
                                    <i
                                        className="uil-phone"
                                        style={{fontStyle: "normal"}}
                                    >
                                        &nbsp;{payment.callingNumber ? payment.callingNumber : <span style={{color:'#98A6AD'}}>Not Available</span>}
                                    </i>
                                </div>
                                <div className="col-3 mx-2">
                                    <i
                                        className="uil-envelope"
                                        style={{fontStyle: "normal"}}
                                    >
                                        &nbsp;&nbsp;{payment.email ? payment.email : <span style={{color:'#98A6AD'}}>Not Available</span>}
                                    </i>
                                </div>
                                <div className="col-3 mx-2">
                                    <i
                                        className="uil-shopping-cart-alt"
                                        style={{fontStyle: "normal"}}
                                    >
                                        &nbsp;&nbsp;{payment.planId ? payment.planId : <span style={{color:'#98A6AD'}}>Not Available</span>} | {payment.planName ? payment.planName : <span style={{color:'#98A6AD'}}>Not Available</span>}
                                    </i>
                                </div>
                            </div>
                            <div  className="row d-flex justify-content-left"  style={{width:'100%'}}>
                                <div className="col-2 mx-2">
                                    <i
                                        className="uil-tag-alt"
                                        style={{fontStyle: "normal"}}
                                    >
                                        &nbsp;{payment.promoCode ? payment.promoCode : <span style={{color:'#98A6AD'}}>No Promo Code</span>}
                                    </i>
                                </div>
                                <div className="col-2 mx-2">
                                    <i
                                        className="uil-link"
                                        style={{fontStyle: "normal"}}
                                    >
                                        &nbsp;{payment.paymentGateway ? payment.paymentGateway : <span style={{color:'#98A6AD'}}>Not Available</span>}
                                    </i>
                                </div>
                                <div className="col-2 mx-2">
                                    <i
                                        className="uil-clock"
                                        style={{fontStyle: "normal"}}
                                    >
                                        &nbsp;{payment.paymentTimeStamp ? moment(payment.paymentTimeStamp).format('DD-MM-YYYY hh:mma') : <span style={{color:'#98A6AD'}}>Not Available</span>}
                                    </i>
                                </div>
                                <div className="col-2 mx-2">
                                    <i
                                        className="uil-user"
                                        style={{fontStyle: "normal"}}
                                    >
                                        &nbsp;{payment.assignedTo ? payment.assignedTo : <span style={{color:'#98A6AD'}}>No Lead Assigned</span>}
                                    </i>
                                </div>
                                <div className="col-2 mx-2">
                                    <i
                                        className="uil-edit"
                                        style={{fontStyle: "normal", cursor: "pointer"}}
                                        onClick={() => history.push(`/createPaymentRecord/${payment.orderId}`)}
                                    >
                                    <span className="text-danger">&nbsp; Change</span>
                                    </i>
                                </div>
                            
                            </div>
                            <div  className="row d-flex justify-content-left"  style={{width:'100%'}}>
                                <div className="col-3 mx-2">
                                    UTM Source - {payment.utmSource ? payment.utmSource : <span style={{color:'#98A6AD'}}>Not Available</span>}
                                </div>
                                <div className="col-3 mx-2">
                                    UTM Medium - {payment.utmMedium ? payment.utmMedium : <span style={{color:'#98A6AD'}}>Not Available</span>}
                                </div>
                                <div className="col-3 mx-2">
                                    UTM Campaign - {payment.utmCampaign ? payment.utmCampaign : <span style={{color:'#98A6AD'}}>Not Available</span>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

const PaymentsPlan = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    const [focusedInput, setFocusedInput] = useState();

    useEffect(() => {
        dispatch(getPaymentsPlan({start: moment(startDate).format('YYYY-MM-DD'), end: moment(endDate).format('YYYY-MM-DD')}))
    }, [dispatch, startDate, endDate])

    const paymentsPlan = useSelector((state) => paymentsPlanSelector(state))
    console.log(paymentsPlan)

    const data = [];
    let totalSales = 0;
    let totalTransactions = 0;
    let pendingTransactions = 0;
    let failedTransactions = 0; 
    let muzaiyenTransactions = 0;
    let deepaliTransactions = 0;
    let aakritiTransactions = 0;
    let marketingTransactions = 0;

    if(paymentsPlan)
    {
        paymentsPlan.forEach((item) => {
            if(item.status.toLowerCase() === 'success')
            {
                data.push({
                    orderId: item.orderId,
                    planId: item.planId,
                    userId: item.userId,
                    amount: `₹ ${item.amount.toString().replace(/(\d)(?=(\d\d)+\d$)/g, "$1,")}`,
                    promoCode: item.promoCode,
                    paymentGateway: item.paymentGateway,
                    paymentTimeStamp: moment(item.paymentTimeStamp).format('DD-MM-YYYY HH:mma'),
                    action: item
                })

                totalSales = totalSales + parseInt(item.amount);
            }
            else if(item.status.toLowerCase() === 'pending')
            {
                pendingTransactions = pendingTransactions + 1
            }
            else{
                failedTransactions = failedTransactions + 1
            }

            if(item.assignedTo.toLowerCase() === 'muzaiyen')
            {
                muzaiyenTransactions = muzaiyenTransactions + parseInt(item.amount);
            }
            else if(item.assignedTo.toLowerCase() === 'deepali mehra')
            {
                deepaliTransactions = deepaliTransactions + parseInt(item.amount);
            }
            else if(item.assignedTo.toLowerCase() === 'aakriti mahaur')
            {
                aakritiTransactions = aakritiTransactions + parseInt(item.amount);
            }
            if(item.assignedTo.toLowerCase() === 'marketing')
            {
                marketingTransactions = marketingTransactions + parseInt(item.amount);
            }

            totalTransactions = totalTransactions + 1
        })
    }
    

    const [columnDefs] = useState([
        {headerName:"", field:"action", cellRenderer:Action, resizable:true, sortable:true, autoHeight: true, cellClassRules:'no-border-cell'},
        {headerName:"Order Id", field:"orderId",  resizable:true, sortable:true, filter:true, width:0, suppressNavigable: true, cellClass: 'no-border', hide: true},
        {headerName:"Plan Id", field:"planId", resizable:true, sortable:true, filter:true, width:0, suppressNavigable: true, cellClass: 'no-border', hide: true},
        {headerName:"User Id", field:"userId", resizable:true, sortable:true, filter:true, width:0, suppressNavigable: true, cellClass: 'no-border', hide: true},
        {headerName:"Amount", field:"amount",resizable:true, sortable:true, filter:true, width:0, suppressNavigable: true, cellClass: 'no-border', hide: true},
        {headerName:"Promo Code", field:"promoCode", resizable:true, sortable:true, filter:true, width:0, suppressNavigable: true, cellClass: 'no-border', hide: true},
        {headerName:"Payment Gateway", field:"paymentGateway", width:0, suppressNavigable: true, cellClass: 'no-border', hide: true},
        {headerName:"Time", field:"paymentTimeStamp", resizable:true, sortable:true, filter:true, width:0, suppressNavigable: true, cellClass: 'no-border', hide: true},
    ])

    const gridRef = useRef();

    // const onFilterTextBoxChanged = useCallback(() => {
    //     gridRef.current.api.setQuickFilter(
    //         document.getElementById('filter-text-box').value
    //     );
    // }, []);
    
    // const onPrintQuickFilterTexts = useCallback(() => {
    //     gridRef.current.api.forEachNode(function (rowNode, index) {
    //     });
    // }, []);

    const onBtnExport = useCallback(() => {
        const exportCols = {
            columnKeys: ['paymentId','orderId', 'planId', 'userId', 'amount', 'promoCode', 'paymentGateway', 'paymentTimeStamp'],
        };
        gridRef.current.api.exportDataAsCsv(exportCols);
    }, []);

    const popupParent = useMemo(() => {
        return document.body;
    }, []);

    const getRowStyle = params => {
        return { fontFamily: "Cerebri Sans", fontSize: "0.9rem", border: "none"};
    };

    function onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
      }

    return (
        <Navbar>
        <div className="row mb-3">
                <div className="col-8">
                    <h3 className="page-title page-title-text">
                        Payments Tracker
                    </h3>
                </div>
                <div className="col-4 d-flex justify-content-end">
                    <DateRangePicker
                        startDate={startDate}
                        startDateId="start-date"
                        endDate={endDate}
                        endDateId="end-date"
                        onDatesChange={({ startDate, endDate }) => {
                            setStartDate(startDate);
                            setEndDate(endDate);
                        }}
                        focusedInput={focusedInput}
                        onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                        displayFormat="DD/MM/YYYY"
                        isOutsideRange={() => false}
                        minimumNights={0}
                        pagination={true} 
                        paginationPageSize={20}
                    />
                </div>
            </div>

            <div className="row d-flex justify-content-between">
                <div className="card border rounded col-2" style={{width: "20%"}}>
                    <div className="card-body">
                        <h4 className="header-title mb-3">Total Sales</h4>
                        <h3>
                            {`₹ ${totalSales.toString().replace(/(\d)(?=(\d\d)+\d$)/g, "$1,")}`}
                        </h3>
                    </div>
                </div>
                <div className="card border rounded col-2" style={{width: "20%"}}>
                    <div className="card-body">
                        <h4 className="header-title mb-3">Total Transactions</h4>
                        <h3>
                            {totalTransactions}
                        </h3>
                    </div>
                </div>
                <div className="card border rounded col-2" style={{width: "20%"}}>
                    <div className="card-body">
                        <h4 className="header-title mb-3">Pending Transactions</h4>
                        <h3>
                            {pendingTransactions}
                        </h3>
                    </div>
                </div>
                <div className="card border rounded col-2" style={{width: "20%"}}>
                    <div className="card-body">
                        <h4 className="header-title mb-3">Failed Transactions</h4>
                        <h3>
                            {failedTransactions}
                        </h3>
                    </div>
                </div>
            </div>

            <div className="card border ">
                <div className="card-body ">
                    <div className="row d-flex justify-content-between">
                        <div className="col-3 p-2">
                            <h4 className="header-title mb-3">Muzaiyen</h4>
                            <h3>
                                {`₹ ${muzaiyenTransactions.toString().replace(/(\d)(?=(\d\d)+\d$)/g, "$1,")}`}
                            </h3>
                        </div>
                        <div className="col-3 p-2">
                            <h4 className="header-title mb-3">Deepali</h4>
                            <h3>
                                {`₹ ${deepaliTransactions.toString().replace(/(\d)(?=(\d\d)+\d$)/g, "$1,")}`}
                            </h3>
                        </div>
                        <div className="col-3 p-2">
                            <h4 className="header-title mb-3">Aakriti</h4>
                            <h3>
                                {`₹ ${aakritiTransactions.toString().replace(/(\d)(?=(\d\d)+\d$)/g, "$1,")}`}
                            </h3>
                        </div>
                        <div className="col-3 p-2">
                            <h4 className="header-title mb-3">Marketing</h4>
                            <h3>
                                {`₹ ${marketingTransactions.toString().replace(/(\d)(?=(\d\d)+\d$)/g, "$1,")}`}
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="tab-content">
                            <div
                                className="tab-pane show active"
                                id="basic-datatable-preview"
                            >

                                <div id="basic-datatable-wrapper" className="ag-theme-material" style={{height: '100vh'}}>
                                    <div className="col d-flex justify-content-end px-3">
                                        <div>
                                            <button 
                                                className="btn btn-success"
                                                style={{width:'200px', height:'45px'}}
                                                onClick={() => {
                                                    dispatch(setPaymentsPlanDetails({}))
                                                    history.push('/createPaymentRecord')
                                                }}
                                            > 
                                                    Create Payment Record
                                            </button>
                                            <a 
                                                className="btn btn-info ms-2"
                                                onClick={onBtnExport}
                                                style={{width:'45px', height:'45px'}}
                                                
                                                >
                                                <i class="dripicons-download"></i>
                                            </a>
                                        </div>
                                        
                                    </div>

                                    <AgGridReact
                                        ref={gridRef}
                                        rowData={data}
                                        columnDefs={columnDefs}
                                        pagination={true} 
                                        paginationPageSize={20}
                                        cacheQuickFilter={true}
                                        suppressExcelExport={true}
                                        popupParent={popupParent}
                                        enableCellTextSelection={true}
                                        suppressCellSelection={true}
                                        getRowStyle={getRowStyle}
                                        onFirstDataRendered={onFirstDataRendered}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </Navbar>
    )
}

export default PaymentsPlan;