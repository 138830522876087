import { CHALLENGE_THUMBNAIL_PRESIGNED_URL_SET, CHALLENGE_HERO_PRESIGNED_URL_SET, CHALLENGE_LANDING_PRESIGNED_URL_SET } from "../../actions/challenges"
import { PROFILE_PRESIGNED_URL_SET } from "../../actions/profilePhotoUpload"
import { TASK_THUMBNAIL_PRESIGNED_URL_SET } from "../../actions/tasks"
import { LAB_REPORT_PRESIGNED_URL_SET, LAB_TEST_THUMBNAIL_PRESIGNED_URL_SET } from "../../actions/labTests"
import { MEAL_TEMPLATE_PRESIGNED_URL_SET } from "../../actions/meals"
import { CONTENT_PRESIGNED_URL_SET } from '../../actions/contentImageUpload';
import { DOCTOR_PROFILE_PRESIGNED_URL_SET } from "../../actions/doctorFacingTech"


const initState = []
export const presignedUrlsReducer = (state=initState,action) =>{
    switch(action.type){
        case PROFILE_PRESIGNED_URL_SET:
            return {...state,profilePhoto:action.payload}
        case TASK_THUMBNAIL_PRESIGNED_URL_SET:
            return {...state,taskThumbnail:action.payload}
        case CHALLENGE_HERO_PRESIGNED_URL_SET:
            return {...state,challengeHero:action.payload}
        case CHALLENGE_THUMBNAIL_PRESIGNED_URL_SET:
            return {...state,challengeThumbnail:action.payload}
        case CHALLENGE_LANDING_PRESIGNED_URL_SET:
            return {...state,challengeLanding:action.payload}
        case LAB_REPORT_PRESIGNED_URL_SET:
            return {...state, labReportUrl: action.payload}
        case MEAL_TEMPLATE_PRESIGNED_URL_SET:
            return {...state,mealTemplate:{...action.payload}}
        case LAB_TEST_THUMBNAIL_PRESIGNED_URL_SET:
            return {...state,labTestThumbnail:action.payload}
        case CONTENT_PRESIGNED_URL_SET:
            return {...state,contentImage:action.payload}
            case DOCTOR_PROFILE_PRESIGNED_URL_SET:
                return {...state,doctorProfilePresignedUrl:action.payload}
        default: return state
    }
}