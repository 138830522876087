import {
    COMMENT_GET,
    COMMENT_SET,
    COMMENT_SET_ERROR,
    } from "../../actions/comments"

const initState = []
export const commentsReducer = (state=initState,action) =>{
    switch(action.type){
        case COMMENT_GET:
            return {data: null, fetching: true, error: null}
        case COMMENT_SET:
            return {data: action.payload, fetching: false, error: null}
        case COMMENT_SET_ERROR:
            return {data: null, fetching: false, error: action.payload}
        default: return state
    }
}

