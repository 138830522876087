export const USER_SYMPTOM = `[USER_SYMPTOM]`

export const USER_SYMPTOM_GET = `${USER_SYMPTOM} GET`
export const USER_SYMPTOM_SET = `${USER_SYMPTOM} SET`

export const getUserSymptom = (data) =>({
    type:USER_SYMPTOM_GET,
    payload:data
})

export const setUserSymptom = (data) =>({
    type:USER_SYMPTOM_SET,
    payload:data
})
