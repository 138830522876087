import React from "react";

const BasicInfoForm = ({
    setBasicInfoActive,
    setOtherInfoActive,
    setMedicalInfoActive,

    email,
    setEmail,
    firstName,
    setFirstName,
    middleName,
    setMiddleName,
    lastName,
    setLastName,
    countryCode,
    setCountryCode,
    callingNumber,
    setCallingNumber,
    whatsappNumber,
    setWhatsappNumber,
    age,
    setAge
}) => {
    return (
        <div>
            <div className="row">
                <div className="col-12">
                    <form className="form-horizontal">
                        <input
                            autocomplete="false"
                            name="hidden"
                            type="text"
                            style={{ display: "none" }}
                        />
                        <div className="row mb-4">
                            <label
                                htmlFor="simpleInput1"
                                className="col-2 col-form-label"
                            >
                                First Name
                            </label>
                            <div className="col-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={firstName}
                                    onChange={(e) => {
                                        setFirstName(e.target.value);
                                    }}
                                    id="simpleInput1"
                                    placeholder="First Name"
                                    autoComplete="off"
                                />
                            </div>
                        </div>

                        {/* <div className="row mb-4">
                            <label
                                htmlFor="simpleInput2"
                                className="col-2 col-form-label"
                            >
                                Middle Name
                            </label>
                            <div className="col-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={middleName}
                                    onChange={(e) => {
                                        setMiddleName(e.target.value);
                                    }}
                                    id="simpleInput2"
                                    placeholder="Middle Name"
                                    autoComplete="off"
                                />
                            </div>
                        </div> */}

                        <div className="row mb-4">
                            <label
                                htmlFor="simpleInput3"
                                className="col-2 col-form-label"
                            >
                                Last Name
                            </label>
                            <div className="col-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={lastName}
                                    onChange={(e) => {
                                        setLastName(e.target.value);
                                    }}
                                    id="simpleInput1"
                                    placeholder="Last Name"
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <label
                                htmlFor="simpleInput3"
                                className="col-2 col-form-label"
                            >
                                Email
                            </label>
                            <div className="col-10">
                                <input
                                    type="email"
                                    className="form-control"
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                    id="simpleInput1"
                                    placeholder="Email"
                                    autoComplete="off"
                                />
                            </div>
                        </div>

                        <div className="row mb-4">
                            <label
                                htmlFor="simpleInput4"
                                className="col-2 col-form-label"
                            >
                                Calling Number
                            </label>
                            <div className="col-10">
                                <div className="row">
                                    <div className="col-2">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={countryCode}
                                            onChange={(e) => {
                                                setCountryCode(e.target.value);
                                            }}
                                            id="simpleInput4"
                                            placeholder="Country Code"
                                            autoComplete="off"
                                        /> 
                                    </div>
                                    <div className="col-10">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={callingNumber}
                                            onChange={(e) => {
                                                setCallingNumber(e.target.value);
                                            }}
                                            id="simpleInput4"
                                            placeholder="Calling Number"
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <label
                                htmlFor="simpleInput3"
                                className="col-2 col-form-label"
                            >
                                WhatsappNumber
                            </label>
                            <div className="col-10">
                                <input
                                    type="email"
                                    className="form-control"
                                    value={whatsappNumber}
                                    onChange={(e) => {
                                        setWhatsappNumber(e.target.value);
                                    }}
                                    id="simpleInput1"
                                    placeholder="Whatsapp Number"
                                    autoComplete="off"
                                />
                            </div>
                        </div>

                        <div className="row mb-4">
                            <label
                                htmlFor="simpleInput3"
                                className="col-2 col-form-label"
                            >
                                Age
                            </label>
                            <div className="col-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={age}
                                    onChange={(e) => {
                                        setAge(e.target.value);
                                    }}
                                    id="simpleInput1"
                                    placeholder="Current Age"
                                    autoComplete="off"
                                />
                            </div>
                        </div>

                        <div className="justify-content-center d-flex row mt-5">
                            <div className="col-12 d-flex justify-content-between mx-3">
                                <div>&nbsp;</div>
                                <button
                                    type="button"
                                    className="btn"
                                    style={{ backgroundColor: "#373750" }}
                                    onClick={() => {
                                        setBasicInfoActive();
                                        setOtherInfoActive("active");
                                        setMedicalInfoActive("");
                                    }}
                                >
                                    <span style={{ color: "#FFF" }}>
                                        Next{" "}
                                        <i className="mdi mdi-arrow-right"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default BasicInfoForm;
