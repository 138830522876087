import { LAB_TESTS_CONFIG_SET, LAB_TESTS_SET, LAB_TEST_SKUS_SET} from "../../actions/labTests";

const initState = []
export const labTestsReducer = (state=initState,action) =>{
    switch(action.type){
        case LAB_TESTS_SET:
            return {...state,labTests:action.payload}
        case LAB_TESTS_CONFIG_SET:
            return {...state,labTestsConfig:action.payload}
        case LAB_TEST_SKUS_SET:
            return {...state,labTestSKUs:action.payload}
        default: return state
    }
}


