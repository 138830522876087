
import React from 'react';
import 'chart.js/auto';
import { Line} from 'react-chartjs-2';

const DailyAppSignupsChart = ({title,xaxis,yaxis}) => {
    const options = {
        plugins: {
          legend: false,
          datalabels: {
              display: true,
              align: 'center',
              anchor: 'center'
           },
           title: {
            display: true,
            align: 'center',
            text: title,
            color:'#6C757D'
        }
        },
        elements:{
            line:{
              borderJoinStyle:'bevel',
              'borderWidth':'2' 
            },
            point:{
                radius: 1
            }
        },
        scales: {
          x: {
            grid: {
              display: false,
            }
          },
        },
        bezierCurve : true,
        maintainAspectRatio: false       
      };
      
    const data = {
        labels: xaxis,
        datasets: [
            {
            label: "App Signup",
            data: [...yaxis],
            borderColor: 'rgba(245, 115, 90, 0.9)',
            backgroundColor: 'rgba(245, 115, 90, 0.9)',
            tension:0.3,      
            },
            {
            label: "App Signup",
            data: [...yaxis],
            fill: true,
            backgroundColor: 'rgba(55, 55, 80, 1)',
            type:'bar'
            },
        ],
      };
  return(
     <div className="col-12 p-3" style={{backgroundColor:'white'}}>
        <Line 
            data={data} 
            options={options}
            height={400}
            width={null}
            />
     </div> 
  )}
;

export default DailyAppSignupsChart;