import { ALL_BOOKINGS_SET, EVENT_INSIGHTS_SET,MY_BOOKINGS_SET,USER_INSIGHTS_SET, PAYMENT_INSIGHTS_SET } from "../../actions/insights";
const initState = []
export const insightsReducer = (state=initState,action) =>{
    switch(action.type){
        case EVENT_INSIGHTS_SET:
            return {...state,eventInsights:action.payload}
        case USER_INSIGHTS_SET:
            return {...state,userInsights:action.payload}
        case ALL_BOOKINGS_SET:
            return {...state,allBookings:action.payload}
        case MY_BOOKINGS_SET:
            return {...state,myBookings:action.payload}
        case PAYMENT_INSIGHTS_SET:
            return {...state, paymentInsights: action.payload}
        default: return state
    }
}

