import { VOUCHERS_SET, VOUCHER_SET } from "../../../actions/promoCodes"

const initState = []
export const vouchersReducer = (state=initState,action) =>{
    switch(action.type){
        case VOUCHERS_SET:
            return {...state,vouchers:action.payload}
        case VOUCHER_SET:
            return {...state,voucherDetails:action.payload}
        default: return state
    }
}