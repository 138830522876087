/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Navbar from '../navbar'
import {useDispatch, useSelector} from 'react-redux'
import { initiatePayment } from '../../actions/payments'
import { paymentsSelector } from '../../selector/payments'
import axios from 'axios'
import { RAZORPAY_KEY_ID,ROOT_URL } from '../../config'

const InitiatePaymentButton = ({gateway}) => {

    const optionsObj = useSelector(state=>paymentsSelector(state))
    
    const razorpayHandler = () => {
        const options = {
            key:RAZORPAY_KEY_ID,
            name:`razorpay payment`,
            description:'payement description',
            order_id:optionsObj.id,
            handler: async (resp) =>{
                try{
                    const url = `${ROOT_URL}/capturePlanPayment`
                    await axios.post(
                        url,
                        {
                            paymentId:resp.razorpay_payment_id,
                            orderId:optionsObj.id,
                            signature:resp.razorpay_signature
                        },{withCredentials:true,credentials:'include'})
                }catch(err){
                }
            },
            theme:{color:'#00955A'}
        }
        const razor = new window.Razorpay(options)
        razor.open()
    }

    const paytmHandler = () => {
        
        const isDate = (val) => {
            return Object.prototype.toString.call(val) === "[object Date]";
        };

        const isObj = (val) => {
            return typeof val === "object";
        };

        const stringifyValue = (val) => {
            if (isObj(val) && !isDate(val)) {
              return JSON.stringify(val);
            } else {
              return val;
            }
        };

        const buildForm = ({ action, params }) => {
            const form = document.createElement("form");
            form.setAttribute("method", "post");
            form.setAttribute("action", action);
    
            Object.keys(params).forEach((key) => {
              const input = document.createElement("input");
              input.setAttribute("type", "hidden");
              input.setAttribute("name", key);
              input.setAttribute("value", stringifyValue(params[key]));
              form.appendChild(input);
            });
    
            return form;
        };

        const post = (information) => {
            const form = buildForm(information);
            document.body.appendChild(form);
            form.submit();
            form.remove();
        };

        const paymentInformation = {
            action: "https://securegw-stage.paytm.in/order/process",
            params: {...optionsObj}
        }
        post(paymentInformation)
    }

    const simplHandler = () => {
        window.location.assign(optionsObj)
    }

    const handleInititatePayment = (gateway) => {
        switch (gateway) {
            case 'razorpay':
                razorpayHandler()
                break;
            case 'paytm':
                paytmHandler()
                break;
            case 'simpl':
                simplHandler()
                break;
            default: break;
        }
    }
    

    return (
        <button 
            className="btn btn-success mt-3"
            onClick={()=>{handleInititatePayment(gateway)}}
            >
            Initiate Payment
        </button>
    )

}

const GetOptionsObjectButton = ({paymentObj,gateway}) => {
    const dispatch = useDispatch()
    const getOptionsObject = () => {
        switch (gateway) {
            case 'razorpay':
                dispatch(initiatePayment({
                    ...paymentObj, gateway:'razorpay'
                }))
                break;
            case 'paytm':
                dispatch(initiatePayment({
                    ...paymentObj, gateway:'paytm'
                }))
                break;
            case 'simpl':
                dispatch(initiatePayment({
                    ...paymentObj, gateway:'simpl'
                }))
                break;
            default: break;
        }
    }
    const handleGetOptionsObj = () => {
        getOptionsObject()
    }
    return (
        <button 
            className="btn btn-info mt-3"
            onClick={()=>{handleGetOptionsObj()}}
                >
            Get Options Object
        </button>
    )

}

const PaymentsCard = ({image,title, gateway}) => {
    const paymentObj = {
        callingNumber:'919901631898',
        whatsappNumber:'919901631898',
        email:'shamssahal@gmail.com',
        name:'Sahal Shams',
        currency:'INR',
        promoCode:'TESTSKU',
        promoTyoe:'Coupon',
        dynamicPricingId:'',
        age:'26',
        dob:'2022-01-01',
        description:'',
        address:'abc',
        city:'abc',
        state:'abc',
        pincode:'560102',
        utm_source:'Admin',
        utm_medium:'Admin',
        utm_campaign:'Admin',
        utm_data:'Admin',
        orderItems:{
            // 'SKU000028':
            //     {
            //         items:['PLAN004'],
            //         category: 'Plan',
            //         quantity:1,
            //         skuName:'PCOS Care Plan 12 - Months'
            //     },
            // 'SKU000012':
            //     {
            //         items:['ABNRQRJR1N',],
            //         category: 'Consultation',
            //         quantity:1,
            //         skuName:'Expert Consultation with Garima Sharma'
            //     },
            'SKU000061':
                {
                    items: ['2022-10-01 23:00:00',],
                    category:'Lab Test',
                    quantity:1,
                    skuName:"Women's Health - Comprehensive - Test"
                },    
    }
}


    return (
        <div className="col-md-6 col-lg-3 mx-3" >
            <div className="card p-3">
                <img src={image} className="card-img-top" alt="..." height="60" width="200"/>
                <div className="card-body">
                    <h5 className="card-title">{title}</h5>
                    <GetOptionsObjectButton
                        paymentObj={paymentObj}
                        gateway={gateway}
                    />
                   <InitiatePaymentButton
                       gateway={gateway}
                   />
                </div> 
            </div> 
        </div> 
    )
}

const Payments = () => {
  return (
    <Navbar pasgeTitle="Payments Testing">
        <div className="d-flex mx-3">
            <PaymentsCard
                image="https://upload.wikimedia.org/wikipedia/en/thumb/8/89/Razorpay_logo.svg/1896px-Razorpay_logo.svg.png"
                title="Razorpay"
                gateway="razorpay"

            />
            <PaymentsCard
                image="https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Paytm_Logo_%28standalone%29.svg/2560px-Paytm_Logo_%28standalone%29.svg.png"
                title="Paytm"
                gateway="paytm"

            />
            <PaymentsCard
                image="https://upload.wikimedia.org/wikipedia/commons/f/fb/Simpl_Logo.png"
                title="Simpl"
                gateway="simpl"

            />
        </div>
    </Navbar>
  )
}

export default Payments