import {
    TOPIC_CONTENT_MAP_GET,
    TOPIC_CONTENT_MAP_SET,
    TOPIC_CONTENT_MAP_ERROR,

    TOPIC_CONTENT_ADD_REQ,
    TOPIC_CONTENT_ADD_SET,
    TTOPIC_CONTENT_ADD_ERROR,

    TOPIC_CONTENT_REMOVE_REQ,
    TOPIC_CONTENT_REMOVE_SET,
    TOPIC_CONTENT_REMOVE_ERROR,

    } from "../../actions/topicsContentMap"

const initState = []
export const toicpsContentMapReducer = (state=initState,action) =>{
    switch(action.type){
        
        //Reducer for content category
        case TOPIC_CONTENT_REMOVE_REQ:
        case TOPIC_CONTENT_MAP_GET:
        case TOPIC_CONTENT_ADD_REQ:
            const ccg_newData = {fetching: true, data: null}
            return ccg_newData;
        case TOPIC_CONTENT_MAP_SET:
        case TOPIC_CONTENT_ADD_SET:
        case TOPIC_CONTENT_REMOVE_SET:
            const ccs_newData = {fetching: false, data: action.payload}
            return ccs_newData
        case TOPIC_CONTENT_MAP_ERROR:
        case TTOPIC_CONTENT_ADD_ERROR:
        case TOPIC_CONTENT_REMOVE_ERROR:
            const cce_newData = {fetching: false, data: action.payload, error: true}
            return cce_newData
        default: return state
    }
}

