import React,{useEffect} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import Navbar from '../../../navbar'
import { Grid, _ } from "gridjs-react";
import { activateCoupons, deactivateCoupons, getCoupons } from '../../../../actions/promoCodes';
import {couponsSelector} from '../../../../selector/promoCodes/coupons'
import moment from 'moment';
import { useHistory } from 'react-router-dom';


const Table = () => {
    const history = useHistory();
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(getCoupons())
    },[dispatch])
    const coupons = useSelector((state)=>couponsSelector(state))
    
    const handleActivate = (couponId) =>{
        dispatch(activateCoupons({couponId}))
        
    }
    
    const handleDeactivate = (couponId) =>{
        dispatch(deactivateCoupons({couponId}))
        
    }
    const data = coupons?coupons.map((coupon)=>[
        coupon.couponId,
        coupon.offerName,
        coupon.type,
        coupon.type==='Discount'?`${coupon.discount}%`:_(<span>&#8377; {coupon.discount}</span>),
        moment(coupon.startDate).format('YYYY-MM-DD hh:mm A'),
        moment(coupon.endDate).format('YYYY-MM-DD hh:mm A'),
        coupon.totalUsage,
        coupon.maxUsage,
        coupon.status===0?_(
            <div className="row d-flex justify-content-left">
                <button
                    className="col-10 btn btn-success btn-rounded"
                    onClick={()=>{handleActivate(coupon.couponId)}}
                    style={{marginLeft:'-30px'}}
                >
                    Activate 
                </button>  
                <div 
                    className="col-2"
                    onClick={()=>{
                        history.push(`/updateCoupon/${coupon.couponId}`)
                    }}
                >
                    <i className="mdi mdi-pencil text-warning font-20"></i>
                </div> 
            </div>
        ):_(
            <div className="row d-flex justify-content-left">
                <button
                    className="col-10 btn btn-danger btn-rounded"
                    onClick={()=>{handleDeactivate(coupon.couponId)}}
                    style={{marginLeft:'-30px'}}
                >
                    Deactivate
                </button>  
                <div 
                    className="col-2"
                    onClick={()=>{
                        history.push(`/updateCoupon/${coupon.couponId}`)
                    }}
                >
                    <i className="mdi mdi-pencil text-warning font-20"></i>
                </div> 
            </div>
        )

    ]):[]
    return (
        <div className="col-12">
        <div className="card">
            <div className="card-body">
                <div className="tab-content">
                    <div
                        className="tab-pane show active"
                        id="basic-datatable-preview"
                    >
                        <div id="basic-datatable-wrapper">
                            <Grid
                                data={data}
                                columns={[
                                    "Coupon Code",
                                    "Offer Name",
                                    "Type",
                                    "Discount",
                                    "Starts On",
                                    "Ends On",
                                    "Total Usage",
                                    "Max Usage",
                                    "Action"
                                ]}
                                search={true}
                                pagination={{
                                    enabled: true,
                                    limit: 20,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}


const Coupons = () => {
    return (
        <div>
            <Navbar pageTitle={"Coupons"}>
                <Table/>
            </Navbar>
        </div>
    )
}

export default Coupons