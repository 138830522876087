export const LAB_TESTS = `[LAB_TESTS]`
export const LAB_TESTS_CONFIG = `[LAB_TESTS_CONFIG]`
export const LAB_TEST_SKU = `[LAB_TEST_SKU]`
export const LAB_TEST_SKUS = `[LAB_TEST_SKUS]`

export const LAB_TEST_SKU_CREATE = `[LAB_TEST_SKU_CREATE]`
export const LAB_TEST_SKU_UPDATE = `[LAB_TEST_SKU_UPDATE]`
export const LAB_TEST_SKU_DELETE = `[LAB_TEST_SKU_DELETE]`

export const LAB_TEST_SKUS_GET = `[LAB_TEST_SKUS_GET]`
export const LAB_TEST_SKUS_SET = `[LAB_TEST_SKUS_SET]`

export const LAB_TEST_SKU_GET = `[LAB_TEST_SKU_GET]`
export const LAB_TEST_SKU_SET = `[LAB_TEST_SKU_SET]`

export const LAB_TESTS_GET = `${LAB_TESTS} GET`
export const LAB_TESTS_SET = `${LAB_TESTS} SET`

export const LAB_TESTS_CONFIG_GET = `${LAB_TESTS_CONFIG} GET`
export const LAB_TESTS_CONFIG_SET = `${LAB_TESTS_CONFIG} SET`


export const LAB_REPORT_PRESIGNED_URL = `[LAB_REPORT_PRESIGNED_URL]`;
export const LAB_REPORT_PRESIGNED_URL_GET = `${LAB_REPORT_PRESIGNED_URL} GET`;
export const LAB_REPORT_PRESIGNED_URL_SET = `${LAB_REPORT_PRESIGNED_URL} SET`;

export const LAB_TEST_THUMBNAIL_PRESIGNED_URL = `[LAB_TEST_THUMBNAIL_PRESIGNED_URL]`;
export const LAB_TEST_THUMBNAIL_PRESIGNED_URL_GET = `${LAB_TEST_THUMBNAIL_PRESIGNED_URL} GET`;
export const LAB_TEST_THUMBNAIL_PRESIGNED_URL_SET = `${LAB_TEST_THUMBNAIL_PRESIGNED_URL} SET`;

export const LAB_REPORT_URL = `[LAB_REPORT_URL]`;
export const LAB_REPORT_URL_UPDATE = `${LAB_REPORT_URL} UPDATE`;

export const getAllLabTests = (data) => ({
    type:LAB_TESTS_GET,
    payload:data  
})

export const setAllLabTests = (data) => ({
    type:LAB_TESTS_SET,
    payload:data  
});

export const getLabReportPresignedUrl = (data) => ({
    type: LAB_REPORT_PRESIGNED_URL_GET,
    payload: data
})

export const setLabReportPresignedUrl = (data) => ({
    type: LAB_REPORT_PRESIGNED_URL_SET,
    payload: data
})

export const getLabTestThumbnailPresignedUrl = (data) => ({
    type: LAB_TEST_THUMBNAIL_PRESIGNED_URL_GET,
    payload: data
})

export const setLabTestThumbnailPresignedUrl = (data) => ({
    type: LAB_TEST_THUMBNAIL_PRESIGNED_URL_SET,
    payload: data
})


export const updateLabReportUrl = (data) => ({
    type: LAB_REPORT_URL_UPDATE,
    payload: data
})

export const getLabTestsConfig = () => ({
    type: LAB_TESTS_CONFIG_GET,
    payload: {}
})

export const setLabTestsConfig = (data) => ({
    type: LAB_TESTS_CONFIG_SET,
    payload: data
})

export const createLabTestSKU = (data) => ({
    type: LAB_TEST_SKU_CREATE,
    payload: data
})

export const updateLabTestSKU = (data) => ({
    type: LAB_TEST_SKU_UPDATE,
    payload: data
})

export const getAllLabTestSKUs = () => ({
    type: LAB_TEST_SKUS_GET,
    payload: {}
})

export const setAllLabTestSKUs = (data) => ({
    type: LAB_TEST_SKUS_SET,
    payload: data
})

export const getLabTestSKU = (data) => ({
    type: LAB_TEST_SKU_GET,
    payload: data
})

export const setLabTestSKU = (data) => ({
    type: LAB_TEST_SKU_SET,
    payload: data
})

export const deleteLabTestSKU = (data) => ({
    type: LAB_TEST_SKU_DELETE,
    payload: data
})

