export const WEBINAR = `[WEBINAR]`;
export const WEBINARS = `[WEBINARS]`;
export const WEBINAR_USERS = '[WEBINAR_USERS]'

export const WEBINARS_GET = `${WEBINARS} GET`
export const WEBINAR_GET = `${WEBINAR} GET`
export const WEBINAR_UPDATE = `${WEBINAR} UPDATE`
export const WEBINARS_SET = `${WEBINARS} SET`
export const WEBINAR_USERS_GET = `${WEBINAR_USERS} GET`
export const WEBINAR_USERS_SET = `${WEBINAR_USERS} SET`
export const WEBINAR_CREATE = `${WEBINAR} CREATE`
export const WEBINAR_SEND_BURST = `${WEBINAR} SEND_BURST`


export const getWebinars = () => ({
    type: WEBINARS_GET,
     payload:{}
})

export const updateWebinar = (data) => ({
    type: WEBINAR_UPDATE,
     payload:data
})

export const setWebinars = (data) => ({
    type: WEBINARS_SET,
    payload:data
});

export const getWebinarUsers = (data) => ({
    type: WEBINAR_USERS_GET,
    payload: data
})

export const setWebinarUsers = (data) => ({
    type: WEBINAR_USERS_SET,
    payload: data
})

export const createWebinar = (data) => ({
    type: WEBINAR_CREATE,
    payload: data
})

export const sendWebinarBurst  = (data) => ({
    type: WEBINAR_SEND_BURST,
    payload: data
})