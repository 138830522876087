/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect,useState} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import Navbar from '../../navbar'
import { Grid, _ } from "gridjs-react";
import { getExotelLogs, setExotelFilteredLogs, setFilter } from '../../../actions/exotel';
import { exotelLogsSelector, filteredLogsSelector, filterSelector } from '../../../selector/exotel';
import moment from 'moment';
import ExotelAnalyticsBoard from './ExotelAnalyticsBoard';
import { healthCoachList } from './hcList';

const Table = ({logs}) => {
    const data = logs?logs.map((log)=>[
        moment(log.dateCreated).subtract(330,'minutes').format('DD-MM-YYYY HH:mm:ss'),
        log.toNumber,
        log.fromNumber,
        log.hcName.name,
        _(log.status==='completed'?
        <span className={`badge bg-success`}>completed</span> 
        :<span className={`badge bg-danger`}>failed</span>),
        log.direction,
        _(
            <audio controls>
                <source src={log.audioRec} type="audio/mpeg"/>
                    Your browser does not support the audio element.
            </audio>
        )
    ]):[]
    return(
        <div className="col-12">
            <div className="card">
                <div className="card-body">
                    <div className="tab-content">
                        <div
                            className="tab-pane show active"
                            id="basic-datatable-preview"
                        >
                            <div id="basic-datatable-wrapper">
                                <Grid
                                    data={data}
                                    columns={[
                                        "Timestamp",
                                        "To",
                                        "From",
                                        "HC Name",
                                        "Status",
                                        "Direction",
                                        "Audio Recording"
                                    ]}
                                    search={true}
                                    pagination={{
                                        enabled: true,
                                        limit: 20,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ExotelLogs = () => {

    const filter = useSelector((state) => filterSelector(state));

    const [start,setStart] = useState(moment().format('YYYY-MM-DD'))
    const [end,setEnd] = useState(moment().format('YYYY-MM-DD'))

    const [filterBy, setFilterBy] = useState(filter?filter.filterBy:'')
    const [healthCoach, setHealthCoach] = useState(filter?filter.filterValue:'');

    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(getExotelLogs())
    },[dispatch])

    const logs = useSelector(state=>exotelLogsSelector(state))
    const filteredLogs = useSelector((state) => filteredLogsSelector(state))

    const handleStart = (e) => {
        setStart(e.target.value)
    } 
    const handleEnd = (e) => {
        setEnd(e.target.value)
    }
    const handleClear = () => {
        dispatch(getExotelLogs())
        setStart(moment().format('YYYY-MM-DD'))
        setEnd(moment().format('YYYY-MM-DD'))
    }
    const handleSearch = () => {
        dispatch(getExotelLogs({start,end}))
    }

    const handleCoachName = (coachName) => {
        setHealthCoach(coachName)
        dispatch(setExotelFilteredLogs(logs.filter((log) => log.hcName.name === coachName)))
        dispatch(setFilter({filterBy: filterBy, filterValue: coachName}))
    }

    const renderFilterValue = () => {
        const hcMap = healthCoachList();
        const hcNames = Array.from(hcMap.values());

        if(filterBy === 'Health Coach Name')
        {
            return (
                <select
                    className="form-select"
                    id="healthCoach"
                    value={healthCoach}
                    onChange={e=>{handleCoachName(e.target.value)}}
                >
                    <option value="" disabled selected>Select Health Coach Name</option>
                    {
                        hcNames.map((value) => (
                            <option key={value.name} >{value.name}</option>
                        ))
                    }
                </select>
            )
        }
        else{
            return null;
        }
    }
    return (
        <div>
            <Navbar>
                <div className="page-title-box">
                    <div className="page-title-right">
                        <form className="d-flex">
                            <div className="input-group mx-2">
                                <label
                                    htmlFor="start"
                                    className="col-3 mt-1"
                                >
                                    Start : 
                                </label>
                                <input 
                                    className="form-control" 
                                    id="date" 
                                    type="date" 
                                    name="date"
                                    value={start}
                                    onChange={(e)=>{handleStart(e)}}
                                    />
                            </div>
                            <div className="input-group">
                                <label
                                    htmlFor="end"
                                    className="col-3 mt-1"
                                >
                                    End : 
                                </label>
                                <input 
                                    className="form-control" 
                                    id="end" 
                                    type="date" 
                                    name="date"
                                    value={end}
                                    onChange={(e)=>{handleEnd(e)}}
                                    />
                            </div>
                            <a 
                                className="btn btn-primary ms-2"
                                onClick={(e)=>{handleSearch(e)}}
                            >
                                <i class="uil-search"></i>
                            </a>
                            <a 
                                className="btn btn-danger ms-2"
                                onClick={(e)=>{handleClear(e)}}
                            >
                                <i class="uil-multiply"></i>
                            </a>
                        </form>
                    </div>
                    <h3 className="page-title">Exotel Logs</h3>
                </div>
                <ExotelAnalyticsBoard/>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="text-start">
                                    <div className="row mb-1">
                                        <div className="col-10">
                                            <p className="header-title mt-0 mb-3">
                                                Apply Filter
                                            </p>
                                        </div>
                                        <div className="col-2">
                                            <button 
                                            className="btn btn-danger w-100"
                                            onClick={()=>{
                                                setFilterBy('')
                                                setHealthCoach('')
                                                dispatch(setExotelFilteredLogs(logs))
                                                //dispatch(setFilter({}))
                                            }}
                                            >
                                                Clear All
                                            </button> 
                                        </div>                                
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className="row">
                                                <div className="col-3">
                                                    <label
                                                        htmlFor="filterBy"
                                                        className="col-form-label"
                                                    >
                                                        Filter By:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                <select
                                                        className="form-select"
                                                        id="led-by"
                                                        value={filterBy}
                                                        onChange={e=>setFilterBy(e.target.value)}
                                                    >
                                                        <option value="" disabled selected>Filter Logs By</option>
                                                        <option>Health Coach Name</option>
                                                    </select>
                                                </div>                                            
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="row">
                                                <div className="col-3">
                                                    <label
                                                        htmlFor="filterBy"
                                                        className="col-form-label"
                                                    >
                                                        Filter Value:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    {renderFilterValue()}
                                                </div>                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Table
                    logs={healthCoach ? filteredLogs : logs}
                />
            </Navbar>
        </div>
    )
}

export default ExotelLogs
