import { apiRequest,API_SUCCESS,API_ERROR } from "../../../actions/api"
import {ROOT_URL} from '../../../config/'
import Alert from "sweetalert2";

import { PAYMENT, PAYMENT_INITIATE, setPayment } from "../../../actions/payments";

export const paymentsMiddleware = ({dispatch}) => next => (action) =>{
    next(action)
    switch(action.type){
        case PAYMENT_INITIATE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/initiatePlanPayment`,
                method:'post',
                feature:PAYMENT
            }))
            break;
        
        case `${PAYMENT} ${API_SUCCESS}`:
            dispatch(setPayment(action.payload))
            Alert.fire({
                position: 'top-end',
                icon: 'success',
                title: action.message,
                showConfirmButton: false,
                timer: 1500
            })

            break;

        case `${PAYMENT} ${API_ERROR}`:
            Alert.fire({
                position: 'top-end',
                icon: 'error',
                title: action.message,
                showConfirmButton: false,
                timer: 1500
            })
            break;
        
        
        default: break;
    }
}
