import { PRIMARY_CONCERN_SET } from "../../actions/primaryConcern"
const initState = []
export const primaryConcernReducer = (state=initState,action) =>{
    switch(action.type){
        case PRIMARY_CONCERN_SET:
            return {...state,primaryConcerns:action.payload}
        default: return state
    }
}

