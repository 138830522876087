/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

const PatientRow = (
    {
        firstName,
        lastName,
        callingNumber,
        whatsappNumber,
        age,
        height, 
        weight,
    }) => {
    return (
        <tr>
            <td>
                <p
                    className="m-0 d-inline-block align-middle font-16"
                >
                <a
                    href="apps-ecommerce-products-details.html"
                    className="text-body"
                >
                    {`${firstName} ${lastName}`}
                </a>
                <br />
                </p>
            </td>
            <td>{callingNumber}</td>
            <td>{whatsappNumber}</td>
            <td>{age}</td>
            <td>{height}</td>
            <td>{weight}</td>
        </tr> 
    )
}

export default PatientRow
