export const exotelLogsSelector = state => state.exotelReducer.exotelLogs?state.exotelReducer.exotelLogs.logData:[]
export const filteredLogsSelector = state => state.exotelReducer.filteredLogs?state.exotelReducer.filteredLogs:[]
export const filterSelector = state => state.exotelReducer.filter?state.exotelReducer.filter:{}

export const exotelTotalCallsSelector = state => state.exotelReducer.exotelLogs?state.exotelReducer.exotelLogs.totalCalls:0
export const exotelCompletedCallsSelector = state => state.exotelReducer.exotelLogs?state.exotelReducer.exotelLogs.completedCalls:0
export const exotelOneMinCallSelector = state => state.exotelReducer.exotelLogs?state.exotelReducer.exotelLogs.oneMinCall:0
export const exotelSumOneMinCallSelector = state => state.exotelReducer.exotelLogs?state.exotelReducer.exotelLogs.sumOneMinCall:0

export const hcTotalCallsSelector = state => state.exotelReducer.exotelLogs?state.exotelReducer.exotelLogs.hcTotalCalls:[]
export const hcCompletedCallsSelector = state => state.exotelReducer.exotelLogs?state.exotelReducer.exotelLogs.hcCompletedCalls:[]
export const hcOneMinCallSelector = state => state.exotelReducer.exotelLogs?state.exotelReducer.exotelLogs.hcOneMinCall:[]
export const hcSumOneMinCallSelector = state => state.exotelReducer.exotelLogs?state.exotelReducer.exotelLogs.hcSumOneMinCall:[]

