import { apiRequest,API_SUCCESS,API_ERROR } from "../../../actions/api"
import {
    getAllEvents, 
    EVENT, 
    EVENT_CREATE, 
    EVENTS, 
    EVENTS_GET, 
    EVENT_RESCHEDULE,
    setAllEvents, 
    RESCHEDULE, 
    EVENT_DELETE, 
    EVENT_GET, 
    setEventDetails, 
    EVENT_EDIT, 
    EVENT_MARK, 
    EVENT_UNMARK, 
    EVENTS_CALENDAR_GET, 
    EVENTS_CALENDAR,
    setAllCalendarEvents,
} from "../../../actions/event"
import {ROOT_URL} from '../../../config/'
import Alert from "sweetalert2";
import { getTrialUsers } from "../../../actions/users";

export const eventMiddleware = ({dispatch}) => next => (action) =>{
    next(action)
    switch(action.type){
        case EVENT_CREATE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/event`,
                method:'post',
                feature:EVENT
            }))
            break;
        case EVENT_EDIT:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/event`,
                method:'put',
                feature:EVENT
            }))
            break;
        
        case EVENT_MARK:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/markTrialBooking`,
                method:'put',
                feature:EVENT
            }))
            
            break;
        case EVENT_UNMARK:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/unmarkTrialBooking`,
                method:'put',
                feature:EVENT
            }))
            break;
        
        case `${EVENT} ${API_SUCCESS}`:
            if(
                action.message==='Event Created Successfully' || 
                action.message === 'Event Deleted' ||
                action.message === 'Event Edited' ||
                action.message === 'Marked' ||
                action.message === 'Unmarked'
                ){
                Alert.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: action.message,
                    showConfirmButton: false,
                    timer: 1500
                  }).then(()=>{
                    dispatch(getAllEvents({startDate:null,endDate:null}))
                  })
                
            }
            else{
                dispatch(setEventDetails(action.payload))
            }

            if(action.message === 'Marked' || action.message === 'Unmarked')
            {
                dispatch(getTrialUsers({start: action.payload.start, end: action.payload.end}))
            }
            break;
        case `${EVENT} ${API_ERROR}`:
            Alert.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Event Could Not Be Created',
                showConfirmButton: false,
                timer: 1500
              })
            break;
        
        case EVENTS_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/events`,
                method:`get`,
                feature:EVENTS
            }))
            break;
        case `${EVENTS} ${API_SUCCESS}`:
            dispatch(setAllEvents(action.payload))
            break;
        case `${EVENTS} ${API_ERROR}`:
            break;
        
        case `${EVENT_RESCHEDULE}`:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/reschedule`,
                method:'put',
                feature:RESCHEDULE
            }))
            break;
        case `${RESCHEDULE} ${API_SUCCESS}`:
            Alert.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Event Rescheduled Successfully',
                showConfirmButton: false,
                timer: 1500
              })
            break;
        case `${RESCHEDULE} ${API_ERROR}`:
            Alert.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Event could not be rescheduled',
                showConfirmButton: false,
                timer: 1500
              })
            break;
        case EVENT_DELETE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/event`,
                method:'delete',
                feature:EVENT
            }))
            break;
        case EVENT_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/event`,
                method:'get',
                feature:EVENT
            }))
            break;
        
        case EVENTS_CALENDAR_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/eventsCalendar`,
                method:`get`,
                feature:EVENTS_CALENDAR
            }))
            break;
        case `${EVENTS_CALENDAR} ${API_SUCCESS}`:
                dispatch(setAllCalendarEvents(action.payload))
                break;
        case `${EVENTS_CALENDAR} ${API_ERROR}`:
                break;
            
        default: break;
    }
}
