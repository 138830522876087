/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useState,useEffect} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Navbar from '../navbar'
import axios from 'axios'
import Alert from "sweetalert2";
import moment from 'moment'
import Multiselect from 'multiselect-react-dropdown';
import { planSelector } from '../../selector/plan'
import { getPlans } from '../../actions/plan'
import { allTasksSelector, cTasksSelector } from '../../selector/tasks'
import { alterChallengeTask, getAllTasks } from '../../actions/tasks'
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';
import _ from "lodash";
import { createNewChallenge, getChallengeLandingPresignedUrl, getChallengeThumbnailPresignedUrl, getChallengeDetails, updateChallenege, getChallengeHeroPresignedUrl } from '../../actions/challenges'
import { challengeThumbnailPresignedUrlSelector, challengeHeroPresignedUrlSelector, challengeLandingPresignedUrlSelector } from '../../selector/presignedUrlSelector'
import { challengeDetailsSelector } from '../../selector/challenges/index'
import ChallengeLanding from './ChallengeLanding'

const Task = ({
    taskId,
    taskThumbnail,
    taskTitle,
    taskReward,
    taskObj,
    challengeDayTasks,
    index,
    setModal
}) => {
    const dispatch = useDispatch()
    const newTaskList = _.cloneDeep(challengeDayTasks)
    return (
        <div class="row mx-n1 g-0 w-100">
            <div className="col-12">
                <div 
                    className="card m-1 shadow-none border"
                    onClick={()=>{
                        if(taskObj!==null){
                            newTaskList[index] = [...challengeDayTasks[index], taskObj]
                            dispatch(alterChallengeTask(newTaskList))
                            setModal(false)
                        }}
                        }
                    >
                    <div className="p-1">
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <div className="avatar-sm">
                                    <span className="avatar-title bg-white text-secondary rounded">
                                        <img
                                            src={taskThumbnail}
                                            alt="thumbnail"
                                            style={{height:'48px',width:'48px'}}
                                        />
                                    </span>
                                </div>
                            </div>
                            <div className="col ps-0">
                                <a href="#" className="text-muted fw-bold">{taskTitle}</a>
                                <p className="mb-0 font-13">🌕 x {taskReward}</p>
                            </div>
                        </div> 
                    </div> 
                </div> 
            </div>
        </div>

    )
}

const TaskListModal = ({modal,setModal,challengeDayTasks,index}) => {
    const dispatch = useDispatch()

    const [searchTask, setSearchTask] = useState("");
    
    useEffect(()=>{
        dispatch(getAllTasks())
    },[dispatch, searchTask])

    let allTasks = useSelector(state=>allTasksSelector(state))
    
    if(searchTask !== "" && allTasks)
    {
        allTasks = allTasks.filter((task) => {
            if(task.taskTitle.toLowerCase().indexOf(searchTask.toLowerCase()) >= 0)
            {
                return true;
            }
            return false;
        })
    }

    return(
        <PureModal
            header="All Tasks List"
            footer={false}
            width="800px"
            isOpen={modal}
            scrollable={true}

            onClose={() => {
                setModal(false);
                return true;
            }}
        >
            <div className="row">
                <div className="card">
                    <div calssName="card-body">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                                <div className="col-9 mb-3 mt-3">
                                <div className="input-group">
                                    <input 
                                        className="form-control border-end-0 border rounded-pill font-16"
                                        style={{textAlign:'center'}} 
                                        type="text" 
                                        id="example-search-input"
                                        placeholder='Search for a task'
                                        onChange={(e) => setSearchTask(e.target.value)}
                                        />
                                </div>
                                </div>      
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12"
                                style={{
                                    display:'grid',
                                    gridTemplateColumns:'repeat(auto-fill, minmax(240px, 1fr))',
                                    gridGap:'10px'
                                }}
                            >
                                {
                                    allTasks?allTasks.map((task)=>[
                                        <Task
                                            taskId={task.taskId}
                                            taskThumbnail={task.taskThumbnail}
                                            taskTitle={task.taskTitle}
                                            taskReward={task.taskReward}
                                            taskObj={task}
                                            challengeDayTasks={challengeDayTasks}
                                            index={index}
                                            setModal={setModal}
                                        />
                                    ]):[]
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        
        </PureModal>
    )
}

const AddTaskButton = ({challengeDayTasks,index}) => {
    const [modal, setModal] = useState(false);
    return(
        <>
            <button 
                type="button" 
                className="btn btn-outline-secondary btn-rounded font-12"
                // onClick={()=>{onTemplateSend(item.userId,item.fileName)}}
                onClick={() => setModal(true)} 
                style={{padding:'2px' ,width:'30%', height:'22px'}}
            >

            Add Task
            </button>
            <TaskListModal
                modal={modal}
                setModal={setModal}
                challengeDayTasks={challengeDayTasks}
                index={index}
            />
        </>
    )
}

const TaskTableRow = ({index,challengeDayTasks}) => {
    const dispatch = useDispatch()
    const dayTasks = challengeDayTasks[index]
    let dayTasksValues = Object.values(dayTasks)
    let totalReward = 0
    const newTaskList = _.cloneDeep(challengeDayTasks)
    const handleDelete = (taskId) => {
        let newDayTask = _.cloneDeep(newTaskList[index])
        newDayTask = (newDayTask.length !== 0)?newDayTask.filter((task) => task.taskId !== taskId):[]
        newTaskList[index] = newDayTask
        dispatch(alterChallengeTask(newTaskList))
    }

    return(
        <tr>
            <td>{`Day - ${index + 1}`}</td>
            <td>
                <div className="row d-flex justify-content-center mb-1">
                    {
                        (dayTasksValues.length !== 0)?dayTasksValues.map((item)=>{
                            totalReward+=parseInt(item.taskReward,10)
                            return(
                                <div className="d-flex justify-content-center">
                                    <Task
                                        taskId={item.taskId}
                                        taskTitle={item.taskTitle}
                                        taskThumbnail={item.taskThumbnail}
                                        taskReward={item.taskReward}
                                        taskObj={null}
                                    />
                                    <button 
                                        className="btn btn-rounded font-20"
                                        style={{ marginLeft: "1rem" }}
                                        onClick={()=>{handleDelete(item.taskId)}}
                                    >
                                        {" "}
                                        <i
                                            
                                            className="mdi mdi-delete text-danger"
                                        ></i>
                                    </button>
                                </div>

                            
                            )
                        }):""
                }
                </div>
                <div className="row d-flex justify-content-center">
                    <AddTaskButton
                        challengeDayTasks={challengeDayTasks}
                        index={index}
                    />
                </div>
            </td>
            <td>🌕 x {totalReward}</td>
        </tr>
    )
}

const TaskTable = () => {

    const cTasks = useSelector(state=>cTasksSelector(state))

    return(
        <div className="h-100">
            <div className="row mx-2">
                <div className="justify-content-between d-flex" style={{marginTop:'1.75rem'}}>
                    <h3 className="d-flex justify-content-center">Challenge Tasks</h3>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-lg-12">
                        <table className="table table-centered w-100 dt-responsive nowrap">
                            <thead className="table-light">
                                <tr>
                                    <th className="all">Challenge Day</th>
                                    <th>Assigned Tasks</th>
                                    <th>Total Reward</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                cTasks?cTasks.map((taskObj,index)=>{
                                    return(
                                        <TaskTableRow
                                            challengeDayTasks={cTasks}
                                            index={index}
                                        />
                                    )
                            }):[]}
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}


const CreateChallenge = (props) => {
    const dispatch = useDispatch()
    const history = useHistory()
    
    const challengeId = props.match.params.challengeId;

    const [challengeName,setChallengeName] = useState('')
    const [validateChallengeName,setValidateChallengeName] = useState(true)
    
    const [challengeCategory,setChallengeCategory] = useState('')
    const [challengeSubCategory,setChallengeSubcategory] = useState('')

    const [startDate,setStartDate] = useState('')
    const [validateStartDate,setValidateStartDate] = useState(true)

    const [endDate,setEndDate] = useState('')
    const [validateEndDate, setValidateEndDate] = useState(true)

    const [challengeThumbnail,setChallengeThumbnail] = useState('')
    const [challengeHero,setChallengeHero] = useState('')
    const [challengeThumbnailUploadUrl,setChallengeThumbnailUploadUrl] = useState('')
    const [challengeHeroUploadUrl,setChallengeHeroUploadUrl] = useState('')
    const [thumbnailSrc, setThumbnailSrc] = useState('/assets/images/users/avatar.png')
    const [heroSrc, setHeroSrc] = useState('/assets/images/users/avatar.png')

    const [challengeDescription, setChallengeDescription] = useState("")
    const [challengeWhatsappGroup, setChallengeWhatsappGroup] = useState("");

    const [applicablePlans,setApplicablePlans] = useState([])

    const [payToJoinPlans,setPayToJoinPlans] = useState([])
    const [validatePayToJoinPlans,setValidatePayToJoinPlans] = useState(true)
    
    const [freeToJoinPlans,setFreeToJoinPlans] = useState([])
    const [validateFreeToJoinPlans,setValidateFreeToJoinPlans] = useState(true)

    const [challengeCurrentCapacity, setChallengeCurrentCapacity] = useState(0);

    const [challengeLanding, setChallengeLanding] = useState([]);
    const [fileMap, setFileMap] = useState();

    
    let duration = 0;

    useEffect(()=>{
        if(challengeId)
        {
            dispatch(getChallengeDetails(challengeId));
        }
        else{
            setChallengeLanding([])
        }
    },[dispatch, challengeId])

    useEffect(() => {
        setChallengeName("");
        setChallengeCategory("");
        setStartDate("")
        setEndDate("")
        setChallengeThumbnailUploadUrl("")
        setChallengeHeroUploadUrl("")
        setChallengeSubcategory("")
        setFreeToJoinPlans([])
        setPayToJoinPlans([])
        setChallengeThumbnail('')
        setChallengeHero('')
        setChallengeDescription('')
        setChallengeWhatsappGroup('')
        setThumbnailSrc('/assets/images/users/avatar.png')
        setHeroSrc('/assets/images/users/avatar.png')
        setChallengeCurrentCapacity(0);
        dispatch(alterChallengeTask([]))
    }, [dispatch])

    let cTasks = useSelector(state=>cTasksSelector(state))

    const currentChallenge = useSelector(state=>challengeDetailsSelector(state))
    console.log(currentChallenge)

    useEffect(() => {
        if(challengeId && currentChallenge)
        {
            setChallengeName(currentChallenge.challengeName);
            setChallengeCategory(currentChallenge.challengeCategory);
            setStartDate(moment(currentChallenge.challengeStartDate).format("YYYY-MM-DD"))
            setEndDate(moment(currentChallenge.challengeEndDate).format("YYYY-MM-DD"))
            setChallengeThumbnailUploadUrl(currentChallenge.challengeThumbnail)
            setChallengeHeroUploadUrl(currentChallenge.challengeHero)
            setChallengeSubcategory(currentChallenge.challengeSubCategory)
            setThumbnailSrc(currentChallenge.challengeThumbnail)
            setHeroSrc(currentChallenge.challengeHero)
            setChallengeDescription(currentChallenge.challengeDescription)
            setChallengeWhatsappGroup(currentChallenge.challengeWhatsappGroup)
            setChallengeCurrentCapacity(currentChallenge.challengeCurrentCapacity);
            setChallengeLanding(currentChallenge.challengeLanding)

            const freePlansOptionsArray = currentChallenge.freeToJoinPlans?currentChallenge.freeToJoinPlans.map((item)=>{return ({name:item.planName,id:item.planId})}):[]
            setFreeToJoinPlans(freePlansOptionsArray)

            const payPlansOptionsArray = currentChallenge.payToJoinPlans?currentChallenge.payToJoinPlans.map((item)=>{return ({name:item.planName,id:item.planId})}):[]
            setPayToJoinPlans(payPlansOptionsArray)

            if(currentChallenge)
            {
                let dataObj = [];
                if(currentChallenge.challengeTask){
                    dataObj = Object.entries(currentChallenge.challengeTask)
                }

                const newcTasks = (dataObj.length !== 0)?dataObj.map(([key, value]) => value):[]
                dispatch(alterChallengeTask(newcTasks))
            }
        }
    }, [dispatch, challengeId, currentChallenge])

    
    useEffect(() => {dispatch(getPlans())}, [dispatch]);
    useEffect(()=>{},[duration])
    const plans = useSelector((state)=>planSelector(state))
    
    useEffect(()=>{
        const plansOptionsArray = plans?plans.map((item)=>{return({name:item.planName,id:item.planId})}):[]
        setApplicablePlans(plansOptionsArray)
    },[plans])

    const handleChallengeLanding = (landingUrl, fileMap) => {
        console.log(landingUrl)
        setFileMap(fileMap)
        setChallengeLanding(landingUrl);
    }


    const onFileChange = (event, imageType) => {
        if(imageType === "Challenge Hero")
        {
            setChallengeHero(event.target.files[0])
            dispatch(getChallengeHeroPresignedUrl(event.target.files[0].type))
        }
        else{
            setChallengeThumbnail(event.target.files[0])
            dispatch(getChallengeThumbnailPresignedUrl(event.target.files[0].type))
        }
        
    }

    const thumbnailUploadConfigs = useSelector(state=>challengeThumbnailPresignedUrlSelector(state))
    const heroUploadConfigs = useSelector(state=>challengeHeroPresignedUrlSelector(state))

    const challengeThumbnailUploadFile = async () => {
        await axios.put(thumbnailUploadConfigs.url,challengeThumbnail,{
            headers:{
               'Content-Type':challengeThumbnail.type
            }
        }).then((val)=>{
           setChallengeThumbnailUploadUrl(`https://d1caq8coktrxsi.cloudfront.net/${thumbnailUploadConfigs.key}`)
           Alert.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Thumbnail Uploaded',
            showConfirmButton: false,
            timer: 1500
          })
           
        }).catch((err)=>{
           Alert.fire({
               position: 'top-end',
               icon: 'error',
               title: 'Image Upload Failed',
               showConfirmButton: false,
               timer: 1500
             })
        })
    }

    const challengeHeroUploadFile = async () => {
        await axios.put(heroUploadConfigs.url,challengeHero,{
            headers:{
               'Content-Type':challengeHero.type
            }
        }).then((val)=>{
           setChallengeHeroUploadUrl(`https://d1caq8coktrxsi.cloudfront.net/${heroUploadConfigs.key}`)
           Alert.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Hero Uploaded',
            showConfirmButton: false,
            timer: 1500
          })
           
        }).catch((err)=>{
           Alert.fire({
               position: 'top-end',
               icon: 'error',
               title: 'Image Upload Failed',
               showConfirmButton: false,
               timer: 1500
             })
        })
    }

    const [filterChallengeLanding, setFilterChallengeLanding] = useState([])

    const handleChallengeLandingPresignedUrl = () => {
        const filter = [];

        challengeLanding.forEach((item, itemIndex) => {
            if(Array.isArray(item))
            {
                item.forEach((subItem, subItemIndex) => {
                    if(typeof subItem !== "string")
                    {
                        filter.push({...subItem, index: [itemIndex, subItemIndex]})
                    }
                })
            }
            else{
                if(typeof item !== "string")
                {
                    filter.push({...item, index: [itemIndex, 0]})
                }
            }
        });

        setFilterChallengeLanding(filter)
        console.log(challengeLanding, filter)
        dispatch(getChallengeLandingPresignedUrl(JSON.stringify(filter)))
    }

    const landingUploadConfig = useSelector((state) => challengeLandingPresignedUrlSelector(state))
    console.log(filterChallengeLanding)

    const challengeLandingUploadFile = async () => {

        const uploadUrl = []

        if(filterChallengeLanding.length > 0)
        {
            for(const item of landingUploadConfig) {
                console.log(item)
                
                await axios.put(item.url, fileMap.get(item.fileName), {
                    headers:{
                        'Content-Type':item.fileType
                    }
                }).then((val)=>{
                    if(Array.isArray(challengeLanding[item.index[0]]))
                    {
                        challengeLanding[item.index[0]][item.index[1]] = `https://d1caq8coktrxsi.cloudfront.net/${item.key}`
                    }
                    else{
                        challengeLanding[item.index[0]] = `https://d1caq8coktrxsi.cloudfront.net/${item.key}`
                    }
                    uploadUrl.push(`https://d1caq8coktrxsi.cloudfront.net/${item.key}`)
                }).catch((err)=>{
                        Alert.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'Image Upload Failed',
                            showConfirmButton: false,
                            timer: 1500
                        })
                })
            }
        }

        await Promise.all(challengeLanding);
        console.log(challengeLanding)

        return challengeLanding
    }


    if(startDate!=='' && endDate !==''){
        const start = moment(startDate)
        const end = moment(endDate)
        duration = end.diff(start,'days') + 1
        const newcTasks = []
       if(cTasks.length>duration){
            for(let i=0; i<duration; i+=1){
                newcTasks.push(cTasks[i])
            }
            if(newcTasks.length!==0){
                dispatch(alterChallengeTask([...newcTasks]))
            }
        }
        else{
            for(let i=0; i<duration-cTasks.length;i+=1){
                newcTasks.push([])
            }
            if(newcTasks.length!==0){
                dispatch(alterChallengeTask([...cTasks,...newcTasks]))
            }
        }
    }

    const handleSubmit = async () => {
        if(challengeName===''){
            setValidateChallengeName(false)
        }
        if(startDate ===''){
            setValidateStartDate(false)
        }
        if(endDate === ''){
            setValidateEndDate(false)
        }
        if(payToJoinPlans.length===0){
            setValidatePayToJoinPlans(false)
        }
        if(freeToJoinPlans.length===0){
            setValidateFreeToJoinPlans(false)
        }
        if(challengeThumbnailUploadUrl===''){
            Alert.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Challenge thumbnail not uploaded',
                showConfirmButton: false,
                timer: 1500
              })
            
        }
        else{
            if(challengeId)
            {
                dispatch(updateChallenege({
                    challengeId,
                    challengeName,
                    startDate,
                    endDate,
                    challengeCategory,
                    challengeSubCategory,
                    payToJoinPlans,
                    freeToJoinPlans,
                    thumbnailUrl:challengeThumbnailUploadUrl,
                    heroUrl: challengeHeroUploadUrl,
                    challengeTasks:cTasks,
                    challengeDescription,
                    challengeWhatsappGroup,
                    challengeCurrentCapacity,
                    challengeLanding: await challengeLandingUploadFile()
                }))

            }
            else{
                dispatch(createNewChallenge({
                    challengeName,
                    startDate,
                    endDate,
                    challengeCategory,
                    challengeSubCategory,
                    payToJoinPlans,
                    freeToJoinPlans,
                    thumbnailUrl:challengeThumbnailUploadUrl,
                    heroUrl: challengeHeroUploadUrl,
                    challengeTasks:cTasks,
                    challengeDescription,
                    challengeWhatsappGroup,
                    challengeLanding: await challengeLandingUploadFile()
                }))
            }
            history.push('/challenges')
        }
    }

  return (
    <div>
        <Navbar pageTitle={challengeId?"Update Challenge":"Create New Challenge"}>
            <div className="card">
                <div className="card-body">
                    
                    {/* Create Challenge Form */}
                    <div className="row mx-2">
                        <div className="col-lg-6">

                            {/* Challenge Name */}
                            <div className="row mb-3">
                                <label
                                    htmlFor="challenge name"
                                    className="col-3 col-form-label "
                                >
                                    Challenge Name:
                                </label>
                                <div className="col-9">
                                    <input
                                        type="text"
                                        className="form-control mt-2"
                                        value={challengeName}
                                        onChange={(e) => {
                                            setChallengeName(e.target.value);
                                        }}
                                        onBlur={(e)=>{
                                            if(e.target.value === ''){
                                                setValidateChallengeName(false)
                                            }else{
                                                setValidateChallengeName(true)
                                            }
                                        }}
                                        id="challenge-name"
                                        placeholder="Name for the challenge"
                                        autoComplete="off"
                                    />
                                    {validateChallengeName === false ?(
                                        <div className="text-danger">
                                            Please give challenge a name.
                                        </div>
                                    ):null}
                                </div>
                            </div>

                            {/* Challenge Description */}
                            <div className="row mb-3">
                                <label
                                    htmlFor="challenge description"
                                    className="col-3 col-form-label "
                                >
                                    Challenge Description:
                                </label>
                                <div className="col-9">
                                    <textarea
                                        className="form-control mt-2"
                                        value={challengeDescription}
                                        onChange={(e) => {
                                            setChallengeDescription(e.target.value);
                                        }}
                                        id="challenge-name"
                                        placeholder="Challenge Details"
                                        autoComplete="off"
                                    />
                                </div>
                            </div>

                            {/* Challenge Start Date */}
                            <div className="row mb-3">
                                <label
                                    htmlFor="start"
                                    className="col-3 mt-1"
                                >
                                    Start Date : 
                                </label>
                                <div className="col-9">
                                    <input 
                                        className="form-control" 
                                        id="startDate" 
                                        type="date" 
                                        name="startDate"
                                        value={startDate}
                                        onChange={(e)=>{setStartDate(e.target.value)}}
                                        onBlur={(e)=>{
                                            if(e.target.value === ''){
                                                setValidateStartDate(false)
                                            }else{
                                                setValidateStartDate(true)
                                            }
                                        }}
                                    />
                                    {validateStartDate === false ?(
                                        <div className="text-danger">
                                            Please give a valid start date.
                                        </div>
                                    ):null}
                                </div>
                            </div>


                            {/* Challenge End Date */}
                            <div className="row mb-3">
                                <label
                                    htmlFor="end"
                                    className="col-3 mt-1"
                                >
                                    End Date : 
                                </label>
                                <div className="col-9">
                                    <input 
                                        className="form-control" 
                                        id="date" 
                                        type="date" 
                                        name="date"
                                        value={endDate}
                                        onChange={(e)=>{setEndDate(e.target.value)}}
                                        onBlur={(e)=>{
                                            if(e.target.value === ''){
                                                setValidateEndDate(false)
                                            }else{
                                                setValidateEndDate(true)
                                            }
                                        }}
                                    />
                                    {validateEndDate === false ?(
                                        <div className="text-danger">
                                            Please give a valid end date.
                                        </div>
                                    ):null}
                                </div>
                            </div>

                            {/* Challenge Category */}
                            <div className="row mb-3">
                                <label
                                    htmlFor="challenge-category"
                                    className="col-3 col-form-label"
                                >
                                    Challenge Category:
                                </label>
                                <div className="col-9">
                                    <select
                                        className="form-select"
                                        id="challenge-category"
                                        value={challengeCategory}
                                        onChange={e=>setChallengeCategory(e.target.value)}
                                        placeholder="Select Challenge Category"
                                    >
                                        <option value="" disabled selected>Select Challenge Category</option>
                                        <option>PCOS or PCOD Care</option>
                                        <option>General Wellness</option>
                                        <option>All Users</option>
                                    </select>
                                </div>
                            </div>

                            {/* Challenge Subcategory */}
                            <div className="row mb-3">
                                <label
                                    htmlFor="challenge-subcategory"
                                    className="col-3 col-form-label"
                                >
                                    Challenge Subcategory :
                                </label>
                                <div className="col-9">
                                    <select
                                        className="form-select mt-1"
                                        id="challeneg-subcategory"
                                        value={challengeSubCategory}
                                        onChange={e=>setChallengeSubcategory(e.target.value)}
                                        placeholder="Select Challenge Subcategory"
                                    >
                                        <option value="" disabled selected>Select Challenge Subcategory</option>
                                        <option>Open</option>
                                        <option>Bootcamp</option>
                                        <option>Premium</option>
                                    </select>
                                </div>
                            </div>

                        </div>

                        <div className="col-lg-6">

                        {/* Challenge Whatsapp Group */}                
                        <div className="row mb-3">
                                <label
                                    htmlFor="whatsapp-group"
                                    className="col-3 col-form-label "
                                >
                                    Whatsapp Group:
                                </label>
                                <div className="col-9">
                                    <input
                                        type="text"
                                        className="form-control mt-2"
                                        value={challengeWhatsappGroup}
                                        onChange={(e) => {
                                            setChallengeWhatsappGroup(e.target.value);
                                        }}
                                        id="whatsapp-group"
                                        placeholder="Challenge Whatsapp Group"
                                        autoComplete="off"
                                    />
                                </div>
                            </div>

                        {/* Pay To Join Plans */}
                        <div className="row mb-3">
                                <label
                                    htmlFor="applicable-plans"
                                    className="col-3 col-form-label mt-1"
                                >
                                    Pay to Join Plans: 
                                </label>
                                <div className="col-9 mt-2">
                                    <Multiselect
                                        options={applicablePlans} 
                                        selectedValues={payToJoinPlans} 
                                        onSelect={(selectedList)=>{
                                            setPayToJoinPlans(selectedList)
                                            setValidatePayToJoinPlans(true)
                                            }} 
                                        onRemove={(selectedList)=>{setPayToJoinPlans(selectedList)}} 
                                        displayValue="name"
                                        closeIcon='cancel'
                                        style={{
                                            chips: { 
	                                            background: '#F5735A'
                                            },
                                        }}
                                    />
                                    {validatePayToJoinPlans=== false ?(
                                        <div className="text-danger">
                                            Please select atleast one pay to join plan
                                        </div>
                                    ):null}
                                </div>
                        </div>

                        {/* Free To Join Plans */}
                        <div className="row mb-3">
                                <label
                                    htmlFor="applicable-plans"
                                    className="col-3 col-form-label mt-1"
                                >
                                    Free to Join Plans: 
                                </label>
                                <div className="col-9 mt-2">
                                    <Multiselect
                                        options={applicablePlans} 
                                        selectedValues={freeToJoinPlans} 
                                        onSelect={(selectedList)=>{
                                            setFreeToJoinPlans(selectedList)
                                            setValidateFreeToJoinPlans(true)
                                            }} 
                                        onRemove={(selectedList)=>{setFreeToJoinPlans(selectedList)}} 
                                        displayValue="name"
                                        closeIcon='cancel'
                                        style={{
                                            chips: { 
	                                            background: '#F5735A'
                                            },
                                        }}
                                    />
                                    {validateFreeToJoinPlans=== false ?(
                                        <div className="text-danger">
                                            Please select atleast one free to join plan
                                        </div>
                                    ):null}
                                </div>
                        </div>


                        {/* Challenge Thumbnail */}
                        <div className="row mb-3">
                            <div className='col-5'>
                                <h4>Upload Challenge Thumbail</h4>
                                <img 
                                    src={challengeThumbnail? URL.createObjectURL(challengeThumbnail) : thumbnailSrc} 
                                    alt="profile"
                                    style={{height:'50px',width:'50px'}}    
                                    />
                                
                                <div>
                                
                                    {challengeThumbnail===''?
                                    <>
                                        <p className="mt-1">Choose a challenge thumbnail</p>
                                        <input 
                                            type="file" 
                                            accept="image/*"
                                            onChange={(event)=>{onFileChange(event, "Challenge Thumbnail")}}
                                            style={{display:'block'}}
                                            />
                                    </>
                                    
                                    :<button 
                                        className="btn btn-info btn-rounded mt-3"
                                        onClick={challengeThumbnailUploadFile}
                                        style={{padding:'2px' ,width:'100px'}}
                                    >   
                                        Upload
                                    </button>
                                    }
                                </div>
                            </div> 
                            <div className='col-4'>
                                <h4>Upload Challenge Hero</h4>
                                <img 
                                    src={challengeHero? URL.createObjectURL(challengeHero) : heroSrc} 
                                    alt="profile"
                                    style={{height:'50px',width:'50px'}}    
                                    />
                                
                                <div>
                                
                                    {challengeHero===''?
                                    <>
                                        <p className="mt-1">Choose a challenge hero</p>
                                        <input 
                                            type="file" 
                                            accept="image/*"
                                            onChange={(event)=>{onFileChange(event, "Challenge Hero")}}
                                            style={{display:'block'}}
                                            />
                                    </>
                                    
                                    :<button 
                                        className="btn btn-info btn-rounded mt-3"
                                        onClick={challengeHeroUploadFile}
                                        style={{padding:'2px' ,width:'100px'}}
                                    >   
                                        Upload
                                    </button>
                                    }
                                </div>
                            </div>        
                        </div>

                        </div>

                    </div>

                    {/* Challenge Landing */}
                    <div className="row">
                        <ChallengeLanding 
                            handleChallengeLanding={handleChallengeLanding} 
                            challengeId={challengeId}
                            handleChallengeLandingPresignedUrl={handleChallengeLandingPresignedUrl}
                        />
                    </div>

                    {/* Challenge Task Table */}
                    <div className="row">
                        {duration>0?
                            <TaskTable cTasks={cTasks}/>
                        :null}
                    </div>
                    {/* Create Challenge Button */}
                    <div className="row">
                        <div className="justify-content-center d-flex" style={{marginTop:'1.75rem'}}>
                            <button 
                                type="submit" 
                                className="btn btn-success"
                                onClick={()=>{
                                    handleSubmit()
                                }}
                            >   
                            {
                                challengeId?"Update Challenge":"Create Challenge"
                            }                
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Navbar>
    </div>
  )
}

export default CreateChallenge