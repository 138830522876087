import React,{useEffect} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import { getEventDetails, setEventDetails } from '../../../actions/event'
import { eventDetailsSelector } from '../../../selector/Event'
import Navbar from '../../navbar'
import EventForm from './EventForm'
import moment from 'moment'

const CreateEvent = (props) => {
    const dispatch = useDispatch()
    const eventId = props.match.params.eventId
    const eventDetails = useSelector(state=>eventDetailsSelector(state))
    useEffect(()=>{
        if(eventId){
            dispatch(getEventDetails(eventId))
        }else{
            dispatch(setEventDetails({}))
        }
    },[dispatch,eventId])
    
    return (
        <div>
            <Navbar pageTitle={eventId?"Edit Event":"Create New Event"}>
                <EventForm
                    eventId={eventId}
                    name={eventDetails.eventName?eventDetails.eventName:''}
                    type={eventDetails?eventDetails.offeringTypeName:''}
                    by={eventDetails.userId?`${eventDetails.name} - ${eventDetails.roleName} - ${eventDetails.userId}`:''}
                    byId={eventDetails?eventDetails.userId:''}
                    st={eventDetails?moment(eventDetails.start).format('YYYY-MM-DD'):''}
                    time={eventDetails?moment(eventDetails.start).format('HH:mm'):''}
                    dur={eventDetails?eventDetails.duration:''}
                    joinUrl={eventDetails?eventDetails.joinUrl:''}
                    participants={eventDetails && eventId ?eventDetails.participants:[]}
                    eventDetails={eventDetails}
                    role={eventDetails?eventDetails.roleId:''}
                />
            </Navbar>
        </div>
    )
}

export default CreateEvent;
