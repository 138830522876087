export const TASK = `[TASK]`
export const TASKS = `[TASKS]`
export const C_TASK = `[C_TASK]`

export const TASK_CREATE = `${TASK} CREATE`
export const TASK_UPDATE = `${TASK} UPDATE`
export const C_TASK_ALTER = `${C_TASK} ALTER`

export const TASKS_GET = `${TASKS} GET`
export const TASKS_SET = `${TASKS} SET`

export const TASK_THUMBNAIL_PRESIGNED_URL = `[TASK_THUMBNAIL_PRESIGNED_URL]`

export const TASK_THUMBNAIL_PRESIGNED_URL_GET = `${TASK_THUMBNAIL_PRESIGNED_URL} GET`
export const TASK_THUMBNAIL_PRESIGNED_URL_SET = `${TASK_THUMBNAIL_PRESIGNED_URL} SET`


export const getTaskThumbnailPresignedUrl = (data) => ({
    type:TASK_THUMBNAIL_PRESIGNED_URL_GET,
    payload:data
})

export const setTaskThumbnailPresignedUrl = (data) => ({
    type:TASK_THUMBNAIL_PRESIGNED_URL_SET,
    payload:data
})

export const createNewTask = (data) => ({
    type:TASK_CREATE,
    payload:data
})

export const updateTask = (data) => ({
    type: TASK_UPDATE,
    payload: data
})

export const getAllTasks = () => ({
    type:TASKS_GET,
    payload:{}
})

export const setAllTasks = (data) => ({
    type:TASKS_SET,
    payload:data

})

export const alterChallengeTask = (data) => ({
    type: C_TASK_ALTER,
    payload:data,
})