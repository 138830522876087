import { apiRequest,API_SUCCESS,API_ERROR } from "../../../actions/api"
import { ALL_BOOKINGS, ALL_BOOKINGS_GET, EVENT_INSIGHTS, EVENT_INSIGHTS_GET, MY_BOOKINGS, MY_BOOKINGS_GET, setAllBookings, setEventInsights, setMyBookings, setUserInsights, setPaymentInsights, USER_INSIGHTS, USER_INSIGHTS_GET, PAYMENT_INSIGHTS, PAYMENT_INSIGHTS_GET } from "../../../actions/insights"
import {ROOT_URL} from '../../../config/'

export const insightsMiddleware = ({dispatch}) => next => (action) =>{
    next(action)
    switch(action.type){
        
        case USER_INSIGHTS_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/userInsights`,
                method:'get',
                feature:USER_INSIGHTS
            }))
            break;
        case `${USER_INSIGHTS} ${API_SUCCESS}`:
            dispatch(setUserInsights(action.payload))
            break;
        case `${USER_INSIGHTS} ${API_ERROR}`:
            break;
        
        case EVENT_INSIGHTS_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/eventInsights`,
                method:'get',
                feature:EVENT_INSIGHTS
            }))
            break;
        case `${EVENT_INSIGHTS} ${API_SUCCESS}`:
            dispatch(setEventInsights(action.payload))
            break;
        case `${EVENT_INSIGHTS} ${API_ERROR}`:
            break;

        case PAYMENT_INSIGHTS_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/paymentInsights`,
                method:'get',
                feature:PAYMENT_INSIGHTS
            }))
            break;
        
        case `${PAYMENT_INSIGHTS} ${API_SUCCESS}`:
            dispatch(setPaymentInsights(action.payload))
            break;
        
        case ALL_BOOKINGS_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/allBookings`,
                method:'get',
                feature:ALL_BOOKINGS
            }))
            break;
        case `${ALL_BOOKINGS} ${API_SUCCESS}`:
                dispatch(setAllBookings(action.payload))
            break;
        case `${ALL_BOOKINGS} ${API_ERROR}`:
            break;

        case MY_BOOKINGS_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/myBookings`,
                method:'get',
                feature:MY_BOOKINGS
            }))
            break;
        case `${MY_BOOKINGS} ${API_SUCCESS}`:
                dispatch(setMyBookings(action.payload))
            break;
        case `${MY_BOOKINGS} ${API_ERROR}`:
            break;

        default: break;
    }
}

