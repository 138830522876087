import React,{useEffect} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import Navbar from '../../navbar'
import { Grid, _ } from "gridjs-react";
import { getPlans, setPlanDetails } from '../../../actions/plan';
import { planSelector } from '../../../selector/plan';
import { useHistory } from 'react-router';

const Table = () =>{    
    const dispatch = useDispatch()
    const history = useHistory()
    useEffect(()=>{
        dispatch(getPlans())
        dispatch(setPlanDetails())
    },[dispatch])
    const plans = useSelector((state)=>planSelector(state))
    const data = plans?plans.map((plan)=>[
        plan.planId,
        plan.planName,
        plan.planDuration,
        plan.planCost,
        _(
            plan.planStatus===1?
                <span className={`badge bg-success`}>Active</span>:
                <span className={`badge bg-danger`}>Inactive</span>
        ),
        _(
            <>
                <button
                    className="btn btn-rounded"
                    onClick={()=>{history.push(`/planDetails/${plan.planId}`)}}
                >
                    <i className="dripicons-clipboard text-info"> </i>
                </button>  
                <button
                    className="btn btn-rounded"
                    onClick={()=>{history.push(`/createNewPlan/${plan.planId}`)}}
                >
                    <i className="mdi mdi-pencil text-warning font-20"> </i>
                </button>  
            </>
        )
    ]):[]
    return(
        <div className="col-12">
            <div className="card">
                <div className="card-body">
                    <div className="tab-content">
                        <div
                            className="tab-pane show active"
                            id="basic-datatable-preview"
                        >
                            <div id="basic-datatable-wrapper">
                                <Grid
                                    data={data}
                                    columns={[
                                        "Plan Id",
                                        "Plan Name",
                                        "Plan Duration",
                                        "Plan Cost",
                                        "Plan Status",
                                        "Plan Details"
                                    ]}
                                    search={true}
                                    pagination={{
                                        enabled: true,
                                        limit: 20,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Plans = ()  => {
    return (
        <div>
            <Navbar pageTitle={"Current Plans"}>
                <Table/>
            </Navbar>
        </div>
    )
}

export default Plans
