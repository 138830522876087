/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Navbar from '../navbar';
import { getExpertDetails } from '../../actions/expert';
import { expertDetailsSelector } from '../../selector/expert';
import { useHistory } from 'react-router-dom';

const ExpertBanner = ({userId, expertDetails}) => {

    const history = useHistory()

    return (<div className="row">
                <div className="col-12">
                    <div className="card bg-secondary">
                        <div className="card-body profile-user-box">
                            <div className="row align-items-center">
                                <div
                                    className="col-2 justify-content-center"
                                    style={{ display: "flex" }}
                                >
                                    <div className="avatar-lg">
                                        <img
                                            src={expertDetails.profileImage ? expertDetails.profileImage : "/assets/images/users/avatar-9.jpg"}
                                            alt=""
                                            className="rounded-circle img-thumbnail"
                                        />
                                    </div>
                                </div>
                                <div className="col-10">
                                    <div>
                                        <div className="row">
                                            <div className="col-8">
                                                <h4 className="mt-1 mb-1 text-white">
                                                    {expertDetails.name}
                                                </h4>
                                            </div>
                                            <div className="col-8">
                                                <h4 className="mt-1 mb-1 text-white">
                                                    YOE: {expertDetails.yoe}
                                                </h4>
                                            </div>
                                            <div
                                                className="col-4"
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-end",
                                                    paddingRight: "",
                                                }}
                                            >
                                                <a
                                                    className="btn btn-link"
                                                    
                                                >
                                                    <span 
                                                        className="text-white"
                                                        onClick={()=>{history.push(`/updateExpert/${userId}`)}}
                                                    >
                                                        <i className="mdi mdi-pencil"></i>{" "}
                                                        Edit Expert
                                                    </span>
                                                </a>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-8">
                                                <ul className="mt-0 list-inline text-light">
                                                    <li className="list-inline-item me-3">
                                                        <h5 className="">{expertDetails.spec ? expertDetails.spec : ""}</h5>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div
                                                className="col-4"
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-end",
                                                    paddingRight: "2em",
                                                }}
                                            >
                                                <div>
                                                    <h5 className="mb-1 text-white">
                                                        {expertDetails.location ? expertDetails.location : ""}
                                                    </h5>
                                                    <p className="mb-0 font-13 text-white-50">
                                                        Location
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
}

const ExpertDetails = (props) => {

    const userId = props.match.params.userId;

    const dispatch = useDispatch()

    useEffect(() => {
        if(userId)
        {
            dispatch(getExpertDetails(userId))
        }
    }, [userId,dispatch])

    const expertDetails = useSelector((state) => expertDetailsSelector(state))
    console.log(expertDetails)

    return (
        <Navbar pageTitle="Expert Details">
            <div>
                <ExpertBanner
                    userId={userId}
                    expertDetails={expertDetails}
                />
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="text-start">
                                <h4 className="header-title mt-0 mb-4">
                                    Bio
                                </h4>

                                <div className="row">
                                    <div className="">
                                        <p className="text-muted">
                                            {expertDetails.bio}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="text-start mt-2">
                                <h4 className="header-title mt-0 mb-4">
                                    AOE
                                </h4>

                                <div className="row">
                                    <div className="">
                                        <p className="text-muted">
                                            {expertDetails.aoe}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="text-start mt-2">
                                <h4 className="header-title mt-0 mb-4">
                                    Education
                                </h4>

                                <div className="row">
                                    <div className="">
                                        <p className="text-muted">
                                            {expertDetails.education}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="text-start mt-2">
                                <h4 className="header-title mt-0 mb-4">
                                    Awards
                                </h4>

                                <div className="row">
                                    <div className="">
                                        <p className="text-muted">
                                            {expertDetails.awards}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Navbar>
    )
}

export default ExpertDetails;