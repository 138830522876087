export const profilePhotoPresignedUrlSelector = state => state.presignedUrlsReducer.profilePhoto?state.presignedUrlsReducer.profilePhoto:{}

export const taskThumbnailPresignedUrlSelector = state => state.presignedUrlsReducer.taskThumbnail?state.presignedUrlsReducer.taskThumbnail:''

export const challengeThumbnailPresignedUrlSelector = state => state.presignedUrlsReducer.challengeThumbnail?state.presignedUrlsReducer.challengeThumbnail:'';

export const challengeHeroPresignedUrlSelector = state => state.presignedUrlsReducer.challengeHero?state.presignedUrlsReducer.challengeHero:''

export const challengeLandingPresignedUrlSelector = state => state.presignedUrlsReducer.challengeLanding?state.presignedUrlsReducer.challengeLanding: []

export const labReportPresignedUrlSelector = state => state.presignedUrlsReducer.labReportUrl?state.presignedUrlsReducer.labReportUrl:''

export const mealTemplatePresignedUrlSelector = state => state.presignedUrlsReducer.mealTemplate?state.presignedUrlsReducer.mealTemplate:''

export const labTestThumbnailPresignedUrlSelector = state => state.presignedUrlsReducer.labTestThumbnail?state.presignedUrlsReducer.labTestThumbnail:'';

export const contentPhotoPresignedUrlSelector = state => state.presignedUrlsReducer.contentImage?state.presignedUrlsReducer.contentImage:{}

export const doctorProfilePresignedUrlSelector = state => state.presignedUrlsReducer.doctorProfilePresignedUrl?state.presignedUrlsReducer.doctorProfilePresignedUrl:'';