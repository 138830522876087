import React,{useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { useHistory } from 'react-router'
import Navbar from '../../navbar'
import NewPlanForm from './NewPlanForm'
import PlanBuilder from './PlanBuilder'
import { createPlan, getPlanDetails, updatePlan } from '../../../actions/plan';
import { planDetailsSelector } from '../../../selector/plan'

const CreateNewPlan = (props)  => {

    const planId = props.match.params.planId;

    const [selectedOfferings,setSelectedOfferings] = useState([])
    const [planName,setPlanName] = useState('')
    const [validatePlanName,setValidatePlanName] = useState(true)
    const [planDuration,setPlanDuration] = useState('')
    const [validatePlanDuration,setValidatePlanDuration] = useState(true)
    const [planPrice,setPlanPrice] = useState('')
    const [validatePlanPrice,setValidatePlanPrice] = useState(true)
    const [planStrikeOffPrice, setPlanStrikeOffPrice] = useState('');
    const [waTemplate, setWaTemplate] = useState('');
    const [pauseDay, setPauseDay] = useState('');
    const [category, setCategory] = useState('')
    const [subCategory, setSubCategory] = useState('')

    const dispatch = useDispatch()
    const history  = useHistory()

    useEffect(() => {
        if(planId)
        {
            dispatch(getPlanDetails(planId))
        }
        else{
            setPlanName('')
            setPlanDuration('')
            setPlanPrice('')
            setPlanStrikeOffPrice('')
            setWaTemplate('')
            setCategory('')
            setSubCategory('')
            setPauseDay('')
            setSelectedOfferings([])
        }
    }, [planId,dispatch])


    const planDetails = useSelector((state) => planDetailsSelector(state));
    console.log(planDetails)

    useEffect(() => {
        if(planId && planDetails)
        {
            setPlanName(planDetails.planName)
            setPlanDuration(planDetails.planDuration)
            setPlanPrice(planDetails.planCost)
            setPlanStrikeOffPrice(planDetails.planActualCost)
            setWaTemplate(planDetails.onboardingTemplateWA)
            setCategory(planDetails.planCategory)
            setSubCategory(planDetails.planSubCategory)
            setPauseDay(planDetails.pauseDays)
            setSelectedOfferings(planDetails.offerings)
        }
    }, [planId, planDetails])
    
    const handleCreatePlan = () => {
        if(planName===''){
            setValidatePlanName(false)
        }
        if(planDuration==='' || planDuration === 0){
            setValidatePlanDuration(false)
        }
        if(planPrice === ''){
            setValidatePlanPrice(false)
        }
        else{
            if(planId)
            {
                if(planName!=='' && (planDuration!=='' || planDuration !== 0) && planPrice !== ''){
                    dispatch(updatePlan({
                        planId,
                        planName,
                        planDuration,
                        planPrice,
                        offerings:selectedOfferings,
                        planStrikeOffPrice,
                        waTemplate,
                        pauseDay,
                        category,
                        subCategory
                    }))
                    history.push("/plans")
                }
            }
            else{
                if(planName!=='' && (planDuration!=='' || planDuration !== 0) && planPrice !== ''){
                    dispatch(createPlan({
                        planName,
                        planDuration,
                        planPrice,
                        offerings:selectedOfferings,
                        planStrikeOffPrice,
                        waTemplate,
                        pauseDay,
                        category,
                        subCategory
                    }))
                    history.push("/plans")
                }
            }
            
        }
    }
    return (
        <div>
            <Navbar pageTitle={"Plan Builder"}>
               <div>
                   <NewPlanForm
                       planName={planName}
                       setPlanName={setPlanName}
                       planDuration={planDuration}
                       setPlanDuration={setPlanDuration}
                       planPrice={planPrice}
                       setPlanPrice={setPlanPrice}
                       validatePlanName={validatePlanName}
                       setValidatePlanName={setValidatePlanName}
                       validatePlanDuration={validatePlanDuration}
                       setValidatePlanDuration={setValidatePlanDuration}
                       validatePlanPrice={validatePlanPrice}
                       setValidatePlanPrice={setValidatePlanPrice}
                       planStrikeOffPrice={planStrikeOffPrice}
                       setPlanStrikeOffPrice={setPlanStrikeOffPrice}
                       pauseDay={pauseDay}
                       setPauseDay={setPauseDay}
                       waTemplate={waTemplate}
                       setWaTemplate={setWaTemplate}
                       category={category}
                       setCategory={setCategory}
                       subCategory={subCategory}
                       setSubCategory={setSubCategory}
                   />
                   <PlanBuilder
                        planId={planId}
                       selectedOfferings={selectedOfferings}
                       setSelectedOfferings={setSelectedOfferings}
                       handleCreatePlan={handleCreatePlan}
                   />
               </div> 
            </Navbar>
        </div>
    )
}

export default CreateNewPlan
