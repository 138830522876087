import React,{useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Navbar from '../navbar';
import axios from 'axios';
import Alert from "sweetalert2";
import { getProfilePresignedUrl } from '../../actions/profilePhotoUpload';
import { profilePhotoPresignedUrlSelector } from '../../selector/presignedUrlSelector';
import { createExpert, getSpec, updateExpert, getExpertDetails } from '../../actions/expert';
import { specSelector, expertDetailsSelector } from '../../selector/expert';
import { useHistory } from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';

const CreateExpert = (props) => {

    const userId = props.match.params.userId;

    const dispatch = useDispatch()
    const history = useHistory()

    const [name, setName] = useState('');
    const [bio, setBio] = useState('');
    const [yoe, setYoe] = useState('')
    const [location, setLocation] = useState('')
    const [rate, setRate] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('');
    const [language, setLanguage] = useState('')
    const [education, setEducation] = useState('');
    const [awards, setAwards] = useState('')
    const [spec, setSpec] = useState([]);
    const [aoe, setAoe] = useState('');
    const [patients, setPatients] = useState('');
    const [reviews, setReviews] = useState('');
    const [role, setRole] = useState('')

    const [profileImage,setProfileImage] = useState('')
    const [uploadUrl, setUploadUrl] = useState('');
    const [src, setSrc] = useState('/assets/images/users/avatar.png')

    useEffect(() => {
        dispatch(getSpec());
    }, [dispatch])

    useEffect(() => {
        if(userId)
        {
            dispatch(getExpertDetails(userId))
        }
    }, [userId,dispatch])

    useEffect(() => {
        if(!userId)
        {
            setName('')
            setBio('')
            setYoe('')
            setLocation('')
            setRate('')
            setEmail('')
            setLanguage('')
            setEducation('')
            setAwards('')
            setSpec('')
            setAoe('')
            setPatients('')
            setReviews('')
            setRole('')
            setUploadUrl('')
            setSrc('/assets/images/users/avatar.png')
            setPhone('')
        }
    }, [dispatch, userId])

    const expertDetails = useSelector((state) => expertDetailsSelector(state))
    console.log(expertDetails)

    useEffect(() => {
        if(userId && expertDetails)
        {
            setName(expertDetails.name)
            setBio(expertDetails.bio)
            setYoe(expertDetails.yoe)
            setLocation(expertDetails.location)
            setRate(expertDetails.rate)
            setEmail(expertDetails.email)
            setLanguage(expertDetails.language)
            setEducation(expertDetails.education)
            setAwards(expertDetails.award)
            setAoe(expertDetails.aoe)
            setPatients(expertDetails.patients)
            setReviews(expertDetails.reviews)
            setRole(expertDetails.roleId === "ROLE0005" ? 'Nutrition Coach' : 'Expert Consultant')
            setUploadUrl(expertDetails.profileImage)
            setSrc(expertDetails.profileImage)
            setPhone(expertDetails.phoneNumber)

            setSpec(expertDetails.spec ? expertDetails.spec.split(", ").map((item, idx) => {return {name: item, id: idx}}): [])
        }
    }, [userId, expertDetails])

    const roleOptions = ['Nutrition Coach', 'Expert Consultant']
    const specList = useSelector((state) => specSelector(state))
    const specOptions = specList ? specList.map((item) => {
        return {name: item.specialisationName, id: item.specialisationId}
    }) : [];
    console.log(specOptions)
    
    const onFileChange = (event) => {
        setProfileImage(event.target.files[0])
        dispatch(getProfilePresignedUrl(event.target.files[0].type))
    }
    const uploadConfigs = useSelector(state=>profilePhotoPresignedUrlSelector(state))
    
    
    const uploadFile = async () => {
             await axios.put(uploadConfigs.url,profileImage,{
                 headers:{
                    'Content-Type':profileImage.type
                 }
             }).then((val)=>{
                const url = `https://image-store-admin.s3.amazonaws.com/${uploadConfigs.key}`
                setUploadUrl(url);
                Alert.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Image Uploaded',
                    showConfirmButton: false,
                    timer: 1500
                  })
             }).catch((err)=>{
                Alert.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Image Upload Failed',
                    showConfirmButton: false,
                    timer: 1500
                  })
             })
    }

    const handleSubmit = () => {
        let specialisation = '';

        if(spec)
        {
            spec.forEach((item, idx) => {
                if(idx === 0)
                {
                    specialisation = item.name;
                }
                else{
                    specialisation = item.name + ", " + specialisation;
                }
            });
        }

        if(userId)
        {
            dispatch(updateExpert({
                userId,
                name, 
                bio, 
                yoe, 
                location, 
                rate, 
                email, 
                phone, 
                education, 
                awards, 
                spec: specialisation, 
                aoe, 
                patients, 
                reviews, 
                role, 
                uploadUrl,
                language
            }))
        }
        else{
            dispatch(createExpert(
                {name, 
                bio, 
                yoe, 
                location, 
                rate, 
                email, 
                phone, 
                education, 
                awards, 
                spec: specialisation, 
                aoe, 
                patients, 
                reviews, 
                role, 
                uploadUrl,
                language}
            ))
        }
        history.push('/experts')
    }

    return (
        <Navbar pageTitle={userId ? "Update Expert" : "Create Expert"}>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="row mb-3">
                                        <label
                                            htmlFor="event-name"
                                            className="col-4 col-form-label"
                                        >
                                            Name
                                        </label>
                                        <div className="col-8">
                                            <input
                                                type="text"
                                                className="form-control"        
                                                id="event-name"
                                                placeholder="Expert Name"
                                                autoComplete="off"
                                                value={name}
                                                onChange={(e)=>{
                                                    setName(e.target.value)
                                                }}
                                            />
                                            {/* {validatePlanName === false ?(
                                                    <div className="text-danger">
                                                        Please give plan a name.
                                                    </div>
                                                ):null} */}
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label
                                            htmlFor="event-name"
                                            className="col-4 col-form-label"
                                        >
                                            Bio
                                        </label>
                                        <div className="col-8">
                                            <textarea
                                                className="form-control"        
                                                id="event-name"
                                                placeholder="Bio"
                                                autoComplete="off"
                                                value={bio}
                                                onChange={(e)=>{
                                                    setBio(e.target.value)
                                                }}
                                                
                                            />
                                            {/* {validatePlanDuration === false ?(
                                                    <div className="text-danger">
                                                        Please set duration of plan in days
                                                    </div>
                                                ):null} */}
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label
                                            htmlFor="event-name"
                                            className="col-4 col-form-label"
                                        >
                                            YOE
                                        </label>
                                        <div className="col-8">
                                            <input
                                                type="text"
                                                className="form-control"        
                                                id="event-name"
                                                placeholder="YOE"
                                                autoComplete="off"
                                                value={yoe}
                                                onChange={(e)=>{
                                                    setYoe(e.target.value)
                                                }}
                                            />
                                            {/* {validatePlanPrice === false ?(
                                                    <div className="text-danger">
                                                        Please set the Price of the plan
                                                    </div>
                                                ):null} */}
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label
                                            htmlFor="event-name"
                                            className="col-4 col-form-label"
                                        >
                                            Location
                                        </label>
                                        <div className="col-8">
                                            <input
                                                type="text"
                                                className="form-control"        
                                                id="event-name"
                                                placeholder="Location"
                                                autoComplete="off"
                                                required={true}
                                                value={location}
                                                onChange={(e)=>{
                                                    setLocation(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label
                                            htmlFor="event-name"
                                            className="col-4 col-form-label"
                                        >
                                            Rate
                                        </label>
                                        <div className="col-8">
                                            <input
                                                type="text"
                                                className="form-control"        
                                                id="event-name"
                                                placeholder="Rate"
                                                autoComplete="off"
                                                required={true}
                                                value={rate}
                                                onChange={(e)=>{
                                                    setRate(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label
                                            htmlFor="event-name"
                                            className="col-4 col-form-label"
                                        >
                                            Email
                                        </label>
                                        <div className="col-8">
                                            <input
                                                type="text"
                                                className="form-control"        
                                                id="event-name"
                                                placeholder="Email"
                                                autoComplete="off"
                                                required={true}
                                                value={email}
                                                onChange={(e)=>{
                                                    setEmail(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label
                                            htmlFor="event-name"
                                            className="col-4 col-form-label"
                                        >
                                            Phone
                                        </label>
                                        <div className="col-8">
                                            <input
                                                type="text"
                                                className="form-control"        
                                                id="event-name"
                                                placeholder="Phone"
                                                autoComplete="off"
                                                required={true}
                                                value={phone}
                                                onChange={(e)=>{
                                                    setPhone(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row mb-3">
                                            <label
                                                htmlFor="event-name"
                                                className="col-4 col-form-label"
                                            >
                                                Language
                                            </label>
                                            <div className="col-8">
                                                <input
                                                    type="text"
                                                    className="form-control"        
                                                    id="event-name"
                                                    placeholder="Language"
                                                    autoComplete="off"
                                                    required
                                                    value={language}
                                                    onChange={(e)=>{
                                                        setLanguage(e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label
                                                htmlFor="event-name"
                                                className="col-4 col-form-label"
                                            >
                                                Education
                                            </label>
                                            <div className="col-8">
                                                <input
                                                    type="text"
                                                    className="form-control"        
                                                    id="event-name"
                                                    placeholder="Education"
                                                    autoComplete="off"
                                                    required
                                                    value={education}
                                                    onChange={(e)=>{
                                                        setEducation(e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label
                                                htmlFor="event-name"
                                                className="col-4 col-form-label"
                                            >
                                                Awards
                                            </label>
                                            <div className="col-8">
                                                <input
                                                    type="text"
                                                    className="form-control"        
                                                    id="event-name"
                                                    placeholder="Awards"
                                                    autoComplete="off"
                                                    required
                                                    value={awards}
                                                    onChange={(e)=>{
                                                        setAwards(e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label
                                                htmlFor="event-name"
                                                className="col-4 col-form-label"
                                            >
                                                Spec
                                            </label>
                                            <div className="col-8">
                                                <Multiselect
                                                    options={specOptions ? specOptions : []} 
                                                    selectedValues={spec} 
                                                    onSelect={(selectedList)=>{
                                                        setSpec(selectedList)
                                                        }} 
                                                    onRemove={(selectedList)=>{setSpec(selectedList)}} 
                                                    displayValue="name"
                                                    closeIcon='cancel'
                                                    style={{
                                                        chips: { 
                                                            background: '#F5735A'
                                                        },
                                                    }}
                                                />
                                                {/* <select
                                                    className="form-select disabledbutton"
                                                    id="event-name"
                                                    required
                                                    value={spec}
                                                    onChange={e=> setSpec(e.target.value)}
                                                >
                                                    <option value="" disabled selected>Select Spec</option>
                                                    {
                                                        specOptions ? specOptions.map((item) => {
                                                            return <option key={item.specialisationId}>{item.specialisationName}</option>
                                                       }): null
                                                    }
                                                </select> */}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label
                                                htmlFor="event-name"
                                                className="col-4 col-form-label"
                                            >
                                                AOE
                                            </label>
                                            <div className="col-8">
                                                <input
                                                    type="text"
                                                    className="form-control"        
                                                    id="event-name"
                                                    placeholder="AOE"
                                                    autoComplete="off"
                                                    required
                                                    value={aoe}
                                                    onChange={(e)=>{
                                                        setAoe(e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label
                                                htmlFor="event-name"
                                                className="col-4 col-form-label"
                                            >
                                                Patients
                                            </label>
                                            <div className="col-8">
                                                <input
                                                    type="text"
                                                    className="form-control"        
                                                    id="event-name"
                                                    placeholder="Patients"
                                                    autoComplete="off"
                                                    required
                                                    value={patients}
                                                    onChange={(e)=>{
                                                        setPatients(e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label
                                                htmlFor="event-name"
                                                className="col-4 col-form-label"
                                            >
                                                Reviews
                                            </label>
                                            <div className="col-8">
                                                <input
                                                    type="text"
                                                    className="form-control"        
                                                    id="event-name"
                                                    placeholder="Reviews"
                                                    autoComplete="off"
                                                    required
                                                    value={reviews}
                                                    onChange={(e)=>{
                                                        setReviews(e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label
                                                htmlFor="event-name"
                                                className="col-4 col-form-label"
                                            >
                                                Role
                                            </label>
                                            <div className="col-8">
                                                <select
                                                    className="form-select disabledbutton"
                                                    id="event-name"
                                                    value={role}
                                                    onChange={e=>setRole(e.target.value)}
                                                >
                                                    <option value="" disabled selected>Select Role</option>
                                                    {
                                                       roleOptions.map((item) => {
                                                        return <option>{item}</option>
                                                       })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label
                                                htmlFor="event-name"
                                                className="col-4 col-form-label"
                                            >
                                                Profile Image
                                            </label>
                                            
                                            <div className="col-4">
                                                
                                                {profileImage===''?
                                                <>
                                                    <h5>Choose a profile photo</h5>
                                                    <input 
                                                        type="file" 
                                                        accept="image/*"
                                                        onChange={(event)=>{onFileChange(event)}}
                                                        style={{display:'block'}}
                                                        />
                                                </>
                                                
                                                :<button 
                                                    className="btn btn-success mt-3" 
                                                    style={{marginLeft:'50px'}}
                                                    onClick={() => uploadFile()}
                                                >   
                                                    Upload
                                                </button>
                                                }
                                            </div>
                                            <div className='ml-2 col-4'>
                                                <img 
                                                    src={profileImage? URL.createObjectURL(profileImage) : src} 
                                                    alt="profile"
                                                    style={{height:'70px',width:'70px'}}    
                                                    />
                                                </div>
                                        </div>
                                </div>
                                <div className="row">
                                <div className="justify-content-center d-flex">
                                    <div>&nbsp;</div>
                                    <button
                                        type="submit"
                                        className="btn btn-success"
                                        onClick={() => handleSubmit()}
                                    >
                                        {userId ? "Update Expert" : "Create Expert"}
                                    </button>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </Navbar>  
    )
}

export default CreateExpert;