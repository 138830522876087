import { EXPERTS_SET, SPEC_SET, EXPERT_DETAILS_SET } from "../../actions/expert";
const initState = []

export const expertReducer = (state=initState,action) => {
    switch(action.type)
    {
        case EXPERTS_SET:
            return {...state, allExperts: action.payload};
        case SPEC_SET:
            return {...state, specList: action.payload};
        case EXPERT_DETAILS_SET:
            return {...state, expertDetails: action.payload}
        default: return state
    }
}