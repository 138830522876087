import {
    TOPICS_GET,
    TOPICS_SET,
    TOPICS_SET_ERROR,

    CONTENT_ITEMS_GET,
    CONTENT_ITEMS_SET,
    CONTENT_ITEMS_SET_ERROR,

    TOPICS_CREATE,
    TOPICS_UPDATE,
    TOPICS_DELTE,

    CONTENT_CREATE_REQ,
    CONTENT_UPDATE_REQ,
    CONTENT_DELETE_REQ,
    } from "../../actions/content"

const initState = []
export const contentReducer = (state=initState,action) =>{
    switch(action.type){

        //Reducer for content category
        case TOPICS_UPDATE:
        case TOPICS_DELTE:
        case TOPICS_GET:
        case TOPICS_CREATE:
            const ccg_currentData = state.contentData;
            const ccg_newData = {...ccg_currentData, topics: {fetching: true, data: null}}
            return {...state,contentData: ccg_newData}
        case TOPICS_SET:
            const ccs_currentData = state.contentData;
            const ccs_newData = {...ccs_currentData, topics: {fetching: false, data: action.payload}}
            return {...state,contentData: ccs_newData}
        case TOPICS_SET_ERROR:
            const cce_currentData = state.contentData;
            const cce_newData = { ...cce_currentData, topics: {fetching: false, data: action.payload, error: true}}
            return {...state,contentData: cce_newData}
        
        

        //Reducer for content items
        case CONTENT_CREATE_REQ:
        case CONTENT_UPDATE_REQ:
        case CONTENT_DELETE_REQ:
        case CONTENT_ITEMS_GET:
            const cig_currentData = state.contentData;
            const cig_newData = {...cig_currentData, contentItems: {fetching: true, data: null}}
            return {...state,contentData: cig_newData}
        case CONTENT_ITEMS_SET:
            const cis_currentData = state.contentData;
            const cis_newData = {...cis_currentData, contentItems: {fetching: false, data: action.payload}}
            return {...state,contentData: cis_newData}
        case CONTENT_ITEMS_SET_ERROR:
            const cie_currentData = state.contentData;
            const cie_newData = {...cie_currentData, contentItems: {fetching: false, data: action.payload, error: true}}
            return {...state,contentData: cie_newData}


        default: return state
    }
}

