import React,{useState,useEffect} from 'react'
import {useSelector,useDispatch} from 'react-redux'
import { planSelector } from '../../../selector/plan';
import { createCoupons } from '../../../actions/promoCodes';
import { useHistory } from 'react-router';
import Multiselect from 'multiselect-react-dropdown';
import { getPlans } from '../../../actions/plan';
import { getAllSKUs } from '../../../actions/SKU';
import { SKUSelector } from '../../../selector/SKU';
import moment from 'moment';

const CouponsForm = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const [offerName,setOfferName] = useState("");
    const [validateOfferName,setValidateOfferName] = useState(true)

    const [promoCode, setPromoCode] = useState("")
    const [validatePromoCode, setValidatePromoCode] = useState(true)

    const [type,setType] = useState("")
    const [validateType,setValidateType] = useState(true)

    const [discount,setDiscount] = useState("")
    const [validateDiscount,setValidateDiscount] = useState(true)

    const [maxUsage,setMaxUsage] = useState("")
    const [validateMaxUsage,setValidateMaxUsage] = useState(true)
    
    const [startDate, setStartDate] = useState("")
    const [validateStartDate,setValidateStartDate] = useState(true)
    
    const [endDate, setEndDate] = useState("")
    const [validateEndDate,setValidateEndDate] = useState(true)

    const [maxAmount,setMaxAmount] = useState("")

    const [description,setDescription] = useState("")
    const [validateDescription,setValidateDescription] = useState(true)

    const [allSkus,setAllSkus] = useState([])

    const [applicableSkus,setApplicableSkus] = useState([])
    const [selectAllSkus, setSelectAllSkus] = useState(false)

    // eslint-disable-next-line no-unused-vars
    const [validateApplicableSkus,setValidateApplicableSkus] = useState(true)
    
    useEffect(() => {
        dispatch(getAllSKUs())
    }, [dispatch]);

    const skus = useSelector((state)=> SKUSelector(state))
    
    useEffect(()=>{
        const skuOptionsArray = skus?skus.map((item)=>{return({name:`${item.skuName} - ${item.skuId}`,id:item.skuId})}):[]
        setAllSkus(skuOptionsArray)
    },[skus])


    useEffect(() => {
        if(selectAllSkus)
        {
            setApplicableSkus(allSkus)
        }
        else{
            setApplicableSkus([])
        }
    }, [selectAllSkus,allSkus]);

    const handleSubmit = () => {
        dispatch(createCoupons({
            offerName,
            promoCode,
            type,
            discount: parseFloat(discount),
            maxUsage: parseInt(maxUsage,10),
            startDate: moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
            maxAmount: parseInt(maxAmount,10),
            description,
            applicableSkus,
        }))
        history.push('/coupons')
    }
    
    useEffect(() => {   
        const script = document.createElement("script");
        script.src = "/assets/js/advancedForm.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);

    return (
        <div className="row justify-content-center">
            <div className="col-10">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-1"></div>
                            <div className="col-lg-10">
                                {/* Offer Name field */}

                                <div className="row mb-3">
                                    <label
                                        htmlFor="offer-name"
                                        className="col-2 col-form-label"
                                    >
                                        Offer Name: 
                                    </label>
                                    <div className="col-9">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={offerName}
                                            onChange={(e) => {
                                                setOfferName(e.target.value);
                                            }}
                                            onBlur={(e)=>{
                                                if(e.target.value === ''){
                                                    setValidateOfferName(false)
                                                }else{
                                                    setValidateOfferName(true)
                                                }
                                            }}
                                            id="offer-name"
                                            placeholder="Offer Name"
                                            autoComplete="off"
                                        />
                                        {validateOfferName === false ?(
                                            <div className="text-danger">
                                                Please give a offer name.
                                            </div>
                                        ):null}
                                    </div>
                                </div>

                                {/* Promo Code field */}

                                <div className="row mb-3">
                                    <label
                                        htmlFor="prefix"
                                        className="col-2 col-form-label"
                                    >
                                        Promo Code: 
                                    </label>
                                    <div className="col-9">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={promoCode}
                                            onChange={(e) => {
                                                setPromoCode(e.target.value);
                                            }}
                                            onBlur={(e)=>{
                                                if(e.target.value === ''){
                                                    setValidatePromoCode(false)
                                                }else{
                                                    setValidatePromoCode(true)
                                                }
                                            }}
                                            id="promoCode"
                                            placeholder="Set Promo Code"
                                            autoComplete="off"
                                        />
                                        {validatePromoCode === false ?(
                                            <div className="text-danger">
                                                Please set coupon promocode
                                            </div>
                                        ):null}
                                    </div>
                                </div>

                                {/* Type Select */}
                                <div className="row mb-3">
                                    <label
                                        htmlFor="type"
                                        className="col-2 col-form-label"
                                    >
                                        Type:
                                    </label>
                                    <div className="col-9">
                                        <select
                                                className="form-select"
                                                id="type"
                                                value={type}
                                                onChange={e=>setType(e.target.value)}
                                                placeholder="Select Voucher Type"
                                                onBlur={(e)=>{
                                                    if(e.target.value === 'Select Event Type'){
                                                        setValidateType(false)
                                                    }else{
                                                        setValidateType(true)
                                                    }
                                                }}
                                            >
                                                <option value="" disabled selected>Select Event Type</option>
                                                <option>Discount</option>
                                                <option>Flat</option>
                                            </select>
                                            {
                                                validateType === false ? (
                                                <div className="text-danger">
                                                    Please select event type
                                                </div>
                                                ) : null
                                            }
                                    </div>
                                </div>

                                {/* Discount Amount / Percentage Field */}
                                <div className="row mb-3">
                                    <label
                                        htmlFor="discount"
                                        className="col-2 col-form-label"
                                    >
                                        {type==='Flat'?'Amount: ':'Percentage %: '}
                                    </label>
                                    <div className="col-9">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={discount}
                                            onChange={(e) => {
                                                setDiscount(e.target.value);
                                            }}
                                            onBlur={(e)=>{
                                                if(e.target.value === ''){
                                                    setValidateDiscount(false)
                                                }else{
                                                    setValidateDiscount(true)
                                                }
                                            }}
                                            id="discount"
                                            placeholder="Discount"
                                            autoComplete="off"
                                        />
                                        {validateDiscount === false ?(
                                            <div className="text-danger">
                                                Please set voucher prefix
                                            </div>
                                        ):null}
                                    </div>
                                </div>

                                {/* Max Usage field */}
                                <div className="row mb-3">
                                    <label
                                        htmlFor="prefix"
                                        className="col-2 col-form-label"
                                    >
                                        Max Usage: 
                                    </label>
                                    <div className="col-9">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={maxUsage}
                                            onChange={(e) => {
                                                setMaxUsage(e.target.value);
                                            }}
                                            onBlur={(e)=>{
                                                if(e.target.value === ''){
                                                    setValidateMaxUsage(false)
                                                }else{
                                                    setValidateMaxUsage(true)
                                                }
                                            }}
                                            id="maxUsage"
                                            placeholder="Number of times coupon can be used"
                                            autoComplete="off"
                                        />
                                        {validateMaxUsage === false ?(
                                            <div className="text-danger">
                                                Please set max usage
                                            </div>
                                        ):null}
                                    </div>
                                </div>

                                {/* Start Date Field */}
                                <div className="row mb-3">
                                    <label
                                        htmlFor="prefix"
                                        className="col-2 col-form-label"
                                    >
                                        Start Date: 
                                    </label>
                                    <div className="col-9">
                                        <input
                                            type="datetime-local"
                                            className="form-control"
                                            value={startDate}
                                            onChange={(e) => {
                                                setStartDate(e.target.value);
                                            }}
                                            onBlur={(e)=>{
                                                if(e.target.value === ''){
                                                    setValidateStartDate(false)
                                                }else{
                                                    setValidateStartDate(true)
                                                }
                                            }}
                                            id="startDate"
                                            placeholder="Start Date"
                                            autoComplete="off"
                                        />
                                        {validateStartDate === false ?(
                                            <div className="text-danger">
                                                Please set coupon's Start Date
                                            </div>
                                        ):null}
                                    </div>
                                </div>

                                {/* End Date Field  */}
                                <div className="row mb-3">
                                    <label
                                        htmlFor="prefix"
                                        className="col-2 col-form-label"
                                    >
                                        End Date: 
                                    </label>
                                    <div className="col-9">
                                        <input
                                            type="datetime-local"
                                            className="form-control"
                                            value={endDate}
                                            onChange={(e) => {
                                                setEndDate(e.target.value);
                                            }}
                                            onBlur={(e)=>{
                                                if(e.target.value === ''){
                                                    setValidateEndDate(false)
                                                }else{
                                                    setValidateEndDate(true)
                                                }
                                            }}
                                            id="endDate"
                                            placeholder="End Date"
                                            autoComplete="off"
                                        />
                                        {validateEndDate === false ?(
                                            <div className="text-danger">
                                                Please set coupon's End Date 
                                            </div>
                                        ):null}
                                    </div>
                                </div>

                                {/* Max Amount Field */}
                                <div className="row mb-3">
                                    <label
                                        htmlFor="prefix"
                                        className="col-2 col-form-label"
                                    >
                                        Max Amount: 
                                    </label>
                                    <div className="col-9">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={maxAmount}
                                            onChange={(e) => {
                                                setMaxAmount(e.target.value,10);
                                            }}
                                            id="maxAmount"
                                            placeholder="Set Max amount for Voucher"
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>

                                {/* Description Field */}
                                <div className="row mb-3">
                                    <label
                                        htmlFor="prefix"
                                        className="col-2 col-form-label"
                                    >
                                        Description: 
                                    </label>
                                    <div className="col-9">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={description}
                                            onChange={(e) => {
                                                setDescription(e.target.value);
                                            }}
                                            onBlur={(e)=>{
                                                if(e.target.value === ''){
                                                    setValidateDescription(false)
                                                }else{
                                                    setValidateDescription(true)
                                                }
                                            }}
                                            id="description"
                                            placeholder="Description for voucher"
                                            autoComplete="off"
                                        />
                                        {validateDescription === false ?(
                                            <div className="text-danger">
                                                Please set coupon description
                                            </div>
                                        ):null}
                                    </div>
                                </div>

                                {/* Applicable Plans Multi select */}
                                <div className="row mb-1">
                                    <label
                                        htmlFor="prefix"
                                        className="col-2 col-form-label"
                                    >
                                        Applicable SKUs: 
                                    </label>
                                    <div className="col-9">
                                        <Multiselect
                                            options={allSkus}
                                            selectedValues={applicableSkus}
                                            onSelect={(selectedSkus) => {
                                                setApplicableSkus(selectedSkus)
                                                setValidateApplicableSkus(true)
                                            }}
                                            onRemove={(selectedList) => {setApplicableSkus(selectedList)}}
                                            displayValue="name"
                                            closeIcon='cancel'
                                            style={{
                                            chips: { 
	                                            background: '#F5735A'
                                            },
                                        }}
                                        />
                                        <div className='my-1'>
                                            <input 
                                                type="checkbox" 
                                                class="form-check-input" 
                                                checked={selectAllSkus} 
                                                onChange={(e)=> {
                                                    setSelectAllSkus(!selectAllSkus)
                                                }}/>
                                            <label class="form-check-label px-2">Select All</label>
                                        </div>
                                    </div>
                                </div>
                                

                                



                            </div>
                            <div className="row">
                                <div className="justify-content-center d-flex" style={{marginTop:'1.75rem'}}>
                                    <button 
                                        type="submit" 
                                        className="btn btn-success"
                                        onClick={()=>{handleSubmit()}}
                                    >                   
                                        Create Coupons
                                    </button>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>            

    )
}

export default CouponsForm
