import { DOCTORS_SET, DOCTOR_SET, DOCTOR_PATIENTS_SET } from "../../actions/doctorFacingTech"
const initState = []

export const doctorFacingTechReducer = (state=initState,action) =>{
    switch(action.type){
        case DOCTORS_SET:
            return {...state, doctors:action.payload}
        case DOCTOR_SET:
            return {...state, doctorProfileData:action.payload}
        case DOCTOR_PATIENTS_SET:
            return {...state, doctorPatients:action.payload}
        default: return state
    }
}