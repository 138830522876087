import React, {useEffect, useState} from 'react';
import { useSelector } from "react-redux";
import { TopicContentMapSelector } from "../../../selector/topicContent.js"
import { contentTopicsSelector } from "../../../selector/content";


const Social = () => {
  return(
              <div>
                <div className='content_preview__social__action'>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div style={{ marginRight: 16, alignItems: 'center'}}>
                      <img
                        src={"/assets/images/contents/heart.png"}
                        alt="user"
                        width={16}
                         style={{ paddingBottom: 4}}
                    /> 30</div>
                    <div><img
                        src={"/assets/images/contents/message-circle.png"}
                        alt="user"
                        width={16}
                         style={{ paddingBottom: 4}}
                    /> 8</div>
                  </div>
                  <div>
                    <img
                        src={"/assets/images/contents/share-2.png"}
                        alt="user"
                        width={16}
                        style={{ paddingBottom: 4}}
                    /> 33</div>
                </div> 
          </div>
  )
}

const TopicImg = ({thumbnail_url}) => {
  return(
    <img
            src= {thumbnail_url || 'https://image-store-admin.s3.amazonaws.com/UVI0001/6b9646f7-5ee7-4730-bbce-3aaa79d6de37.jpeg'}
            alt="user"
            width={16}
            style={{ width: 36, height: 36, backgroundColor: 'grey', borderRadius: 20}}
          />
  )
}

const PollsOptions = ({polls, selected, active, setActive, description, title}) => {
  return(
    <div className='content_preview_poll__container'>
        <div className='content_preview__title'>{title || ""}</div>
        <div className='content_preview__description'>{description || ""}</div>
        <div className='content_preview_poll__polls'>
          {
            polls.map(i => {
              if(active){
                return(
                  <div
                    className={active === i.name ? 'content_preview_poll__poll_after_selected' : 'content_preview_poll__poll_after'}
                    onClick={() => setActive(i.name)}
                  >
                    <span style={{"zIndex": 10}}>{i.name || 'New option'}</span>
                  </div>
                )
              }
              return(
                <div onClick={() => setActive(i.name)} className='content_preview_poll__poll'>{i.name || 'New option'}</div>
              )
            })
          }
        </div>
        <div className='content_preview_poll__poll_details'>
          <div className='content_preview_poll__poll_detail'>789 Views</div>
          <div className='content_preview_poll__poll_detail'>4  Days</div>
        </div>
    </div>
  )
}

const Poll = ({props, selectedTopicData}) => {
    const [active, setActive] = useState('')

  return(
    <div className='content_preview__container'>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 8, alignItems: 'center'}}>
         <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
          <TopicImg thumbnail_url={(selectedTopicData && selectedTopicData.thumbnail_url) || null}/>
          <div style={{marginLeft: 10}}>{ (selectedTopicData && selectedTopicData.topicName) || 'PCOS'}</div>
         </div>
        
        <div>Follow</div>
      </div>
      {
        props.title || props.description ||  (props.pollOptions && props.pollOptions.length) ? 
        [
          <div style={{"marginTop": "20px"}} />,
          <div>
            {/* {props.heroURL ? <img src={props.heroURL} style={{ height: 200, width: '100%'}} alt="Hero" /> : null} */}
            {/* {props.description ? <div className='content_preview__lable'>No one can see your vote</div> : null} */}
            {props.pollOptions.length === 0 ? null :
              <PollsOptions
                polls={props.pollOptions || []}
                active={active}
                setActive={setActive}
                description={props.description}
                title={props.title}
              />
            }
          </div>,
          props.title ? <Social /> : null,
        ] : 
        <div>
          <div className='content_preview__title'>Start editing to see a preview</div>
        </div>
      }
      
    </div>
  )
}

const Video = ({props, selectedTopicData}) => {
    const [contentURL, setContentURL] = useState(props.contentURL)
    useEffect(()=>{
      setContentURL(props.contentURL)
    }, [props.contentURL])

  return(
    <div className='content_preview__container' >
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 8, alignItems: 'center'}}>
         <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
          <TopicImg thumbnail_url={(selectedTopicData && selectedTopicData.thumbnail_url) || null}/>
          <div style={{marginLeft: 10}}>{ (selectedTopicData && selectedTopicData.topicName) || 'PCOS'}</div>
         </div>
        
        <div>Follow</div>
      </div>
      {
        props.title || props.description ? 
        [
          <div style={{"marginTop": "20px"}} />,
          <div className='border' style={{ borderRadius: 8, paddingTop: 10, paddingBottom: 10, 'box-shadow': '0px 2px 8px rgba(0, 0, 0, 0.08)'}}>
            {
              contentURL && contentURL.includes('.mp4') ? 
                <video key={"previewVideo"} style={{ height: 200, width: '100%'}} width="750" height="500" controls >
                      <source src={contentURL} type="video/mp4"/>
                </video> : <div style={{height: 200, width: '100%', backgroundColor: 'black'}} />
              }
            
          <div style={{ paddingLeft: 10, paddingTop: 10, paddingRight: 10,}}>
            <div className='content_preview__title'>{props.title || ""}</div>
            <div className='content_preview__description'>{props.readTime || "0"} min</div>
            </div>
          </div>,
          props.title ? <Social /> : null,
        ] : 
        <div>
          <div className='content_preview__title'>Start editing to see a preview</div>
        </div>
      }
      
    </div>
  )
}

const Blog = ({props, selectedTopicData}) => {

  const handleClick = () => {
    window.open(props.contentURL);
  }

  return(
    <div className='content_preview__container'>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 8, alignItems: 'center'}}>
         <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
          <TopicImg thumbnail_url={(selectedTopicData && selectedTopicData.thumbnail_url) || null}/>
          <div style={{marginLeft: 10}}>{ (selectedTopicData && selectedTopicData.topicName) || 'PCOS'}</div>
         </div>
        
        <div>Follow</div>
      </div>
      {
        props.title || props.heroURL ? 
        [
          <div style={{"marginTop": "20px"}} />,
          <div className='border' onClick={handleClick} style={{ borderRadius: 8, paddingTop: 0, paddingBottom: 10, overflow: 'hidden', cursor: 'pointer', 'box-shadow': '0px 2px 8px rgba(0, 0, 0, 0.08)'}}>
          {props.heroURL ? <img src={props.heroURL} style={{ height: 200, width: '100%'}} alt="Hero" /> : null}
          <div style={{ paddingLeft: 10, paddingTop: 10, paddingRight: 10,}}>
            <div style={{ 'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'space-between', 'alignItems': 'center'}}>
              <div className='content_preview__title'>{props.title || ""}</div>
              <div className='content_preview__description'>{props.readTime || "0"} min</div>
            </div>
            <div className='content_preview__description'>{props.description || ""}</div>
            </div>
          </div>,
          props.title ? <Social /> : null,
        ] : 
        <div>
          <div className='content_preview__title'>Start editing to see a preview</div>
        </div>
      }
      
    </div>
  )
}

const Post = ({props, selectedTopicData}) => {
  return(
    <div className='content_preview__container'>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 8, alignItems: 'center'}}>
         <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
          <TopicImg thumbnail_url={(selectedTopicData && selectedTopicData.thumbnail_url) || null}/>
          <div style={{marginLeft: 10}}>{ (selectedTopicData && selectedTopicData.topicName) || 'PCOS'}</div>
         </div>
        
        <div>Follow</div>
      </div>
      {
        props.title || props.heroURL ? 
        [
          <div>
          <div style={{ padding: 8}}>
            <div className='content_preview__description'>{props.description || ""}</div>
          </div>
          <div className='border' style={{ borderRadius: 8, overflow: 'hidden', cursor: 'pointer' }}>
          {props.heroURL ? <img src={props.heroURL} style={{ height: 200, width: '100%'}} alt="Hero" /> : null}
          </div>
          <div style={{ paddingLeft: 10, paddingTop: 10, paddingRight: 10,}}>
            {/* <div style={{ 'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'space-between', 'alignItems': 'center'}}>
              <div className='content_preview__title'>{props.title || ""}</div>
              <div className='content_preview__description'>{props.readTime || "0"} min</div>
            </div> */}
            </div>
          </div>,
          props.title ? <Social /> : null,
        ] : 
        <div>
          <div className='content_preview__title'>Start editing to see a preview</div>
        </div>
      }
      
    </div>
  )
}


const ContentPreview = (props) => {
    const [activeTopic, setActiveTopic] = useState([])

  const topicContentMap = useSelector((state) => TopicContentMapSelector(state));
  const topicItem = useSelector((state) => contentTopicsSelector(state));


    useEffect(() => {
    if(topicContentMap.data && topicContentMap.data.map){
        const arr = [];
        topicContentMap.data.map.forEach(i => arr.push(i.topic_id))
        setActiveTopic(arr)
    } else {
      setActiveTopic([])
    }
  },[topicContentMap, topicContentMap.fetching])

  let selectedTopicData = null;
  // eslint-disable-next-line array-callback-return
  topicItem && topicItem.data && topicItem.data.map(i => {
    if(activeTopic && activeTopic.includes(i.topic_id)){
      selectedTopicData= i
    }}
  )
  let component;
  switch(props.contentType){
    case 'poll':
      component = <Poll props={props} selectedTopicData={selectedTopicData} />
      break;
    case 'video':
      component = <Video props={props} selectedTopicData={selectedTopicData} />
      break;
    case 'blog':
      component = <Blog props={props} selectedTopicData={selectedTopicData} />
      break;
    case 'post':
      component = <Post props={props} selectedTopicData={selectedTopicData} />
      break;
    default:
      component = <div>Select a content type</div>
  }

  return component

  
}

export default ContentPreview;