import React from 'react'
import UserRow from './UserRow'

const UserTable = ({users,handleDeleteParticipant,showActions}) => {
    return (
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive">
                                <h4 className="header-title mt-0 mb-2">
                                    Participants
                                </h4>
                            <table className="table table-centered w-100 dt-responsive nowrap">
                                <thead className="thead-light">
                                    <tr>
                                        <th className="all">Name</th>
                                        <th>Phone Number</th>
                                        <th>Plan Name</th>
                                        <th>Primary Concern</th>
                                        {showActions?<th>Actions</th>:null}
                                    </tr>
                                </thead>
                                <tbody>
                                    {users?users.map(
                                        ({userId,firstName,lastName,callingNumber,interestArea,planName})=>{
                                            return(
                                                <UserRow
                                                    userId={userId}
                                                    firstName={firstName}
                                                    lastName={lastName}
                                                    callingNumber={callingNumber}
                                                    planName={planName}
                                                    interestArea={interestArea}
                                                    handleDeleteParticipant={handleDeleteParticipant}
                                                    showActions={showActions}
                                                    key={userId}
                                                />
                                            )
                                        }):null}
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            )
}

export default UserTable
