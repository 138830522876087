import React from 'react'

const NewPlanForm = ({
    planName,
    setPlanName,
    planDuration,
    setPlanDuration,
    planPrice,
    setPlanPrice,
    validatePlanName,
    setValidatePlanName,
    validatePlanDuration,
    setValidatePlanDuration,
    validatePlanPrice,
    setValidatePlanPrice,
    planStrikeOffPrice,
    setPlanStrikeOffPrice,
    pauseDay,
    setPauseDay,
    waTemplate,
    setWaTemplate,
    category,
    setCategory,
    subCategory,
    setSubCategory,
}) => {

    const categoryOptions = ['PCOS', 'General Wellness', 'Lab Test'];
    const subCategoryOptions = ['Long Term', 'Fitness', 'General', 'Bootcamp', 'Diet', 'Basic', 'Advance'];

    return (
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="row mb-3">
                                    <label
                                        htmlFor="event-name"
                                        className="col-4 col-form-label"
                                    >
                                        Plan Name
                                    </label>
                                    <div className="col-8">
                                        <input
                                            type="text"
                                            className="form-control"        
                                            id="event-name"
                                            placeholder="Plan Name"
                                            autoComplete="off"
                                            value={planName}
                                            onChange={(e)=>{
                                                setPlanName(e.target.value)
                                            }}
                                            onBlur={(e)=>{
                                                if(e.target.value === ''){
                                                    setValidatePlanName(false)
                                                }else{
                                                    setValidatePlanName(true)
                                                }
                                            }}
                                        />
                                        {validatePlanName === false ?(
                                                <div className="text-danger">
                                                    Please give plan a name.
                                                </div>
                                            ):null}
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        htmlFor="event-name"
                                        className="col-4 col-form-label"
                                    >
                                        Plan Duration
                                    </label>
                                    <div className="col-8">
                                        <input
                                            type="text"
                                            className="form-control"        
                                            id="event-name"
                                            placeholder="Plan Duration (days)"
                                            autoComplete="off"
                                            value={planDuration}
                                            onChange={(e)=>{
                                                setPlanDuration(e.target.value)
                                            }}
                                            onBlur={(e)=>{
                                                if(e.target.value===''){
                                                    setValidatePlanDuration(false)
                                                }else{
                                                    setValidatePlanDuration(true)
                                                }
                                            }}
                                        />
                                        {validatePlanDuration === false ?(
                                                <div className="text-danger">
                                                    Please set duration of plan in days
                                                </div>
                                            ):null}
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        htmlFor="event-name"
                                        className="col-4 col-form-label"
                                    >
                                        Plan Price
                                    </label>
                                    <div className="col-8">
                                        <input
                                            type="text"
                                            className="form-control"        
                                            id="event-name"
                                            placeholder="Plan Price (INR)"
                                            autoComplete="off"
                                            value={planPrice}
                                            onChange={(e)=>{
                                                setPlanPrice(e.target.value)
                                            }}
                                            onBlur={(e)=>{
                                                if(e.target.value === ''){
                                                    setValidatePlanPrice(false)
                                                }else{
                                                    setValidatePlanPrice(true)
                                                }
                                            }}
                                        />
                                        {validatePlanPrice === false ?(
                                                <div className="text-danger">
                                                    Please set the Price of the plan
                                                </div>
                                            ):null}
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        htmlFor="event-name"
                                        className="col-4 col-form-label"
                                    >
                                        Plan Strike Off Price
                                    </label>
                                    <div className="col-8">
                                        <input
                                            type="text"
                                            className="form-control"        
                                            id="event-name"
                                            placeholder="Plan Strike Off Price (INR)"
                                            autoComplete="off"
                                            required={true}
                                            value={planStrikeOffPrice}
                                            onChange={(e)=>{
                                                setPlanStrikeOffPrice(e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row mb-3">
                                        <label
                                            htmlFor="event-name"
                                            className="col-4 col-form-label"
                                        >
                                            Plan WA Template
                                        </label>
                                        <div className="col-8">
                                            <input
                                                type="text"
                                                className="form-control"        
                                                id="event-name"
                                                placeholder="Plan WA Template"
                                                autoComplete="off"
                                                required
                                                value={waTemplate}
                                                onChange={(e)=>{
                                                    setWaTemplate(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label
                                            htmlFor="event-name"
                                            className="col-4 col-form-label"
                                        >
                                            Plan Pause Days
                                        </label>
                                        <div className="col-8">
                                            <input
                                                type="text"
                                                className="form-control"        
                                                id="event-name"
                                                placeholder="Plan Pause Days"
                                                autoComplete="off"
                                                required
                                                value={[pauseDay]}
                                                onChange={(e)=>{
                                                    setPauseDay(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label
                                            htmlFor="event-name"
                                            className="col-4 col-form-label"
                                        >
                                            Plan Category
                                        </label>
                                        <div className="col-8">
                                            <select
                                                className="form-select disabledbutton"
                                                id="event-name"
                                                required
                                                value={category}
                                                onChange={e=> setCategory(e.target.value)}
                                            >
                                                <option value="" disabled selected>Select Plan Category</option>
                                                {
                                                    categoryOptions.map((item)=>{
                                                        return (<option key={item}>{item}</option>)
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label
                                            htmlFor="event-name"
                                            className="col-4 col-form-label"
                                        >
                                            Plan Sub Category
                                        </label>
                                        <div className="col-8">
                                            <select
                                                className="form-select disabledbutton"
                                                id="event-name"
                                                value={subCategory}
                                                onChange={e=>setSubCategory(e.target.value)}
                                            >
                                                <option value="" disabled selected>Select Plan Sub Category</option>
                                                {
                                                    subCategoryOptions.map((item)=>{
                                                        return (<option key={item}>{item}</option>)
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>    
    )
}

export default NewPlanForm
