import React,{useState,useEffect} from 'react'
import Navbar from '../../navbar'
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import { deleteEvent, getAllEvents,setEventFilter, setFilteredEvents, setDateFilter } from '../../../actions/event';
import { eventFilterSelector, eventsSelector, filteredEventsSelector, dateFilterSelector } from '../../../selector/Event';
import { getOfferingType } from '../../../actions/offering';
import { getProvider } from '../../../actions/provider';
import { offeringTypeSelector } from '../../../selector/offering';
import { providerSelector } from '../../../selector/provider';
import { Grid, _ } from "gridjs-react";
import Alert from "sweetalert2";
import { useHistory } from 'react-router-dom';


const DateFilter = () => {
    const dispatch = useDispatch();
    const dateFilter = useSelector((state) => dateFilterSelector(state))
    const [startDate,setStartDate] = useState(dateFilter.startDate ? dateFilter.startDate : moment());
    const [endDate,setEndDate] = useState(dateFilter.endDate ? dateFilter.endDate : moment());
    const [focusedInput, setFocusedInput] = useState();

    useEffect(()=>{
        dispatch(getAllEvents({startDate,endDate}))
    },[dispatch, startDate, endDate])

    return(
        <div className="col-4 d-flex justify-content-end">
            <DateRangePicker
                startDate={startDate}
                startDateId="start-date"
                endDate={endDate}
                endDateId="end-date"
                onDatesChange={({ startDate, endDate }) => {
                    setStartDate(startDate);
                    setEndDate(endDate);
                    dispatch(setDateFilter({startDate, endDate}))
                }}
                focusedInput={focusedInput}
                onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                displayFormat="DD/MM/YYYY"
                isOutsideRange={() => false}
                minimumNights={0}
            />
        </div>
    )
    
}


const EventsV2 = () =>  {
    const dispatch = useDispatch()
    const filter = useSelector((state) => eventFilterSelector(state));
    const events = useSelector((state=>eventsSelector(state)))

    const filteredEvents = useSelector((state=>filteredEventsSelector(state)))
    
    const [filterBy,setFilterBy] = useState(filter?filter.filterBy:'')
    const [ledBy,setLedBy] = useState(filter?filter.filterValue:'')
    const [eventType,setEventType] = useState(filter?filter.filterValue:'')
    
    const offeringType = useSelector(state=>offeringTypeSelector(state))
    const provider = useSelector(state=>providerSelector(state))

    // console.log("events",events)
    // console.log("offeringType",offeringType)
    // console.log("provider",provider)

    useEffect(()=>{
        dispatch(getOfferingType())
        dispatch(getProvider())
        
        if(filterBy==='Coach Name'){
            const ledById = ledBy?ledBy.split(' ').slice(-1)[0]:''
            if(ledById!==''){
                dispatch(setFilteredEvents(events.filter((event)=>event.ledById===ledById)))
            }
        }
        else if(filterBy==='Event Type'){
            if(eventType){
                dispatch(setFilteredEvents(events.filter((event)=>event.eventType===eventType)))
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dispatch, events.length])

    const handleLeadSelect = (value) =>{
        setLedBy(value)
        const ledById = value.split(' ').slice(-1)[0]
        dispatch(setFilteredEvents(events.filter((event)=>event.ledById===ledById)))
        dispatch(setEventFilter({filterBy: filterBy, filterValue: value}))
    }

    const handleEventTypeSelect = (value) => {
        setEventType(value)
        dispatch(setFilteredEvents(events.filter((event)=>event.eventType===value)))
        dispatch(setEventFilter({filterBy: filterBy, filterValue: value}))
    }

    const eventTypeSelector = (eventType,offeringType) =>{
        return(
            <select
            className="form-select"
            id="led-by"
            value={eventType}
            onChange={e=>handleEventTypeSelect(e.target.value)}
            placeholder="Select Event Type"
        >
            <option value="" disabled selected>Select Event Type</option>
            {offeringType?offeringType.map((offer)=>{
                return(
                    <option 
                        key={offer.offeringTypeName}
                    >
                        {offer.offeringTypeName}
                    </option>
                )
            }):null}
        </select>
        )
    }

    const renderFilterValue = () =>{
        if(filterBy==='Coach Name'){
            return(
                leadBySelector(ledBy,provider)
            )
        }else if(filterBy==='Event Type'){
            return(
                eventTypeSelector(eventType,offeringType)
            )
        }else{
            return null
        }
    }

    const leadBySelector = (ledBy,provider) =>{
        return(
            <select
            className="form-select"
            id="led-by"
            value={ledBy}
            onChange={e=>handleLeadSelect(e.target.value)}
        >
            <option value="" disabled selected>Select a Coach</option>
            {provider?provider.map((item)=>(
                <option 
                    key={item.userId}
                >   {item.name} - {item.roleName} - {item.userId}
                </option>
                )):null}
        </select>
        )
    }

    const EventsTable = ({events}) => {
        const history = useHistory()

        const handleEventDelete = (eventId) => {
            Alert.fire({
                title:'Are you sure you want to delete this event ?',
                showDenyButton:true,
                showConfirmButton:true,
                confirmButtonText:'Go Ahead !',
                denyButtonText:'Nope Wait',
            }).then(({value})=>{
                if(value){
                    dispatch(deleteEvent({eventId}))
                }
            })
        }


        const handleEventDetails = (eventId) => {
            history.push(`/eventDetails/${eventId}`)
        }
        
    
        const data = events?events.map((event)=>[
            event.eventId,
            event.title,
            event.ledBy.split('-')[0],
            event.start,
            event.eventType,
            event.currentCapacity,
            _(
                <div>
                    <div className="row d-flex justify-content-between">
                        <div 
                            className="col-3"
                            onClick={()=>{handleEventDetails(event.eventId)}}
                        >
                            <i className="uil-info-circle text-info font-20"></i>
                        </div>
                        
                        <div 
                            className="col-3"
                            onClick={()=>handleEventDelete(event.eventId)}
                        >
                            <i className="mdi mdi-delete text-danger font-20"></i>
                        </div> 
                        
                        
                        
                        <div className="col-3">
                            <a href={event.startUrl} target="_blank" rel="noreferrer">
                                <i className=" uil-video text-primary font-20"></i>
                            </a>
                        </div>
                    </div>
                </div>
            )
        ]):[]
        return (
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="tab-content">
                            <div
                                className="tab-pane show active"
                                id="basic-datatable-preview"
                            >
                                <div id="basic-datatable-wrapper">
                                    <Grid
                                        data={data}
                                        columns={[
                                            {name:"Event ID",width:100},
                                            {name:"Event Name",width:100},
                                            {name:"Led By",width:100},
                                            {name:"Start Time",width:150},
                                            {name:"Event Type",width:100},
                                            {name:"Bookings",width:100},
                                            {name:"Actions",width:100},
                                        
                                        ]}
                                        search={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <Navbar>

                <div className="row">
                    <div className="col-8">
                        <h3 className="page-title page-title-text">
                            All Events
                        </h3>
                    </div>
                    <DateFilter/>
                </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="text-start">
                                        <div className="row mb-1">
                                            <div className="col-10">
                                                <p className="header-title mt-0 mb-3">
                                                    Apply Filter
                                                </p>
                                            </div>
                                            <div className="col-2">
                                                <button 
                                                className="btn btn-danger w-100"
                                                onClick={()=>{
                                                    setFilterBy('')
                                                    setEventType('')
                                                    setLedBy('')
                                                    dispatch(setFilteredEvents(events))
                                                    dispatch(setEventFilter({}))
                                                }}
                                                >
                                                    Clear All
                                                </button> 
                                            </div>                                
                                        </div>

                                        <div className="row">
                                            <div className="col-6">
                                                <div className="row">
                                                    <div className="col-3">
                                                        <label
                                                            htmlFor="filterBy"
                                                            className="col-form-label"
                                                        >
                                                            Filter By:
                                                        </label>
                                                    </div>
                                                    <div className="col-9">
                                                        <select
                                                            className="form-select"
                                                            id="led-by"
                                                            value={filterBy}
                                                            onChange={e=>setFilterBy(e.target.value)}
                                                        >
                                                            <option value="" disabled selected>Filter Events By</option>
                                                            <option>Coach Name</option>
                                                            <option>Event Type</option>
                                                        </select>
                                                    </div>                                            
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="row">
                                                    <div className="col-3">
                                                        <label
                                                            htmlFor="filterBy"
                                                            className="col-form-label"
                                                        >
                                                            Filter Value:
                                                        </label>
                                                    </div>
                                                    <div className="col-9">
                                                        {renderFilterValue()}
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <div className="row">
                    <EventsTable
                        events = {(ledBy || eventType) ? filteredEvents :events}
                    />
                </div>
            </Navbar>
        </div>
    )
}

export default EventsV2