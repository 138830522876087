import React from "react";

const OtherInfoForm = ({
    setBasicInfoActive,
    setOtherInfoActive,
    setMedicalInfoActive,

    height,
    setHeight,
    weight,
    city,
    setCity,
    state,
    setState,
    setWeight,
    acqChannel,
    setAcqChannel,
}) => {
    return (
        <div>
            <div className="row">
                <div className="col-12">
                    <form className="form-horizontal">
                        <input
                            autocomplete="false"
                            name="hidden"
                            type="text"
                            style={{ display: "none" }}
                        />
                        <div className="row mb-4">
                            <label
                                htmlFor="example-select"
                                className="col-2 col-form-label"
                            >
                                {" "}
                                Acquisition Channel
                            </label>
                            <div className="col-10">
                                <select
                                    className="form-select"
                                    id="example-select"
                                    value={acqChannel}
                                    onChange={e=>setAcqChannel(e.target.value)}
                                >

                                    <option>Instagram</option>
                                    <option>Instagram-Whatsapp</option>
                                    <option>Whatsapp</option>
                                    <option>Landing Page Quiz</option>
                                    <option>Calendly</option>
                                    <option>Setmore</option>
                                    <option>Direct Call</option>
                                </select>
                            </div>
                        </div>


                        <div className="row mb-4">
                            <label
                                htmlFor="simpleInput7"
                                className="col-2 col-form-label"
                            >
                                Height
                            </label>
                            <div className="col-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={height}
                                    onChange={(e) => {
                                        setHeight(e.target.value);
                                    }}
                                    id="simpleInput2"
                                    placeholder="Height"
                                    autoComplete="off"
                                />
                            </div>
                        </div>

                        <div className="row mb-4">
                            <label
                                htmlFor="simpleInput8"
                                className="col-2 col-form-label"
                            >
                                Weight
                            </label>
                            <div className="col-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={weight}
                                    onChange={(e) => {
                                        setWeight(e.target.value);
                                    }}
                                    id="simpleInput1"
                                    placeholder="Weight"
                                    autoComplete="off"
                                />
                            </div>
                        </div>

                        <div className="row mb-4">
                            <label
                                htmlFor="simpleInput6"
                                className="col-2 col-form-label"
                            >
                                City
                            </label>
                            <div className="col-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={city}
                                    onChange={(e) => {
                                        setCity(e.target.value);
                                    }}
                                    id="simpleInput6"
                                    placeholder="City"
                                    autoComplete="off"
                                />
                            </div>
                        </div>

                        <div className="row mb-4">
                            <label
                                htmlFor="simpleInput7"
                                className="col-2 col-form-label"
                            >
                                State
                            </label>
                            <div className="col-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={state}
                                    onChange={(e) => {
                                        setState(e.target.value);
                                    }}
                                    id="simpleInput2"
                                    placeholder="State"
                                    autoComplete="off"
                                />
                            </div>
                        </div>

                        <div className="d-flex justify-content-center row mt-5">
                            <div className="col-12 d-flex justify-content-between">
                                <button
                                    type="button"
                                    className="btn"
                                    style={{ backgroundColor: "#373750" }}
                                    onClick={() => {
                                        setBasicInfoActive("active");
                                        setOtherInfoActive("");
                                        setMedicalInfoActive("");
                                    }}
                                >
                                    <span style={{ color: "#FFF" }}>
                                        <i className="mdi mdi-arrow-left"></i>{" "}
                                        Previous
                                    </span>
                                </button>
                                <button
                                    type="button"
                                    className="btn"
                                    style={{ backgroundColor: "#373750" }}
                                    onClick={() => {
                                        setBasicInfoActive("");
                                        setOtherInfoActive("");
                                        setMedicalInfoActive("active");
                                    }}
                                >
                                    <span style={{ color: "#FFF" }}>
                                        Next{" "}
                                        <i className="mdi mdi-arrow-right"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default OtherInfoForm;
