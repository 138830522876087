import {UNMARKED_EVENT_SET_COUNT } from "../../actions/navbarStats/unmarkedTrials";

const initState = []
export const navbarStatsReducer = (state=initState,action) =>{
    switch(action.type){
        case UNMARKED_EVENT_SET_COUNT:
            return {...state,unmarkedEventCount:action.payload}
        default: return state
    }
}

