import { EVENTS_SET,EVENT_SET, FILTERED_EVENTS_SET, EVENT_FILTER_SET, DATE_FILTER_SET, EVENTS_CALENDAR_SET, FILTERED_CALENDAR_EVENTS_SET} from "../../actions/event"
const initState = []

export const eventReducer = (state=initState,action) =>{
    switch(action.type){
        case EVENTS_SET:
            return {...state,events:action.payload}
        case EVENTS_CALENDAR_SET:
            return { ...state, eventsCalendar:action.payload}
        case FILTERED_EVENTS_SET:
            return {...state,filteredEvents:action.payload}
        case FILTERED_CALENDAR_EVENTS_SET:
            return {...state,filteredCalendarEvents:action.payload}
        case EVENT_SET:
            return {...state, eventDetails:action.payload}
        case EVENT_FILTER_SET:
            return {...state, filter: action.payload}
        case DATE_FILTER_SET:
            return {...state, dateFilter: action.payload}
        default: return state
    }
}

